var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined, CheckOutlined, ClockCircleOutlined, CloseOutlined, DownloadOutlined, EditOutlined, ExclamationCircleOutlined, InfoCircleOutlined, LinkOutlined, MailOutlined, MoreOutlined, } from "@ant-design/icons";
import { Collapse as AntdCollapse, Tag as AntdTag, Button, Col, Divider, Dropdown, Form, Menu, Modal, Row, Space, Spin, Tooltip, Typography, message, } from "antd";
import styled from "styled-components";
import { ApplicationStatuses, DocumentType, IdentType, InviteStatus, RelatedEntityKind, } from "@teylor-tools/Api";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { Axios } from "src/utils/Axios";
import SignatoryDetailsPopover from "./SignatoryDetailsPopover";
const { Text } = Typography;
const { confirm } = Modal;
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${({ theme }) => theme.colorSuccess} !important;
`;
const Collapse = styled(AntdCollapse) `
	background-color: #ffffff;

	.ant-collapse-header {
		padding: 24px !important;
		align-items: center !important;
	}
	.ant-collapse-content > .ant-collapse-content-box {
		padding: 16px 0;
	}
`;
const KycTooltip = styled(Tooltip) `
	position: absolute;
	top: 4px;
`;
const SignatureItemRow = styled.div `
	margin: 0 24px;
	padding-left: 24px;
`;
const Tag = styled(AntdTag) `
	border: none;
`;
const CheckIcon = styled(CheckOutlined) `
	color: ${({ theme }) => theme.colorSuccess};
`;
const CheckCircleIcon = styled(CheckCircleOutlined) `
	color: ${({ theme }) => theme.colorSuccess};
	margin-right: 8px;
`;
const ClockIcon = styled(ClockCircleOutlined) `
	color: rgba(0, 0, 0, 0.25);
	margin-right: 8px;
`;
const InfoIcon = styled(InfoCircleOutlined) `
	color: rgba(0, 0, 0, 0.25);
	margin-left: 8px;
`;
const ActionButtonsWrapper = styled.div `
	display: flex;
	gap: 8px;
	justify-content: flex-end;
`;
const InputUpload = styled.input `
	display: none;
`;
var Status;
(function (Status) {
    Status["NOT_INVITED"] = "not-invited";
    Status["INVITED"] = "invited";
    Status["INVITE_EXPIRED"] = "invite-expired";
    Status["IN_PROGRESS"] = "in-progress";
    Status["COMPLETE"] = "complete";
    Status["ACTION_REQUIRED"] = "action-required";
})(Status || (Status = {}));
const getStatus = (signatory) => {
    if (signatory.invite_status === InviteStatus.NotInvited)
        return Status.NOT_INVITED;
    if (signatory.invite_status === InviteStatus.NotAccepted)
        return Status.INVITED;
    if (signatory.invite_status === InviteStatus.Expired)
        return Status.INVITE_EXPIRED;
    if (signatory.invite_status === InviteStatus.Accepted) {
        const requiredActions = (signatory.kyc_status &&
            Object.keys(signatory.kyc_status).filter((key) => { var _a, _b; return (_b = (_a = signatory.kyc_status) === null || _a === void 0 ? void 0 : _a[key]) === null || _b === void 0 ? void 0 : _b.is_required; })) ||
            [];
        const isCompleted = requiredActions.every((action) => { var _a, _b; return (_b = (_a = signatory.kyc_status) === null || _a === void 0 ? void 0 : _a[action]) === null || _b === void 0 ? void 0 : _b.is_completed; });
        return isCompleted ? Status.COMPLETE : Status.IN_PROGRESS;
    }
    return Status.ACTION_REQUIRED;
};
const getStatusColor = (status) => {
    switch (status) {
        case Status.NOT_INVITED:
            return undefined;
        case Status.INVITED:
            return "gold";
        case Status.INVITE_EXPIRED:
        case Status.ACTION_REQUIRED:
            return "red";
        case Status.IN_PROGRESS:
            return "blue";
        case Status.COMPLETE:
            return "green";
        default:
            return undefined;
    }
};
var Field;
(function (Field) {
    Field["KYC"] = "kyc";
    Field["LOAN_CONTRACT"] = "loan-contract";
    Field["GUARANTOR_CONTRACT"] = "guarantor-contract";
    Field["LOAN_TERMS_AND_CONDITIONS"] = "loan-terms-and-conditions";
})(Field || (Field = {}));
const signatureRequirements = Object.values(Field);
const SignatureView = ({ signatory, isEditable, onUpdate }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { application, updatePendingItems } = useApplication();
    const [isLoading, setIsLoading] = useState(false);
    const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
    const inputRef = useRef(null);
    const [isEdit, setIsEdit] = useState(false);
    const [copiedLink, setCopiedLink] = useState(false);
    const [emailForm] = Form.useForm();
    const [guarantorAgreement, setGuarantorAgreement] = useState();
    const guarantorAgreementUploadRef = useRef(null);
    const isPaidOut = application.status === ApplicationStatuses.PaidOut;
    const getGuarantorAgreement = useCallback(() => {
        Axios.get(`admin/applications/${application.applicationId}/documents`, {
            document_type: DocumentType.GuarantorContractSigned,
            related_entity_kind: RelatedEntityKind.Signatory,
            related_entity_id: signatory.signatory_id,
        })
            .then(({ data }) => {
            setGuarantorAgreement(data.result[0]);
        })
            .catch((err) => {
            void Axios.error(err);
        });
    }, [application.applicationId, signatory.signatory_id]);
    useEffect(() => {
        getGuarantorAgreement();
    }, [getGuarantorAgreement]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (copiedLink)
                setCopiedLink(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [copiedLink]);
    const updateEmail = ({ email }) => {
        setIsLoading(true);
        void Axios.patch(`/admin/signatories/${signatory.signatory_id}`, Object.assign(Object.assign({}, signatory), { email }))
            .then(() => __awaiter(void 0, void 0, void 0, function* () {
            yield onUpdate();
            setIsEdit(false);
        }))
            .catch((error) => {
            void Axios.error(error);
        })
            .finally(() => {
            updatePendingItems();
            setIsLoading(false);
        });
    };
    const inviteSignatory = useCallback((id) => {
        setIsLoading(true);
        Axios.post(`/admin/applications/${application.applicationId}/signatories/${id}/invite`, {
            email: signatory.email,
        })
            .then(() => __awaiter(void 0, void 0, void 0, function* () {
            void message.success(t("application.contract.signatory-invite-success"));
            yield onUpdate();
        }))
            .catch((err) => Axios.error(err))
            .finally(() => {
            setIsLoading(false);
            setIsInviteModalVisible(false);
            setIsEdit(false);
            updatePendingItems();
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [application.applicationId, signatory.email]);
    const cancelInvitation = useCallback(() => {
        setIsLoading(true);
        Axios.delete(`/admin/applications/${application.applicationId}/signatories/${signatory.signatory_id}/invite`)
            .then(() => __awaiter(void 0, void 0, void 0, function* () {
            void message.success(t("application.signatures.invitation-cancel-success"));
            yield onUpdate();
        }))
            .catch((err) => Axios.error(err))
            .finally(() => {
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application.applicationId, signatory.signatory_id]);
    const showCancelInvitationModal = () => {
        confirm({
            title: t("application.signatures.are-you-sure"),
            icon: _jsx(ExclamationCircleOutlinedIcon, {}),
            content: t("application.signatures.cancel-invitation-modal-content"),
            okButtonProps: { danger: true },
            okText: t("application.signatures.cancel-invite"),
            onOk: cancelInvitation,
        });
    };
    const regenerateSignatureLink = () => {
        setIsLoading(true);
        Axios.post(`/admin/signatories_requirements/${signatory.signatory_id}/video_idents`)
            .then(() => __awaiter(void 0, void 0, void 0, function* () {
            void message.success(t("application.contract.signature-link-re-generate-success"));
            yield onUpdate();
        }))
            .catch((err) => Axios.error(err))
            .finally(() => {
            setIsLoading(false);
            updatePendingItems();
        });
    };
    const showRegenerateSignatureLinkModal = () => {
        confirm({
            title: t("application.signatures.are-you-sure"),
            icon: _jsx(ExclamationCircleOutlinedIcon, {}),
            content: t("application.signatures.re-generate-signature-link-modal-content"),
            okButtonProps: { danger: true },
            okText: t("application.contract.re-generate"),
            onOk: regenerateSignatureLink,
        });
    };
    const regenerateDocument = () => {
        setIsLoading(true);
        Axios.post(`/admin/signatories/${signatory.signatory_id}/generate_contract`)
            .then(() => __awaiter(void 0, void 0, void 0, function* () {
            void message.success(t("application.signatures.document-re-generate-success"));
            yield onUpdate();
        }))
            .catch((err) => Axios.error(err))
            .finally(() => {
            setIsLoading(false);
            updatePendingItems();
        });
    };
    const showRegenerateDocumentModal = () => {
        confirm({
            title: t("application.signatures.are-you-sure"),
            icon: _jsx(ExclamationCircleOutlinedIcon, {}),
            content: t("application.signatures.re-generate-document-modal-content"),
            okButtonProps: { danger: true },
            okText: t("application.contract.re-generate"),
            onOk: regenerateDocument,
        });
    };
    const markAsCompleted = (type) => {
        setIsLoading(true);
        Axios.patch(`/admin/signatories_requirements/${signatory.signatory_id}/manually_completed`, {
            ident_type: type,
        })
            .then(() => {
            void message.success(t("application.signatures.marked-as-completed"));
            void onUpdate();
        })
            .catch((err) => Axios.error(err))
            .finally(() => {
            updatePendingItems();
            setIsLoading(false);
        });
    };
    const signatureRequirementsToDisplay = signatureRequirements.filter((field) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        switch (field) {
            case Field.KYC:
                return (_b = (_a = signatory.kyc_status) === null || _a === void 0 ? void 0 : _a.ident_completed) === null || _b === void 0 ? void 0 : _b.is_required;
            case Field.LOAN_CONTRACT:
                return (_d = (_c = signatory.kyc_status) === null || _c === void 0 ? void 0 : _c.loan_signature) === null || _d === void 0 ? void 0 : _d.is_required;
            case Field.GUARANTOR_CONTRACT:
                return (_f = (_e = signatory.kyc_status) === null || _e === void 0 ? void 0 : _e.guarantor_contract_uploaded) === null || _f === void 0 ? void 0 : _f.is_required;
            case Field.LOAN_TERMS_AND_CONDITIONS:
                return (((_h = (_g = signatory.kyc_status) === null || _g === void 0 ? void 0 : _g.sepa_mandate_accepted) === null || _h === void 0 ? void 0 : _h.is_required) ||
                    ((_k = (_j = signatory.kyc_status) === null || _j === void 0 ? void 0 : _j.legal_signature_accepted) === null || _k === void 0 ? void 0 : _k.is_required));
        }
    });
    const isSignatureItemCompleted = (field) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        switch (field) {
            case Field.KYC:
                return !!((_b = (_a = signatory.kyc_status) === null || _a === void 0 ? void 0 : _a.ident_completed) === null || _b === void 0 ? void 0 : _b.is_completed);
            case Field.LOAN_CONTRACT:
                return !!((_d = (_c = signatory.kyc_status) === null || _c === void 0 ? void 0 : _c.loan_signature) === null || _d === void 0 ? void 0 : _d.is_completed);
            case Field.GUARANTOR_CONTRACT:
                return !!((_f = (_e = signatory.kyc_status) === null || _e === void 0 ? void 0 : _e.guarantor_contract_uploaded) === null || _f === void 0 ? void 0 : _f.is_completed);
            case Field.LOAN_TERMS_AND_CONDITIONS:
                return (!!((_h = (_g = signatory.kyc_status) === null || _g === void 0 ? void 0 : _g.sepa_mandate_accepted) === null || _h === void 0 ? void 0 : _h.is_completed) &&
                    !!((_j = signatory.kyc_status.legal_signature_accepted) === null || _j === void 0 ? void 0 : _j.is_completed));
        }
    };
    const getMoreMenuItems = (field) => {
        switch (field) {
            case Field.KYC:
                return [
                    {
                        label: t("application.contract.mark-as-complete"),
                        key: "mark-as-complete",
                        onClick: () => {
                            markAsCompleted(IdentType.Ident);
                        },
                    },
                    {
                        label: t("application.signatures.re-generate-signature-link"),
                        key: "re-generate-signature-link",
                        onClick: () => showRegenerateSignatureLinkModal(),
                    },
                ];
            case Field.LOAN_CONTRACT:
                return [
                    {
                        label: t("application.contract.mark-as-complete"),
                        key: "mark-as-complete",
                        onClick: () => {
                            markAsCompleted(IdentType.Esign);
                        },
                    },
                ];
            case Field.GUARANTOR_CONTRACT:
                return [
                    {
                        label: t("application.contract.upload-signed-document"),
                        key: "upload-signed-document",
                        onClick: () => {
                            var _a;
                            (_a = guarantorAgreementUploadRef === null || guarantorAgreementUploadRef === void 0 ? void 0 : guarantorAgreementUploadRef.current) === null || _a === void 0 ? void 0 : _a.click();
                        },
                    },
                    {
                        label: t("application.signatures.re-generate-document"),
                        key: "re-generate-document",
                        onClick: () => showRegenerateDocumentModal(),
                    },
                ];
        }
    };
    const getUploadInfo = (file) => __awaiter(void 0, void 0, void 0, function* () {
        if (!application.applicationId)
            return;
        const uploadResponse = yield Axios.post(`/admin/applications/${application.applicationId}/documents`, {
            file_name: file.name,
            document_type: DocumentType.GuarantorContractSigned,
            related_entity_kind: RelatedEntityKind.Signatory,
            related_entity_id: signatory.signatory_id,
        });
        return uploadResponse === null || uploadResponse === void 0 ? void 0 : uploadResponse.data;
    });
    const uploadFile = (file, url) => __awaiter(void 0, void 0, void 0, function* () {
        if (!url)
            return;
        yield Axios.put(url, file, {
            headers: {
                "Content-Type": file.type,
                "x-ms-blob-type": "BlockBlob",
            },
        });
    });
    const updateFileStatus = ({ documentId, isSuccessful }) => __awaiter(void 0, void 0, void 0, function* () {
        if (!documentId)
            return;
        yield Axios.patch(`/admin/applications/${application.applicationId}/documents/${documentId}/upload_success`, {
            is_upload_successful: isSuccessful,
        });
    });
    const updateFileVisibility = (documentId) => {
        if (!documentId)
            return;
        return Axios.patch(`/admin/applications/${application.applicationId}/documents/${documentId}/client_visible`, {
            is_client_visible: true,
        });
    };
    const handleUploadGuarantorAgreement = (e) => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        if (!e.target.files)
            return;
        setIsLoading(true);
        const file = e.target.files[0];
        try {
            const uploadInfo = yield getUploadInfo(file);
            const { document_id, upload_url } = uploadInfo || {};
            try {
                yield uploadFile(file, upload_url);
                yield updateFileStatus({
                    documentId: document_id,
                    isSuccessful: true,
                });
                yield updateFileVisibility(document_id);
                void getGuarantorAgreement();
                void onUpdate();
                void message.success(t("application.signatures.file-upload-success"));
            }
            catch (err) {
                yield updateFileStatus({
                    documentId: document_id,
                    isSuccessful: false,
                });
                void Axios.error(err, t("application.signatures.file-upload-fail"));
            }
        }
        catch (err) {
            void Axios.error(err, t("application.signatures.file-upload-fail"));
        }
        finally {
            updatePendingItems();
            setIsLoading(false);
        }
        if ((_c = guarantorAgreementUploadRef === null || guarantorAgreementUploadRef === void 0 ? void 0 : guarantorAgreementUploadRef.current) === null || _c === void 0 ? void 0 : _c.value) {
            guarantorAgreementUploadRef.current.value = "";
        }
    });
    const copyToClipboard = (link) => {
        if (!link)
            return;
        void navigator.clipboard.writeText(link);
    };
    const handleGuarantorContractDownload = (guarantorAgreement) => {
        void Axios.get(`admin/applications/${application.applicationId}/documents/${guarantorAgreement.document_id}/download_url`)
            .then(({ data }) => {
            var _a;
            (_a = window.open(data.download_url, "_blank")) === null || _a === void 0 ? void 0 : _a.focus();
        })
            .catch((err) => void Axios.error(err));
    };
    const status = getStatus(signatory);
    return (_jsxs(_Fragment, { children: [_jsx(Spin, Object.assign({ spinning: isLoading, delay: 500 }, { children: _jsx(Collapse, { children: _jsx(Collapse.Panel, Object.assign({ header: _jsxs(Row, Object.assign({ gutter: 16, align: "middle" }, { children: [_jsx(Col, Object.assign({ span: 8 }, { children: _jsx(SignatoryDetailsPopover, { signatory: signatory }) })), _jsx(Col, Object.assign({ span: 8, style: { textAlign: "center" } }, { children: status && (_jsx(Tag, Object.assign({ color: getStatusColor(status) }, { children: t(`application.contract.status.${status}`) }))) })), isEditable && (_jsxs(Col, Object.assign({ span: 8, style: { textAlign: "right" } }, { children: [status === Status.NOT_INVITED && (_jsx(Button, Object.assign({ type: "primary", icon: _jsx(MailOutlined, {}), onClick: (e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setIsInviteModalVisible(true);
                                            } }, { children: t("application.signatures.invite-to-sign") }))), status && [Status.INVITED, Status.INVITE_EXPIRED].includes(status) && (_jsx(Button, Object.assign({ type: "link", icon: _jsx(MailOutlined, {}), onClick: (e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                inviteSignatory(signatory.signatory_id);
                                            } }, { children: t("application.contract.re-invite") }))), status && [Status.INVITED, Status.IN_PROGRESS].includes(status) && (_jsx(Button, Object.assign({ type: "link", onClick: (e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                showCancelInvitationModal();
                                            } }, { children: t("application.signatures.cancel-invite") })))] })))] })) }, { children: signatureRequirementsToDisplay.map((field, idx) => {
                            var _a, _b;
                            const isCompleted = isSignatureItemCompleted(field);
                            return (_jsxs(_Fragment, { children: [_jsx(SignatureItemRow, { children: _jsxs(Row, Object.assign({ gutter: 16, align: "middle" }, { children: [_jsxs(Col, Object.assign({ span: 8 }, { children: [isCompleted ? _jsx(CheckCircleIcon, {}) : _jsx(ClockIcon, {}), _jsx(Text, Object.assign({ strong: true }, { children: t(`application.signatures.${field}`) }))] })), _jsxs(Col, Object.assign({ span: 8, style: { textAlign: "center" } }, { children: [field === Field.KYC && (_jsxs(_Fragment, { children: [_jsx(Text, { children: ((_a = signatory.requirements) === null || _a === void 0 ? void 0 : _a.signature_internal_status)
                                                                        ? t(`application.signatures.status.${signatory.requirements.signature_internal_status}`)
                                                                        : t("application.contract.not-started") }), ((_b = signatory.requirements) === null || _b === void 0 ? void 0 : _b.signature_provider_status) && (_jsx(KycTooltip, Object.assign({ title: t("application.contract.provider-status", {
                                                                        status: signatory.requirements.signature_provider_status,
                                                                    }) }, { children: _jsx(InfoIcon, {}) })))] })), [
                                                            Field.LOAN_CONTRACT,
                                                            Field.GUARANTOR_CONTRACT,
                                                            Field.LOAN_TERMS_AND_CONDITIONS,
                                                        ].includes(field) &&
                                                            (isCompleted ? (_jsx(Text, { children: t("application.signatures.completed") })) : (_jsx(Text, { children: t("application.contract.not-completed") })))] })), _jsx(Col, Object.assign({ span: 8, style: { textAlign: "right" } }, { children: _jsxs(ActionButtonsWrapper, { children: [field == Field.KYC && (_jsx(Tooltip, Object.assign({ title: copiedLink
                                                                    ? t("application.signatures.copied")
                                                                    : t("application.signatures.copy") }, { children: _jsx(Button, { icon: copiedLink ? _jsx(CheckIcon, {}) : _jsx(LinkOutlined, {}), onClick: () => {
                                                                        var _a;
                                                                        setCopiedLink(true);
                                                                        copyToClipboard((_a = signatory.requirements) === null || _a === void 0 ? void 0 : _a.digital_signature_url);
                                                                    } }) }))), field === Field.GUARANTOR_CONTRACT && guarantorAgreement && (_jsx(Tooltip, Object.assign({ title: t("application.signatures.download") }, { children: _jsx(Button, { icon: _jsx(DownloadOutlined, {}), onClick: () => handleGuarantorContractDownload(guarantorAgreement) }) }))), !isPaidOut && (_jsx(InputUpload, { ref: guarantorAgreementUploadRef, type: "file", onChange: (evt) => {
                                                                    void handleUploadGuarantorAgreement(evt);
                                                                } })), field !== Field.LOAN_TERMS_AND_CONDITIONS && !isPaidOut && (_jsx(Dropdown, Object.assign({ overlay: _jsx(Menu, { items: getMoreMenuItems(field) }) }, { children: _jsx(Button, { icon: _jsx(MoreOutlined, {}) }) })))] }) }))] })) }, field), idx !== signatureRequirementsToDisplay.length - 1 && _jsx(Divider, {})] }));
                        }) }), signatory.signatory_id) }, signatory.signatory_id) })), _jsxs(Modal, Object.assign({ open: isInviteModalVisible, title: t("application.signatures.invite-to-sign-application"), okText: t("application.contract.send-invite"), okButtonProps: {
                    disabled: (!((_b = (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.input) === null || _b === void 0 ? void 0 : _b.value) && !signatory.email) || isEdit,
                }, onOk: () => inviteSignatory(signatory.signatory_id), onCancel: () => {
                    setIsInviteModalVisible(false);
                    setIsEdit(false);
                } }, { children: [_jsx("div", Object.assign({ style: { marginBottom: 12 } }, { children: _jsx(Typography.Text, { children: t("application.signatures.invite-user-by-email", {
                                name: `${signatory === null || signatory === void 0 ? void 0 : signatory.first_name} ${signatory === null || signatory === void 0 ? void 0 : signatory.last_name}`,
                            }) }) })), isEdit ? (_jsx(Form, Object.assign({ form: emailForm, onFinish: updateEmail, preserve: false }, { children: _jsx(EmailFormItem, { label: "", initialValue: signatory.email, rules: [
                                {
                                    required: true,
                                    message: t("application_form.field-required"),
                                },
                            ], inputProps: {
                                addonAfter: (_jsxs(Space, { children: [_jsx(CloseOutlined, { onClick: () => setIsEdit(false), style: {
                                                paddingRight: 8,
                                                borderRight: "1px solid rgba(0,0,0,0.3)",
                                            } }), _jsx(CheckOutlined, { onClick: () => emailForm.submit() })] })),
                                style: { marginTop: -8 },
                            } }) }))) : (_jsxs(Space, Object.assign({ align: "center" }, { children: [_jsx(MailOutlined, { style: { color: "rgba(0,0,0,.45)" } }), _jsx(Typography.Text, Object.assign({ strong: true }, { children: signatory.email })), _jsx(EditOutlined, { onClick: () => {
                                    setIsEdit(true);
                                }, style: { marginLeft: 10 } })] })))] }))] }));
};
export default SignatureView;
