import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import styled from "styled-components";
import { rawDateFormat } from "@teylor-tools/utils/dateFormats";
import Avatar from "@ui/data-display/Avatar";
import { useTaskUsers } from "src/hooks/useTaskUsers";
import { filterSelectByIdAndLabel } from "src/utils/filterSelectByIdAndLabel";
const { Text, Title } = Typography;
const FORM_ITEM_NAME = "form-item";
const PersonFieldLabel = styled(Text) `
	display: flex;
	align-items: center;
	font-size: 12px;
	height: 32px;
`;
export const AssigneeField = ({ name, label, onChange, fieldValue, }) => {
    const { editMode, setEditMode, isEditable, handleChange, form } = useEdit(onChange, fieldValue);
    const [users, usersLoading] = useTaskUsers();
    return (_jsxs(_Fragment, { children: [_jsxs(PersonFieldLabel, Object.assign({ type: "secondary" }, { children: [label, isEditable && (_jsx(EditButtons, { editMode: editMode, setEditMode: setEditMode, onSave: handleChange }))] })), _jsx("div", Object.assign({ style: { height: 32 } }, { children: editMode && onChange ? (_jsx(Form, Object.assign({ form: form, initialValues: { [FORM_ITEM_NAME]: fieldValue } }, { children: _jsx(Form.Item, Object.assign({ name: FORM_ITEM_NAME }, { children: _jsx(Select, { loading: usersLoading, disabled: usersLoading, options: users.map((user) => ({ value: user.id, label: user.name })), filterOption: filterSelectByIdAndLabel, allowClear: true, showSearch: true }) })) }))) : (_jsxs(Row, Object.assign({ gutter: 8, align: "middle" }, { children: [_jsx(Col, { children: _jsx(Avatar, { name: name, size: "small" }) }), _jsx(Col, { children: name })] }))) }))] }));
};
export const ReporterField = ({ name, label }) => {
    return (_jsxs(_Fragment, { children: [_jsx(PersonFieldLabel, Object.assign({ type: "secondary" }, { children: label })), _jsx("div", Object.assign({ style: { height: 32 } }, { children: _jsxs(Row, Object.assign({ gutter: 8, align: "middle" }, { children: [_jsx(Col, { children: _jsx(Avatar, { name: name, size: "small" }) }), _jsx(Col, { children: name })] })) }))] }));
};
export const TaskTextField = ({ label, maxLength, onChange, fieldValue, rows = 2, value, }) => {
    const { editMode, setEditMode, isEditable, handleChange, form } = useEdit(onChange, fieldValue);
    return (_jsxs(_Fragment, { children: [_jsxs(Title, Object.assign({ level: 5 }, { children: [label, isEditable && (_jsx(EditButtons, { editMode: editMode, setEditMode: setEditMode, onSave: handleChange }))] })), editMode ? (_jsx(Form, Object.assign({ form: form, initialValues: { [FORM_ITEM_NAME]: fieldValue } }, { children: _jsx(Form.Item, Object.assign({ name: FORM_ITEM_NAME }, { children: _jsx(TextArea, { maxLength: maxLength, rows: rows }) })) }))) : (_jsx(Text, { children: value || fieldValue || "-" }))] }));
};
export const TaskInfoRow = ({ value, label, fieldValue, onChange, children }) => {
    const { editMode, setEditMode, isEditable, handleChange, form } = useEdit(onChange, fieldValue);
    return (_jsxs(Row, Object.assign({ align: "middle", style: { height: 32 } }, { children: [_jsx(Col, Object.assign({ style: { width: 120 } }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: label })) })), _jsx(Col, Object.assign({ flex: "auto" }, { children: _jsxs("div", Object.assign({ style: { display: "flex", alignItems: "center" } }, { children: [editMode ? (_jsx(Form, Object.assign({ form: form, initialValues: { [FORM_ITEM_NAME]: fieldValue }, style: { flex: 1 } }, { children: _jsx(Form.Item, Object.assign({ name: FORM_ITEM_NAME, style: { margin: 0 } }, { children: children })) }))) : (_jsx("div", { children: value })), isEditable && (_jsx(EditButtons, { editMode: editMode, setEditMode: setEditMode, onSave: handleChange }))] })) }))] })));
};
const EditButtons = ({ editMode, setEditMode, onSave }) => {
    return editMode ? (_jsxs(_Fragment, { children: [_jsx(Button, { type: "link", onClick: onSave, icon: _jsx(CheckOutlined, {}) }), _jsx(Button, { type: "link", onClick: () => setEditMode(false), icon: _jsx(CloseOutlined, {}) })] })) : (_jsx(Button, { type: "link", onClick: () => setEditMode(true), icon: _jsx(EditOutlined, {}) }));
};
const useEdit = (onChange, fieldValue) => {
    const [editMode, setEditMode] = useState(false);
    const [form] = Form.useForm();
    const isEditable = useMemo(() => onChange && fieldValue !== undefined, [onChange, fieldValue]);
    const handleChange = () => {
        const fieldVal = form.getFieldValue(FORM_ITEM_NAME);
        const newVal = dayjs.isDayjs(fieldVal) ? fieldVal.format(rawDateFormat) : fieldVal;
        if (newVal !== fieldValue) {
            onChange && onChange(newVal);
        }
        setEditMode(false);
    };
    return {
        editMode,
        setEditMode,
        isEditable,
        handleChange,
        form,
    };
};
