import { jsx as _jsx } from "react/jsx-runtime";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
const StyledWrapper = styled.div `
	background-color: #f5f5f5;
	position: relative;
	overflow: auto;
	display: flex;
	height: 100%;
`;
const Grow = styled.div `
	flex-grow: 1;
	overflow: auto;
`;
const CompanyOutlet = ({ company, updateCompany }) => {
    return (_jsx(StyledWrapper, { children: _jsx(Grow, { children: _jsx(Outlet, { context: { company, updateCompany } }) }) }));
};
export default CompanyOutlet;
