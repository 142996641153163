import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { generatePath } from "react-router-dom";
import { Routes } from "src/Routes";
import { useCompany } from "src/layouts/CompanyLayout/CompanyTypes";
import { DocumentCategoryWrapper } from "src/pages/modules/Documents/DocumentCategoryWrapper";
import { categorizeDocuments } from "src/pages/modules/Documents/documents.helpers";
import { finCrTypes } from "src/pages/modules/Documents/documents.types";
import { FinCr } from "src/pages/modules/FinCr/FinCr";
import { getIssues, getOcrFilesById } from "src/pages/modules/FinCr/fincr.common.request";
import { Axios } from "src/utils/Axios";
export const CompanyFinCr = () => {
    const { t } = useTranslation();
    const { company } = useCompany();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [categorizedDocs, setCategorizedDocs] = useState([]);
    const handleNavigateTo = () => {
        navigate(generatePath(Routes.CompanyFinancials, {
            companyId: company.companyId,
        }));
    };
    const goToDocuments = () => {
        navigate(generatePath(Routes.CompanyDocuments, {
            companyId: company.companyId,
        }));
    };
    const generateDocuments = useCallback(() => {
        Axios.get(`admin/companies/${company.companyId}/documents`, {
            document_type: finCrTypes.join(","),
        })
            .then(({ data }) => getOcrFilesById({ ocrDocs: data.result, path: `admin/companies/${company.companyId}/ocr/documents` }))
            .then((docs) => getIssues(docs, t("company.fincr.issues-request-error")))
            .then((docs) => categorizeDocuments(docs))
            .then((docs) => setCategorizedDocs(docs))
            .catch((err) => void Axios.error(err))
            .finally(() => setIsLoading(false));
    }, [company.companyId, t]);
    useEffect(generateDocuments, [generateDocuments]);
    return (_jsx(FinCr, Object.assign({ breadcrumbTitle: t("company.menu.financials"), currentPageTitle: t("company.fincr.scan"), actionTitle: t("company.fincr.add_document"), goToDocuments: goToDocuments, handleNavigateTo: handleNavigateTo, isDocs: !!categorizedDocs.length, isLoading: isLoading }, { children: categorizedDocs.map((category) => (_jsx(DocumentCategoryWrapper, { 
            // @ts-ignore dynamic types names
            title: t(`documents.category.${category.categoryName}.name`), documents: category.types, generateDocuments: generateDocuments }, category.categoryName))) })));
};
