import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Modal, Space, message } from "antd";
import styled from "styled-components";
import { ApplicationStatuses, FeatureName, ReportType, } from "@teylor-tools/Api";
import TinFormItem, { parseTin } from "@ui/form/form-items/tin-form-item/TinFormItem";
import { validateValueDifferentThan } from "@ui/form/validateValueDifferentThan";
import RepresentativeCard from "@ui/representative-card/RepresentativeCard";
import RepresentativeModal from "@ui/representative-modal/RepresentativeModal";
import { Routes } from "src/Routes";
import CreditScore from "src/components/CreditScore";
import CreditScoreReport from "src/components/CreditScoreReport";
import PepCheck from "src/components/PepCheck";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { Axios } from "src/utils/Axios";
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${({ theme }) => theme.colorError} !important;
`;
const TIN_FORM = "tin-form";
const tinNotEditableStatuses = [
    ApplicationStatuses.Open,
    ApplicationStatuses.FinalReview,
    ApplicationStatuses.AwaitingPayout,
    ApplicationStatuses.PaidOut,
    ApplicationStatuses.Rejected,
];
const CompanyRepresentative = ({ representative, refetchRepresentatives, isEditable }) => {
    const { t } = useTranslation();
    const { confirm } = Modal;
    const [tinModalVisibility, setTinModalVisibility] = useState(false);
    const [isRepresentativeModalOpen, setIsRepresentativeModalOpen] = useState(false);
    const { countries, features } = useSelector((rootState) => rootState.configState);
    const [creditScores, setCreditScores] = useState([]);
    const { application: { applicationId, status, taxIdNumber }, } = useApplication();
    const [form] = Form.useForm();
    const representativeName = `${representative.first_name} ${representative.last_name}`;
    const showEditTinButton = !tinNotEditableStatuses.includes(status);
    const showPepCheck = useMemo(() => { var _a; return (_a = features.find((f) => f.name === FeatureName.BoPepVisibility)) === null || _a === void 0 ? void 0 : _a.value.enabled; }, [features]);
    const getCreditScores = useCallback(() => {
        Axios.get(`admin/signatories/${representative.signatory_id}/credit_score`).then(({ data }) => setCreditScores(data), (err) => {
            void Axios.error(err, t("application.company.error.error-fetching-report"));
            setCreditScores([]);
        });
    }, [representative.signatory_id, t]);
    useEffect(() => {
        void getCreditScores();
    }, [getCreditScores]);
    const deleteRepresentative = () => {
        confirm({
            title: t("application.company_representatives.are-you-sure"),
            icon: _jsx(ExclamationCircleOutlinedIcon, {}),
            content: (_jsx(Trans, Object.assign({ i18nKey: "application.company_representatives.delete-representative-info" }, { children: { name: representativeName } }))),
            okText: t("common.delete"),
            okButtonProps: { danger: true },
            cancelText: t("common.cancel"),
            onOk() {
                Axios.delete(`/admin/signatories/${representative.signatory_id}`).then(() => {
                    void refetchRepresentatives();
                }, (err) => void Axios.error(err, t("application.company_representatives.delete-representative-error")));
            },
        });
    };
    const closeTinModal = () => {
        setTinModalVisibility(false);
        form.resetFields();
    };
    const handleUpdateTin = ({ taxid }) => {
        Axios.patch(`/admin/signatories/${representative.signatory_id}`, Object.assign(Object.assign({}, representative), { taxid: parseTin(taxid) })).then(() => {
            setTinModalVisibility(false);
            void message.success(t("application.company_representatives.tin-added-successfully"));
            void refetchRepresentatives();
        }, (err) => void Axios.error(err, t("application.company_representatives.error-updating-tin")));
    };
    const editRepresentative = (signatory) => {
        Axios.patch(`/admin/signatories/${representative.signatory_id}`, Object.assign({}, signatory)).then(() => {
            void message.success(t("common.saved"));
            void refetchRepresentatives();
            setIsRepresentativeModalOpen(false);
        }, (err) => void Axios.error(err));
    };
    return (_jsxs(_Fragment, { children: [_jsx(RepresentativeCard, { representative: representative, showEditTinButton: showEditTinButton, onEditTin: () => setTinModalVisibility(true), onEditRepresentative: () => setIsRepresentativeModalOpen(true), onDeleteRepresentative: deleteRepresentative, showExtra: isEditable, creditCheck: _jsxs(_Fragment, { children: [_jsx(CreditScore, { riskClassType: representative.risk_class_type, riskClassValue: representative.risk_class_value, creditScoreProvider: representative.credit_score_provider }), _jsx(CreditScoreReport, { getCreditScore: getCreditScores, creditScores: creditScores, requestCreditCheck: status !== ApplicationStatuses.PaidOut
                                ? () => Axios.patch(`admin/signatories/${representative.signatory_id}/credit_check`, {
                                    report_type: ReportType.FullReport,
                                }).then(({ data }) => Promise.resolve(data), (err) => Promise.reject(err))
                                : undefined, documentsPath: generatePath(Routes.ApplicationDocuments, {
                                applicationId: applicationId,
                            }) })] }), pepCheck: showPepCheck ? (_jsx(PepCheck, { pepCheckResult: representative.pep_check_result, signatoryId: representative.signatory_id, refetchRepresentatives: refetchRepresentatives, documentsPath: generatePath(Routes.ApplicationDocuments, {
                        applicationId: applicationId,
                    }), readonly: !isEditable })) : undefined }), _jsx(Modal, Object.assign({ title: representative.taxid
                    ? t("application.company_representatives.edit-tin")
                    : t("application.company_representatives.add-tin"), open: tinModalVisibility, onCancel: closeTinModal, cancelText: t("common.cancel"), okText: t("common.save"), okButtonProps: { htmlType: "submit", form: TIN_FORM } }, { children: _jsx(Form, Object.assign({ id: TIN_FORM, form: form, layout: "vertical", onFinish: handleUpdateTin }, { children: _jsx(TinFormItem, { type: "personal", name: "taxid", initialValue: representative.taxid, rules: [
                            {
                                required: true,
                                message: t("application_form.field-required"),
                            },
                            {
                                validator: (_, value) => validateValueDifferentThan({
                                    value: parseTin(value),
                                    differentThan: taxIdNumber,
                                    error: t("application_form.errors.personalTinMustBeDifferentThanCompanyTin"),
                                }),
                            },
                        ], label: _jsx(Space, Object.assign({ size: 4 }, { children: _jsx(Trans, Object.assign({ i18nKey: "application.company_representatives.tin-for-person" }, { children: {
                                    name: representativeName,
                                } })) })), inputProps: { placeholder: t("application_form.tax-number-placeholder") } }) })) })), isRepresentativeModalOpen && (_jsx(RepresentativeModal, { save: editRepresentative, close: () => setIsRepresentativeModalOpen(false), representative: representative, countries: countries, companyTin: taxIdNumber }))] }));
};
export default CompanyRepresentative;
