import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Empty } from "antd";
import dayjs from "dayjs";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { Table } from "./Table";
import { getPropertyName, roundStringNumber } from "./utils";
const getYearName = (kpi) => {
    const isApplicationKPI = "start_year" in kpi && "end_year" in kpi;
    const startYear = isApplicationKPI ? kpi.start_year : dayjs(kpi.start_date).get("year");
    const endYear = isApplicationKPI ? kpi.end_year : dayjs(kpi.end_date).get("year");
    return startYear === endYear ? startYear : `${startYear} - ${endYear}`;
};
const KpiData = ({ title, data = [] }) => {
    const { t } = useTranslation();
    const { localizedNumber } = useFormatter();
    const getDataSource = () => {
        const dataSource = [];
        data.forEach((kpisEntry) => {
            Object.keys(kpisEntry.kpis).forEach((key) => {
                const value = kpisEntry.kpis[key];
                const yearName = getYearName(kpisEntry);
                // because of how kpis are returned, it's either an object of objects or a plain object with strings
                if (typeof value !== "object") {
                    const foundElementIdx = dataSource.findIndex((item) => item.fieldName === key);
                    if (~foundElementIdx) {
                        dataSource[foundElementIdx][yearName] = isNaN(Number(value))
                            ? value
                            : roundStringNumber(value);
                    }
                    else {
                        dataSource.push({
                            fieldName: key,
                            name: getPropertyName(key),
                            [yearName]: isNaN(Number(value)) ? value : roundStringNumber(value),
                            key: `${key}`,
                        });
                    }
                }
                else {
                    const foundCategoryIndex = dataSource.findIndex((item) => item.fieldName === `category-${key}`);
                    // category descriptor row
                    if (!~foundCategoryIndex) {
                        dataSource.push({
                            fieldName: `category-${key}`,
                            name: _jsx("b", { children: getPropertyName(key) }),
                            key: `${key}`,
                        });
                    }
                    // @ts-ignore take a look at it if you work on this file
                    for (const prop in value) {
                        // find the object if it exists add to it or push a new one.
                        const foundElementIdx = dataSource.findIndex((item) => item.fieldName === prop);
                        if (~foundElementIdx) {
                            dataSource[foundElementIdx][yearName] = isNaN(Number(value[prop]))
                                ? value[prop]
                                : roundStringNumber(value[prop]);
                        }
                        else {
                            dataSource.push({
                                fieldName: prop,
                                name: getPropertyName(prop),
                                [yearName]: isNaN(Number(value[prop]))
                                    ? value[prop]
                                    : roundStringNumber(value[prop]),
                                key: `${prop}`,
                            });
                        }
                    }
                }
            });
        });
        return dataSource;
    };
    const getTableCols = () => {
        return [
            {
                title: `${title}`,
                dataIndex: "name",
                key: "name",
                width: "280px",
            },
            ...data.map((kpisEntry) => {
                const columnHeader = getYearName(kpisEntry);
                return {
                    title: columnHeader,
                    dataIndex: columnHeader,
                    key: columnHeader,
                    width: "160px",
                    render: (value) => {
                        if (!value)
                            return "";
                        return _jsx("span", { children: localizedNumber({ value }) });
                    },
                };
            }),
            {
                dataIndex: "invisible",
            },
        ];
    };
    if (!data.length)
        return _jsx(Empty, { description: t("application.quantitative_analysis.overview.no-data") });
    return (_jsx(Table, { bordered: true, scroll: { y: `calc(100vh - 300px)` }, sticky: true, pagination: false, dataSource: getDataSource(), columns: getTableCols() }));
};
export default KpiData;
