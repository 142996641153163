import { jsx as _jsx } from "react/jsx-runtime";
import { ApplicationStatuses } from "@teylor-tools/Api";
import StatusBase from "./StatusBase";
const ApplicationStatus = (props) => {
    const pickColors = () => {
        switch (props.value) {
            case ApplicationStatuses.PreCheck:
                return {
                    border: "blue3",
                    background: "blue1",
                    colour: "blue8",
                };
            case ApplicationStatuses.InternalReview:
                return {
                    border: "blue3",
                    background: "blue2",
                    colour: "blue8",
                };
            case ApplicationStatuses.InternalPricing:
            case ApplicationStatuses.Pricing:
                return {
                    border: "blue3",
                    background: "blue3",
                    colour: "blue8",
                };
            case ApplicationStatuses.ExternalPricing:
                return {
                    border: "blue3",
                    background: "blue4",
                    colour: "white",
                };
            case ApplicationStatuses.Signature:
                return {
                    border: "blue5",
                    background: "blue5",
                    colour: "white",
                };
            case ApplicationStatuses.FinalReview:
                return {
                    border: "blue5",
                    background: "blue6",
                    colour: "white",
                };
            case ApplicationStatuses.Approved:
                return {
                    border: "blue3",
                    background: "blue7",
                    colour: "white",
                };
            case ApplicationStatuses.AwaitingPayout:
                return {
                    border: "blue3",
                    background: "blue8",
                    colour: "white",
                };
            case ApplicationStatuses.PaidOut:
                return {
                    border: "green3",
                    background: "green1",
                    colour: "green6",
                };
            case ApplicationStatuses.Rejected:
                return {
                    border: "red3",
                    background: "red1",
                    colour: "red6",
                };
            default:
                return {
                    border: "default",
                    background: "default",
                    colour: "default",
                };
        }
    };
    return _jsx(StatusBase, Object.assign({}, props, { label: props.label.toUpperCase(), colors: pickColors }));
};
export default ApplicationStatus;
