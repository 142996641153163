import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Col, Form, Row, Typography, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import styled from "styled-components";
import { Currency } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { fullDateTime } from "@teylor-tools/utils/dateFormats";
import { DataCardItem } from "@ui/data-display/DataCard";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { DataCard, DataCardDivider, DataCardItemEditable } from "src/components/DataCard";
import ApplicationStatus from "src/components/ui/statuses/ApplicationStatus";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { Axios } from "src/utils/Axios";
const { Text } = Typography;
const Wrapper = styled.div `
	margin: 92px 24px 24px;
`;
const ApplicationLeasingDetails = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const { t } = useTranslation();
    const { currency } = useFormatter();
    const { application, updateApplication, updatePendingItems } = useApplication();
    const updateDetailedDesc = (v) => {
        Axios.patch(`admin/applications/${application.applicationId}`, { purposeDescription: v }).then(() => {
            void message.success(t("common.saved"));
            void updateApplication();
            updatePendingItems();
        }, (err) => Axios.error(err));
    };
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: t("application.menu.leasing_details") }), _jsx(Wrapper, { children: _jsxs(Row, { children: [_jsx(Col, Object.assign({ sm: 24 }, { children: _jsxs(DataCard, Object.assign({ loading: !application }, { children: [_jsx(DataCardItem, { label: t("application.leasing_details.facility_amount"), value: _jsx("strong", { children: currency(application.loanSize, {
                                                currency: application.currency || Currency.EUR,
                                            }) }) }), _jsx(DataCardItem, { label: t("application.leasing_details.duration"), value: _jsxs("strong", { children: [application.loanDuration, " ", t("application.overview.months")] }) }), _jsx(DataCardItem, { label: t("application.leasing_details.leasing_factor"), value: application.pricing.leasing_factor ? (_jsx("strong", { children: application.pricing.leasing_factor })) : ("-") }), _jsx(DataCardItem, { label: t("application.leasing_details.service_rate"), value: application.pricing.service_rate ? (_jsx("strong", { children: `${application.pricing.service_rate} ${t("common.per_month")}` })) : ("-") }), _jsx(DataCardItem, { label: t("application.leasing_details.remaining_value"), value: application.pricing.remaining_value ? (_jsxs("strong", { children: [application.pricing.remaining_value * 100, " %"] })) : ("-") }), _jsx(DataCardItem, { label: t("application.leasing_details.insurance_rate"), value: application.pricing.insurance_rate ? (_jsxs("strong", { children: [(application.pricing.insurance_rate * 100).toFixed(4), " %"] })) : ("-") }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("application.overview.product"), value: _jsx(Text, { children: application.product.product_name }) }), _jsx(DataCardDivider, {}), _jsx(DataCardItemEditable, { label: t("application.leasing_details.detailed_desc"), displayedValue: application.purposeDescription, onChange: updateDetailedDesc, customFormItem: (props) => (_jsx(Form.Item, Object.assign({}, props, { rules: [
                                                {
                                                    required: true,
                                                    min: 30,
                                                    message: t("application_form.errors.fieldMustBeMinChars", { min: 30 }),
                                                },
                                            ] }, { children: _jsx(TextArea, { maxLength: 700, showCount: true, rows: 6 }) }))), formItemValue: application.purposeDescription }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("application.state"), value: _jsx(ApplicationStatus, { closable: false, label: t(`application.status.${application.status}`), value: application.status, disabled: false }) }), _jsx(DataCardItem, { label: t("application.created"), value: dayjs(application.created).format(fullDateTime) })] })) })), _jsx(Col, Object.assign({ sm: 24 }, { children: _jsxs(DataCard, Object.assign({ loading: !application }, { children: [_jsx(DataCardItem, { label: t("application.leasing_details.public_tender"), value: ((_a = application.custom_fields_data) === null || _a === void 0 ? void 0 : _a.values.is_public_tender)
                                            ? t("common.yes").toUpperCase()
                                            : t("common.no").toUpperCase() }), _jsx(DataCardItem, { label: t("application.leasing_details.employer_rate_subsidy"), value: currency(Number((_c = (_b = application.custom_fields_data) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c.employer_rate_subsidy), {
                                            currency: application.currency,
                                        }) }), _jsx(DataCardItem, { label: t("application.leasing_details.employer_service_subsidy"), value: currency(Number((_e = (_d = application.custom_fields_data) === null || _d === void 0 ? void 0 : _d.values) === null || _e === void 0 ? void 0 : _e.employer_service_subsidy), {
                                            currency: application.currency,
                                        }) }), _jsx(DataCardItem, { label: t("application.leasing_details.max_bikes_per_employee"), value: (_g = (_f = application.custom_fields_data) === null || _f === void 0 ? void 0 : _f.values) === null || _g === void 0 ? void 0 : _g.max_bikes_per_employee }), _jsx(DataCardItem, { label: t("application.leasing_details.price_limit_bike"), value: currency(Number((_j = (_h = application.custom_fields_data) === null || _h === void 0 ? void 0 : _h.values) === null || _j === void 0 ? void 0 : _j.price_limit_bike), {
                                            currency: application.currency,
                                        }) }), _jsx(DataCardItem, { label: t("application.leasing_details.number_of_employees"), value: (_l = (_k = application.custom_fields_data) === null || _k === void 0 ? void 0 : _k.values) === null || _l === void 0 ? void 0 : _l.number_of_employees })] })) }))] }) })] }));
};
export default ApplicationLeasingDetails;
