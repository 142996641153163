import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resources from "./translations.json";
// detects browser language without locale
const browserLangDetector = {
    name: "browser-lang-detector",
    lookup: () => navigator.language.split("-")[0],
};
const langDetector = new LanguageDetector();
langDetector.addDetector(browserLangDetector);
const i18nInit = () => {
    void i18n
        .use(initReactI18next)
        .use(langDetector)
        .init({
        detection: {
            order: ["cookie", "browser-lang-detector"],
            lookupCookie: "i18next",
            caches: ["cookie"],
        },
        resources,
        defaultNS: "translation",
        fallbackLng: "de",
    });
};
export default i18nInit;
