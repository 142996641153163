import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Space as AntdSpace, Button, Col, Form, Row, Typography } from "antd";
import styled from "styled-components";
import PasswordFormItem from "@ui/form/form-items/password-form-item/PasswordFormItem";
import { Routes } from "src/Routes";
import { Axios } from "../utils/Axios";
const { Title, Text } = Typography;
const Space = styled(AntdSpace) `
	width: 100%;
`;
const CheckCircleIcon = styled(CheckCircleOutlined) `
	color: ${({ theme }) => theme.colorPrimary};
	font-size: 112px;
	margin-top: 8px;
	margin-bottom: 24px;
`;
const Wrapper = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const RegisterPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const { token } = useParams();
    const [form] = Form.useForm();
    const onFinish = ({ password }) => {
        setLoading(true);
        Axios.post("/admin/account/register", {
            password,
            token,
        })
            .then(() => setRegisterSuccess(true))
            .catch((error) => Axios.error(error))
            .finally(() => setLoading(false));
    };
    if (registerSuccess) {
        return (_jsxs(Wrapper, { children: [_jsx(CheckCircleIcon, {}), _jsx(Title, Object.assign({ level: 3, style: { textAlign: "center" } }, { children: t("register.registration-success-title") })), _jsx(Text, Object.assign({ type: "secondary", style: { textAlign: "center" } }, { children: t("register.registration-success-subtitle") })), _jsx(Button, Object.assign({ type: "primary", size: "large", style: { marginTop: 48 }, onClick: () => navigate(Routes.Login) }, { children: t("register.go-to-login") }))] }));
    }
    return (_jsxs(Space, Object.assign({ direction: "vertical", size: 24 }, { children: [_jsxs("div", { children: [_jsx(Title, Object.assign({ level: 3 }, { children: t("register.set-password-title") })), _jsx(Text, Object.assign({ type: "secondary" }, { children: t("register.set-password-subtitle") }))] }), _jsxs(Form, Object.assign({ form: form, layout: "vertical", onFinish: onFinish }, { children: [_jsx(PasswordFormItem, { name: "password", label: t("register.password") }), _jsx(PasswordFormItem, { name: "passwordRepeat", label: t("register.password-repeat"), rules: [
                            { required: true, message: t("application_form.field-required") },
                            {
                                validator: (_, passwordRepeat) => passwordRepeat === form.getFieldValue("password")
                                    ? Promise.resolve()
                                    : Promise.reject(t("application_form.password-repeat-invalid")),
                            },
                        ] }), _jsx(Row, { children: _jsx(Col, Object.assign({ span: 24, style: { textAlign: "center" } }, { children: _jsx(Button, Object.assign({ type: "primary", size: "large", htmlType: "submit", loading: loading }, { children: t("register.set-password-button") })) })) })] }))] })));
};
export default RegisterPage;
