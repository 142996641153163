var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Form } from "antd";
import { FinancialsInput } from "src/pages/modules/Financials/FinancialsInput";
import { Cell, EditableCellValueWrapper } from "./StyledCell";
import { ActionType } from "./reducer";
const flattenData = (data) => {
    return data.flatMap((item) => [item, ...item.children]);
};
export const CompanyFinancialEditableCell = (_a) => {
    var { editable, isEditing, children, dataIndex, record, dispatch, focusedCell, setFocusedCell, data } = _a, restProps = __rest(_a, ["editable", "isEditing", "children", "dataIndex", "record", "dispatch", "focusedCell", "setFocusedCell", "data"]);
    const [editing, setEditing] = useState(false);
    const [form] = Form.useForm();
    const value = Form.useWatch(dataIndex, form);
    useEffect(() => {
        if (isEditing && (focusedCell === null || focusedCell === void 0 ? void 0 : focusedCell.rowKey) === record.key && (focusedCell === null || focusedCell === void 0 ? void 0 : focusedCell.columnKey) === dataIndex) {
            toggleEdit(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusedCell]);
    const toggleEdit = (editingState) => {
        if (!isEditing)
            return;
        setEditing(editingState);
        form === null || form === void 0 ? void 0 : form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };
    const save = () => {
        dispatch({
            type: ActionType.UPDATE_STATEMENT_VALUE,
            statementId: String(dataIndex),
            key: record.inputDataKey,
            value: value !== null ? String(value) : null,
        });
        // Exit editing mode for the current cell
        toggleEdit(false);
    };
    const isEditableCellPresentBellow = (row) => {
        return row.children === undefined && !row.isBold;
    };
    // Move to the next cell previously update data in previous cell
    const handleNextEditableCell = () => {
        save();
        const flatData = flattenData(data);
        const currentRowIndex = flatData.findIndex((row) => row.key === record.key);
        if (currentRowIndex >= 0) {
            let nextRowIndex = currentRowIndex + 1;
            while (nextRowIndex < flatData.length) {
                const nextRow = flatData[nextRowIndex];
                // Assuming `isEditableCellPresentBellow` is a function that takes a row,
                // and returns true if the cell at that position is editable
                if (isEditableCellPresentBellow(nextRow)) {
                    // Found an editable cell, set it as the focused cell and exit the loop
                    setFocusedCell({ rowKey: nextRow.key, columnKey: dataIndex });
                    break;
                }
                nextRowIndex++;
            }
            if (nextRowIndex === flatData.length) {
                // Reached the end without finding an editable cell, move to the first editable cell of the first row
                setFocusedCell({ rowKey: flatData[1].key, columnKey: dataIndex });
            }
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (_jsx(Form, Object.assign({ form: form, component: false }, { children: _jsx(Form.Item, Object.assign({ style: { margin: 0 }, name: dataIndex, rules: [
                    {
                        required: true,
                        message: "",
                    },
                ] }, { children: _jsx(FinancialsInput, { autoFocus: true, onPressEnter: handleNextEditableCell, onBlur: save }) })) }))) : (_jsx(EditableCellValueWrapper, { children: children }));
    }
    return (_jsx(Cell, Object.assign({ editable: editable, editing: editing, onClick: () => !editing && toggleEdit(true) }, restProps, { children: childNode })));
};
