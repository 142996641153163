import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { AppstoreOutlined, ContainerOutlined, IdcardOutlined, ShopOutlined, TeamOutlined, } from "@ant-design/icons";
import Menu from "@ui/menu/Menu";
import { PartnerRoutes } from "src/Routes";
const PartnerMenu = ({ partnerId }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const menuItems = useMemo(() => {
        return [
            {
                icon: _jsx(AppstoreOutlined, {}),
                label: t("partners.menu.overview"),
                key: generatePath(PartnerRoutes.Partner, { partnerId }),
            },
            { type: "divider" },
            {
                icon: _jsx(ShopOutlined, {}),
                label: t("partners.menu.partner_details"),
                key: generatePath(PartnerRoutes.PartnerDetails, { partnerId }),
            },
            {
                icon: _jsx(IdcardOutlined, {}),
                label: t("partners.menu.contact_persons"),
                key: generatePath(PartnerRoutes.PartnerContactPersons, { partnerId }),
            },
            { type: "divider" },
            {
                icon: _jsx(ContainerOutlined, {}),
                label: t("partners.menu.applications"),
                key: generatePath(PartnerRoutes.PartnerApplications, { partnerId }),
            },
            {
                icon: _jsx(TeamOutlined, {}),
                label: t("partners.menu.team"),
                key: generatePath(PartnerRoutes.PartnerTeam, { partnerId }),
            },
        ];
    }, [partnerId, t]);
    return (_jsx(Menu, { selectedKeys: [location.pathname], multiple: true, mode: "inline", onClick: (menuItem) => navigate(menuItem.key), items: menuItems, isCollapsible: true, collapseText: t("menu.collapse") }));
};
export default PartnerMenu;
