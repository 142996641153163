export var ConfigActions;
(function (ConfigActions) {
    ConfigActions["CLEAR_FEATURES"] = "CLEAR_FEATURES";
    ConfigActions["SET_FEATURES"] = "SET_FEATURES";
    ConfigActions["SET_COUNTRY_LIST"] = "SET_COUNTRY_LIST";
    ConfigActions["SET_DOCUMENT_CATEGORIES"] = "SET_DOCUMENT_CATEGORIES";
})(ConfigActions || (ConfigActions = {}));
export const clearFeatures = () => {
    return {
        type: ConfigActions.CLEAR_FEATURES,
    };
};
export const setFeatures = (features) => {
    return {
        type: ConfigActions.SET_FEATURES,
        payload: {
            features,
        },
    };
};
export const setCountryList = (features, countries) => {
    return {
        type: ConfigActions.SET_COUNTRY_LIST,
        payload: {
            features,
            countries,
        },
    };
};
export const setDocumentCategories = (documentCategories) => {
    return {
        type: ConfigActions.SET_DOCUMENT_CATEGORIES,
        payload: {
            documentCategories,
        },
    };
};
