import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { CarryOutOutlined, CheckCircleOutlined, ClockCircleOutlined, ExclamationCircleOutlined, OrderedListOutlined, } from "@ant-design/icons";
import { Space as AntdSpace, Button, DatePicker, Divider, Modal, Select, Spin } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import { Axios } from "src/utils/Axios";
import { AssigneeField, ReporterField, TaskInfoRow, TaskTextField } from "./TaskEditItems";
import TaskPriorityIndicator from "./TaskPriorityIndicator";
import TaskStatusIndicator from "./TaskStatusIndicator";
import { DESC_MAX_LENGTH, SUMMARY_MAX_LENGTH } from "./tasks.helpers";
import { TaskPriorities, TaskStatuses } from "./tasks.types";
const { confirm } = Modal;
export const TASK_ID_SEARCH_PARAM = "task_id";
const Wrapper = styled.div `
	padding: 24px 0;
`;
const Space = styled(AntdSpace) `
	width: 100%;
	padding-left: 24px;
	padding-right: 24px;
`;
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${({ theme }) => theme.colorError} !important;
`;
const SidebarTaskDetails = ({ data, afterDelete, afterUpdate }) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [task, setTask] = useState();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (Array.isArray(data)) {
            // Sidebar used in the ApplicationOutlet needs to filter the tasks list based on task ID from URL params
            const taskId = searchParams.get(TASK_ID_SEARCH_PARAM);
            // keep task details open when navigating between pages
            if (!taskId)
                return;
            return setTask(data.find((task) => task.task_id === taskId));
        }
        else {
            // TasksPage passes directly only the task to display
            setTask(data);
        }
    }, [data, searchParams]);
    const handleUpdate = (update) => {
        if (!task)
            return;
        setLoading(true);
        Axios.patch(`admin/tasks/${task === null || task === void 0 ? void 0 : task.task_id}`, Object.assign(Object.assign({}, task), update))
            .then(() => afterUpdate(), (err) => Axios.error(err))
            .finally(() => setLoading(false));
    };
    const handleDelete = () => {
        confirm({
            title: t("tasks.sidebar.delete-modal.title"),
            icon: _jsx(ExclamationCircleOutlinedIcon, {}),
            content: t("tasks.sidebar.delete-modal.content"),
            okText: t("tasks.sidebar.delete-modal.delete"),
            okButtonProps: { danger: true },
            cancelText: t("common.cancel"),
            onOk() {
                setLoading(true);
                Axios.delete(`admin/tasks/${task === null || task === void 0 ? void 0 : task.task_id}`)
                    .then(() => afterDelete(), (err) => Axios.error(err))
                    .finally(() => setLoading(false));
            },
        });
    };
    if (!task) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(Wrapper, { children: _jsxs(Spin, Object.assign({ spinning: loading }, { children: [_jsxs(Space, Object.assign({ size: 16, direction: "vertical" }, { children: [_jsx(AssigneeField, { name: task.assignee_name || "", label: t("tasks.assignee"), onChange: (v) => handleUpdate({ assignee_id: v }), fieldValue: task.assignee_name }, `${task.task_id}-assignee`), _jsx(ReporterField, { name: task.reporter_name, label: t("tasks.reporter") }, `${task.task_id}-reporter`)] })), _jsx(Divider, {}), _jsxs(Space, Object.assign({ size: 16, direction: "vertical" }, { children: [_jsx(TaskTextField, { label: t("tasks.summary"), maxLength: SUMMARY_MAX_LENGTH, onChange: (v) => handleUpdate({ subject: v }), fieldValue: task.subject || "" }, `${task.task_id}-summary`), _jsx(TaskTextField, { label: t("tasks.description"), maxLength: DESC_MAX_LENGTH, onChange: (v) => handleUpdate({ description: v }), fieldValue: task.description || "", rows: 8, value: _jsx("div", Object.assign({ style: { whiteSpace: "pre-wrap" } }, { children: task.description })) }, `${task.task_id}-desc`)] })), _jsx(Divider, {}), _jsxs(Space, Object.assign({ size: 8, direction: "vertical" }, { children: [_jsx(TaskInfoRow, Object.assign({ value: _jsx(TaskStatusIndicator, { status: task.status, mode: "tag" }), label: _jsxs(_Fragment, { children: [_jsx(CheckCircleOutlined, {}), " ", t("tasks.status")] }), fieldValue: task.status, onChange: (v) => handleUpdate({ status: v }) }, { children: _jsx(Select, { options: TaskStatuses.map((status) => ({
                                    value: status,
                                    label: _jsx(TaskStatusIndicator, { status: status, mode: "tag" }),
                                })) }) }), `${task.task_id}-status`), _jsx(TaskInfoRow, Object.assign({ value: _jsx(TaskPriorityIndicator, { priority: task.priority, showLabel: true }), label: _jsxs(_Fragment, { children: [_jsx(OrderedListOutlined, {}), " ", t("tasks.priority")] }), fieldValue: task.priority, onChange: (v) => handleUpdate({ priority: v }) }, { children: _jsx(Select, { options: TaskPriorities.map((priority) => ({
                                    value: priority,
                                    label: _jsx(TaskPriorityIndicator, { priority: priority, showLabel: true }),
                                })) }) }), `${task.task_id}-priority`), _jsx(TaskInfoRow, Object.assign({ value: task.due_date ? dayjs(task.due_date).format(dayMonthYear) : "-", label: _jsxs(_Fragment, { children: [_jsx(CarryOutOutlined, {}), " ", t("tasks.due-date")] }), fieldValue: task.due_date ? dayjs(task.due_date) : "", onChange: (v) => handleUpdate({ due_date: v }) }, { children: _jsx(DatePicker, { format: dayMonthYear, style: { width: "100%" } }) }), `${task.task_id}-due-date`), _jsx(TaskInfoRow, { value: dayjs(task.created_at).format(dayMonthYear), label: _jsxs(_Fragment, { children: [_jsx(ClockCircleOutlined, {}), " ", t("tasks.created-at")] }) }, `${task.task_id}-created-at`)] })), _jsx(Divider, {}), _jsx(Space, Object.assign({ size: 16, direction: "vertical" }, { children: _jsx(Button, Object.assign({ danger: true, onClick: handleDelete }, { children: "Delete" })) }))] })) }));
};
export default SidebarTaskDetails;
