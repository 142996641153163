import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, DatePicker, Form, Typography, message } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { BankTransactionStatus } from "@teylor-tools/Api";
import { dayMonthYear, rawDateFormat } from "@teylor-tools/utils/dateFormats";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import { Axios } from "src/utils/Axios";
const { Text } = Typography;
const PaymentModalForm = ({ installmentId, afterNewTransaction, currency }) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const requiredRule = {
        required: true,
        message: t("application_form.field-required"),
    };
    const handleFinish = (formValues) => {
        setLoading(true);
        Axios.post(`admin/installments/${installmentId}/payments/manual_create`, {
            amount: formValues.amount.toString(),
            booking_date: dayjs(formValues.date).format(rawDateFormat),
            status: BankTransactionStatus.Posted,
            details: { notes: formValues.notes },
            currency,
        })
            .then(() => {
            afterNewTransaction();
            void message.success(t("loans.payments.modal.transaction-added-success"));
        }, (err) => Axios.error(err, t("loans.payments.modal.transaction-added-error")))
            .finally(() => setLoading(false));
    };
    return (_jsxs(Form, Object.assign({ form: form, layout: "vertical", initialValues: { transactions: [{ amount: 0 }] }, onFinish: handleFinish, disabled: loading }, { children: [_jsx(Form.Item, Object.assign({ label: t("loans.payments.modal.amount"), name: "amount", rules: [
                    {
                        validator: (_, value) => {
                            if (!value || value === "0") {
                                return Promise.reject(t("application_form.field-required"));
                            }
                            return Promise.resolve();
                        },
                    },
                ] }, { children: _jsx(CurrencyInput, { style: { width: "100%" }, currency: currency }) })), _jsx(Form.Item, Object.assign({ label: t("loans.payments.modal.date"), name: "date", rules: [requiredRule] }, { children: _jsx(DatePicker, { style: { width: "100%" }, format: dayMonthYear }) })), _jsx(Form.Item, Object.assign({ label: _jsxs(_Fragment, { children: [_jsx(Text, { children: t("loans.payments.modal.notes") }), _jsx(Text, Object.assign({ type: "secondary", style: { fontSize: "0.8rem", marginLeft: 4 } }, { children: t("loans.payments.modal.optional") })), " "] }), name: "notes" }, { children: _jsx(TextArea, { rows: 4 }) })), _jsx(Form.Item, Object.assign({ style: { textAlign: "right", margin: "16px 0" } }, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit" }, { children: t("loans.payments.modal.add-transaction") })) }))] })));
};
export default PaymentModalForm;
