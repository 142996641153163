var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Spin } from "antd";
import styled from "styled-components";
import { FeatureName } from "@teylor-tools/Api";
import { Axios } from "src/utils/Axios";
import { CreateApplicationHeader } from "./CreateApplicationHeader";
import { CreateApplicationView } from "./create-application-view/CreateApplicationView";
const FormWrapper = styled.div `
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 100px 0 30px;
`;
const CreateApplication = () => {
    const [searchParams] = useSearchParams();
    const [company, setCompany] = useState();
    const [loading, setLoading] = useState(true);
    const { features } = useSelector((rootState) => rootState.configState);
    const [loanTypes, setLoanTypes] = useState([]);
    const queryCompanyId = useMemo(() => {
        return searchParams.get("comp_id") || "";
    }, [searchParams]);
    const fetchCompanyById = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.get(`/admin/companies/${queryCompanyId}`)
            .then(({ data }) => {
            setCompany(data);
        })
            .catch((err) => void Axios.error(err));
    }), [queryCompanyId]);
    const fetchLoanTypes = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const defaultPartner = (_a = features.find((f) => f.name === FeatureName.DefaultPartner)) === null || _a === void 0 ? void 0 : _a.value.partner_id;
        if (!defaultPartner)
            return;
        yield Axios.get(`/admin/partners/${defaultPartner}/config`)
            .then(({ data }) => {
            setLoanTypes(data.application_types);
        })
            .catch((error) => {
            void Axios.error(error);
        });
    }), [features]);
    const fetchAllData = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            yield Promise.allSettled([fetchCompanyById(), fetchLoanTypes()]);
        }
        finally {
            setLoading(false);
        }
    }), [fetchCompanyById, fetchLoanTypes]);
    useEffect(() => {
        if (queryCompanyId) {
            void fetchAllData();
        }
        else {
            setLoading(true);
            fetchLoanTypes().finally(() => setLoading(false));
        }
    }, [fetchAllData, fetchLoanTypes, queryCompanyId]);
    if (queryCompanyId && loading)
        return _jsx(Spin, {});
    return (_jsxs(_Fragment, { children: [_jsx(CreateApplicationHeader, { companyId: company === null || company === void 0 ? void 0 : company.companyId, companyName: company === null || company === void 0 ? void 0 : company.companyName }), _jsx(FormWrapper, { children: loading ? (_jsx(Spin, {})) : (_jsx(CreateApplicationView, { company: company, possibleLoanTypes: loanTypes })) })] }));
};
export default CreateApplication;
