import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Collapse } from "antd";
import styled from "styled-components";
import BaseRepresentativeListItem from "@ui/representative-list-item/RepresentativeListItem";
const RepresentativeListItem = styled(BaseRepresentativeListItem) `
	margin-bottom: 12px;
`;
const StyledCollapse = styled(Collapse) `
	.ant-collapse-item > .ant-collapse-header,
	.ant-collapse-content-box {
		padding-left: 0;
		padding-right: 0;
	}
`;
const RepresentativeList = ({ representatives }) => {
    const { t } = useTranslation();
    return (_jsx(StyledCollapse, Object.assign({ defaultActiveKey: ["1"], ghost: true, expandIconPosition: "end" }, { children: _jsx(Collapse.Panel, Object.assign({ header: `${t("application.preview.company-representative")} (${representatives.length})` }, { children: representatives.map(({ first_name, last_name, email, telephone, signatory_id }) => {
                return (_jsx(RepresentativeListItem, { representative: {
                        name: `${first_name} ${last_name}`,
                        email,
                        phone: telephone,
                    } }, signatory_id));
            }) }), "1") })));
};
export default RepresentativeList;
