import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PlusOutlined, RightOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Spin, Typography } from "antd";
import styled from "styled-components";
import Loader from "src/components/ui/Loader";
import { FinCrEmpty } from "./FinCrEmpty";
const { Text } = Typography;
const Wrapper = styled.div `
	padding: 16px 24px;
	background: white;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 9;
	.ant-typography {
		word-break: keep-all;
	}
`;
const TopNav = styled(Breadcrumb) `
	ol {
		align-items: baseline;
	}
`;
const FinancialsLink = styled(Text) `
	font-size: 16px;
	cursor: pointer;
`;
const DocumentWrapper = styled.div `
	margin-top: 64px;
	padding: 24px;
`;
export const FinCr = ({ breadcrumbTitle, currentPageTitle, actionTitle, handleNavigateTo, goToDocuments, isDocs, isLoading, children, }) => {
    return (_jsxs(_Fragment, { children: [_jsxs(Wrapper, Object.assign({ title: breadcrumbTitle }, { children: [_jsxs(TopNav, Object.assign({ separator: _jsx(RightOutlined, {}) }, { children: [_jsx(Breadcrumb.Item, { children: _jsx(FinancialsLink, Object.assign({ onClick: handleNavigateTo }, { children: breadcrumbTitle })) }), _jsx(Breadcrumb.Item, { children: _jsx(Typography.Title, Object.assign({ level: 5 }, { children: currentPageTitle })) })] })), _jsx(Button, Object.assign({ onClick: goToDocuments, icon: _jsx(PlusOutlined, {}) }, { children: actionTitle }))] })), _jsx(DocumentWrapper, { children: isLoading && !isDocs ? (_jsx(Loader, {})) : !isDocs ? (_jsx(FinCrEmpty, { goToDocuments: goToDocuments })) : (_jsx(Spin, Object.assign({ spinning: isLoading }, { children: children }))) })] }));
};
