import { jsx as _jsx } from "react/jsx-runtime";
import { useCompany } from "src/layouts/CompanyLayout/CompanyTypes";
import DocumentsTab from "src/pages/modules/Documents/DocumentsTab";
import { Axios } from "src/utils/Axios";
const CompanyDocuments = () => {
    const { company } = useCompany();
    return (_jsx(DocumentsTab, { apiBasePath: `admin/companies/${company.companyId}`, 
        // @ts-ignore some mismatch between types
        apiGetDocsRequest: () => Axios.get(`admin/companies/${company.companyId}/documents`), apiGetArchivePath: `admin/companies/${company.companyId}/files_archive`, allowUpload: true, allowDelete: true, editMode: "full" }));
};
export default CompanyDocuments;
