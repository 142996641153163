import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Layout } from "antd";
import { BOUserFeature } from "@teylor-tools/Api";
import Menu from "@ui/menu/Menu";
import { Routes } from "src/Routes";
const { Content, Sider } = Layout;
const SettingsLayout = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { features } = useSelector((state) => state.profileState);
    const menuItems = [
        {
            key: Routes.SettingsTeam,
            label: t("settings.team"),
        },
        {
            key: Routes.SettingsRoles,
            label: t("settings.roles"),
        },
        ...((features === null || features === void 0 ? void 0 : features.includes(BOUserFeature.GlobalRate))
            ? [
                {
                    key: Routes.SettingsRates,
                    label: t("settings.rates"),
                },
            ]
            : []),
        ...((features === null || features === void 0 ? void 0 : features.includes(BOUserFeature.BankAccount))
            ? [
                {
                    key: Routes.SettingsAccountBalances,
                    label: t("settings.account-balances"),
                },
            ]
            : []),
    ];
    return (_jsxs(Layout, Object.assign({ style: { minHeight: "100vh" } }, { children: [_jsx(Sider, Object.assign({ theme: "light", width: 200 }, { children: _jsx(Menu, { selectedKeys: [location.pathname], mode: "inline", onClick: (menuItem) => {
                        navigate(menuItem.key);
                    }, items: menuItems, withHeader: true, headerTitle: t("settings.title") }) })), _jsx(Layout, { children: _jsx(Content, { children: _jsx(Outlet, {}) }) })] })));
};
export default SettingsLayout;
