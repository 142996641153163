import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, MoreOutlined, SettingOutlined } from "@ant-design/icons";
import { Dropdown, Row, Tag, Typography } from "antd";
import dayjs from "dayjs";
import { FinancialStatementType, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import { DownIcon, HeaderCell, Table, UpIcon } from "src/pages/modules/Financials/FinancialStyles";
import { FinancialsModal } from "src/pages/modules/Financials/FinancialsModal";
import { expandable, tableRows, totalsRows, } from "src/pages/modules/Financials/financialsTableRows";
import { getEditableColumnCel } from "src/pages/modules/Financials/getEditableColumnCel";
import { MenuOptions, } from "src/pages/modules/Financials/types";
import { CompanyFinancialEditableCell } from "./CompanyFinancialEditableCell";
import { ActionType } from "./reducer";
const HEADINGS_HEIGHT = 250; // 250px is the height of top bar + page heading + table heading
export const CompanyFinancialsTable = ({ financials, isEditing, dispatch, }) => {
    const { t } = useTranslation();
    const [scrollY, setScrollY] = useState(window.innerHeight - HEADINGS_HEIGHT);
    const [focusedCell, setFocusedCell] = useState(null);
    const { localizedNumber } = useFormatter();
    const [editStatement, setEditStatement] = useState();
    useEffect(() => {
        setFocusedCell(null);
    }, [isEditing]);
    // This properly sets the scroll behavior of the table
    useEffect(() => {
        function handleResize() {
            setScrollY(window.innerHeight - HEADINGS_HEIGHT);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const handleMenuClick = ({ key, statement, }) => {
        switch (key) {
            case MenuOptions.DELETE:
                dispatch({
                    type: ActionType.DELETE_STATEMENT,
                    statementId: statement.company_financial_data_id,
                });
                return;
            case MenuOptions.SETTINGS:
                setEditStatement(statement);
                return;
            default:
                return;
        }
    };
    const data = expandable.map((section) => {
        return {
            key: section,
            section: t(`company.financials.${section}`),
            children: tableRows[section].map((row) => {
                const isTotalRow = totalsRows.some((boldRow) => boldRow === row);
                const data = financials.reduce((acc, financialData) => {
                    const companyId = financialData.company_financial_data_id;
                    if (companyId) {
                        acc[companyId] = isTotalRow
                            ? financialData.summary_data &&
                                financialData.summary_data[row]
                            : financialData.input_data[row];
                    }
                    return acc;
                }, {});
                return Object.assign({ key: `${section}-${row}`, inputDataKey: row, isBold: isTotalRow, section: t(`company.financials.${row}`) }, data);
            }),
        };
    });
    const columns = [
        {
            dataIndex: "section",
            width: 330,
            align: "left",
            title: "",
            editable: false,
        },
        ...financials.map((financialStatement) => {
            return {
                dataIndex: financialStatement.company_financial_data_id,
                width: 210,
                align: "right",
                editable: isEditing,
                render: (value) => {
                    if (!isValidNumericValue(value))
                        return "";
                    return _jsx("span", { children: localizedNumber({ value }) });
                },
                title: () => {
                    return (_jsxs(_Fragment, { children: [_jsxs(HeaderCell, { children: [_jsx(Typography.Text, { children: financialStatement.statement_type === FinancialStatementType.InterimFinancials
                                            ? `${t("company.financials.interim")} ${dayjs(financialStatement.financials_date).format("YYYY")}.${dayjs(financialStatement.financials_date).format("MM")}`
                                            : `${t("company.financials.annual")} ${dayjs(financialStatement.financials_date).format("YYYY")}` }), isEditing && (_jsx(Dropdown, Object.assign({ menu: {
                                            onClick: ({ key }) => handleMenuClick({
                                                key: key,
                                                statement: financialStatement,
                                            }),
                                            items: [
                                                {
                                                    label: t("company.financials.settings"),
                                                    key: MenuOptions.SETTINGS,
                                                    icon: _jsx(SettingOutlined, {}),
                                                },
                                                {
                                                    label: t("company.financials.delete"),
                                                    key: MenuOptions.DELETE,
                                                    icon: _jsx(DeleteOutlined, {}),
                                                },
                                            ],
                                        }, placement: "bottom" }, { children: _jsx(MoreOutlined, {}) })))] }), financialStatement.origin && (_jsx(Row, { children: _jsx(Tag, { children: t(`company.financials.${financialStatement.origin}`) }) }))] }));
                },
            };
        }),
        { dataIndex: "empty" }, // one extra empty column to properly handle the layout
    ].map((col) => getEditableColumnCel({ col, data, dispatch, focusedCell, setFocusedCell, isEditing }));
    const components = {
        body: {
            cell: CompanyFinancialEditableCell,
        },
    };
    return (_jsxs(_Fragment, { children: [_jsx(Table, { components: components, columns: columns, dataSource: data, bordered: true, scroll: { y: scrollY }, pagination: { hideOnSinglePage: true }, expandable: {
                    indentSize: 0,
                    defaultExpandAllRows: true,
                    expandIcon: ({ expanded, onExpand, record }) => {
                        if (!expandable.some((section) => section === record.key))
                            return null;
                        return expanded ? (_jsx(UpIcon, { onClick: (e) => onExpand(record, e) })) : (_jsx(DownIcon, { onClick: (e) => onExpand(record, e) }));
                    },
                } }), editStatement && (_jsx(FinancialsModal, { statement: editStatement, onCancel: () => setEditStatement(undefined), onFinish: ({ type, date }) => {
                    dispatch({
                        type: ActionType.UPDATE_STATEMENT_SETTINGS,
                        statementId: editStatement.company_financial_data_id,
                        statementType: type,
                        date,
                    });
                    setEditStatement(undefined);
                } }))] }));
};
