var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, MoreOutlined, SettingOutlined } from "@ant-design/icons";
import { Dropdown, Form, Row, Tag, Typography } from "antd";
import dayjs from "dayjs";
import styled, { css } from "styled-components";
import { FinancialStatementType, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import { DownIcon, HeaderCell, Table, UpIcon } from "src/pages/modules/Financials/FinancialStyles";
import { FinancialsInput } from "src/pages/modules/Financials/FinancialsInput";
import { FinancialsModal } from "src/pages/modules/Financials/FinancialsModal";
import { cellsWithTotalValues, expandable, tableRows, totalsRows, } from "src/pages/modules/Financials/financialsTableRows";
import { MenuOptions, } from "src/pages/modules/Financials/types";
import { ActionType } from "./reducer";
const HEADINGS_HEIGHT = 250; // 250px is the height of top bar + page heading + table heading
const Cell = styled.td `
	background: #ffffff;
	${({ editable, editing, theme }) => editable &&
    css `
			cursor: pointer;
			border: solid 1px ${editing ? "transparent" : "rgba(0, 0, 0, 0.06)"} !important;
			padding: ${editing ? "0px" : "8px 16px"} !important;
			${editing && "box-shadow: 0px 0px 4px rgba(24, 144, 255, 0.5)"};

			&:hover {
				border: solid 1px ${editing ? "transparent" : theme.colorPrimary} !important;
			}
		`};
`;
const EditableCellValueWrapper = styled.div `
	height: 22px;
	cursor: pointer;
`;
const flattenData = (data) => {
    return data.flatMap((item) => [item, ...item.children]);
};
const EditableCell = (_a) => {
    var { editable, isEditing, children, dataIndex, record, dispatch, focusedCell, setFocusedCell, data } = _a, restProps = __rest(_a, ["editable", "isEditing", "children", "dataIndex", "record", "dispatch", "focusedCell", "setFocusedCell", "data"]);
    const [editing, setEditing] = useState(false);
    const [form] = Form.useForm();
    const value = Form.useWatch(dataIndex, form);
    useEffect(() => {
        if (isEditing && (focusedCell === null || focusedCell === void 0 ? void 0 : focusedCell.rowKey) === record.key && (focusedCell === null || focusedCell === void 0 ? void 0 : focusedCell.columnKey) === dataIndex) {
            toggleEdit(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusedCell]);
    const toggleEdit = (editingState) => {
        if (!isEditing)
            return;
        setEditing(editingState);
        form === null || form === void 0 ? void 0 : form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };
    const save = () => {
        dispatch({
            type: ActionType.UPDATE_STATEMENT_VALUE,
            statementId: dataIndex,
            key: record.inputDataKey,
            value: value !== null ? String(value) : null,
        });
        // Exit editing mode for the current cell
        toggleEdit(false);
    };
    const isEditableCellPresentBellow = (row) => {
        return row.children === undefined && !row.isBold;
    };
    // Move to the next cell previously update data in previous cell
    const handleNextEditableCell = () => {
        save();
        const flatData = flattenData(data);
        const currentRowIndex = flatData.findIndex((row) => row.key === record.key);
        if (currentRowIndex >= 0) {
            let nextRowIndex = currentRowIndex + 1;
            while (nextRowIndex < flatData.length) {
                const nextRow = flatData[nextRowIndex];
                // Assuming `isEditableCellPresentBellow` is a function that takes a row,
                // and returns true if the cell at that position is editable
                if (isEditableCellPresentBellow(nextRow)) {
                    // Found an editable cell, set it as the focused cell and exit the loop
                    setFocusedCell({ rowKey: nextRow.key, columnKey: dataIndex });
                    break;
                }
                nextRowIndex++;
            }
            if (nextRowIndex === flatData.length) {
                // Reached the end without finding an editable cell, move to the first editable cell of the first row
                setFocusedCell({ rowKey: flatData[1].key, columnKey: dataIndex });
            }
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (_jsx(Form, Object.assign({ form: form, component: false }, { children: _jsx(Form.Item, Object.assign({ style: { margin: 0 }, name: dataIndex, rules: [
                    {
                        required: true,
                        message: "",
                    },
                ] }, { children: _jsx(FinancialsInput, { autoFocus: true, onPressEnter: handleNextEditableCell, onBlur: save }) })) }))) : (_jsx(EditableCellValueWrapper, { children: children }));
    }
    return (_jsx(Cell, Object.assign({ editable: editable, editing: editing, onClick: () => !editing && toggleEdit(true) }, restProps, { children: childNode })));
};
export const FinancialsTable = ({ financials, isEditing, dispatch }) => {
    const { t } = useTranslation();
    const [scrollY, setScrollY] = useState(window.innerHeight - HEADINGS_HEIGHT);
    const [editStatement, setEditStatement] = useState();
    const [focusedCell, setFocusedCell] = useState(null);
    const { localizedNumber } = useFormatter();
    useEffect(() => {
        setFocusedCell(null);
    }, [isEditing]);
    // This properly sets the scroll behavior of the table
    useEffect(() => {
        function handleResize() {
            setScrollY(window.innerHeight - HEADINGS_HEIGHT);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const handleMenuClick = ({ key, statement }) => {
        switch (key) {
            case MenuOptions.DELETE:
                dispatch({ type: ActionType.DELETE_STATEMENT, statementId: statement.financial_data_id });
                return;
            case MenuOptions.SETTINGS:
                setEditStatement(statement);
                return;
            default:
                return;
        }
    };
    const data = expandable.map((section) => {
        return {
            key: section,
            section: t(`application.financials.${section}`),
            children: tableRows[section].map((row) => {
                const isTotalRow = totalsRows.some((boldRow) => boldRow === row);
                const data = financials.reduce((acc, financialStatement) => {
                    acc[financialStatement.financial_data_id] = isTotalRow
                        ? financialStatement.summary_data &&
                            financialStatement.summary_data[row]
                        : financialStatement.input_data[row];
                    return acc;
                }, {});
                return Object.assign({ key: `${section}-${row}`, inputDataKey: row, isBold: isTotalRow, section: t(`application.financials.${row}`) }, data);
            }),
        };
    });
    const defaultColumns = [
        {
            dataIndex: "section",
            width: 330,
            align: "left",
            title: "",
            editable: false,
        },
        ...financials.map((financialStatement) => {
            return {
                dataIndex: financialStatement.financial_data_id,
                width: 210,
                align: "right",
                editable: isEditing,
                render: (value) => {
                    if (!isValidNumericValue(value))
                        return "";
                    return _jsx("span", { children: localizedNumber({ value }) });
                },
                title: () => {
                    return (_jsxs(_Fragment, { children: [_jsxs(HeaderCell, { children: [_jsx(Typography.Text, { children: financialStatement.statement_type === FinancialStatementType.InterimFinancials
                                            ? `${t("application.financials.interim")} ${dayjs(financialStatement.financials_date).format("YYYY")}.${dayjs(financialStatement.financials_date).format("MM")}`
                                            : `${t("application.financials.annual")} ${dayjs(financialStatement.financials_date).format("YYYY")}` }), isEditing && (_jsx(Dropdown, Object.assign({ menu: {
                                            onClick: ({ key }) => handleMenuClick({
                                                key: key,
                                                statement: financialStatement,
                                            }),
                                            items: [
                                                {
                                                    label: t("application.financials.settings"),
                                                    key: MenuOptions.SETTINGS,
                                                    icon: _jsx(SettingOutlined, {}),
                                                },
                                                {
                                                    label: t("application.financials.delete"),
                                                    key: MenuOptions.DELETE,
                                                    icon: _jsx(DeleteOutlined, {}),
                                                },
                                            ],
                                        }, placement: "bottom" }, { children: _jsx(MoreOutlined, {}) })))] }), financialStatement.origin && (_jsx(Row, { children: _jsx(Tag, { children: t(`application.financials.${financialStatement.origin}`) }) }))] }));
                },
            };
        }),
        { dataIndex: "empty" }, // one extra empty column to properly handle the layout
    ];
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return Object.assign(Object.assign({}, col), { onCell: (record) => {
                const isEditable = !(expandable.some((section) => section === record.key) ||
                    cellsWithTotalValues.some((total) => total === record.key));
                return {
                    record,
                    editable: isEditable,
                    isEditing,
                    dataIndex: col.dataIndex,
                    dispatch,
                    focusedCell,
                    setFocusedCell,
                    data,
                };
            } });
    });
    const components = {
        body: {
            cell: EditableCell,
        },
    };
    return (_jsxs(_Fragment, { children: [_jsx(Table, { components: components, columns: columns, dataSource: data, bordered: true, scroll: { y: scrollY }, pagination: { hideOnSinglePage: true }, expandable: {
                    indentSize: 0,
                    defaultExpandAllRows: true,
                    expandIcon: ({ expanded, onExpand, record }) => {
                        if (!expandable.some((section) => section === record.key))
                            return null;
                        return expanded ? (_jsx(UpIcon, { onClick: (e) => onExpand(record, e) })) : (_jsx(DownIcon, { onClick: (e) => onExpand(record, e) }));
                    },
                } }), editStatement && (_jsx(FinancialsModal, { statement: editStatement, onCancel: () => setEditStatement(undefined), onFinish: ({ type, date }) => {
                    dispatch({
                        type: ActionType.UPDATE_STATEMENT_SETTINGS,
                        statementId: editStatement.financial_data_id,
                        statementType: type,
                        date,
                    });
                    setEditStatement(undefined);
                } }))] }));
};
