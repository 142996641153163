import { FC, ReactElement } from "react";

import { Divider as AntdDivider, Col, Drawer, DrawerProps, Row, Spin, Typography } from "antd";
import styled from "styled-components";

const Divider = styled(AntdDivider)`
	margin: 0;
`;

const CardContainer = styled.div`
	padding: 24px;
`;

const DetailsLabel = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: rgba(0, 0, 0, 0.45);

	.anticon {
		margin-right: 8px;
	}
`;

const Line = styled.div`
	margin: 4px 0;
`;

export const Heading = styled.div`
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	color: rgba(0, 0, 0, 0.85);
	margin-bottom: 22px;
`;

export const StyledListRow = styled.div`
	margin-bottom: 12px;
`;

const LabelContent = styled(Typography.Text)`
	word-break: break-all;
`;

export const PreviewDetailsLine = ({
	icon,
	label,
	children,
	copyable,
}: {
	icon?: ReactElement;
	label: string;
	children: ReactElement | string;
	copyable?: boolean;
}) => (
	<Line>
		<Row>
			<Col span={10}>
				<DetailsLabel>
					{icon || false} {label}
				</DetailsLabel>
			</Col>
			<Col span={14}>
				<LabelContent copyable={copyable}>{children}</LabelContent>
			</Col>
		</Row>
	</Line>
);

export interface LineData {
	icon?: ReactElement;
	label: string;
	value: ReactElement | ReactElement[] | string;
}

interface Props extends DrawerProps {
	entityId: string;
	loading?: boolean;
	visible: boolean;
	lineData?: LineData[];
	header?: ReactElement;
	children?: ReactElement | ReactElement[];
}

const Preview: FC<Props> = ({
	entityId,
	loading = false,
	visible,
	lineData,
	header,
	children,
	...props
}: Props) => {
	return (
		<Drawer
			width={400}
			placement="right"
			mask={false}
			closable={false}
			bodyStyle={{ padding: 0 }}
			open={visible}
			title={
				<Row justify="space-between" wrap={false} style={{ wordBreak: "break-all" }}>
					{header}
				</Row>
			}
			{...props}
		>
			<Spin spinning={loading}>
				{lineData && (
					<CardContainer>
						{lineData.map(({ icon, label, value }) => (
							<PreviewDetailsLine key={`${entityId}_${label}`} icon={icon} label={label}>
								<Typography.Text>{value}</Typography.Text>
							</PreviewDetailsLine>
						))}
					</CardContainer>
				)}
				{children}
			</Spin>
		</Drawer>
	);
};

export const Cell = ({ children }: { children?: ReactElement | ReactElement[] }) => {
	return (
		<>
			<Divider />
			<CardContainer>{children}</CardContainer>
		</>
	);
};

export default Preview;
