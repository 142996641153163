var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse as AntdCollapse, Tag as AntdTag, Divider, Space, Switch, Typography, message, } from "antd";
import styled from "styled-components";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { Axios } from "src/utils/Axios";
import SignatoryDetailsPopover from "./SignatoryDetailsPopover";
const { Text } = Typography;
const ContentWrapper = styled.div `
	padding: 24px;
`;
const Collapse = styled(AntdCollapse) `
	background-color: #ffffff;

	.ant-collapse-header {
		padding: 24px !important;
	}
`;
const SwitchItem = styled(Space) `
	margin: 0px 24px;
`;
const Tag = styled(AntdTag) `
	border: none;
	background: #f5f5f5;
	border-radius: 200px;
`;
const emptyRequirements = {
    is_loan_signature_required: false,
    is_ubo_signature_required: false,
    is_video_id_required: false,
    guarantor_contract_url: "",
    guarantor_contract_filename: "",
    digital_signature_url: "",
};
var Field;
(function (Field) {
    Field["kyc"] = "is_video_id_required";
    Field["loan-contract"] = "is_loan_signature_required";
    Field["guarantor-contract"] = "is_ubo_signature_required";
})(Field || (Field = {}));
const BeforeSignatureView = ({ signatories, onUpdate }) => {
    const { t } = useTranslation();
    const { updatePendingItems } = useApplication();
    const [isLoadingUpdate, setIsLoadingUpdate] = useState();
    const updateSignatoryRequirements = ({ signatory, field, checked, }) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoadingUpdate({ signatoryId: signatory.signatory_id, field });
        yield Axios.patch(`/admin/signatories/${signatory.signatory_id}/requirements`, Object.assign(Object.assign({}, (signatory.requirements || emptyRequirements)), { signatory_id: signatory.signatory_id, application_id: signatory.application_id, [field]: checked }))
            .then(() => __awaiter(void 0, void 0, void 0, function* () {
            void message.success(t("application.contract.signatory-requirements-update-success"));
            yield onUpdate();
        }))
            .catch((e) => Axios.error(e, t("application.contract.signatory-requirements-update-fail")))
            .finally(() => {
            updatePendingItems();
            setIsLoadingUpdate(undefined);
        });
    });
    const signatureRequirements = Object.keys(Field);
    return (_jsx(ContentWrapper, { children: _jsx(Space, Object.assign({ direction: "vertical", size: 30, style: { width: "100%" } }, { children: signatories.map((signatory) => {
                const requirementsCount = signatureRequirements.filter((requirement) => { var _a; return (_a = signatory.requirements) === null || _a === void 0 ? void 0 : _a[Field[requirement]]; }).length;
                return (_jsx(Collapse, { children: _jsx(Collapse.Panel, Object.assign({ header: _jsxs(Space, Object.assign({ size: 16 }, { children: [_jsx(SignatoryDetailsPopover, { signatory: signatory }), _jsx(Tag, { children: requirementsCount || t("application.contract.none") })] })) }, { children: signatureRequirements.map((field, idx) => {
                            var _a;
                            return (_jsxs(_Fragment, { children: [_jsxs(SwitchItem, { children: [_jsx(Switch, { loading: (isLoadingUpdate === null || isLoadingUpdate === void 0 ? void 0 : isLoadingUpdate.signatoryId) === signatory.signatory_id &&
                                                    (isLoadingUpdate === null || isLoadingUpdate === void 0 ? void 0 : isLoadingUpdate.field) === Field[field], checked: !!((_a = signatory.requirements) === null || _a === void 0 ? void 0 : _a[Field[field]]), onChange: (checked) => void updateSignatoryRequirements({
                                                    signatory,
                                                    checked,
                                                    field: Field[field],
                                                }) }), _jsx(Text, Object.assign({ strong: true }, { children: t(`application.signatures.${field}`) }))] }, field), idx !== signatureRequirements.length - 1 && _jsx(Divider, {})] }));
                        }) }), signatory.signatory_id) }, signatory.signatory_id));
            }) })) }));
};
export default BeforeSignatureView;
