import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Form, Layout, Select, Spin, Typography, message } from "antd";
import styled from "styled-components";
import { FeatureName } from "@teylor-tools/Api";
import { DataCardItem } from "@ui/data-display/DataCard";
import PasswordFormItem from "@ui/form/form-items/password-form-item/PasswordFormItem";
import MainHeader from "@ui/main-header/MainHeader";
import { DataCard } from "src/components/DataCard";
import { Axios } from "src/utils/Axios";
const { Content } = Layout;
const Wrapper = styled.div `
	margin: 24px;
	display: grid;
	gap: 24px;
	grid-template-rows: auto 1fr;
	grid-template-columns: minmax(460px, 1464px);
`;
const ProfilePage = () => {
    const { t, i18n } = useTranslation();
    const [languageForm] = Form.useForm();
    const [passwordForm] = Form.useForm();
    const [isLoadingPassword, setIsLoadingPassword] = useState(false);
    const [isLoadingLanguage, setIsLoadingLanguage] = useState(false);
    const { features } = useSelector((state) => state.configState);
    const { email, name } = useSelector((state) => state.profileState);
    const languages = useMemo(() => {
        var _a;
        return ((_a = features.find((f) => f.name === FeatureName.InterfaceLanguages)) === null || _a === void 0 ? void 0 : _a.value.supported) || [];
    }, [features]);
    const onLanguageChange = ({ language }) => {
        setIsLoadingLanguage(true);
        Axios.patch(`/admin/profile`, { interface_language: language })
            .then(() => void i18n.changeLanguage(language), (err) => Axios.error(err))
            .finally(() => setIsLoadingLanguage(false));
    };
    const onPasswordChange = ({ newPass, currentPass }) => {
        setIsLoadingPassword(true);
        Axios.post("/admin/account/change_password", { password: newPass, old_password: currentPass })
            .then(() => {
            passwordForm.resetFields();
            void message.success(t("profile.password.password-change-success"));
        })
            .catch((error) => Axios.error(error))
            .finally(() => {
            setIsLoadingPassword(false);
        });
    };
    return (_jsx(Layout, Object.assign({ style: { minHeight: "100vh" } }, { children: _jsx(Layout, { children: _jsxs(Content, { children: [_jsx(MainHeader, { title: t("profile.page-title") }), _jsxs(Wrapper, { children: [_jsxs(DataCard, Object.assign({ title: t("profile.personal-info.card-title") }, { children: [_jsx(DataCardItem, { label: t("profile.personal-info.name"), value: name }), _jsx(DataCardItem, { label: t("profile.personal-info.email"), value: email })] })), _jsx(Spin, Object.assign({ spinning: isLoadingPassword }, { children: _jsx(DataCard, Object.assign({ title: t("profile.password.card-title") }, { children: _jsxs(Form, Object.assign({ form: passwordForm, layout: "vertical", onFinish: onPasswordChange }, { children: [_jsx(PasswordFormItem, { name: "currentPass", label: t("profile.password.current-password"), checkPattern: false }), _jsx(PasswordFormItem, { name: "newPass", label: t("profile.password.new-password") }), _jsx(PasswordFormItem, { name: "newPassRepeat", label: t("profile.password.new-password-repeat"), rules: [
                                                    { required: true, message: t("application_form.field-required") },
                                                    {
                                                        validator: (_, passRepeat) => passRepeat === passwordForm.getFieldValue("newPass")
                                                            ? Promise.resolve()
                                                            : Promise.reject(t("application_form.password-repeat-invalid")),
                                                    },
                                                ] }), _jsx("div", Object.assign({ style: { marginTop: 24 } }, { children: _jsx(Button, Object.assign({ type: "primary", size: "large", htmlType: "submit" }, { children: t("profile.password.change-password-btn") })) }))] })) })) })), _jsx(Spin, Object.assign({ spinning: isLoadingLanguage }, { children: _jsx(DataCard, Object.assign({ title: t("profile.language-select.card-title") }, { children: _jsxs(Form, Object.assign({ form: languageForm, layout: "vertical", onFinish: onLanguageChange }, { children: [_jsx(Form.Item, Object.assign({ label: t("profile.language-select.label"), name: "language", initialValue: i18n.language, style: { marginBottom: 4 } }, { children: _jsx(Select, { children: languages.map((langCode) => (_jsx(Select.Option, Object.assign({ value: langCode }, { children: t(`common.languages.${langCode}`) }), langCode))) }) })), _jsx(Typography.Text, Object.assign({ type: "secondary", style: { fontSize: 12 } }, { children: t(`profile.language-select.info`) })), _jsx("div", Object.assign({ style: { marginTop: 24 } }, { children: _jsx(Button, Object.assign({ type: "primary", size: "large", htmlType: "submit" }, { children: t("common.save") })) }))] })) })) }))] })] }) }) })));
};
export default ProfilePage;
