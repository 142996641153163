import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Typography, message, theme } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { downloadFileFromUrl } from "@teylor-tools/utils/downloadFileFromUrl";
import { Axios } from "src/utils/Axios";
const { useToken } = theme;
const ConfirmModal = styled(Modal) `
	padding: 48px;
`;
const DocumentsBulkActions = ({ apiBasePath, apiGetArchivePath, docIdsInSelection, docsInUpload, removeDocFromUpload, allowDelete, onBulkDeleteSuccess, }) => {
    const { t } = useTranslation();
    const { token } = useToken();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [actionIds, idsToRemoveFromUploadMode] = useMemo(() => {
        // selectedDocIds stores temporaryId of documents in upload mode
        // find document_id for those docs to perform actions on BE
        const selectedDocsInUploadMode = docsInUpload.filter((doc) => docIdsInSelection.includes(doc.updateId));
        // no files in upload mode selected, easy-peasy
        if (!selectedDocsInUploadMode.length)
            return [docIdsInSelection, []];
        // find docs to perform BE actions
        const selectedDocIdsInUploadMode = selectedDocsInUploadMode.reduce((acc, curr) => {
            acc.push(curr.updateId);
            return acc;
        }, []);
        const selectedDocIdsNotInUploadMode = [...docIdsInSelection].filter((id) => !selectedDocIdsInUploadMode.includes(id));
        const successfulDocIdsInUploadMode = selectedDocsInUploadMode.reduce((acc, curr) => {
            if (curr.status === "success" && curr.document_id)
                acc.push(curr.document_id);
            return acc;
        }, []);
        return [
            [...selectedDocIdsNotInUploadMode, ...successfulDocIdsInUploadMode],
            selectedDocIdsInUploadMode,
        ];
    }, [docIdsInSelection, docsInUpload]);
    const bulkDelete = () => {
        setIsLoading(true);
        const deletePromises = actionIds.map((docId) => Axios.delete(`${apiBasePath}/documents/${docId}`));
        Promise.all(deletePromises)
            .then(() => void message.success(t("application.documents.files-deleted")))
            .catch(() => void message.error(t("application.documents.error.files-deleted")))
            .finally(() => {
            onBulkDeleteSuccess();
            setIsLoading(false);
        });
        idsToRemoveFromUploadMode.forEach((id) => removeDocFromUpload(id));
    };
    const bulkDownloadDocs = () => {
        downloadFileFromUrl(`${Axios.apiPath}/${apiGetArchivePath}/${actionIds.join(",")}`, `documents-${dayjs().format("YYYY.MM.DD_hh:mm")}`);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Row, Object.assign({ gutter: 24 }, { children: [allowDelete && (_jsx(Col, { children: _jsx(Button, Object.assign({ danger: true, icon: _jsx(DeleteOutlined, {}), onClick: () => setDeleteModalOpen(true) }, { children: t("application.documents.delete-selected") })) })), _jsx(Button, Object.assign({ type: "primary", icon: _jsx(DownloadOutlined, {}), onClick: bulkDownloadDocs, disabled: !actionIds.length }, { children: t("application.documents.download-selected") }))] })), _jsxs(ConfirmModal, Object.assign({ open: deleteModalOpen, footer: [
                    _jsx(Button, Object.assign({ type: "ghost", onClick: () => setDeleteModalOpen(false) }, { children: t("application.documents.cancel") }), "back"),
                    _jsx(Button, Object.assign({ danger: true, onClick: () => {
                            bulkDelete();
                            setDeleteModalOpen(false);
                        } }, { children: t("application.documents.delete") }), "submit"),
                ], onCancel: () => setDeleteModalOpen(false), okButtonProps: { loading: isLoading }, cancelButtonProps: { loading: isLoading } }, { children: [_jsxs(Row, Object.assign({ justify: "start" }, { children: [_jsx(Col, Object.assign({ span: 2 }, { children: _jsx(ExclamationCircleOutlined, { style: { color: token.colorError, fontSize: "24px" } }) })), _jsx(Col, { children: _jsx(Typography.Title, Object.assign({ level: 5 }, { children: t("application.documents.confirm-delete") })) })] })), _jsx(Row, { children: _jsx(Col, Object.assign({ offset: 2 }, { children: _jsx(Typography.Text, { children: t("application.documents.delete-warning") }) })) })] }))] }));
};
export default DocumentsBulkActions;
