import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Form, Input, Row, Typography } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { MFAStep } from "@teylor-tools/Api";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";
import { Routes } from "src/Routes";
import { login } from "src/store/actions/authActions";
import { Axios } from "src/utils/Axios";
import { MFAView } from "./mfa-view/MFAView";
const LoginPage = () => {
    const [sending, setSending] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isMfaEnabled, setMfaEnabled] = useState(false);
    const [mfaInstruction, setMfaInstruction] = useState();
    const [userData, setUserData] = useState();
    const onSubmit = (form) => {
        setSending(true);
        Axios.post("/admin/account/login", form)
            .then(({ data }) => {
            var _a;
            if (data.is_mfa_enabled && ((_a = data === null || data === void 0 ? void 0 : data.next_login_step) === null || _a === void 0 ? void 0 : _a.step) !== MFAStep.LoginStepNone) {
                setMfaEnabled(data.is_mfa_enabled);
                setMfaInstruction(data.next_login_step);
                setUserData({ email: data.email });
            }
            else {
                dispatch(login());
            }
        })
            .catch((error) => void Axios.error(error, t("login.error")))
            .finally(() => setSending(false));
    };
    return (_jsx(_Fragment, { children: !isMfaEnabled ? (_jsxs(_Fragment, { children: [_jsx(Typography.Title, Object.assign({ level: 3, style: { marginBottom: "48px" } }, { children: t("login.title") })), _jsxs(Form, Object.assign({ onFinish: onSubmit, layout: "vertical" }, { children: [_jsx(EmailFormItem, { rules: [
                                {
                                    required: true,
                                    message: t("application_form.field-required"),
                                },
                            ] }), _jsx(FormItem, Object.assign({ name: "password", label: t("application_form.password"), labelAlign: "left", rules: [
                                {
                                    required: true,
                                    message: t("application_form.field-required"),
                                },
                            ] }, { children: _jsx(Input.Password, {}) })), _jsx(Row, Object.assign({ justify: "center", style: { marginTop: "48px" } }, { children: _jsx(Col, { children: _jsx(Button, Object.assign({ type: "primary", size: "large", htmlType: "submit", loading: sending }, { children: t("login.login") })) }) })), _jsx(Row, Object.assign({ justify: "center", style: { marginTop: "16px" } }, { children: _jsx(Col, { children: _jsxs(Typography.Text, { children: [t("login.forgot_password_hint"), _jsx(Link, Object.assign({ to: Routes.ForgotPassword, style: { marginLeft: "4px" } }, { children: t("login.forgot_password_hint_click_here") }))] }) }) }))] }))] })) : (_jsx(MFAView, { option: mfaInstruction, userData: userData })) }));
};
export default LoginPage;
