import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PaperClipOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import styled from "styled-components";
const EmptyContainer = styled.div `
	display: flex;
	margin-top: 48px;
	flex-direction: column;
	place-items: center;
`;
const UploadBtn = styled(Button) `
	display: block;
	margin-inline: auto;
	margin-top: 24px;
`;
export const FinCrEmpty = ({ goToDocuments }) => {
    const { t } = useTranslation();
    return (_jsxs(EmptyContainer, { children: [_jsx(PaperClipOutlined, { style: { fontSize: "42px", color: "#d9d9d9" } }), _jsx(Typography.Title, Object.assign({ level: 5 }, { children: t("fincr.empty") })), _jsx(UploadBtn, Object.assign({ onClick: goToDocuments, type: "primary", icon: _jsx(PlusOutlined, { style: { fontSize: "16px" } }) }, { children: t("fincr.upload-documents") }))] }));
};
