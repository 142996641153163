import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { EuroCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import styled from "styled-components";
const { Title, Text } = Typography;
const Wrapper = styled.div `
	margin-top: 24px;
	text-align: center;
	padding-top: 68px;
	padding-bottom: 40px;
`;
const EuroIcon = styled(EuroCircleOutlined) `
	font-size: 48px;
	color: #d9d9d9;
	margin-bottom: 24px;
`;
const ButtonWrapper = styled.div `
	margin-top: 32px;
`;
export const FinancialsEmpty = ({ addFinancials }) => {
    const { t } = useTranslation();
    return (_jsx(_Fragment, { children: _jsxs(Wrapper, { children: [_jsx(EuroIcon, {}), _jsx(Title, Object.assign({ level: 5 }, { children: t("financials.empty.no-financials-title") })), _jsx(Text, Object.assign({ type: "secondary" }, { children: t("financials.empty.no-financials-subtitle") })), _jsx(ButtonWrapper, { children: _jsx(Button, Object.assign({ type: "primary", size: "large", onClick: addFinancials, icon: _jsx(PlusOutlined, {}) }, { children: t("financials.empty.add-statement") })) })] }) }));
};
