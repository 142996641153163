import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined, DownOutlined, ExclamationCircleOutlined, FileOutlined, LoadingOutlined, } from "@ant-design/icons";
import { Button, Col, Dropdown, Form, Modal, Row, Tag, Tooltip, Typography, message, theme, } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { DocumentType, OcrFileDocumentType, OcrFilePeriodType, } from "@teylor-tools/Api";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import { downloadFileFromUrl } from "@teylor-tools/utils/downloadFileFromUrl";
import { default as BaseDatePicker } from "src/components/ui/DatePicker";
import FinancialsIssues from "src/pages/applications/subpages/ApplicationFinancials/FinancialsIssues";
import { finalStates, scanningStates } from "src/pages/modules/Documents/documents.helpers";
import { Axios } from "src/utils/Axios";
const { Text } = Typography;
const DocRow = styled.div `
	padding: 20px 24px;
	margin: 0 !important;

	&:hover {
		background-color: #fafafa;
	}
`;
const FilenameCol = styled.div `
	display: flex;
	align-items: center;
	gap: 10px;
	margin-right: 8px;
`;
const FileIcon = styled(FileOutlined) `
	color: ${({ theme }) => theme.colorPrimary};
`;
const DataText = styled(Text) `
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	word-break: break-all !important;
`;
const FormItem = styled(Form.Item) `
	margin-bottom: 0;

	.ant-form-item-control-input {
		min-height: 22px;
	}
`;
const DatePicker = styled(BaseDatePicker) `
	height: 22px;

	.ant-picker-header {
		display: none;
	}
`;
const ActionsWrapper = styled.div `
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
`;
const IssueTag = styled(Tag) `
	cursor: pointer;
`;
const LinkButton = styled(Button) `
	padding: 0;
`;
var DownloadFormats;
(function (DownloadFormats) {
    DownloadFormats["json"] = "json";
    DownloadFormats["xlsx"] = "xlsx";
})(DownloadFormats || (DownloadFormats = {}));
const getStatusIcon = (isScanning, errorColor, status) => {
    if (isScanning)
        return _jsx(LoadingOutlined, {});
    if (!status)
        return _jsx(CheckCircleOutlined, { style: { color: "gray" } });
    switch (status) {
        case "error":
        case "request_failed":
            return _jsx(ExclamationCircleOutlined, { style: { color: errorColor } });
        case "upload_pending":
        case "scanning":
            return _jsx(LoadingOutlined, {});
        case "completed":
            return _jsx(CheckCircleOutlined, { style: { color: "green" } });
    }
};
const FinCRDocumentRow = ({ doc, updateDocs }) => {
    var _a;
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [isInEditMode, setIsInEditMode] = useState(false);
    const [showIssuesModal, setShowIssuesModal] = useState(false);
    const [isScanning, setIsScanning] = useState(false);
    const [datepickerData, setDatepickerData] = useState({
        financials_date: doc.financials_date ? doc.financials_date : undefined,
    });
    const [ocrResponse, setOcrResponse] = useState();
    const isInterim = useMemo(() => {
        return !!(doc.document_type &&
            [
                DocumentType.CompanyDocBwa,
                DocumentType.CompanyDocBwaandsusa,
                DocumentType.CompanyDocSusa,
                // @ts-ignore dynamic types
            ].includes(doc.document_type));
    }, [doc.document_type]);
    const disableEditMode = () => {
        form.resetFields();
        setIsInEditMode(false);
    };
    const enableEditMode = () => {
        form.setFieldsValue({
            financials_date: datepickerData.financials_date
                ? dayjs(datepickerData.financials_date)
                : undefined,
        });
        setIsInEditMode(true);
    };
    const hasAllRequiredFields = (financials_date) => {
        if (!financials_date) {
            void message.error(t("application.fincr.missing-fields"));
            return false;
        }
        return true;
    };
    const renderAdminActions = () => {
        var _a;
        const downloadItems = [
            {
                label: t("application.fincr.json"),
                key: DownloadFormats.json,
            },
            {
                label: t("application.fincr.xlsx"),
                key: DownloadFormats.xlsx,
            },
        ];
        return isInEditMode ? (_jsxs(ActionsWrapper, { children: [_jsx(LinkButton, Object.assign({ type: "link", onClick: disableEditMode }, { children: t("common.cancel") })), _jsx(LinkButton, Object.assign({ type: "link", onClick: form.submit }, { children: t("common.save") }))] })) : (_jsxs(ActionsWrapper, { children: [!!((_a = doc.warnings) === null || _a === void 0 ? void 0 : _a.length) && !isScanning && (_jsx(IssueTag, Object.assign({ onClick: () => setShowIssuesModal(true), color: "error" }, { children: t("application.fincr.issues") }))), (doc.status === "completed" || doc.status === "error") && (_jsx(Dropdown, Object.assign({ disabled: isScanning, menu: {
                        items: downloadItems,
                        onClick: ({ key }) => {
                            handleDownload(key, doc.document_id);
                        },
                    } }, { children: _jsxs(LinkButton, Object.assign({ type: "link" }, { children: [t("application.fincr.download"), _jsx(DownOutlined, {})] })) }))), _jsx(LinkButton, Object.assign({ type: "link", disabled: isScanning, onClick: enableEditMode }, { children: t("common.edit") })), doc.status && finalStates.includes(doc.status) ? (_jsx(LinkButton, Object.assign({ type: "link", disabled: isScanning, onClick: handleRescan }, { children: t("application.fincr.rescan") }))) : (_jsx(Button, Object.assign({ type: "primary", ghost: true, size: "small", disabled: !(doc.financials_date || datepickerData.financials_date) || isScanning, onClick: handleScan }, { children: t("application.fincr.scanBtn") })))] }));
    };
    const handleDownload = (format, docId) => {
        downloadFileFromUrl(`${Axios.apiPath}/admin/ocr/${docId}/results?format=${format}`, `FinCR_results_${docId}.${format}`);
    };
    const handleScan = () => {
        if (!hasAllRequiredFields(datepickerData.financials_date))
            return;
        setIsScanning(true);
        const reqBody = isInterim
            ? {
                document_id: doc.document_id,
                financials_date: datepickerData.financials_date,
                period_type: OcrFilePeriodType.Interim,
                document_type: OcrFileDocumentType.DeBwaSusa,
            }
            : {
                document_id: doc.document_id,
                financials_date: datepickerData.financials_date,
                period_type: OcrFilePeriodType.FullYear,
                document_type: OcrFileDocumentType.AnnualStatement,
            };
        void Axios.post("/admin/ocr", reqBody).then(({ data }) => {
            if (!finalStates.includes(data.status)) {
                pollFileStatus();
            }
        });
    };
    const handleRescan = () => {
        Modal.confirm({
            icon: _jsx(ExclamationCircleOutlined, { style: { color: token.colorError } }),
            title: t("application.fincr.modal-title"),
            content: t("application.fincr.modal-content"),
            centered: true,
            cancelText: t("common.cancel"),
            okText: t("application.fincr.rescan"),
            okButtonProps: { style: { backgroundColor: token.colorError } },
            maskClosable: true,
            onOk: () => {
                setIsScanning(true);
                void Axios.post(`/admin/ocr/${doc.document_id}/rescan`).then(() => {
                    pollFileStatus();
                });
            },
        });
    };
    const handleSave = (v) => {
        const { financials_date } = datepickerData;
        const updatedFinancialsDate = v.financials_date.format("YYYY-MM-DD");
        if (!hasAllRequiredFields(updatedFinancialsDate || financials_date)) {
            return disableEditMode();
        }
        // if doc hasn't been scanned yet, update its financials_date locally for a scan, otherwise patch BE
        if (!doc.status) {
            setDatepickerData(Object.assign(Object.assign({}, datepickerData), (updatedFinancialsDate && { financials_date: updatedFinancialsDate })));
            return disableEditMode();
        }
        const reqBody = isInterim
            ? {
                document_id: doc.document_id,
                financials_date: updatedFinancialsDate,
                period_type: OcrFilePeriodType.Interim,
            }
            : {
                document_id: doc.document_id,
                financials_date: updatedFinancialsDate,
                period_type: OcrFilePeriodType.FullYear,
            };
        Axios.patch(`admin/ocr/${doc.document_id}`, reqBody)
            .then(updateDocs, (error) => void Axios.error(error))
            .finally(disableEditMode);
    };
    const pollFileStatus = () => {
        Axios.get(`/admin/ocr/${doc.document_id}`).then(({ data }) => setOcrResponse(data), (error) => void Axios.error(error));
    };
    useEffect(() => {
        isScanning && doc.status && setIsScanning(scanningStates.includes(doc.status));
        if (doc.financials_date) {
            setDatepickerData({
                financials_date: doc.financials_date,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doc]);
    useEffect(() => {
        if ((ocrResponse === null || ocrResponse === void 0 ? void 0 : ocrResponse.status) && finalStates.includes(ocrResponse.status)) {
            return updateDocs();
        }
        let pollingTimer;
        if (((ocrResponse === null || ocrResponse === void 0 ? void 0 : ocrResponse.status) && scanningStates.includes(ocrResponse.status)) ||
            (doc.status && scanningStates.includes(doc.status))) {
            pollingTimer = setTimeout(pollFileStatus, 5000);
        }
        return () => clearTimeout(pollingTimer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ocrResponse]);
    return (_jsxs(_Fragment, { children: [_jsx(DocRow, { children: _jsxs(Row, Object.assign({ gutter: 16, justify: "space-between", align: "middle" }, { children: [_jsx(Col, Object.assign({ span: 16 }, { children: _jsx(Form, Object.assign({ form: form, onFinish: handleSave }, { children: _jsxs(Row, Object.assign({ gutter: [4, 8], align: "middle" }, { children: [_jsxs(FilenameCol, { children: [getStatusIcon(isScanning, token.colorError, doc.status), _jsx(FileIcon, {}), _jsx(Tooltip, Object.assign({ title: doc.document_name }, { children: _jsx(DataText, { children: doc.document_name }) }))] }), _jsx(Col, { children: _jsx(Tag, Object.assign({ style: { marginRight: 4 } }, { children: isInterim ? t("application.fincr.interim") : t("application.fincr.annual") })) }), isInEditMode ? (_jsx(_Fragment, { children: _jsx(Col, { children: _jsx(FormItem, Object.assign({ name: "financials_date" }, { children: _jsx(DatePicker, { format: dayMonthYear, style: { minWidth: 80 } }) })) }) })) : (_jsx(Col, { children: datepickerData.financials_date && (_jsx(Tag, { children: dayjs(datepickerData.financials_date).format(dayMonthYear) })) }))] })) })) })), _jsx(Col, Object.assign({ span: 8 }, { children: renderAdminActions() }))] })) }, doc.document_id), !!((_a = doc.warnings) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(Modal, Object.assign({ title: t("application.fincr.ocr-issues"), open: showIssuesModal, closable: true, cancelText: t("common.close"), onCancel: () => setShowIssuesModal(false), okText: t("application.fincr.rescan"), onOk: () => {
                    handleRescan();
                    setShowIssuesModal(false);
                } }, { children: _jsx(FinancialsIssues, { warnings: doc.warnings }) })))] }));
};
export default FinCRDocumentRow;
