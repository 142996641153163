import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { SelectOutlined } from "@ant-design/icons";
import { Button, Col, Input, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import dayjs from "dayjs";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import MainHeader from "@ui/main-header/MainHeader";
import SearchList, { DIRECT_OPEN_COLUMN } from "src/components/SearchList";
import TaskPriorityIndicator from "src/components/tasks/TaskPriorityIndicator";
import TaskStatusIndicator from "src/components/tasks/TaskStatusIndicator";
import { TaskPriorities, TaskStatuses, navigateToTaskEntity, } from "src/components/tasks/tasks.types";
import TimePassed from "src/components/ui/TimePassed";
import { useTaskUsers } from "src/hooks/useTaskUsers";
import { Axios } from "src/utils/Axios";
import { filterSelectByIdAndLabel } from "src/utils/filterSelectByIdAndLabel";
import TaskPreview from "./TaskPreview";
var SearchField;
(function (SearchField) {
    SearchField["text"] = "text";
    SearchField["status"] = "status";
    SearchField["assignee_id"] = "assignee_id";
    SearchField["reporter_id"] = "reporter_id";
    SearchField["priority"] = "priority";
})(SearchField || (SearchField = {}));
const TasksPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [previewTask, setPreviewTask] = useState();
    const [users, usersLoading] = useTaskUsers();
    const ref = useRef();
    const { userId } = useSelector((state) => state.profileState);
    const columns = [
        {
            render: (t) => (_jsx(Button, { type: "link", icon: _jsx(SelectOutlined, {}), onClick: () => navigateToTaskEntity(navigate, t.related_entity_type, t.related_entity_id) })),
            className: DIRECT_OPEN_COLUMN,
        },
        {
            dataIndex: "subject",
            title: t("tasks.summary"),
            render: (summary) => (summary.length > 80 ? summary.slice(0, 80) + "..." : summary),
        },
        {
            dataIndex: "status",
            title: t("tasks.status"),
            render: (status) => _jsx(TaskStatusIndicator, { status: status, mode: "tag" }),
        },
        {
            dataIndex: "priority",
            title: t("tasks.priority"),
            render: (priority) => _jsx(TaskPriorityIndicator, { priority: priority, showLabel: true }),
            width: 115,
            sorter: true,
        },
        {
            dataIndex: "due_date",
            title: t("tasks.due-date"),
            render: (date) => (date ? dayjs(date).format(dayMonthYear) : "-"),
            sorter: true,
        },
        {
            dataIndex: "assignee_name",
            title: t("tasks.assignee"),
        },
        {
            dataIndex: "reporter_name",
            title: t("tasks.reporter"),
        },
        {
            dataIndex: "created_at",
            title: t("tasks.created-at"),
            render(created) {
                return _jsx(TimePassed, { date: created });
            },
            sorter: true,
        },
    ];
    const handleTaskUpdate = () => {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getTableData().then((tasks) => {
            if (previewTask) {
                setPreviewTask(tasks.find((task) => task.task_id === previewTask.task_id));
            }
        }, (err) => {
            void Axios.error(err);
            setPreviewTask(undefined);
        });
    };
    useEffect(() => {
        const userExists = users.find((user) => user.id === userId);
        if (userExists && userId) {
            const queryParams = {
                assignee_id: userId,
            };
            navigate({ search: `?${createSearchParams(queryParams)}` });
        }
    }, [users, userId, navigate]);
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("tasks.title") }), _jsxs(SearchList, Object.assign({ ref: ref, endpoint: "/admin/tasks", columns: columns, showingOfString: "tasks.search.search-showing", onRow: (record) => ({ onClick: () => setPreviewTask(record) }), rowKey: ({ task_id }) => task_id }, { children: [_jsx(Col, Object.assign({ span: 6 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.text }, { children: _jsx(Input.Search, { placeholder: t("tasks.search-placeholder"), allowClear: true }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.status }, { children: _jsx(Select, { placeholder: t("tasks.status"), mode: "multiple", maxTagCount: "responsive", showArrow: true, allowClear: true, options: TaskStatuses.map((status) => ({
                                    label: _jsx(TaskStatusIndicator, { status: status, mode: "tag" }),
                                    value: status,
                                })) }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.reporter_id }, { children: _jsx(Select, { loading: usersLoading, disabled: usersLoading, placeholder: t("tasks.created-by"), mode: "multiple", showArrow: true, allowClear: true, maxTagCount: "responsive", options: users.map(({ id, name }) => ({
                                    label: name,
                                    value: id,
                                })), filterOption: filterSelectByIdAndLabel }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.assignee_id }, { children: _jsx(Select, { loading: usersLoading, disabled: usersLoading, placeholder: t("tasks.assignee"), mode: "multiple", showArrow: true, allowClear: true, maxTagCount: "responsive", options: users.map(({ id, name }) => ({
                                    label: name,
                                    value: id,
                                })), filterOption: filterSelectByIdAndLabel }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.priority }, { children: _jsx(Select, { placeholder: t("tasks.priority"), mode: "multiple", maxTagCount: "responsive", showArrow: true, allowClear: true, options: TaskPriorities.map((priority) => ({
                                    label: _jsx(TaskPriorityIndicator, { priority: priority, showLabel: true }),
                                    value: priority,
                                })) }) })) }))] })), _jsx(TaskPreview, { task: previewTask, close: () => setPreviewTask(undefined), afterDelete: () => {
                    var _a;
                    void ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.getTableData());
                    setPreviewTask(undefined);
                }, updateTasks: handleTaskUpdate })] }));
};
export default TasksPage;
