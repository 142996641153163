import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Tag, theme } from "antd";
import { TaskStatus } from "@teylor-tools/Api";
const TaskStatusIndicator = ({ status, mode = "icon" }) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const color = useMemo(() => {
        switch (status) {
            case TaskStatus.Todo:
                return "#D9D9D9";
            case TaskStatus.InProgress:
                return "#63BEFF";
            case TaskStatus.Done:
                return token.colorSuccess;
            default:
                return "";
        }
    }, [status, token.colorSuccess]);
    return mode === "icon" ? (_jsx(CheckCircleOutlined, { style: { color } })) : (_jsx(Tag, Object.assign({ color: color, style: { color: token.colorText } }, { children: t(`tasks.status.${status}`) })));
};
export default TaskStatusIndicator;
