import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Table as AntdTable } from "antd";
import styled from "styled-components";
import { Accounts, Assets, EquityAndLiabilities, Sections } from "./financialsTableRows";
export const Table = styled(AntdTable) `
	table {
		border-top: none !important;
	}

	thead > tr > td {
		background-color: #ffffff !important;
		padding-bottom: 0px !important;
		border-bottom: none !important;
	}

	thead > tr > th {
		background-color: #ffffff !important;
	}

	th {
		border-bottom: none !important;
	}

	& tbody > tr > td:first-child {
		background-color: #fafafa;
	}

	.ant-table-body {
		overflow: auto !important;
	}

	.ant-table-cell {
		background-color: #ffffff;
	}

	.ant-table-row-level-0 {
		font-weight: 600;
	}

	.ant-table-row-level-0 > td {
		padding-top: 32px;
	}

	.ant-table-row-level-1 > td {
		padding: 8px 16px;
	}

	${`[data-row-key="${Sections.accounts}-${Accounts.net_income}"]`},
	${`[data-row-key="${Sections.assets}-${Assets.total_assets_published}"]`},
	${`[data-row-key="${Sections["equity-and-liabilities"]}-${EquityAndLiabilities.equity_published}"]`},
${`[data-row-key="${Sections["equity-and-liabilities"]}-${EquityAndLiabilities.total_liabilities_published}"]`} {
		font-weight: 600;
	}

	.ant-table-header {
		padding: 0 24px;
		border-top: 1px solid rgba(0, 0, 0, 0.06);
		border-bottom: 1px solid rgba(0, 0, 0, 0.06);
	}

	.ant-table-body {
		background-color: #f5f5f5;
		padding: 24px;
	}

	& tbody > tr > td {
		border: 1px solid rgba(0, 0, 0, 0.06);
	}

	& tbody > tr > td:last-child {
		background-color: #f5f5f5;
		border-right: none !important;
		border-top: none !important;
		border-bottom: none !important;
	}

	.ant-table-tbody > tr.ant-table-row:hover > td {
		background-color: #ffffff;
	}

	.ant-table-tbody > tr.ant-table-row:hover > td:last-child {
		background-color: #f5f5f5;
	}

	.ant-table-tbody > tr.ant-table-row:hover > td:first-child {
		background-color: #fafafa;
	}

	.ant-table.ant-table-bordered
		> .ant-table-container
		> .ant-table-header
		> table
		> thead
		> tr
		> th {
		border-right: solid 1px rgba(0, 0, 0, 0.06);
	}
`;
export const UpIcon = styled(UpOutlined) `
	margin-right: 8px;
`;
export const DownIcon = styled(DownOutlined) `
	margin-right: 8px;
`;
export const HeaderCell = styled.div `
	display: grid;
	grid-template-columns: auto min-content;
	gap: 4px;
	align-items: center;
	justify-content: space-between;
`;
