import { jsx as _jsx } from "react/jsx-runtime";
import { Spin } from "antd";
import styled from "styled-components";
const StyledLoader = styled.div `
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
`;
const Loader = () => {
    return (_jsx(StyledLoader, { children: _jsx(Spin, { size: "large" }) }));
};
export default Loader;
