import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LeftOutlined } from "@ant-design/icons";
import { Button, DatePicker, Drawer, Form, Select, Typography, message } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { FeatureName, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { dayMonthYear, rawDateFormat } from "@teylor-tools/utils/dateFormats";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import NumberInputLocalized from "@ui/form/inputs/number-input-localized/NumberInputLocalized";
import { Axios } from "src/utils/Axios";
import { RATE_PRECISION } from "./SettingsRates";
const TitleWrapper = styled.div `
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const disabledDate = (current) => {
    // Can not select days after today
    return current && current > dayjs().endOf("day");
};
const RateCreation = ({ createRate, close, updateRates }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { percentToDecimal } = useFormatter();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const { features } = useSelector((rootState) => rootState.configState);
    const ratePeriodsFeature = features.find((f) => f.name === FeatureName.GlobalRatePeriods);
    const rateNamesFeature = features.find((f) => f.name === FeatureName.GlobalRateNames);
    const addRate = (values) => {
        setIsLoading(true);
        Axios.post("admin/global_rates", Object.assign(Object.assign({}, values), { global_rate_value: percentToDecimal(values.global_rate_value), global_rate_effective_date: values.global_rate_effective_date.format(rawDateFormat) }))
            .then(() => {
            void message.success(t("common.saved"));
            form.resetFields();
            updateRates();
            close();
        }, (err) => Axios.error(err, t("common.request-failed")))
            .finally(() => setIsLoading(false));
    };
    const requiredRule = {
        required: true,
        message: t("application_form.field-required"),
    };
    const validateRateValue = (rule, value) => {
        if (!isValidNumericValue(value)) {
            return Promise.reject(t("application_form.field-required"));
        }
        const min = -3;
        const max = 10;
        const val = Number(value);
        if (val < min || val > max) {
            return Promise.reject(t("application_form.errors.fieldMustBeBetweenMinMax", {
                min: `${min}%`,
                max: `${max}%`,
            }));
        }
        return Promise.resolve();
    };
    return (_jsx(_Fragment, { children: _jsx(Drawer, Object.assign({ destroyOnClose: true, title: _jsx(TitleWrapper, { children: _jsx(Typography.Text, { children: t("settings.rates.drawer.title") }) }), closeIcon: _jsx(LeftOutlined, {}), placement: "right", onClose: () => {
                close();
                form.resetFields();
            }, open: createRate, size: "large" }, { children: _jsxs(Form, Object.assign({ form: form, onFinish: addRate, layout: "vertical", disabled: isLoading, initialValues: {
                    global_rate_name: rateNamesFeature === null || rateNamesFeature === void 0 ? void 0 : rateNamesFeature.value.default,
                    global_rate_period: ratePeriodsFeature === null || ratePeriodsFeature === void 0 ? void 0 : ratePeriodsFeature.value.default,
                    global_rate_value: "",
                } }, { children: [_jsx(Form.Item, Object.assign({ label: t("settings.rates.type"), name: "global_rate_name", rules: [requiredRule] }, { children: _jsx(Select, { options: (rateNamesFeature === null || rateNamesFeature === void 0 ? void 0 : rateNamesFeature.value.supported)
                                ? (_a = rateNamesFeature === null || rateNamesFeature === void 0 ? void 0 : rateNamesFeature.value.supported) === null || _a === void 0 ? void 0 : _a.map((name) => ({
                                    value: name,
                                    label: t(`settings.rates.types.${name}`),
                                }))
                                : [] }) })), _jsx(Form.Item, Object.assign({ label: t("settings.rates.frequency"), name: "global_rate_period", rules: [requiredRule] }, { children: _jsx(Select, { options: (ratePeriodsFeature === null || ratePeriodsFeature === void 0 ? void 0 : ratePeriodsFeature.value.supported)
                                ? (_b = ratePeriodsFeature === null || ratePeriodsFeature === void 0 ? void 0 : ratePeriodsFeature.value.supported) === null || _b === void 0 ? void 0 : _b.map((period) => ({
                                    value: period,
                                    label: t(`settings.rates.frequencies.${period}`),
                                }))
                                : [] }) })), _jsx(Form.Item, Object.assign({ label: t("settings.rates.value"), name: "global_rate_value", rules: [{ validator: validateRateValue }] }, { children: _jsx(NumberInputLocalized, { style: { width: "100%" }, precision: RATE_PRECISION, placeholder: t("settings.rates.value.placeholder"), addonBefore: "%" }) })), _jsx(Form.Item, Object.assign({ label: t("settings.rates.date"), name: "global_rate_effective_date", rules: [requiredRule] }, { children: _jsx(DatePicker, { format: dayMonthYear, style: { width: "100%" }, disabledDate: disabledDate }) })), _jsx(Button, Object.assign({ type: "primary", htmlType: "submit", loading: isLoading }, { children: t("settings.rates.drawer.add-btn") }))] })) })) }));
};
export default RateCreation;
