import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { Space as AntdSpace, Button, Divider, Typography } from "antd";
import styled from "styled-components";
import TasksList from "src/components/tasks/TasksList";
import { SideActions, useLayout } from "src/layouts/ApplicationLayout/application.types";
import PendingItemsList from "src/layouts/ApplicationLayout/modules/pending-items/PendingItemsList";
const { Title, Text } = Typography;
const Wrapper = styled.div `
	padding: 24px 0;
`;
const Space = styled(AntdSpace) `
	width: 100%;
	padding-left: 24px;
	padding-right: 24px;
`;
const SidebarTasks = ({ pendingItems, pendingItemsCount, tasks, tasksEnabled }) => {
    const { t } = useTranslation();
    const { setActiveMenu } = useLayout();
    return (_jsxs(Wrapper, { children: [_jsxs(Space, Object.assign({ direction: "vertical", size: 24 }, { children: [tasksEnabled && (_jsx(Title, Object.assign({ level: 5 }, { children: `${t("application.pending-items.title")} (${pendingItemsCount})` }))), !!pendingItemsCount && (_jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.pending-items.description") }))), _jsx(PendingItemsList, { pendingItems: pendingItems })] })), tasksEnabled && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsxs(Space, Object.assign({ direction: "vertical", size: 24 }, { children: [_jsx(Title, Object.assign({ level: 5 }, { children: `${t("tasks.tasks")} (${tasks.length})` })), _jsx(Button, Object.assign({ type: "primary", ghost: true, icon: _jsx(PlusOutlined, {}), onClick: () => setActiveMenu(SideActions.createTask) }, { children: t("tasks.sidebar.create-new-task-btn") })), _jsx(TasksList, { tasks: tasks, showTaskDetailsSidebar: () => setActiveMenu(SideActions.taskDetails) })] }))] }))] }));
};
export default SidebarTasks;
