import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { Button, Checkbox, Col, Form, Input, Row, message } from "antd";
import { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import styled from "styled-components";
import { Routes } from "src/Routes";
import { Axios } from "src/utils/Axios";
const StyledForm = styled(Form) `
	flex: auto;
	padding: 24px;
`;
const StyledRow = styled(Row) `
	flex-direction: column;
	height: 100%;
`;
const StyledFormItem = styled(FormItem) `
	height: 100%;
	flex-direction: row;

	.ant-form-item-row {
		flex: 0;
		display: flex;
	}

	.ant-form-item-label {
		display: block;
		flex: unset;
	}

	.ant-form-item-control {
		flex: 1;
		display: block;

		.ant-form-item-control-input,
		.ant-form-item-control-input-content {
			display: block;
			height: 100%;
		}
	}

	.ant-form-item-row,
	.ant-form-item-control {
		height: calc(100% - 22px);
	}
`;
const BigTextarea = styled(Input.TextArea) `
	height: 100% !important;
	margin-bottom: 16px;
`;
const ApplicationAnalystReport = ({ applicationId, disabled, updatePendingItems }) => {
    const { t } = useTranslation();
    const [form] = useForm();
    useEffect(() => {
        Axios.get(`/admin/applications/${applicationId}/scoring_feedback`).then(({ data }) => {
            form.setFieldsValue(data);
        }, (error) => {
            var _a;
            // TODO: fix once BE stops returning 404 on missing data
            if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) !== 404) {
                void Axios.error(error, t("application.qualitative_analysis.error.be-get"));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationId]);
    const save = (values) => {
        Axios.patch(`/admin/applications/${applicationId}/scoring_feedback`, values)
            .then(({ data }) => {
            void message.success(t("application.qualitative_analysis.saved"));
            form.setFieldsValue({
                flags_reviewed: data.flags_reviewed,
                scoring_feedback: data.scoring_feedback,
            });
        }, (error) => {
            void Axios.error(error, t("application.qualitative_analysis.error.be"));
        })
            .finally(updatePendingItems);
    };
    return (_jsx(StyledForm, Object.assign({ form: form, layout: "vertical", onFinish: save, disabled: disabled }, { children: _jsxs(StyledRow, Object.assign({ style: { flexDirection: "column" } }, { children: [_jsx(Col, Object.assign({ flex: "auto" }, { children: _jsxs(Row, Object.assign({ justify: "space-between", style: { height: "100%", flexDirection: "column" } }, { children: [_jsx(Col, Object.assign({ flex: "auto" }, { children: _jsx(StyledFormItem, Object.assign({ label: t("application.qualitative_analysis.report"), name: "scoring_feedback", required: false, rules: [
                                        {
                                            required: true,
                                            min: 200,
                                            message: t("application_form.errors.fieldMustBeMinChars", { min: 200 }),
                                        },
                                    ] }, { children: _jsx(BigTextarea, { showCount: true, rows: 5 }) })) })), _jsx(Col, { children: _jsx(FormItem, Object.assign({ name: "flags_reviewed", valuePropName: "checked", rules: [
                                        {
                                            validator: (_, value) => value
                                                ? Promise.resolve()
                                                : Promise.reject(t("application.qualitative_analysis.checkbox")),
                                        },
                                    ], style: { marginTop: "16px" } }, { children: _jsx(Checkbox, { children: _jsx(Trans, { i18nKey: "application.qualitative_analysis.confirm-report", components: [
                                                _jsx(Link, { to: generatePath(Routes.ApplicationQuantitative, {
                                                        applicationId: applicationId,
                                                    }) }, 1),
                                                _jsx(Link, { to: generatePath(Routes.ApplicationQualitative, {
                                                        applicationId: applicationId,
                                                    }) }, 2),
                                            ] }) }) })) })] })) })), _jsx(Col, Object.assign({ flex: "80px" }, { children: _jsx(Row, Object.assign({ align: "middle", justify: "end", style: { height: "100%" } }, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit" }, { children: t("common.save") })) })) }))] })) })));
};
export default ApplicationAnalystReport;
