import { createContext, useContext } from "react";
import { useOutletContext } from "react-router-dom";
export var SideActions;
(function (SideActions) {
    SideActions["loanInformation"] = "loan-information";
    SideActions["sharedNotes"] = "shared-notes";
    SideActions["activityLog"] = "activity-log";
    SideActions["analystReport"] = "analyst-report";
    SideActions["tasks"] = "tasks";
    SideActions["createTask"] = "create-task";
    SideActions["taskDetails"] = "task-details";
})(SideActions || (SideActions = {}));
export function useLoan() {
    return useOutletContext();
}
export const LayoutContext = createContext({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setActiveMenu: () => { },
});
export function useLayout() {
    return useContext(LayoutContext);
}
