var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { SelectOutlined } from "@ant-design/icons";
import { Button, Col, Input, Select, Tag, Tooltip, Typography, message } from "antd";
import FormItem from "antd/es/form/FormItem";
import styled from "styled-components";
import { LoanStatus as LoanStatusType } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { downloadGeneratedFileFromUrl } from "@teylor-tools/utils/downloadFileFromUrl";
import MainHeader from "@ui/main-header/MainHeader";
import { Routes } from "src/Routes";
import LoanStatus from "src/components/ui/statuses/LoanStatus";
import { Axios } from "src/utils/Axios";
import SearchList, { DIRECT_OPEN_COLUMN } from "../../components/SearchList";
import LoanPreview from "./LoanPreview";
const Center = styled.div `
	display: flex;
	justify-content: center;
`;
var SearchField;
(function (SearchField) {
    SearchField["text"] = "text";
    SearchField["status"] = "status";
    SearchField["is_eligible"] = "is_eligible";
    SearchField["product_id"] = "product_id";
})(SearchField || (SearchField = {}));
const LoansPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { currency, date } = useFormatter();
    const [loan, setLoan] = useState();
    const [isExportLoading, setExportIsLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const exportPortfolio = () => __awaiter(void 0, void 0, void 0, function* () {
        setExportIsLoading(true);
        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            yield downloadGeneratedFileFromUrl(`${Axios.apiPath}/admin/portfolio_export`, `portfolio.zip`);
        }
        catch (e) {
            console.error(e);
            setExportIsLoading(false);
            yield message.error(`${t("common.error-code")} ${e}`);
        }
        setExportIsLoading(false);
    });
    const columns = [
        {
            render: (v) => (_jsx(Button, { type: "link", icon: _jsx(SelectOutlined, {}), onClick: () => navigate(generatePath(Routes.Loan, {
                    loanId: v.loan_id,
                })) })),
            className: DIRECT_OPEN_COLUMN,
        },
        {
            dataIndex: "loan_status",
            title: t("application.state"),
            width: 70,
            render: (item) => {
                return (_jsx(Tooltip, Object.assign({ title: t(`loans.status.${item}`) }, { children: _jsx(Center, { children: _jsx(LoanStatus, { closable: false, label: item.toUpperCase(), value: item, disabled: false, short: true }) }) })));
            },
        },
        {
            dataIndex: ["company", "companyName"],
            title: t("application.company"),
        },
        {
            dataIndex: "amount",
            title: t("application.amount"),
            sorter: true,
            render(amount, loan) {
                return amount ? currency(amount, { currency: loan.currency }) : "-";
            },
        },
        {
            dataIndex: "payment",
            title: t("loans.payment"),
            sorter: true,
            render(_, loan) {
                var _a;
                return ((_a = loan.payment_plan) === null || _a === void 0 ? void 0 : _a.payment)
                    ? currency(loan.payment_plan.payment, { currency: loan.currency })
                    : "-";
            },
        },
        {
            dataIndex: "interest_rate",
            title: t("loans.interest-rate"),
            render(rate) {
                return `${(Number(rate) * 100).toFixed(2)}%`;
            },
        },
        {
            dataIndex: "duration_months",
            title: t("loans.duration"),
            render(duration) {
                return `${duration}m`;
            },
        },
        {
            dataIndex: "payout_date",
            title: t("loans.paid-out-date"),
            render(payout_date) {
                return _jsx(Typography.Text, { children: date(payout_date) });
            },
            sorter: true,
        },
        {
            dataIndex: ["product", "product_name"],
            title: t("loans.product"),
        },
    ];
    useEffect(() => {
        Axios.get("admin/filter_values")
            .then(({ data }) => setProducts(data.products || []))
            .catch((err) => void Axios.error(err));
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("loans.title"), extra: _jsx(Button, Object.assign({ loading: isExportLoading, disabled: isExportLoading, onClick: () => void exportPortfolio() }, { children: t("loans.export-portfolio") })) }), _jsxs(SearchList, Object.assign({ endpoint: "/admin/loans", columns: columns, showingOfString: "loans.search.search-showing", rowKey: ({ loan_id }) => loan_id, onRow: (record) => {
                    return {
                        onClick() {
                            setLoan(record);
                        },
                    };
                } }, { children: [_jsx(Col, Object.assign({ span: 6 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.text }, { children: _jsx(Input.Search, { placeholder: t("loans.placeholder"), allowClear: true }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.status }, { children: _jsx(Select, { placeholder: t("loans.status"), mode: "multiple", showArrow: true, optionFilterProp: "label", maxTagCount: "responsive", allowClear: true, tagRender: ({ label, value, disabled, closable, onClose }) => (_jsx(LoanStatus, { label: label, value: value, disabled: disabled, closable: closable, onClose: onClose })), options: Object.values(LoanStatusType).map((value) => {
                                    return {
                                        label: t(`loans.status.${value}`),
                                        value: value,
                                    };
                                }) }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.is_eligible }, { children: _jsx(Select, { placeholder: t("loans.eligible"), showArrow: true, optionFilterProp: "label", allowClear: true, options: [
                                    {
                                        label: t("common.yes"),
                                        value: "true",
                                    },
                                    {
                                        label: t("common.no"),
                                        value: "false",
                                    },
                                ] }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.product_id }, { children: _jsx(Select, { loading: !products.length, disabled: !products.length, placeholder: t("loans.product"), mode: "multiple", showArrow: true, allowClear: true, maxTagCount: "responsive", optionFilterProp: "label", tagRender: ({ label, closable, onClose }) => (_jsx(Tag, Object.assign({ closable: closable, onClose: onClose }, { children: label }))), options: products.map(({ product_id, product_name }) => {
                                    return {
                                        label: product_name,
                                        value: product_id,
                                    };
                                }) }) })) }))] })), _jsx(LoanPreview, { loan: loan, onClose: () => setLoan(undefined) })] }));
};
export default LoansPage;
