import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Tag, Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import { DataCardItem } from "@ui/data-display/DataCard";
import OverviewScores from "@ui/scoring/scores/Scores";
import OverviewAlyze from "@ui/scoring/scoring-overview-alyze/ScoringOverviewAlyze";
import OverviewScoreIssues from "@ui/scoring/scoring-overview-issues/ScoringOverviewIssues";
import { DataCard } from "src/components/DataCard";
import OverviewSummaryTables from "src/pages/modules/AnalysisQuantitative/OverviewSummaryTables";
const CONTENT_MAX_WIDTH = 1464;
const Wrapper = styled.div `
	max-width: ${CONTENT_MAX_WIDTH}px;
`;
const TwoColumnsWrapper = styled.div `
	display: grid;
	column-gap: 24px;
	grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
`;
const CompanyScoreOverview = ({ score, financials = [], kpis = [] }) => {
    const { t } = useTranslation();
    if (!score)
        return null;
    return (_jsxs(Wrapper, { children: [_jsxs(TwoColumnsWrapper, { children: [_jsxs("div", { children: [_jsxs(DataCard, Object.assign({ title: t("company.scoring.status") }, { children: [_jsx(DataCardItem, { label: t("company.scoring.status"), value: score.is_approved ? (_jsx(Tag, Object.assign({ color: "green" }, { children: t("company.scoring.status.approved") }))) : (_jsx(Tag, Object.assign({ color: "blue" }, { children: t("company.scoring.status.draft") }))) }), _jsx(DataCardItem, { label: t("company.scoring.approval_date"), value: _jsx(Typography.Text, Object.assign({ strong: true }, { children: score.approved_at ? dayjs(score.approved_at).format(dayMonthYear) : "-" })) }), _jsx(DataCardItem, { label: t("company.scoring.approved_by"), value: _jsx(Typography.Text, Object.assign({ strong: true }, { children: score.approved_by_entity_name || "-" })) })] })), _jsx(OverviewAlyze, { scoreGrade: score.final_mark }), _jsx(OverviewScores, { scores: score })] }), _jsx(OverviewScoreIssues, { scoreWarnings: score.warnings })] }), _jsx(OverviewSummaryTables, { financials: financials, kpis: kpis })] }));
};
export default CompanyScoreOverview;
