import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NoteSubjectKind } from "@teylor-tools/Api";
import Notes from "src/components/Notes";
import { Axios } from "src/utils/Axios";
const LoanNotes = ({ loanId, showXLatest }) => {
    const [loading, setLoading] = useState(true);
    const [notes, setNotes] = useState([]);
    const { t } = useTranslation();
    const getNotes = () => {
        setLoading(true);
        Promise.all([
            Axios.get(`/admin/loans/${loanId}/notes`, Object.assign({ loan_id: loanId }, (showXLatest && { page: 1, page_size: showXLatest }))),
            Axios.get(`admin/bo_users/list`),
        ])
            .then(([notes, bo]) => {
            const { data: noteData } = notes;
            const { data: boData } = bo;
            const notesWithInfo = noteData.result
                ? noteData.result.map((note) => {
                    // find by created id, add needed properties
                    const noteCreatorProfile = boData.find((boUser) => boUser.id === note.created_by);
                    return Object.assign(Object.assign({}, note), { name: noteCreatorProfile === null || noteCreatorProfile === void 0 ? void 0 : noteCreatorProfile.name, email: noteCreatorProfile === null || noteCreatorProfile === void 0 ? void 0 : noteCreatorProfile.email });
                })
                : [];
            setNotes(notesWithInfo);
        })
            .catch((error) => {
            void Axios.error(error, t("common.request-failed"));
        })
            .finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        getNotes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loanId]);
    const handleAddNote = ({ newNote, noteForm }) => {
        if (!newNote)
            return;
        setLoading(true);
        Axios.post(`/admin/loans/${loanId}/notes`, {
            subject_id: loanId,
            subject_kind: NoteSubjectKind.Loan,
            message: newNote,
        })
            .then(() => {
            getNotes();
            noteForm.resetFields();
        }, (error) => {
            void Axios.error(error);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    return _jsx(Notes, { notes: notes, loading: loading, handleAddNote: handleAddNote });
};
export default LoanNotes;
