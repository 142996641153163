import dayjs from "dayjs";
export var ActionType;
(function (ActionType) {
    ActionType["FETCH_STATEMENTS"] = "FETCH_STATEMENTS";
    ActionType["CANCEL"] = "CANCEL";
    ActionType["UPDATE_STATEMENT_VALUE"] = "UPDATE_STATEMENT_VALUE";
    ActionType["UPDATE_STATEMENT_SETTINGS"] = "UPDATE_STATEMENT_SETTINGS";
    ActionType["DELETE_STATEMENT"] = "DELETE_STATEMENT";
})(ActionType || (ActionType = {}));
export const initialState = {
    initialFinancials: [],
    financials: [],
};
export const reducer = (state, action) => {
    switch (action.type) {
        case ActionType.FETCH_STATEMENTS:
            return Object.assign(Object.assign({}, state), { initialFinancials: action.statements, financials: action.statements });
        case ActionType.CANCEL:
            return Object.assign(Object.assign({}, state), { financials: state.initialFinancials });
        case ActionType.UPDATE_STATEMENT_VALUE: {
            const financials = state.financials.map((statement) => {
                if (statement.financial_data_id !== action.statementId)
                    return statement;
                return Object.assign(Object.assign({}, statement), { input_data: Object.assign(Object.assign({}, statement.input_data), { [action.key]: action.value }) });
            });
            return Object.assign(Object.assign({}, state), { financials });
        }
        case ActionType.UPDATE_STATEMENT_SETTINGS: {
            const financials = state.financials.map((statement) => {
                if (statement.financial_data_id !== action.statementId)
                    return statement;
                return Object.assign(Object.assign({}, statement), { statement_type: action.statementType, financials_date: dayjs(action.date).format("YYYY-MM-DD") });
            });
            return Object.assign(Object.assign({}, state), { financials });
        }
        case ActionType.DELETE_STATEMENT: {
            const financials = state.financials.filter((statement) => statement.financial_data_id !== action.statementId);
            return Object.assign(Object.assign({}, state), { financials });
        }
        default:
            return Object.assign({}, state);
    }
};
