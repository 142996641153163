import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import dayjs from "dayjs";
import { useFormatter } from "@teylor-tools/hooks/formatter";
const dateSettings = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
};
let timer;
const TimePassed = ({ date }) => {
    const { t } = useTranslation();
    const formatter = useFormatter();
    const stopTicking = () => clearInterval(timer);
    const pickString = () => {
        const pickDiff = (mesaurement) => dayjs(new Date()).diff(date, mesaurement);
        const weeks = pickDiff("weeks");
        const days = pickDiff("days");
        const hours = pickDiff("hours");
        const minutes = pickDiff("minutes");
        if (weeks > 4) {
            // render basic date
            stopTicking();
            return formatter.date(date, dateSettings);
        }
        else if (weeks <= 4 && days >= 7) {
            return t("common.dates.week-ago_other", { count: weeks });
        }
        else if (days < 7 && days > 0) {
            return t("common.dates.day-ago_other", { count: days });
        }
        else if (days < 1 && hours > 0) {
            return t("common.dates.hour-ago_other", { count: hours });
        }
        else if (hours < 1 && minutes > 0) {
            return t("common.dates.minute-ago_other", { count: minutes });
        }
        else if (isNaN(weeks)) {
            stopTicking();
            return "-";
        }
        else {
            return t("common.dates.moments-ago");
        }
    };
    const [displayDate, setDisplayDate] = useState(pickString());
    const setDate = () => {
        setDisplayDate(pickString());
    };
    useEffect(() => {
        timer = setInterval(setDate, 1000);
        return () => stopTicking();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(Tooltip, Object.assign({ placement: "top", title: formatter.date(date, dateSettings) }, { children: displayDate })));
};
export default TimePassed;
