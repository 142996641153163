var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Col, Space, Typography } from "antd";
import styled from "styled-components";
import AvatarBadge from "@ui/data-display/Avatar";
export const OwnerLabel = styled.div `
	font-size: 12px;
	color: rgba(0, 0, 0, 0.45);
`;
export const Assigned = (_a) => {
    var { name, label } = _a, props = __rest(_a, ["name", "label"]);
    const { t } = useTranslation();
    const nameRender = () => {
        if (name) {
            return (_jsxs(Space, Object.assign({ align: "center" }, { children: [_jsx(AvatarBadge, { name: name, size: "small" }), _jsx(Typography.Text, Object.assign({ style: { maxWidth: 140 }, ellipsis: { tooltip: name } }, { children: name }))] })));
        }
        else {
            return _jsx("span", { children: t("application.preview.unassigned") });
        }
    };
    return (_jsxs(Col, Object.assign({}, props, { children: [_jsx(OwnerLabel, { children: t(label) }), nameRender()] })));
};
