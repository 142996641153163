import { createContext, useContext } from "react";
import { useOutletContext } from "react-router-dom";
export var SideActions;
(function (SideActions) {
    SideActions["applicationInformation"] = "application-information";
    SideActions["tasksAndPendingItems"] = "tasks-and-pending-items";
    SideActions["createTask"] = "create-task";
    SideActions["taskDetails"] = "task-details";
    SideActions["sharedNotes"] = "shared-notes";
    SideActions["activityLog"] = "activity-log";
    SideActions["analystReport"] = "analyst-report";
    SideActions["payoutChecklist"] = "payout-checklist";
    SideActions["finalReviewChecklist"] = "final-review-checklist";
})(SideActions || (SideActions = {}));
export function useApplication() {
    return useOutletContext();
}
export const LayoutContext = createContext({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setActiveMenu: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    openShareApplicationModal: () => { },
});
export function useLayout() {
    return useContext(LayoutContext);
}
