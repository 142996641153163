import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table as AntdTable } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { yearMonthDay } from "@teylor-tools/utils/dateFormats";
const HEADINGS_HEIGHT = 265; // the height of top bar + page heading + table heading + pagination
const Table = styled((AntdTable)) `
	table {
		border-top: none !important;
	}

	th {
		border-bottom: none !important;
	}

	.ant-table-body {
		overflow: auto !important;
	}

	tr {
		background-color: #fff;

		&:nth-of-type(odd) {
			background-color: #fafafa;
		}
	}

	th {
		background-color: #fff !important;
	}

	& tbody > tr > td {
		padding: 8px 16px;
	}

	.ant-table-header {
		padding: 0 24px;
		border-top: 1px solid rgba(0, 0, 0, 0.06);
		border-bottom: 1px solid rgba(0, 0, 0, 0.06);
	}

	.ant-table-body {
		background-color: #f5f5f5;
		padding: 24px;
	}

	.ant-table-tbody > tr.ant-table-row:hover {
		background-color: #f7f7f7;
	}

	.ant-pagination {
		padding-right: 40px;
	}
`;
export const RepaymentTable = ({ repayments }) => {
    const { t } = useTranslation();
    const [scrollY, setScrollY] = useState(window.innerHeight - HEADINGS_HEIGHT);
    const { currency } = useFormatter();
    // This properly sets the scroll behavior of the table
    useEffect(() => {
        function handleResize() {
            setScrollY(window.innerHeight - HEADINGS_HEIGHT);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const data = repayments.map((installment) => ({
        key: installment.installment_id,
        date: installment.due_date,
        amount: installment.amount
            ? currency(installment.amount, { currency: installment.currency })
            : "",
        principal: currency(installment.amount_principal, { currency: installment.currency }),
        interest: installment.amount_interest
            ? currency(installment.amount_interest, { currency: installment.currency })
            : "",
    }));
    const columns = [
        {
            dataIndex: "date",
            render: (v) => dayjs(v).format(yearMonthDay),
            title: () => t("application.repayment.table-date"),
        },
        {
            dataIndex: "amount",
            align: "right",
            title: () => t("application.repayment.table-amount"),
        },
        {
            dataIndex: "principal",
            align: "right",
            title: () => t("application.repayment.table-principal"),
        },
        {
            dataIndex: "interest",
            align: "right",
            title: () => t("application.repayment.table-interest"),
        },
    ];
    return (_jsx(Table, { columns: columns, dataSource: data, bordered: true, scroll: { y: scrollY }, pagination: { hideOnSinglePage: true, pageSize: 20 } }));
};
