import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { Axios } from "src/utils/Axios";
import CompanyRepresentative from "./modules/CompanyRepresentative";
import CompanyRepresentativeEmpty from "./modules/CompanyRepresentativeEmpty";
const ContentWrapper = styled.div `
	margin-top: 64px;
	padding: 24px;
`;
const RepresentativesWrapper = styled.div `
	display: grid;
	gap: 16px;
	grid-template-columns: repeat(auto-fill, minmax(460px, auto));
`;
const LoanRepresentatives = () => {
    const { t } = useTranslation();
    const { loan } = useOutletContext();
    const [representatives, setRepresentatives] = useState();
    const getRepresentatives = useCallback(() => {
        Axios.get(`/admin/loans/${loan.loan_id}/signatories`).then(({ data }) => {
            setRepresentatives(data.signatories);
        }, (err) => {
            void Axios.error(err);
        });
    }, [loan.loan_id]);
    useEffect(() => {
        void getRepresentatives();
    }, [getRepresentatives]);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: `${t("application.company_representatives.company-representatives")} (${(representatives === null || representatives === void 0 ? void 0 : representatives.length) || 0})` }), _jsx(ContentWrapper, { children: !representatives || !representatives.length ? (_jsx(CompanyRepresentativeEmpty, {})) : (_jsx(RepresentativesWrapper, { children: representatives.map((representative) => (_jsx(CompanyRepresentative, { representative: representative, loanId: loan.loan_id }, representative.signatory_id))) })) })] }));
};
export default LoanRepresentatives;
