import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { SelectOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import { Tooltip } from "antd";
import styled from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { Routes } from "src/Routes";
import TimePassed from "src/components/ui/TimePassed";
import ApplicationStatus from "src/components/ui/statuses/ApplicationStatus";
import { useCompany } from "src/layouts/CompanyLayout/CompanyTypes";
import { Axios } from "src/utils/Axios";
const Wrapper = styled.div `
	margin: 88px 24px 24px;
	display: grid;
	gap: 24px;
	grid-template-rows: auto 1fr;
	grid-template-columns: minmax(460px, 1464px);
`;
const InnerWrapper = styled.div `
	display: grid;
	column-gap: 24px;
	grid-template-columns: repeat(auto-fit, minmax(460px, auto));

	.direct-open-column {
		padding: 0 !important;
	}
`;
const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 20;
const CompanyApplications = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { currency } = useFormatter();
    const { company } = useCompany();
    const [applications, setApplications] = useState();
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
    const [totalRows, setTotalRows] = useState();
    const getApplications = useCallback(() => {
        setLoading(true);
        Axios.get(`admin/companies/${company.companyId}/applications`, {
            page: currentPage,
            page_size: pageSize,
        })
            .then(({ data }) => {
            var _a;
            setApplications(data);
            setTotalRows(Number(((_a = data === null || data === void 0 ? void 0 : data.pagination) === null || _a === void 0 ? void 0 : _a.total_rows) || 0));
        })
            .catch((error) => Axios.error(error))
            .finally(() => setLoading(false));
    }, [company.companyId, currentPage, pageSize]);
    useEffect(getApplications, [getApplications]);
    const columns = [
        {
            className: "direct-open-column",
            width: 38,
            render: (application) => (_jsx(Button, { type: "link", style: { width: "38px" }, icon: _jsx(SelectOutlined, {}), onClick: (e) => {
                    e.stopPropagation();
                    navigate(generatePath(Routes.Application, { applicationId: application.application_id }));
                } })),
        },
        {
            title: t("application.amount"),
            render(a) {
                return a.amount ? currency(a.amount, { currency: a.currency }) : "-";
            },
        },
        {
            dataIndex: "status",
            title: t("application.state"),
            width: 70,
            render: (item) => {
                return (_jsx(Tooltip, Object.assign({ title: t(`application.status.${item}`) }, { children: _jsx(ApplicationStatus, { closable: false, label: t(`application.status.${item}`), value: item, disabled: false }) })));
            },
        },
        {
            dataIndex: "duration",
            title: t("application.duration"),
            render(duration) {
                return `${duration} ${t("common.months")}`;
            },
        },
        {
            dataIndex: ["product", "product_name"],
            title: t("application.product-name"),
        },
        {
            dataIndex: "created_at",
            title: t("application.created"),
            render(created) {
                return _jsx(TimePassed, { date: created });
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, Object.assign({ title: t("company.applications.count", { count: (applications === null || applications === void 0 ? void 0 : applications.result.length) || 0 }) }, { children: _jsx(Button, Object.assign({ onClick: () => navigate(`${generatePath(Routes.Applications)}?text=${company.companyName}`), icon: _jsx(UnorderedListOutlined, {}) }, { children: t("company.applications.open_in_list_view") })) })), _jsx(Wrapper, { children: _jsx(InnerWrapper, { children: _jsx(Table, { loading: loading, columns: columns, rowKey: "application_id", dataSource: (applications === null || applications === void 0 ? void 0 : applications.result) || [], onChange: (pagination) => {
                            setCurrentPage(pagination.current || DEFAULT_PAGE);
                            setPageSize(pagination.pageSize || DEFAULT_PAGE_SIZE);
                        }, pagination: {
                            pageSize: pageSize,
                            current: currentPage,
                            total: totalRows,
                            position: ["bottomRight"],
                            showSizeChanger: true,
                        } }) }) })] }));
};
export default CompanyApplications;
