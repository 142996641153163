import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CheckCircleOutlined, CloseCircleOutlined, DownOutlined, ExclamationCircleOutlined, MoreOutlined, RightOutlined, } from "@ant-design/icons";
import { Button, Divider, Dropdown, Modal, Space, Spin, Typography, message } from "antd";
import styled from "styled-components";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { Axios } from "src/utils/Axios";
import BenchmarkResults from "../../../modules/AnalysisQuantitative/BenchmarkResults";
import FinancialSummary from "../../../modules/AnalysisQuantitative/FinancialSummary";
import KpiData from "../../../modules/AnalysisQuantitative/KpiData";
import CompanyScoreOverview from "./CompanyScoreOverview";
const { confirm } = Modal;
const Wrapper = styled.div `
	margin-top: 64px;
	padding: 24px;
`;
const DropdownArea = styled.div `
	display: flex;
	align-items: center;
	margin-right: auto;
	cursor: pointer;
`;
const DropdownTitle = styled.span `
	font-weight: 600;
	line-height: 24px;
	text-align: left;
`;
var MenuItem;
(function (MenuItem) {
    MenuItem["OVERVIEW"] = "overview";
    MenuItem["FINANCIAL_SUMMARY"] = "financial-summary";
    MenuItem["BENCHMARK_RESULTS"] = "benchmark-results";
    MenuItem["RISK_STANDARD"] = "risk-standard";
    MenuItem["RISK_STANDARD_MULTI"] = "risk-standard-multi";
    MenuItem["FUTURE_PERFORMANCE"] = "future-performance";
    MenuItem["CASH_FLOW"] = "cash-flow";
    MenuItem["ADDITIONAL_LOAN"] = "additional-loan";
})(MenuItem || (MenuItem = {}));
const menuItems = Object.values(MenuItem);
const CompanyScoreDetails = () => {
    const { t } = useTranslation();
    const { companyId, scoreId } = useParams();
    const [currentMenuItem, setCurrentMenuItem] = useState(MenuItem.OVERVIEW);
    const [loading, setLoading] = useState(false);
    const [score, setScore] = useState();
    const [financials, setFinancials] = useState();
    const [kpis, setKpis] = useState();
    const getScoringData = useCallback(() => {
        setLoading(true);
        Promise.all([
            Axios.get(`admin/companies/${companyId}/scores/${scoreId}`),
            Axios.get(`admin/companies/${companyId}/scores/${scoreId}/financial_data`),
            Axios.get(`admin/companies/${companyId}/kpis`),
        ])
            .then(([scoring, financial, kpi]) => {
            setScore(scoring.data);
            setFinancials(financial.data.financials);
            setKpis(kpi.data.kpis);
        })
            .catch((error) => void Axios.error(error))
            .finally(() => setLoading(false));
    }, [companyId, scoreId]);
    useEffect(() => {
        getScoringData();
    }, [getScoringData]);
    // const downloadScoringData = () => {
    // 	//TODO implement download
    // };
    const approveScoring = (scoreId) => {
        setLoading(true);
        Axios.patch(`admin/companies/${companyId}/scores/${scoreId}/approve`)
            .then(() => {
            void getScoringData();
            void message.success(t("company.scoring.approved"));
        })
            .catch((error) => void Axios.error(error))
            .finally(() => setLoading(false));
    };
    const unapproveScoring = (scoreId) => {
        setLoading(true);
        Axios.patch(`admin/companies/${companyId}/scores/${scoreId}/unapprove`)
            .then(() => {
            void getScoringData();
            void message.success(t("company.scoring.unapproved"));
        })
            .catch((error) => void Axios.error(error))
            .finally(() => setLoading(false));
    };
    const showApproveModal = (scoreId) => {
        confirm({
            title: t("company.scoring.are-you-sure"),
            icon: _jsx(ExclamationCircleOutlined, {}),
            content: t("company.scoring.approve_modal"),
            okText: t("common.confirm"),
            onOk: () => {
                void approveScoring(scoreId);
            },
        });
    };
    const showUnapproveModal = (scoreId) => {
        confirm({
            title: t("company.scoring.are-you-sure"),
            icon: _jsx(ExclamationCircleOutlined, {}),
            content: t("company.scoring.unapprove_modal"),
            okText: t("common.confirm"),
            onOk: () => {
                void unapproveScoring(scoreId);
            },
        });
    };
    const renderMenuItems = (items) => {
        return items.map((item, idx) => {
            return {
                label: (_jsxs(_Fragment, { children: [_jsx(Typography.Text, Object.assign({ onClick: () => setCurrentMenuItem(item) }, { children: t(`application.quantitative_analysis.${item}`) })), !idx && _jsx(Divider, { style: { margin: "0" } })] })),
                key: item,
            };
        });
    };
    const renderSelectedDropdown = (current) => {
        switch (current) {
            case MenuItem.OVERVIEW:
                return _jsx(CompanyScoreOverview, { score: score, financials: financials, kpis: kpis });
            case MenuItem.FINANCIAL_SUMMARY:
                return (_jsx(FinancialSummary, { title: t("application.quantitative_analysis.financial-summary"), summary: financials }));
            case MenuItem.BENCHMARK_RESULTS:
                return (_jsx(BenchmarkResults, { title: t("application.quantitative_analysis.benchmark-results"), benchmarkData: score === null || score === void 0 ? void 0 : score.benchmark_result }));
            case MenuItem.RISK_STANDARD:
                return (_jsx(KpiData, { title: t("application.quantitative_analysis.risk-standard"), data: kpis === null || kpis === void 0 ? void 0 : kpis.filter((kpi) => kpi.category === "risk_standard") }));
            case MenuItem.RISK_STANDARD_MULTI:
                return (_jsx(KpiData, { title: t("application.quantitative_analysis.risk-standard-multi"), data: kpis === null || kpis === void 0 ? void 0 : kpis.filter((kpi) => kpi.category === "risk_standard_multi") }));
            case MenuItem.FUTURE_PERFORMANCE:
                return (_jsx(KpiData, { title: t("application.quantitative_analysis.future-performance"), data: kpis === null || kpis === void 0 ? void 0 : kpis.filter((kpi) => kpi.category === "future_performance") }));
            case MenuItem.CASH_FLOW:
                return (_jsx(KpiData, { title: t("application.quantitative_analysis.cash-flow"), data: kpis === null || kpis === void 0 ? void 0 : kpis.filter((kpi) => kpi.category === "cash_flow") }));
            case MenuItem.ADDITIONAL_LOAN:
                return (_jsx(KpiData, { title: t("application.quantitative_analysis.additional-loan"), data: kpis === null || kpis === void 0 ? void 0 : kpis.filter((kpi) => kpi.category === "additional_loan") }));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs(SecondaryHeader, Object.assign({ title: t("application.quantitative_analysis.heading") }, { children: [_jsxs(DropdownArea, { children: [_jsx(RightOutlined, { style: { marginInline: "12px", color: "#D9D9D9" } }), _jsx(Dropdown, Object.assign({ menu: {
                                    items: renderMenuItems(menuItems),
                                } }, { children: _jsxs(Space, { children: [_jsx(DropdownTitle, { children: t(`application.quantitative_analysis.${currentMenuItem}`) }), _jsx(DownOutlined, {})] }) }))] }), score && (_jsx(Dropdown, Object.assign({ menu: {
                            items: [
                                // {
                                // TODO: implement download when backend is ready
                                // 	key: "downloadBtn",
                                // 	label: t("application.quantitative_analysis.downloadRawData"),
                                // 	icon: <DownloadOutlined />,
                                // 	onClick: downloadScoringData,
                                // },
                                ...(score.is_approved
                                    ? [
                                        {
                                            key: "unapprove",
                                            label: t("company.scoring.unapprove_scoring"),
                                            icon: _jsx(CloseCircleOutlined, {}),
                                            onClick: () => showUnapproveModal(score.company_score_id),
                                        },
                                    ]
                                    : [
                                        {
                                            key: "approve",
                                            label: t("company.scoring.approve_scoring"),
                                            icon: _jsx(CheckCircleOutlined, {}),
                                            onClick: () => showApproveModal(score.company_score_id),
                                        },
                                    ]),
                            ],
                        } }, { children: _jsx(Button, { children: _jsx(MoreOutlined, {}) }) })))] })), _jsx(Wrapper, { children: _jsx(Spin, Object.assign({ spinning: loading }, { children: renderSelectedDropdown(currentMenuItem) })) })] }));
};
export default CompanyScoreDetails;
