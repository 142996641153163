import { ProfileActions } from "src/store/actions/profileActions";
const defaultState = {
    userId: undefined,
    email: undefined,
    features: undefined,
    name: undefined,
};
const profileReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ProfileActions.SET_PROFILE:
            return Object.assign(Object.assign({}, state), action.payload);
        case ProfileActions.RESET_PROFILE:
            return Object.assign({}, defaultState);
    }
    return state;
};
export default profileReducer;
