import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { LeftOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Layout, Menu, Space, Spin, Tag } from "antd";
import { Content as AntdContent } from "antd/lib/layout/layout";
import styled from "styled-components";
import MainHeader from "@ui/main-header/MainHeader";
import { useLastMainRoute } from "src/layouts/MenuLayout/MenuLayout";
import { Axios } from "src/utils/Axios";
import PartnerMenu from "./modules/PartnerMenu";
import PartnerOutlet from "./modules/PartnerOutlet";
const Content = styled(AntdContent) `
	flex: 1 1 auto;
	height: 100%;
	overflow: auto;
`;
const StyledLoader = styled.div `
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
`;
const HeaderWrapper = styled.div `
	display: flex;
	gap: 8px;
	align-items: center;
`;
const PartnerLayout = () => {
    const { t } = useTranslation();
    const { partnerId } = useParams();
    const navigate = useNavigate();
    const [activeMenu, setActiveMenu] = useState();
    const [loading, setLoading] = useState(true);
    const [partner, setPartner] = useState();
    const { lastMainRoute } = useLastMainRoute();
    const updatePartner = (params) => {
        if (params) {
            return Axios.patch(`/admin/partners/${partnerId}`, params).then(() => {
                void getPartner();
            }, (err) => {
                void Axios.error(err);
            });
        }
        else {
            return getPartner();
        }
    };
    const togglePartner = ({ is_enabled }) => {
        Axios.patch(`/admin/partners/${partnerId}/enable`, { is_enabled }).then(() => {
            void getPartner();
        }, (err) => {
            void Axios.error(err);
        });
    };
    const getPartner = () => {
        return Axios.get(`/admin/partners/${partnerId}`).then(({ data }) => {
            setPartner(data);
        }, (err) => {
            void Axios.error(err);
        });
    };
    useEffect(() => {
        setLoading(true);
        getPartner().finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partnerId]);
    return (_jsx(_Fragment, { children: _jsxs(Layout, Object.assign({ style: { minHeight: "100vh" } }, { children: [_jsx(MainHeader, { title: _jsx(Spin, Object.assign({ spinning: loading }, { children: partner && (_jsxs(HeaderWrapper, { children: [_jsx(LeftOutlined, { onClick: () => {
                                        navigate(lastMainRoute);
                                    } }), partner.name, partner.is_enabled ? (_jsx(Tag, { children: t("partners.status.active") })) : (_jsx(Tag, Object.assign({ color: "red" }, { children: t("partners.status.disabled") })))] })) })), extra: _jsxs(Space, { children: [(partner === null || partner === void 0 ? void 0 : partner.crm_link) && (_jsx(Button, Object.assign({ type: "primary", ghost: true, onClick: () => window.open(partner.crm_link, "_blank") }, { children: t("partner.open_in_zoho") }))), _jsx(Dropdown, Object.assign({ dropdownRender: () => (_jsx(Menu, { items: [
                                        ...((partner === null || partner === void 0 ? void 0 : partner.is_enabled)
                                            ? [
                                                {
                                                    label: t("partners.disable_partner"),
                                                    key: "disable",
                                                    onClick: () => togglePartner({ is_enabled: false }),
                                                    danger: true,
                                                },
                                            ]
                                            : [
                                                {
                                                    label: t("partners.enable_partner"),
                                                    key: "enable",
                                                    onClick: () => togglePartner({ is_enabled: true }),
                                                },
                                            ]),
                                    ] })), placement: "bottomLeft" }, { children: _jsx(Button, { icon: _jsx(MoreOutlined, {}) }) }))] }) }), _jsxs(Layout, Object.assign({ style: { flexDirection: "row" } }, { children: [partner && _jsx(PartnerMenu, { partnerId: partner.partner_id }), _jsx(Content, { children: partner ? (_jsx(PartnerOutlet, { partner: partner, updatePartner: updatePartner, activeMenu: activeMenu, setActiveMenu: setActiveMenu })) : (_jsx(StyledLoader, { children: _jsx(Spin, { size: "large" }) })) })] }))] })) }));
};
export default PartnerLayout;
