var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { CommentOutlined, FileProtectOutlined, FileSearchOutlined, HistoryOutlined, InfoCircleOutlined, LeftOutlined, UnorderedListOutlined, } from "@ant-design/icons";
import { Badge, Layout, Menu, Row, Space, Spin, Tag, Typography } from "antd";
import Sider from "antd/lib/layout/Sider";
import { Content as AntdContent } from "antd/lib/layout/layout";
import styled from "styled-components";
import { ApplicationStatuses, ChecklistType, PartnerType, TaskRelatedEntityType, TaskStatus, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import MainHeader from "@ui/main-header/MainHeader";
import { Routes } from "src/Routes";
import ApplicationStatus from "src/components/ui/statuses/ApplicationStatus";
import { LayoutContext, SideActions } from "src/layouts/ApplicationLayout/application.types";
import { getPendingItemAction } from "src/layouts/ApplicationLayout/modules/pending-items/get-pending-item-action";
import { getPendingItemsCount } from "src/layouts/ApplicationLayout/modules/pending-items/pending-items.helpers";
import { useLastMainRoute } from "src/layouts/MenuLayout/MenuLayout";
import { Axios } from "src/utils/Axios";
import ApplicationMenu from "./modules/ApplicationMenu";
import ApplicationOutlet from "./modules/ApplicationOutlet";
import MoveApplication from "./modules/MoveApplication";
import ShareApplication from "./modules/ShareApplication";
const Content = styled(AntdContent) `
	flex: 1 1 auto;
	height: 100%;
	overflow: auto;
`;
const StyledLoader = styled.div `
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
`;
const HeaderWrapper = styled.div `
	display: flex;
	gap: 8px;
	align-items: center;
`;
const ApplicationLayout = () => {
    const { t } = useTranslation();
    const { applicationId } = useParams();
    const navigate = useNavigate();
    const { currency } = useFormatter();
    const [activeMenu, setActiveMenu] = useState();
    const [loading, setLoading] = useState(true);
    const [application, setApplication] = useState();
    const [pendingItems, setPendingItems] = useState({
        general: [],
        documents: [],
        signatories: [],
    });
    const [tasks, setTasks] = useState([]);
    const [productConfig, setProductConfig] = useState();
    const { lastMainRoute } = useLastMainRoute();
    const { tasksEnabled } = useSelector((state) => state.configState);
    const [shareApplicationModalOpen, setShareApplicationModalOpen] = useState(false);
    const pendingItemsCount = useMemo(() => getPendingItemsCount(pendingItems), [pendingItems]);
    const pendingItemsAndTasksBadgeCount = useMemo(() => {
        if (!tasksEnabled)
            return pendingItemsCount;
        const tasksCount = tasks.filter((task) => task.status !== TaskStatus.Done).length;
        return tasksCount + pendingItemsCount;
    }, [pendingItemsCount, tasks, tasksEnabled]);
    const updateApplication = (params) => {
        if (params) {
            return Axios.patch(`/admin/applications/${applicationId}`, params).then(() => {
                void getApplication();
                return Promise.resolve();
            }, (err) => {
                return Axios.error(err);
            });
        }
        else {
            return getApplication();
        }
    };
    const getApplication = () => __awaiter(void 0, void 0, void 0, function* () {
        const application = yield Axios.get(`/admin/applications/${applicationId}`).then(({ data }) => {
            setApplication(data);
            return data;
        }, (err) => void Axios.error(err));
        if (application && application.product.product_id && application.product.product_config_id) {
            yield Axios.get(`/admin/products/${application.product.product_id}/config/${application.product.product_config_id}`).then(({ data }) => setProductConfig(data), (err) => void Axios.error(err));
        }
        setLoading(false);
    });
    const getPendingItems = () => __awaiter(void 0, void 0, void 0, function* () {
        return Axios.get(`admin/applications/${applicationId}/next_status_validate`).then(({ data }) => {
            if (!applicationId)
                return;
            const signatoriesPath = generatePath(Routes.ApplicationSignatures, { applicationId });
            const signatoriesPendingItems = (data.signatories_errors || []).map((item) => (Object.assign(Object.assign({}, item), { action: () => navigate(signatoriesPath) })));
            const documentsPath = generatePath(Routes.ApplicationDocuments, { applicationId });
            const documentsPendingItems = data.pending_actions.reduce((acc, action) => {
                var _a;
                (_a = action.errors) === null || _a === void 0 ? void 0 : _a.forEach((err) => {
                    if (err.document_types)
                        acc.push({
                            error: err.error,
                            documentTypes: err.document_types,
                            action: () => navigate(documentsPath),
                        });
                });
                return acc;
            }, []);
            const generalPendingItems = data.pending_actions
                .reduce((acc, action) => {
                var _a;
                (_a = action.errors) === null || _a === void 0 ? void 0 : _a.forEach((err) => {
                    if (!err.document_types && !err.signatories && !acc.includes(err.error)) {
                        acc.push(err.error);
                    }
                });
                return acc;
            }, [])
                .map((item) => ({
                error: item,
                action: getPendingItemAction(item, applicationId, navigate, setActiveMenu, () => setShareApplicationModalOpen(true)),
            }))
                .filter((item) => item.action);
            setPendingItems({
                general: generalPendingItems,
                documents: documentsPendingItems,
                signatories: signatoriesPendingItems,
            });
        });
    });
    const getTasks = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!tasksEnabled)
            return;
        return Axios.get("admin/tasks", {
            related_entity_id: applicationId,
            related_entity_type: TaskRelatedEntityType.Application,
        }).then(({ data }) => setTasks(data.result || []), (err) => void Axios.error(err));
    });
    const showPayoutChecklist = useMemo(() => {
        var _a;
        return !!((_a = application === null || application === void 0 ? void 0 : application.checklists) === null || _a === void 0 ? void 0 : _a.find(({ checklist_type }) => checklist_type === ChecklistType.Payout));
    }, [application === null || application === void 0 ? void 0 : application.checklists]);
    const showFinalReviewChecklist = useMemo(() => {
        var _a;
        return !!((_a = application === null || application === void 0 ? void 0 : application.checklists) === null || _a === void 0 ? void 0 : _a.find(({ checklist_type }) => checklist_type === ChecklistType.FourEyeCheck));
    }, [application === null || application === void 0 ? void 0 : application.checklists]);
    const showChecklists = showPayoutChecklist || showFinalReviewChecklist;
    const sideMenuItems = useMemo(() => {
        const collection = [
            {
                icon: _jsx(InfoCircleOutlined, {}),
                key: SideActions.applicationInformation,
            },
            {
                icon: (_jsx(Badge, Object.assign({ count: pendingItemsAndTasksBadgeCount, size: "small", offset: [-4, 24] }, { children: _jsx(FileProtectOutlined, {}) }))),
                key: SideActions.tasksAndPendingItems,
            },
            {
                icon: _jsx(CommentOutlined, {}),
                key: SideActions.sharedNotes,
            },
            {
                icon: _jsx(HistoryOutlined, {}),
                key: SideActions.activityLog,
            },
            {
                icon: _jsx(FileSearchOutlined, {}),
                key: SideActions.analystReport,
                condition: (application === null || application === void 0 ? void 0 : application.status) === ApplicationStatuses.InternalReview,
            },
            {
                type: "divider",
                condition: (application === null || application === void 0 ? void 0 : application.status) &&
                    [ApplicationStatuses.FinalReview, ApplicationStatuses.AwaitingPayout].includes(application.status),
            },
            ...(showFinalReviewChecklist
                ? [
                    {
                        icon: (_jsx(Badge, Object.assign({ dot: true, offset: [2, 12] }, { children: _jsx(UnorderedListOutlined, {}) }))),
                        key: SideActions.finalReviewChecklist,
                        condition: (application === null || application === void 0 ? void 0 : application.status) === ApplicationStatuses.FinalReview,
                    },
                ]
                : []),
            ...(showPayoutChecklist
                ? [
                    {
                        icon: (_jsx(Badge, Object.assign({ dot: true, offset: [2, 12] }, { children: _jsx(UnorderedListOutlined, {}) }))),
                        key: SideActions.payoutChecklist,
                        condition: (application === null || application === void 0 ? void 0 : application.status) === ApplicationStatuses.AwaitingPayout,
                    },
                ]
                : []),
        ];
        return collection.reduce((acc, _a) => {
            var { condition } = _a, restItem = __rest(_a, ["condition"]);
            if (typeof condition === "undefined" || condition) {
                acc.push(restItem);
            }
            return acc;
        }, []);
    }, [
        application === null || application === void 0 ? void 0 : application.status,
        showPayoutChecklist,
        showFinalReviewChecklist,
        pendingItemsAndTasksBadgeCount,
    ]);
    const handleSiderClick = (key) => {
        if (!activeMenu) {
            return setActiveMenu(key);
        }
        if (key === SideActions.tasksAndPendingItems &&
            (activeMenu === SideActions.createTask || activeMenu === SideActions.taskDetails)) {
            return setActiveMenu(undefined);
        }
        setActiveMenu(key === activeMenu ? undefined : key);
    };
    useEffect(() => {
        setLoading(true);
        Promise.all([getApplication(), getPendingItems(), getTasks()]).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationId]);
    return (_jsx(_Fragment, { children: _jsxs(Layout, Object.assign({ style: { minHeight: "100vh" } }, { children: [_jsx(MainHeader, { title: _jsx(Spin, Object.assign({ spinning: loading }, { children: application && (_jsxs(HeaderWrapper, { children: [_jsx(LeftOutlined, { onClick: () => {
                                        navigate(lastMainRoute);
                                    } }), _jsx(Typography.Title, Object.assign({ level: 5, style: { marginBottom: 0 } }, { children: application.companyName })), _jsxs(Row, { children: [_jsx(Tag, { children: currency(application === null || application === void 0 ? void 0 : application.loanSize, { currency: application.currency }) }), application.partner_type && application.partner_type !== PartnerType.None && (_jsx(Tag, Object.assign({ color: "gold" }, { children: t(`partner.type.${application.partner_type}`) }))), _jsx(ApplicationStatus, { closable: false, label: t(`application.status.${application.status}`), value: application.status, disabled: false })] })] })) })), extra: application && (_jsxs(Space, { children: [_jsx(ShareApplication, { applicationId: application.applicationId, isOpen: shareApplicationModalOpen, open: () => setShareApplicationModalOpen(true), close: () => setShareApplicationModalOpen(false) }), _jsx(MoveApplication, { applicationId: application.applicationId, partnerId: application.partner_id, possibleStates: application.possible_states, statusFlow: application.status_flow, update: () => {
                                    void getApplication();
                                    void getPendingItems();
                                } })] })) }), _jsxs(Layout, Object.assign({ style: { flexDirection: "row" } }, { children: [application && (_jsx(ApplicationMenu, { applicationId: application.applicationId, applicationStatus: application.status, statusFlow: application.status_flow, allBanksLoanPurposes: application.allBanksLoanPurpose, showChecklists: showChecklists, productId: productConfig === null || productConfig === void 0 ? void 0 : productConfig.product_id })), _jsx(Content, { children: _jsx(LayoutContext.Provider, Object.assign({ value: {
                                    activeMenu,
                                    setActiveMenu,
                                    openShareApplicationModal: () => setShareApplicationModalOpen(true),
                                } }, { children: application && !loading ? (_jsx(ApplicationOutlet, { application: application, pendingItems: pendingItems, pendingItemsCount: pendingItemsCount, tasks: tasks, updateTasks: getTasks, productConfig: productConfig, updateApplication: updateApplication, updatePendingItems: () => void getPendingItems() })) : (_jsx(StyledLoader, { children: _jsx(Spin, { size: "large" }) })) })) }), _jsx(Sider, Object.assign({ theme: "light", width: 54, style: { borderLeft: "solid 1px #F0F0F0" }, onCollapse: () => {
                                setActiveMenu(undefined);
                            } }, { children: _jsx(Menu, { inlineCollapsed: true, theme: "light", mode: "inline", onClick: ({ key }) => handleSiderClick(key), items: sideMenuItems, selectedKeys: activeMenu ? [activeMenu] : [] }) }))] }))] })) }));
};
export default ApplicationLayout;
