import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { EuroCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import styled from "styled-components";
const { Title } = Typography;
const Wrapper = styled.div `
	margin-top: 24px;
	text-align: center;
	padding-top: 68px;
	padding-bottom: 40px;
`;
const EuroIcon = styled(EuroCircleOutlined) `
	font-size: 48px;
	color: #d9d9d9;
	margin-bottom: 24px;
`;
const FinancialsEmpty = () => {
    const { t } = useTranslation();
    return (_jsxs(Wrapper, { children: [_jsx(EuroIcon, {}), _jsx(Title, Object.assign({ level: 5 }, { children: t("loans.financials.no-financials-title") }))] }));
};
export default FinancialsEmpty;
