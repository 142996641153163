import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MailOutlined, MobileOutlined, PhoneOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import { Space, theme } from "antd";
import Avatar from "@ui/data-display/Avatar";
const ContactPerson = ({ person }) => {
    const name = `${person.first_name} ${person.last_name}`;
    const { token } = theme.useToken();
    return (_jsx("div", { children: _jsxs(Row, Object.assign({ gutter: [16, 16] }, { children: [_jsx(Col, { children: _jsx(Avatar, { name: name, size: "small" }) }), _jsx(Col, { children: _jsxs(Space, Object.assign({ direction: "vertical" }, { children: [_jsxs("div", { children: [_jsx(Row, { children: _jsx(Typography.Text, Object.assign({ strong: true }, { children: name })) }), _jsx(Row, { children: _jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: person.job_title })) })] }), _jsxs("div", { children: [_jsx(Row, { children: _jsxs(Space, { children: [_jsx(PhoneOutlined, { style: { color: token.colorTextSecondary } }), _jsx(Typography.Text, { children: person.phone_number || "-" })] }) }), _jsx(Row, { children: _jsxs(Space, { children: [_jsx(MobileOutlined, { style: { color: token.colorTextSecondary } }), _jsx(Typography.Text, { children: person.mobile_number || "-" })] }) }), _jsx(Row, { children: _jsxs(Space, { children: [_jsx(MailOutlined, { style: { color: token.colorTextSecondary } }), _jsx(Typography.Text, { children: person.email || "-" })] }) })] })] })) })] })) }));
};
export default ContactPerson;
