import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";
import { List, Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { fullDateTimeUTC } from "@teylor-tools/utils/dateFormats";
import { Axios } from "src/utils/Axios";
const { Text } = Typography;
const SmallText = styled.div `
	margin-top: 8px;
	font-size: 12px;
	word-break: break-word;
`;
const StyledListItem = styled(List.Item) `
	padding: 24px;
`;
const StyledUserOutlined = styled(UserOutlined) `
	margin-right: 10px;
`;
const LoanLogs = ({ loanId }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [elements, setElements] = useState([]);
    useEffect(() => {
        setLoading(true);
        Axios.get(`/admin/loans/${loanId}/logs`, { new_format: true })
            .then(({ data }) => {
            setElements(data.logs || []);
        }, (error) => Axios.error(error))
            .finally(() => {
            setLoading(false);
        });
    }, [loanId]);
    return (_jsx(List, { loading: loading, dataSource: elements, itemLayout: "vertical", renderItem: ({ event_time, actor_id, event_data, event_action, event_name, event_description, }) => (_jsxs(StyledListItem, { children: [_jsx("div", { children: _jsx(Text, Object.assign({ strong: true }, { children: event_time ? dayjs(event_time).utc().format(fullDateTimeUTC) : "-" })) }), _jsx("div", { children: _jsxs(Text, Object.assign({ type: "secondary" }, { children: [_jsx(StyledUserOutlined, { size: 12 }), actor_id] })) }), _jsx("div", { children: _jsx(Text, { children: `${t("loans.logs-event-action")}: ${event_action}` }) }), _jsx("div", { children: _jsx(Text, { children: `${t("loans.logs-event-name")}: ${event_name || "-"}` }) }), _jsx("div", { children: _jsx(Text, { children: `${t("loans.logs-event-description")}: ${event_description || "-"}` }) }), _jsx(SmallText, { children: JSON.stringify(event_data) })] })) }));
};
export default LoanLogs;
