import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";

import { DataCard, DataCardItem } from "../../data-display/DataCard";
import { useTranslations } from "../../translations/translations";
import ScoringGrade, { ScoringGradeValue } from "../scoring-grade/ScoringGrade";

interface Props {
	scoreGrade: ScoringGradeValue;
	rate?: string;
}

const ScoringOverviewAlyze = ({ scoreGrade, rate }: Props) => {
	const t = useTranslations();
	const { decimalToPercent } = useFormatter();

	return (
		<DataCard title={t.scoring.overview.alyze}>
			<DataCardItem
				label={t.scoring.overview.alyzeRating}
				value={<ScoringGrade score={scoreGrade} />}
			/>
			{rate && (
				<DataCardItem
					label={t.scoring.overview.gridRate}
					value={isValidNumericValue(rate) ? decimalToPercent(rate, undefined, true) : undefined}
				/>
			)}
		</DataCard>
	);
};

export default ScoringOverviewAlyze;
