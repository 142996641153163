import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Empty } from "antd";
import { Table } from "./Table";
import { getPropertyName } from "./utils";
var ScoreCategory;
(function (ScoreCategory) {
    ScoreCategory["SolvencyScore"] = "solvency";
    ScoreCategory["ProfitabilityScore"] = "profitability";
    ScoreCategory["LiquidityScore"] = "liquidity";
    ScoreCategory["LeverageScore"] = "leverage";
    ScoreCategory["FuturePerformanceScore"] = "future_performance";
    ScoreCategory["EfficiencyScore"] = "efficiency";
    ScoreCategory["AdditionalLoanScore"] = "additional_loan";
})(ScoreCategory || (ScoreCategory = {}));
const BenchmarkResults = ({ title, benchmarkData = [] }) => {
    const { t } = useTranslation();
    const getBenchmarkCols = () => {
        const columns = [
            {
                title: `${title}`,
                dataIndex: "name",
                key: "name",
                children: [],
                width: "280px",
            },
        ];
        benchmarkData.forEach((scoringForPeriod) => {
            columns.push({
                title: `Period ${scoringForPeriod.period_start} - ${scoringForPeriod.period_end}`,
                dataIndex: `Period ${scoringForPeriod.period_start} - ${scoringForPeriod.period_end}`,
                key: `Period ${scoringForPeriod.period_start} - ${scoringForPeriod.period_end}`,
                width: "160px",
                children: [
                    {
                        title: t("application.quantitative_analysis.overview.title-score"),
                        dataIndex: `${scoringForPeriod.period_start}_${scoringForPeriod.period_end}_score`,
                        key: `${scoringForPeriod.period_start}_${scoringForPeriod.period_end}_score`,
                    },
                    {
                        title: t("application.quantitative_analysis.overview.title-mark"),
                        dataIndex: `${scoringForPeriod.period_start}_${scoringForPeriod.period_end}_mark`,
                        key: `${scoringForPeriod.period_start}_${scoringForPeriod.period_end}_mark`,
                    },
                ],
            });
        });
        return [
            ...columns,
            {
                dataIndex: "invisible",
            },
        ];
    };
    const getBenchmarkData = () => {
        const scores = Object.values(ScoreCategory).map((scoreCategory) => {
            const row = {
                key: scoreCategory,
                name: getPropertyName(scoreCategory),
            };
            benchmarkData.forEach((scoringForPeriod) => {
                if (scoringForPeriod.benchmarking_scores[scoreCategory]) {
                    const score = scoringForPeriod.benchmarking_scores[scoreCategory];
                    // @ts-ignore take a look at it if you work on this file
                    row[`${scoringForPeriod.period_start}_${scoringForPeriod.period_end}_score`] =
                        score === null || score === void 0 ? void 0 : score.value;
                    // @ts-ignore take a look at it if you work on this file
                    row[`${scoringForPeriod.period_start}_${scoringForPeriod.period_end}_mark`] = score === null || score === void 0 ? void 0 : score.mark;
                }
            });
            return row;
        });
        // Add total scores row
        const row = {
            key: "total_score",
            name: "Total score",
        };
        benchmarkData.forEach((scoringForPeriod) => {
            // @ts-ignore take a look at it if you work on this file
            row[`${scoringForPeriod.period_start}_${scoringForPeriod.period_end}_score`] =
                scoringForPeriod.total_score;
            // @ts-ignore take a look at it if you work on this file
            row[`${scoringForPeriod.period_start}_${scoringForPeriod.period_end}_mark`] =
                scoringForPeriod.mark;
        });
        return [...scores, row];
    };
    if (!(benchmarkData === null || benchmarkData === void 0 ? void 0 : benchmarkData.length))
        return _jsx(Empty, { description: t("application.quantitative_analysis.overview.no-data") });
    return (_jsx(Table, { bordered: true, pagination: false, scroll: { y: `calc(100vh - 300px)` }, sticky: true, dataSource: getBenchmarkData(), columns: getBenchmarkCols() }));
};
export default BenchmarkResults;
