import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { Alert as AntdAlert, Button, message } from "antd";
import styled from "styled-components";
import { ApplicationStatuses, PendingActionError, } from "@teylor-tools/Api";
import RepresentativeModal from "@ui/representative-modal/RepresentativeModal";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { Axios } from "src/utils/Axios";
import CompanyRepresentative from "./modules/CompanyRepresentative";
import CompanyRepresentativeEmpty from "./modules/CompanyRepresentativeEmpty";
const Alert = styled(AntdAlert) `
	margin-bottom: 24px;
`;
const ContentWrapper = styled.div `
	margin-top: 64px;
	padding: 24px;
`;
const RepresentativesWrapper = styled.div `
	display: grid;
	gap: 16px;
	grid-template-columns: repeat(auto-fill, minmax(460px, auto));
`;
const ApplicationRepresentatives = () => {
    const { t } = useTranslation();
    const { application, updatePendingItems, pendingItems } = useApplication();
    const [representatives, setRepresentatives] = useState();
    const [showAddRepresentativeModal, setShowAddRepresentativeModal] = useState(false);
    const { countries } = useSelector((rootState) => rootState.configState);
    const isEditable = ![
        ApplicationStatuses.Rejected,
        ApplicationStatuses.PaidOut,
        ApplicationStatuses.AwaitingPayout,
        ApplicationStatuses.OfferAccepted,
        ApplicationStatuses.FinalReview,
    ].includes(application.status);
    const getRepresentatives = useCallback(() => {
        Axios.get(`/admin/applications/${application.applicationId}/signatories`).then(({ data }) => {
            setRepresentatives(data.signatories);
        }, (err) => {
            void Axios.error(err);
        });
    }, [application.applicationId]);
    const addRepresentative = (signatory) => {
        Axios.post(`/admin/applications/${application.applicationId}/signatories`, {
            data: [signatory],
        })
            .then(() => {
            void message.success(t("application.company_representatives.representative-added"));
            void getRepresentatives();
            setShowAddRepresentativeModal(false);
        }, (err) => void Axios.error(err, t("application.company_representatives.error-try-again")))
            .finally(updatePendingItems);
    };
    useEffect(() => {
        void getRepresentatives();
    }, [getRepresentatives]);
    const isOwnershipPercentageExceeded = !!pendingItems.general.find((item) => item.error === PendingActionError.ErrSharePercentageGreaterThen100);
    const isNoSoleSignatory = !!pendingItems.general.find((item) => item.error === PendingActionError.ErrApplicationSignatoriesSoleOrAtleast2);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, Object.assign({ title: `${t("application.company_representatives.company-representatives")} (${(representatives === null || representatives === void 0 ? void 0 : representatives.length) || 0})` }, { children: isEditable ? (_jsx(Button, Object.assign({ type: "primary", icon: _jsx(PlusOutlined, {}), onClick: () => setShowAddRepresentativeModal(true) }, { children: t("common.add") }))) : (_jsx(_Fragment, {})) })), _jsxs(ContentWrapper, { children: [!!(representatives === null || representatives === void 0 ? void 0 : representatives.length) && (isOwnershipPercentageExceeded || isNoSoleSignatory) && (_jsx(Alert, { type: "error", message: isNoSoleSignatory
                            ? t("application.company_representatives.error.err_application_signatories_sole_or_atleast_2")
                            : t("application.company_representatives.error.err_share_percentage_greater_then_100"), showIcon: true })), !representatives || !representatives.length ? (_jsx(CompanyRepresentativeEmpty, { addRepresentative: () => {
                            setShowAddRepresentativeModal(true);
                        } })) : (_jsx(RepresentativesWrapper, { children: representatives.map((representative) => (_jsx(CompanyRepresentative, { representative: representative, applicationStatus: application.status, refetchRepresentatives: () => {
                                getRepresentatives();
                                updatePendingItems();
                            }, isEditable: isEditable, applicationId: application.applicationId }, representative.signatory_id))) }))] }), showAddRepresentativeModal && (_jsx(RepresentativeModal, { save: addRepresentative, close: () => setShowAddRepresentativeModal(false), countries: countries, companyTin: application.taxIdNumber }))] }));
};
export default ApplicationRepresentatives;
