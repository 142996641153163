import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Card as AntdCard, Divider, Spin, Typography } from "antd";
import styled from "styled-components";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { useLoan } from "src/layouts/LoanLayout/loan.types";
import { Axios } from "src/utils/Axios";
const { Text } = Typography;
const Wrapper = styled.div `
	margin: 92px 24px 24px;
`;
const Card = styled(AntdCard) `
	max-width: 720px;
	min-width: 420px;
`;
const GroupWrapper = styled.div `
	display: flex;
`;
const GroupHeader = styled.div `
	flex: 0 0 140px;
`;
const CheckItem = styled.div `
	display: flex;
	gap: 8px;
	margin-bottom: 8px;
`;
const CheckIcon = styled(CheckCircleOutlined) `
	color: ${({ theme }) => theme.colorSuccess};
	margin-top: 3px;
`;
const getChecklist = (url) => {
    return Axios.get(url).then(({ data }) => Promise.resolve(data), (err) => Promise.reject(err));
};
const LoanFinalReviewChecklist = () => {
    const { t } = useTranslation();
    const { loan: { loan_id }, } = useLoan();
    return (_jsx(CompletedChecklist, { title: t("application.final_review.title"), getChecklist: () => getChecklist(`/admin/loans/${loan_id}/four_eye_checklist`) }));
};
const LoanPayoutChecklist = () => {
    const { t } = useTranslation();
    const { loan: { loan_id }, } = useLoan();
    return (_jsx(CompletedChecklist, { title: t("application.payout_checklist.title"), getChecklist: () => getChecklist(`/admin/loans/${loan_id}/payout_checklist`) }));
};
const CompletedChecklist = ({ title, getChecklist }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [checklistGroups, setChecklistGroups] = useState([]);
    useEffect(() => {
        setLoading(true);
        getChecklist()
            .then((checklist) => { var _a; return setChecklistGroups(((_a = checklist.checklist_data) === null || _a === void 0 ? void 0 : _a.groups) || []); }, (err) => Axios.error(err, t("common.request-failed")))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getChecklist]);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: title }), _jsx(Spin, Object.assign({ spinning: loading }, { children: checklistGroups.length && (_jsx(Wrapper, { children: _jsx(Card, { children: checklistGroups.map((group, i) => (_jsxs(Fragment, { children: [_jsxs(GroupWrapper, { children: [_jsx(GroupHeader, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: group.description })) }), _jsx("div", { children: group.items.map((item) => {
                                                switch (item.item_type) {
                                                    case "checkbox":
                                                        return (_jsxs(CheckItem, { children: [_jsx(CheckIcon, {}), _jsx(Text, { children: item.options[0].description })] }, item.id));
                                                    case "text":
                                                        return _jsx(Text, { children: item.text }, item.id);
                                                }
                                            }) })] }), i < checklistGroups.length - 1 && _jsx(Divider, {})] }, group.id))) }) })) }))] }));
};
export { LoanFinalReviewChecklist, LoanPayoutChecklist };
