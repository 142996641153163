import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Drawer, Form, Row, Select, Space, Spin, Tag, Tooltip, Typography, } from "antd";
import { useForm } from "antd/es/form/Form";
import styled from "styled-components";
import { ApplicationStatuses, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { Routes } from "src/Routes";
import ApplicationStatus from "src/components/ui/statuses/ApplicationStatus";
import LoanStatus from "src/components/ui/statuses/LoanStatus";
import { Axios } from "src/utils/Axios";
const { Text } = Typography;
const Section = styled.div `
	margin: 24px;
`;
const MAX_APPLICATIONS = 10;
const ConcentrationLimitsDrawer = ({ isOpen, close, startSession }) => {
    var _a;
    const { t } = useTranslation();
    const [form] = useForm();
    const [loading, setLoading] = useState(true);
    const [applicationsSearch, setApplicationsSearch] = useState([]);
    const [selectedApplications, setSelectedApplications] = useState([]);
    const { currency } = useFormatter();
    const navigate = useNavigate();
    const [loans, setLoans] = useState({ loans: [], total: 0 });
    const applicationSelectDisabled = selectedApplications.length >= MAX_APPLICATIONS;
    const handleApplicationsSearch = (searchQuery) => {
        if (!searchQuery) {
            setApplicationsSearch([]);
            return;
        }
        if (searchQuery.length < 3)
            return;
        void Axios.get("admin/applications", { page_size: 10, page: 1, text: searchQuery }).then(({ data }) => setApplicationsSearch(data.result), (err) => Axios.error(err));
    };
    const selectApplication = ({ applicationId }) => {
        if (!applicationId)
            return;
        const applicationAlreadySelected = !!selectedApplications.find((a) => a.application_id === applicationId);
        if (!applicationAlreadySelected) {
            const application = applicationsSearch.find((a) => a.application_id === applicationId);
            application && setSelectedApplications((prev) => [...prev, application]);
        }
        form.resetFields();
        setApplicationsSearch([]);
    };
    const handleApplicationRemove = (id) => setSelectedApplications((prev) => prev.filter((application) => application.application_id !== id));
    const renderApplicationRow = (application, allowRemove = false) => {
        return (_jsxs(Row, Object.assign({ justify: "space-between", align: "middle" }, { children: [_jsx(Col, { children: application.company_name }), _jsxs(Col, { children: [_jsx(Tag, { children: currency(application.amount, { currency: application.currency }) }), _jsx(ApplicationStatus, { label: t(`application.status.${application.status}`), value: application.status, short: true, closable: false, disabled: false, style: { width: 30, textAlign: "center" } }), allowRemove && (_jsx(Button, { type: "link", onClick: () => handleApplicationRemove(application.application_id), icon: _jsx(DeleteOutlined, {}) }))] })] }), application.application_id));
    };
    const renderLoanRow = (loan) => {
        return (_jsxs(Row, Object.assign({ justify: "space-between", align: "middle" }, { children: [_jsx(Col, { children: loan.company.companyName }), _jsxs(Col, { children: [_jsx(Tag, { children: currency(loan.amount, { currency: loan.currency }) }), _jsx(LoanStatus, { label: t(`loans.status.${loan.loan_status}`), value: loan.loan_status, short: true, closable: false, disabled: false, style: { width: 30, textAlign: "center" } })] })] }), loan.loan_id));
    };
    const getLoans = () => {
        return Axios.get("admin/loans", {
            page: 1,
            page_size: 3,
            is_eligible: true,
        }).then(({ data }) => setLoans({ loans: data.result, total: data.pagination.total_rows }), (err) => Axios.error(err));
    };
    const handleStartSession = () => {
        if (!selectedApplications)
            return;
        const applications = selectedApplications.map((a) => ({ application_id: a.application_id }));
        setLoading(true);
        startSession(applications)
            .then(close)
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        if (!isOpen)
            return;
        setLoading(true);
        setSelectedApplications([]);
        getLoans().finally(() => setLoading(false));
    }, [isOpen]);
    return (_jsx(Drawer, Object.assign({ width: 400, placement: "right", mask: false, closable: false, bodyStyle: { padding: 0 }, open: isOpen, title: _jsxs(Row, Object.assign({ justify: "space-between", wrap: false, style: { wordBreak: "break-all" } }, { children: [_jsx(Typography.Title, Object.assign({ level: 5, style: { marginTop: 8 }, type: loading ? "secondary" : undefined }, { children: t("portfolio.concentration-limits.sidebar.title") })), _jsx(Button, { onClick: close, icon: _jsx(CloseOutlined, {}), disabled: loading })] })) }, { children: _jsxs(Spin, Object.assign({ spinning: loading }, { children: [_jsxs(Section, { children: [_jsxs(Row, Object.assign({ align: "middle", justify: "space-between" }, { children: [_jsx(Text, Object.assign({ strong: true }, { children: `${t("portfolio.concentration-limits.applications")} (${selectedApplications.length})` })), _jsx("div", Object.assign({ style: { height: 32 } }, { children: !!selectedApplications.length && (_jsx(Button, Object.assign({ type: "link", onClick: () => setSelectedApplications([]) }, { children: t("portfolio.concentration-limits.clear-all-applications") }))) }))] })), _jsx(Form, Object.assign({ form: form, onFinish: selectApplication }, { children: _jsx(Tooltip, Object.assign({ title: applicationSelectDisabled
                                    ? t("portfolio.concentration-limits.applications-search-max-tooltip", {
                                        max: MAX_APPLICATIONS,
                                    })
                                    : "" }, { children: _jsx(Form.Item, Object.assign({ name: "applicationId" }, { children: _jsx(Select, { showSearch: true, placeholder: t("portfolio.concentration-limits.applications-search-placeholder"), filterOption: false, onSearch: handleApplicationsSearch, onSelect: form.submit, options: applicationsSearch === null || applicationsSearch === void 0 ? void 0 : applicationsSearch.map((a) => ({
                                            value: a.application_id,
                                            label: renderApplicationRow(a),
                                            disabled: a.status === ApplicationStatuses.PaidOut,
                                        })), allowClear: true, style: { width: "100%", margin: "16px 0 0" }, disabled: applicationSelectDisabled }) })) })) })), _jsx(Space, Object.assign({ size: 16, direction: "vertical", style: { width: "100%" } }, { children: selectedApplications.map((application) => renderApplicationRow(application, true)) }))] }), _jsx(Divider, {}), _jsxs(Section, { children: [_jsxs(Row, Object.assign({ align: "middle", justify: "space-between" }, { children: [_jsx(Text, Object.assign({ strong: true }, { children: `${t("portfolio.concentration-limits.loans")} (${loans.total})` })), _jsx(Button, Object.assign({ type: "link", onClick: () => navigate({ pathname: Routes.Loans, search: `?is_eligible=true` }) }, { children: t("portfolio.concentration-limits.see-all-loans") }))] })), _jsx(Space, Object.assign({ size: 16, direction: "vertical", style: { width: "100%", marginTop: 16 } }, { children: (_a = loans.loans) === null || _a === void 0 ? void 0 : _a.map((loan) => renderLoanRow(loan)) }))] }), _jsx(Divider, {}), _jsx(Section, Object.assign({ style: { textAlign: "right" } }, { children: _jsx(Button, Object.assign({ type: "primary", onClick: handleStartSession }, { children: t("portfolio.concentration-limits.start-session-btn") })) }))] })) })));
};
export default ConcentrationLimitsDrawer;
