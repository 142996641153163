var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Alert, Space, Tag, message } from "antd";
import FormItem from "antd/es/form/FormItem";
import dayjs from "dayjs";
import styled from "styled-components";
import { ApplicationStatuses, FeatureName, MinRateSourcesEnum, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import { DataCardItem } from "@ui/data-display/DataCard";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import PercentInput from "@ui/form/inputs/percent-input/PercentInput";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { DataCard, DataCardDivider, DataCardItemEditable } from "src/components/DataCard";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import ApplicationPricingTlb from "src/pages/applications/subpages/ApplicationPricingTlb";
import { Axios } from "src/utils/Axios";
const Wrapper = styled.div `
	margin: 92px 24px 24px;
`;
const ApplicationPricing = () => {
    var _a, _b;
    const { t } = useTranslation();
    const { application, updateApplication, productConfig, updatePendingItems } = useApplication();
    const { currency, decimalToPercent, percentToDecimal } = useFormatter();
    const { features } = useSelector((state) => state.configState);
    const [latestGlobalRates, setLatestGlobalRates] = useState([]);
    const showTlbPricing = useMemo(() => {
        return (productConfig === null || productConfig === void 0 ? void 0 : productConfig.type) === "loan_bullet" && !application.pricing.external_pricing_allowed;
    }, [productConfig, application]);
    const minInterest = useMemo(() => {
        return (productConfig === null || productConfig === void 0 ? void 0 : productConfig.allow_negative_internal_rate)
            ? -5
            : (isValidNumericValue(application.pricing.minimum_interest_rate) &&
                Number(decimalToPercent(application.pricing.minimum_interest_rate, 2, false, true))) ||
                0;
    }, [
        application.pricing.minimum_interest_rate,
        productConfig === null || productConfig === void 0 ? void 0 : productConfig.allow_negative_internal_rate,
        decimalToPercent,
    ]);
    const minInterestLocalised = useMemo(() => {
        return (productConfig === null || productConfig === void 0 ? void 0 : productConfig.allow_negative_internal_rate)
            ? (-5).toFixed(2)
            : (isValidNumericValue(application.pricing.minimum_interest_rate) &&
                decimalToPercent(application.pricing.minimum_interest_rate)) ||
                "0";
    }, [
        application.pricing.minimum_interest_rate,
        productConfig === null || productConfig === void 0 ? void 0 : productConfig.allow_negative_internal_rate,
        decimalToPercent,
    ]);
    const showPartnerInfo = useMemo(() => {
        var _a;
        return ((_a = features.find((f) => f.name === FeatureName.DefaultPartner)) === null || _a === void 0 ? void 0 : _a.value) !==
            application.partner_id && application.pricing.external_pricing_allowed;
    }, [application.partner_id, features, application.pricing.external_pricing_allowed]);
    const showExternalPricing = application.pricing.external_pricing_allowed && application.partner_id;
    const isPrepaymentRateEditable = useMemo(() => [ApplicationStatuses.Pricing, ApplicationStatuses.InternalPricing].includes(application.status), [application.status]);
    useEffect(() => {
        if ((productConfig === null || productConfig === void 0 ? void 0 : productConfig.type) !== "loan_bullet")
            return;
        void Axios.get(`/admin/global_rates/latest`)
            .then(({ data }) => {
            setLatestGlobalRates(data);
        })
            .catch((error) => {
            void Axios.error(error);
        });
    }, [productConfig]);
    const isInternalPricingEditable = useMemo(() => [
        ApplicationStatuses.Pricing,
        ApplicationStatuses.InternalPricing,
        ApplicationStatuses.OfferAccepted,
        ApplicationStatuses.OfferAvailable,
    ].includes(application.status), [application.status]);
    const update = (values) => {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const update = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (isValidNumericValue(values.interest_rate) && {
            interest_rate: Number(percentToDecimal(values.interest_rate)),
        })), (isValidNumericValue(values.transaction_fee) && {
            transaction_fee: values.transaction_fee,
        })), (isValidNumericValue(values.external_transaction_fee) && {
            external_transaction_fee: values.external_transaction_fee,
        })), (isValidNumericValue(values.external_interest_rate) && {
            external_interest_rate: Number(percentToDecimal(values.external_interest_rate)),
        })), (isValidNumericValue(values.prepayment_rate) && {
            prepayment_rate: Number(percentToDecimal(values.prepayment_rate)),
        })), (isValidNumericValue(values.hedging_rate) && {
            hedging_rate: Number(percentToDecimal(values.hedging_rate)),
        }));
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
        Axios.post(`/admin/applications/${application.applicationId}/pricing`, update)
            .then(() => {
            void updateApplication();
            void message.success(t("application.pricing.saved"));
        }, (error) => void Axios.error(error, t("application.pricing.validation.error.be-update")))
            .finally(updatePendingItems);
    };
    const renderBaseTransactionFee = () => (_jsx(DataCardItemEditable, { label: t("application.pricing.transaction-fee"), displayedValue: isValidNumericValue(application.pricing.transaction_fee)
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                currency(application.pricing.transaction_fee, {
                    currency: application.currency,
                })
            : "", isEditable: isInternalPricingEditable, onChange: (value) => update({ transaction_fee: Number(value) }), customFormItem: (_a) => {
            var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
            return (_jsx(FormItem, Object.assign({}, props, { children: _jsx(CurrencyInput, Object.assign({ min: 0, step: 0.01 }, inputProps, { currency: application.currency })) })));
        }, formItemValue: application.pricing.transaction_fee || 0 }));
    if (showTlbPricing) {
        return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: t("application.pricing.pricing") }), _jsx(Wrapper, { children: _jsx(ApplicationPricingTlb, { rates: latestGlobalRates, update: update }) })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: t("application.pricing.pricing") }), _jsxs(Wrapper, { children: [showPartnerInfo && (_jsx(Alert, { message: t("application.pricing.partner-info"), style: { marginBottom: 24 }, type: "info" })), _jsxs(DataCard, { children: [(productConfig === null || productConfig === void 0 ? void 0 : productConfig.type) === "loan_bullet" && (_jsxs(_Fragment, { children: [latestGlobalRates === null || latestGlobalRates === void 0 ? void 0 : latestGlobalRates.map((rate) => {
                                        return (_jsx(DataCardItem, { label: t(`application.pricing.latest-global-rate.${rate.global_rate_period}`), value: (rate === null || rate === void 0 ? void 0 : rate.global_rate_value) && isValidNumericValue(rate === null || rate === void 0 ? void 0 : rate.global_rate_value) ? (_jsxs(Space, Object.assign({ size: "large" }, { children: [decimalToPercent(rate === null || rate === void 0 ? void 0 : rate.global_rate_value, undefined, true), rate.global_rate_effective_date && (_jsx(Tag, { children: t("application.pricing.latest-global-rate.date", {
                                                            date: dayjs(rate.global_rate_effective_date).format(dayMonthYear),
                                                        }) }))] }))) : ("-") }, rate.global_rate_id));
                                    }), _jsx(DataCardItem, { label: t("application.pricing.floor-rate"), value: productConfig.floor_rate && isValidNumericValue(productConfig.floor_rate)
                                            ? decimalToPercent(productConfig.floor_rate, undefined, true)
                                            : "-" }), _jsx(DataCardDivider, {})] })), ((_a = productConfig === null || productConfig === void 0 ? void 0 : productConfig.min_rate_sources) === null || _a === void 0 ? void 0 : _a.includes(MinRateSourcesEnum.GridRate)) && (_jsx(DataCardItem, { label: t("application.pricing.grid-rate"), value: isValidNumericValue(application.pricing.grid_rate)
                                    ? decimalToPercent(application.pricing.grid_rate, undefined, true)
                                    : "" })), ((_b = productConfig === null || productConfig === void 0 ? void 0 : productConfig.min_rate_sources) === null || _b === void 0 ? void 0 : _b.includes(MinRateSourcesEnum.HedgingRate)) && (_jsx(DataCardItemEditable, { label: t("application.pricing.hedging-rate"), displayedValue: isValidNumericValue(application.pricing.hedging_rate)
                                    ? decimalToPercent(application.pricing.hedging_rate, undefined, true)
                                    : "", isEditable: isInternalPricingEditable, onChange: (value) => update({ hedging_rate: Number(value) }), customFormItem: (_a) => {
                                    var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
                                    return (_jsx(FormItem, Object.assign({}, props, { rules: [
                                            {
                                                type: "number",
                                                max: 10,
                                                message: t("application_form.errors.fieldMustBeMax", {
                                                    max: `10%`,
                                                }),
                                            },
                                        ] }, { children: _jsx(PercentInput, Object.assign({ min: 0 }, inputProps)) })));
                                }, formItemValue: isValidNumericValue(application.pricing.hedging_rate)
                                    ? decimalToPercent(application.pricing.hedging_rate)
                                    : 0 })), _jsx(DataCardItem, { label: t("application.pricing.minimum-interest-rate"), value: isValidNumericValue(application.pricing.minimum_interest_rate)
                                    ? decimalToPercent(application.pricing.minimum_interest_rate, undefined, true)
                                    : "" }), _jsx(DataCardDivider, {}), _jsx(DataCardItemEditable, { label: t("application.pricing.interest-rate"), displayedValue: isValidNumericValue(application.pricing.interest_rate)
                                    ? `${decimalToPercent(application.pricing.interest_rate)}%`
                                    : undefined, isEditable: isInternalPricingEditable, onChange: (value) => update({ interest_rate: Number(value) }), customFormItem: (_a) => {
                                    var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
                                    return (_jsx(FormItem, Object.assign({}, props, { rules: [
                                            {
                                                type: "number",
                                                min: minInterest,
                                                message: t("application_form.errors.fieldMustBeMin", {
                                                    min: `${minInterestLocalised}%`,
                                                }),
                                            },
                                            {
                                                type: "number",
                                                max: 99,
                                                message: t("application_form.errors.fieldMustBeMax", {
                                                    max: `99%`,
                                                }),
                                            },
                                        ] }, { children: _jsx(PercentInput, Object.assign({}, inputProps)) })));
                                }, formItemValue: isValidNumericValue(application.pricing.interest_rate)
                                    ? decimalToPercent(application.pricing.interest_rate)
                                    : 0 }), !showExternalPricing ? (_jsx(_Fragment, { children: renderBaseTransactionFee() })) : (_jsxs(_Fragment, { children: [_jsx(DataCardItemEditable, { label: t("application.pricing.external-interest-rate"), displayedValue: isValidNumericValue(application.pricing.external_interest_rate)
                                            ? decimalToPercent(application.pricing.external_interest_rate, undefined, true)
                                            : "", isEditable: application.status === ApplicationStatuses.ExternalPricing, onChange: (value) => update({ external_interest_rate: Number(value) }), customFormItem: (_a) => {
                                            var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
                                            return (_jsx(FormItem, Object.assign({}, props, { rules: [
                                                    {
                                                        type: "number",
                                                        max: 99,
                                                        message: t("application_form.errors.fieldMustBeMax", {
                                                            max: `99%`,
                                                        }),
                                                    },
                                                ] }, { children: _jsx(PercentInput, Object.assign({ min: 0 }, inputProps)) })));
                                        }, formItemValue: isValidNumericValue(application.pricing.external_interest_rate)
                                            ? decimalToPercent(application.pricing.external_interest_rate)
                                            : 0 }), _jsx(DataCardItem, { label: t("application.pricing.total-interest-rate"), value: isValidNumericValue(application.pricing.total_interest_rate)
                                            ? decimalToPercent(application.pricing.total_interest_rate, undefined, true)
                                            : "" }), _jsx(DataCardDivider, {}), renderBaseTransactionFee(), _jsx(DataCardItemEditable, { label: t("application.pricing.external-transaction-fee"), displayedValue: isValidNumericValue(application.pricing.external_transaction_fee)
                                            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                currency(application.pricing.external_transaction_fee, {
                                                    currency: application.currency,
                                                })
                                            : "", isEditable: application.status === ApplicationStatuses.ExternalPricing, onChange: (value) => update({ external_transaction_fee: Number(value) }), customFormItem: (_a) => {
                                            var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
                                            return (_jsx(FormItem, Object.assign({}, props, { children: _jsx(CurrencyInput, Object.assign({ min: 0, step: 0.01 }, inputProps, { currency: application.currency })) })));
                                        }, formItemValue: application.pricing.external_transaction_fee }), _jsx(DataCardItem, { label: t("application.pricing.total-transaction-fee"), value: isValidNumericValue(application.pricing.total_transaction_fee)
                                            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                currency(application.pricing.total_transaction_fee, {
                                                    currency: application.currency,
                                                })
                                            : "" })] })), (productConfig === null || productConfig === void 0 ? void 0 : productConfig.prepayment_rate) && (_jsxs(_Fragment, { children: [_jsx(DataCardDivider, {}), _jsx(DataCardItemEditable, { label: t("application.pricing.prepayment_rate"), displayedValue: isValidNumericValue(application.pricing.prepayment_rate)
                                            ? decimalToPercent(application.pricing.prepayment_rate, undefined, true)
                                            : "", isEditable: isPrepaymentRateEditable, onChange: (value) => update({ prepayment_rate: Number(value) }), customFormItem: (_a) => {
                                            var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
                                            return (_jsx(FormItem, Object.assign({}, props, { rules: [
                                                    {
                                                        type: "number",
                                                        max: 10,
                                                        message: t("application_form.errors.fieldMustBeMax", {
                                                            max: `10%`,
                                                        }),
                                                    },
                                                ] }, { children: _jsx(PercentInput, Object.assign({ min: 0 }, inputProps)) })));
                                        }, formItemValue: isValidNumericValue(application.pricing.prepayment_rate)
                                            ? decimalToPercent(application.pricing.prepayment_rate)
                                            : undefined })] }))] })] })] }));
};
export default ApplicationPricing;
