import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import styled from "styled-components";
import timePassedUntilNow from "@teylor-tools/utils/timePassedUntilNow";
import { DataCardItem } from "@ui/data-display/DataCard";
import IndustryCodesCard from "@ui/industry-codes-card/IndustryCodesCard";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { DataCard, DataCardDivider } from "src/components/DataCard";
import { useLoan } from "src/layouts/LoanLayout/loan.types";
import { Axios } from "src/utils/Axios";
const Wrapper = styled.div `
	margin: 92px 24px 24px;
`;
const pickTimePassed = (foundationDate, t) => {
    const { monthsPassed, yearsPassed } = timePassedUntilNow(foundationDate);
    return `${t("loans.company.year", { count: yearsPassed })} ${t("loans.company.month", {
        count: monthsPassed,
    })}`;
};
const LoanCompany = () => {
    const { t } = useTranslation();
    const { loan } = useLoan();
    const [loading, setLoading] = useState(false);
    const [company, setCompany] = useState();
    const [creditScore, setCreditScore] = useState();
    const getCompany = useCallback(() => {
        setLoading(true);
        Axios.get(`/admin/companies/${loan.company.companyId}`)
            .then(({ data }) => {
            setCompany(data);
        }, (err) => void Axios.error(err))
            .finally(() => {
            setLoading(false);
        });
    }, [loan.company.companyId]);
    const getCreditScore = useCallback(() => {
        Axios.get(`admin/companies/${loan.company.companyId}/credit_score`).then(({ data }) => setCreditScore(data[0]), (err) => void Axios.error(err));
    }, [loan.company.companyId]);
    const updateIndustryCodes = (values) => {
        return Axios.patch(`/admin/companies/${loan.company.companyId}/industry_codes`, values).then(() => {
            void getCompany();
        }, (err) => void Axios.error(err));
    };
    useEffect(() => {
        getCompany();
        getCreditScore();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: t("loans.menu.company") }), _jsx(Wrapper, { children: _jsx(Row, { children: _jsxs(Col, Object.assign({ sm: 24 }, { children: [_jsxs(DataCard, { children: [_jsx(DataCardItem, { label: t("loans.company.name"), value: _jsx("strong", { children: loan.company.companyName }) }), _jsx(DataCardItem, { label: t("loans.company.purpose"), value: loan.company.businessPurpose }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("loans.company.address"), value: [
                                            `${loan.company.street} ${loan.company.houseNumber}`,
                                            `${loan.company.companyPostCode} ${loan.company.companyLocation}`,
                                            t(`common.countries.${loan.company.companyCountry}`),
                                        ].join(", ") }), _jsx(DataCardItem, { label: t("loans.company.country"), value: loan.company.companyCountry }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("loans.company.hr-number"), value: loan.company.hrNumber }), _jsx(DataCardItem, { label: t("loans.company.tin"), value: loan.company.taxIdNumber }), _jsx(DataCardItem, { label: t("loans.company.client-id"), value: loan.company.companyId }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("loans.company.legal-form"), value: loan.company.companyType }), _jsx(DataCardItem, { label: t("loans.company.founded"), value: loan.company.companyFoundationDate &&
                                            new Date(loan.company.companyFoundationDate).getFullYear() }), _jsx(DataCardItem, { label: t("loans.company.age"), value: loan.company.companyFoundationDate &&
                                            pickTimePassed(loan.company.companyFoundationDate, t) }), _jsx(DataCardItem, { label: t("loans.company.employees"), value: loan.company.numberOfEmployees })] }), _jsx(IndustryCodesCard, { industryCodes: company === null || company === void 0 ? void 0 : company.industry_codes, loading: loading, showEditButton: true, onSave: updateIndustryCodes }), _jsxs(DataCard, { children: [_jsx(DataCardItem, { label: t("loans.company.credit-score"), value: creditScore === null || creditScore === void 0 ? void 0 : creditScore.score }), _jsx(DataCardItem, { label: t("company.pd-rate"), value: (creditScore === null || creditScore === void 0 ? void 0 : creditScore.pd) && `${creditScore.pd}%` })] }), _jsx(DataCard, { children: _jsx(DataCardItem, { label: t("loans.company.iban"), value: loan.fronting_bank_iban }) })] })) }) })] }));
};
export default LoanCompany;
