import { jsx as _jsx } from "react/jsx-runtime";
import { Card, Typography } from "antd";
import styled from "styled-components";
import FinCRDocumentRow from "src/pages/modules/FinCr/FinCRDocumentRow";
import DocumentTypeWrapper from "./DocumentTypeWrapper";
import { CARD_MAX_WIDTH, CARD_MIN_WIDTH } from "./documents.helpers";
const { Text } = Typography;
const DocumentWrapper = styled(Card) `
	margin-bottom: 24px;
	min-width: ${CARD_MIN_WIDTH}px;
	max-width: ${CARD_MAX_WIDTH}px;
	.ant-typography {
		word-break: keep-all;
	}
`;
export const DocumentCategoryWrapper = ({ documents, title, generateDocuments, }) => {
    return (_jsx(DocumentWrapper, Object.assign({ title: _jsx(Text, { children: title }), bodyStyle: { padding: "24px 0" } }, { children: !!documents.length &&
            documents.map(({ typeName, docs }) => (_jsx(DocumentTypeWrapper, Object.assign({ name: typeName }, { children: docs.map((doc) => (_jsx(FinCRDocumentRow, { doc: doc, updateDocs: generateDocuments }, doc.document_id))) }), typeName))) })));
};
