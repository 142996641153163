import { jsx as _jsx } from "react/jsx-runtime";
// import {  useTranslation } from "react-i18next";
import styled from "styled-components";
import { useCompany } from "src/layouts/CompanyLayout/CompanyTypes";
const Wrapper = styled.div `
	margin: 24px;
	display: grid;
	gap: 24px;
	grid-template-rows: auto 1fr;
	grid-template-columns: minmax(460px, 1464px);
`;
const InnerWrapper = styled.div `
	display: grid;
	column-gap: 24px;
	grid-template-columns: repeat(auto-fit, minmax(460px, auto));
`;
const CompanyOverview = () => {
    // const { t } = useTranslation();
    const { company } = useCompany();
    return (_jsx(Wrapper, { children: _jsx(InnerWrapper, { children: company.companyName }) }));
};
export default CompanyOverview;
