import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Col, Row, Typography } from "antd";
import styled from "styled-components";
import Avatar from "@ui/data-display/Avatar";
import EmptyData from "src/components/ui/EmptyData";
import { TASK_ID_SEARCH_PARAM } from "./SidebarTaskDetails";
import TaskPriorityIndicator from "./TaskPriorityIndicator";
import TaskStatusIndicator from "./TaskStatusIndicator";
const TaskRow = styled(Row) `
	padding: 8px 0;

	&:hover {
		cursor: pointer;
	}
`;
const TasksList = ({ tasks, limit = Number.POSITIVE_INFINITY, showTaskDetailsSidebar }) => {
    const navigate = useNavigate();
    if (!tasks.length) {
        return _jsx(EmptyData, {});
    }
    return (_jsx(_Fragment, { children: tasks.slice(0, limit).map((task) => {
            var _a, _b;
            return (_jsxs(TaskRow, Object.assign({ align: "middle", wrap: false, gutter: 8, onClick: () => {
                    navigate({
                        search: `?${createSearchParams({ [TASK_ID_SEARCH_PARAM]: task.task_id })}`,
                    });
                    showTaskDetailsSidebar();
                } }, { children: [_jsx(Col, Object.assign({ flex: "none" }, { children: _jsx(TaskStatusIndicator, { status: task.status, mode: "icon" }) })), _jsx(Col, Object.assign({ flex: "auto" }, { children: _jsx(Typography.Text, { children: task.subject && ((_a = task.subject) === null || _a === void 0 ? void 0 : _a.length) > 80
                                ? ((_b = task.subject) === null || _b === void 0 ? void 0 : _b.slice(0, 80)) + "..."
                                : task.subject }) })), _jsx(Col, Object.assign({ flex: "none" }, { children: _jsx(TaskPriorityIndicator, { priority: task.priority }) })), _jsx(Col, Object.assign({ flex: "none" }, { children: _jsx(Avatar, { name: task.assignee_name || "", size: "small" }) }))] }), task.task_id));
        }) }));
};
export default TasksList;
