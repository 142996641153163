import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Space as AntdSpace, Button, Col, Form, Row, Typography } from "antd";
import styled from "styled-components";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";
import { Routes } from "src/Routes";
import { Axios } from "src/utils/Axios";
const { Title, Text } = Typography;
const Space = styled(AntdSpace) `
	width: 100%;
`;
const CheckCircleIcon = styled(CheckCircleOutlined) `
	color: ${({ theme }) => theme.colorPrimary};
	font-size: 112px;
	margin-top: 8px;
	margin-bottom: 24px;
`;
const Wrapper = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [resetSuccess, setResetSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const onFinish = ({ email }) => {
        setLoading(true);
        Axios.post("/admin/account/reset", {
            email,
        })
            .then(() => setResetSuccess(true), (error) => void Axios.error(error, t("forgot_password.send-reset-email-error")))
            .finally(() => setLoading(false));
    };
    if (resetSuccess) {
        return (_jsxs(Wrapper, { children: [_jsx(CheckCircleIcon, {}), _jsx(Title, Object.assign({ level: 3, style: { textAlign: "center" } }, { children: t("forgot_password.password_reset_email_sent_title") })), _jsx(Text, Object.assign({ type: "secondary", style: { textAlign: "center" } }, { children: t("forgot_password.password_reset_email_sent_subtitle") })), _jsx(Button, Object.assign({ type: "primary", size: "large", style: { marginTop: 48 }, onClick: () => navigate(Routes.Login) }, { children: t("reset_password.go-to-login") }))] }));
    }
    return (_jsxs(Space, Object.assign({ direction: "vertical", size: 24 }, { children: [_jsxs("div", { children: [_jsx(Title, Object.assign({ level: 3 }, { children: t("forgot_password.forgot-password-title") })), _jsx(Text, Object.assign({ type: "secondary" }, { children: t("forgot_password.forgot-password-subtitle") }))] }), _jsxs(Form, Object.assign({ form: form, layout: "vertical", onFinish: onFinish }, { children: [_jsx(EmailFormItem, { rules: [
                            {
                                required: true,
                                message: t("application_form.field-required"),
                            },
                        ] }), _jsx(Row, { children: _jsx(Col, Object.assign({ span: 24, style: { textAlign: "center" } }, { children: _jsx(Button, Object.assign({ type: "primary", size: "large", htmlType: "submit", loading: loading }, { children: t("forgot_password.reset-password-button") })) })) })] }))] })));
};
export default ForgotPasswordPage;
