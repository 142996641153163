import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import { Col, Form, Row, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import styled from "styled-components";
import { ApplicationStatuses, } from "@teylor-tools/Api";
import timePassedUntilNow from "@teylor-tools/utils/timePassedUntilNow";
import { DataCardItem } from "@ui/data-display/DataCard";
import IbanFormItem from "@ui/form/form-items/iban-form-item/IbanFormItem";
import TinFormItem, { parseTin } from "@ui/form/form-items/tin-form-item/TinFormItem";
import { validateValueDifferentThan } from "@ui/form/validateValueDifferentThan";
import IndustryCodesCard from "@ui/industry-codes-card/IndustryCodesCard";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { Routes } from "src/Routes";
import CreditScoreReport from "src/components/CreditScoreReport";
import { DataCard, DataCardDivider, DataCardItemEditable } from "src/components/DataCard";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { Axios } from "src/utils/Axios";
const Wrapper = styled.div `
	margin: 92px 24px 24px;
`;
const pickTimePassed = (foundationDate, t) => {
    const { monthsPassed, yearsPassed } = timePassedUntilNow(foundationDate);
    return `${t("application.company.year_other", { count: yearsPassed })} ${t("application.company.month_other", { count: monthsPassed })}`;
};
const ApplicationCompany = () => {
    var _a;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [company, setCompany] = useState();
    const { application, updateApplication, updatePendingItems } = useApplication();
    const isPaidOut = application.status === ApplicationStatuses.PaidOut;
    const [creditScores, setCreditScores] = useState([]);
    const [signatoriesTins, setSignatoriesTins] = useState([]);
    const getCompany = useCallback(() => {
        setLoading(true);
        Axios.get(`/admin/companies/${application.companyId}`)
            .then(({ data }) => {
            setCompany(data);
        }, (err) => void Axios.error(err))
            .finally(() => {
            setLoading(false);
        });
    }, [application.companyId]);
    const updateApplicationData = (values) => {
        Axios.patch(`/admin/companies/${application.companyId}`, values)
            .then(() => {
            void message.success(t("common.saved"));
            void updateApplication();
        }, (err) => void Axios.error(err))
            .finally(updatePendingItems);
    };
    const updateBankName = (values) => {
        Axios.patch(`/admin/applications/${application.applicationId}/bankinfo`, values)
            .then(() => {
            void message.success(t("common.saved"));
            void updateApplication();
        }, (err) => void Axios.error(err))
            .finally(updatePendingItems);
    };
    const getCreditScores = useCallback(() => {
        Axios.get(`admin/companies/${application.companyId}/credit_score`).then(({ data }) => setCreditScores(data), (err) => {
            void Axios.error(err, t("application.company.error.error-fetching-report"));
            setCreditScores([]);
        });
    }, [application.companyId, t]);
    const getSignatoriesTins = useCallback(() => {
        Axios.get(`admin/applications/${application.applicationId}/signatories`).then(({ data }) => setSignatoriesTins(data.signatories.map((signatory) => signatory.taxid)), (err) => {
            void Axios.error(err);
            setSignatoriesTins([]);
        });
    }, [application.applicationId]);
    const updateIndustryCodes = (values) => {
        return Axios.patch(`/admin/companies/${application.companyId}/industry_codes`, values).then(() => {
            void getCompany();
            updatePendingItems();
        }, (err) => void Axios.error(err));
    };
    useEffect(() => {
        getCompany();
        getCreditScores();
        getSignatoriesTins();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: t("application.menu.company") }), _jsx(Wrapper, { children: _jsx(Row, { children: _jsxs(Col, Object.assign({ sm: 24 }, { children: [_jsxs(DataCard, Object.assign({ loading: !application }, { children: [_jsx(DataCardItem, { label: t("application.company.name"), value: _jsx("strong", { children: application.companyName }) }), isPaidOut ? (_jsx(DataCardItem, { label: t("application.company.purpose"), value: application.businessPurpose })) : (_jsx(DataCardItemEditable, { label: t("application.company.purpose"), displayedValue: application.businessPurpose, formItemValue: application.businessPurpose, onChange: (value) => updateApplicationData({ businessPurpose: value }), customFormItem: (props) => (_jsx(Form.Item, Object.assign({}, props, { children: _jsx(TextArea, { rows: 6 }) }))) })), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("application.company.address"), value: [
                                            `${application.street} ${application.houseNumber}`,
                                            `${application.companyPostCode} ${application.companyLocation}`,
                                            t(`common.countries.${application.companyCountry}`),
                                        ].join(", ") }), _jsx(DataCardItem, { label: t("application.company.country"), value: application.companyCountry }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("application.company.hr-number"), value: application.hrNumber }), isPaidOut ? (_jsx(DataCardItem, { label: t("application.company.tin"), value: application.taxIdNumber })) : (_jsx(DataCardItemEditable, { label: t("application.company.tin"), displayedValue: application.taxIdNumber, onChange: (value) => updateApplicationData({ taxIdNumber: parseTin(value) }), customFormItem: (props) => (_jsx(TinFormItem, Object.assign({}, props, { rules: [
                                                {
                                                    validator: (_, value) => validateValueDifferentThan({
                                                        value: parseTin(value),
                                                        differentThan: signatoriesTins,
                                                        error: t("application_form.errors.companyTinMustBeDifferentThanPeronalTin"),
                                                    }),
                                                },
                                            ] }))), formItemValue: application.taxIdNumber })), _jsx(DataCardItem, { label: t("application.company.client-id"), value: application.company_id_short }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("application.company.legal-form"), value: application.companyType }), _jsx(DataCardItem, { label: t("application.company.founded"), value: application.companyFoundationDate &&
                                            new Date(application.companyFoundationDate).getFullYear() }), _jsx(DataCardItem, { label: t("application.company.age"), value: application.companyFoundationDate &&
                                            pickTimePassed(application.companyFoundationDate, t) }), _jsx(DataCardItem, { label: t("application.company.employees"), value: application.numberOfEmployees })] })), _jsx(IndustryCodesCard, { industryCodes: company === null || company === void 0 ? void 0 : company.industry_codes, loading: loading, showEditButton: !isPaidOut, onSave: updateIndustryCodes }), _jsxs(DataCard, { children: [_jsx(CreditScoreReport, { getCreditScore: getCreditScores, creditScores: creditScores, requestCreditCheck: application.status !== ApplicationStatuses.PaidOut
                                            ? () => Axios.patch(`admin/applications/${application.applicationId}/credit_check`).then(({ data }) => Promise.resolve(data), (err) => Promise.reject(err))
                                            : undefined, documentsPath: generatePath(Routes.ApplicationDocuments, {
                                            applicationId: application.applicationId,
                                        }) }), _jsx(DataCardItem, { label: t("company.pd-rate"), value: ((_a = creditScores[0]) === null || _a === void 0 ? void 0 : _a.pd) && `${creditScores[0].pd}%` })] }), _jsx(DataCard, { children: isPaidOut ? (_jsxs(_Fragment, { children: [_jsx(DataCardItem, { label: t("application.company.bank"), value: application.bankName }), _jsx(DataCardItem, { label: t("application.company.iban"), value: application.iban })] })) : (_jsxs(_Fragment, { children: [_jsx(DataCardItemEditable, { label: t("application.company.bank"), displayedValue: application.bankName, formItemValue: application.bankName, onChange: (v) => updateBankName({ bankName: v }) }), _jsx(DataCardItemEditable, { label: t("application.company.iban"), onChange: (v) => updateBankName({ iban: v }), displayedValue: application.iban, customFormItem: (props) => _jsx(IbanFormItem, Object.assign({}, props)), formItemValue: application.iban })] })) })] })) }) })] }));
};
export default ApplicationCompany;
