import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Form, Modal, Radio } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { FinancialStatementType } from "@teylor-tools/Api";
import BaseDatePicker from "src/components/ui/DatePicker";
const DatePicker = styled(BaseDatePicker) `
	width: 100%;
`;
export const FinancialsModal = ({ statement, loading = false, onCancel, onFinish, }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const type = statement ? statement.statement_type : FinancialStatementType.AnnualStatement;
    const date = statement ? dayjs(statement.financials_date) : dayjs();
    return (_jsx(Modal, Object.assign({ title: statement
            ? t("financials.modal.financial-statement-settings")
            : t("financials.modal.new-financial-statement"), open: true, onCancel: onCancel, onOk: () => void form.validateFields().then(() => form.submit()), okText: statement ? t("common.ok") : t("common.add"), cancelText: t("common.cancel"), okButtonProps: {
            htmlType: "submit",
            loading,
        } }, { children: _jsxs(Form, Object.assign({ form: form, layout: "vertical", onFinish: onFinish, initialValues: { type, date }, disabled: loading }, { children: [_jsx(Form.Item, Object.assign({ name: "type", label: t("financials.modal.type") }, { children: _jsxs(Radio.Group, { children: [_jsx(Radio, Object.assign({ value: FinancialStatementType.AnnualStatement }, { children: t("financials.modal.annual-statement") })), _jsx(Radio, Object.assign({ value: FinancialStatementType.InterimFinancials }, { children: t("financials.modal.interim-statement") }))] }) })), _jsx(Form.Item, Object.assign({ name: "date", label: t("financials.modal.statement-as-of") }, { children: _jsx(DatePicker, {}) }))] })) })));
};
