var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Select, Tag, Typography, message } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import styled from "styled-components";
import { ApplicationStatuses } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { DataCardItem } from "@ui/data-display/DataCard";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { DataCard, DataCardDivider, DataCardItemEditable } from "src/components/DataCard";
import ApplicationStatus from "src/components/ui/statuses/ApplicationStatus";
import { useInterestRate } from "src/hooks/useInterestRate";
import { useMonthlyRate } from "src/hooks/useMonthlyRate";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { Axios } from "src/utils/Axios";
const Wrapper = styled.div `
	margin: 92px 24px 24px;
`;
const ProductWrapper = styled.div `
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
`;
const ApplicationLoan = () => {
    const { application, updateApplication, updatePendingItems } = useApplication();
    const { t } = useTranslation();
    const { currency } = useFormatter();
    const [availableProducts, setAvailableProducts] = useState();
    const isPaidOut = application.status === ApplicationStatuses.PaidOut;
    const internalReviewStatusIndex = application.status_flow.findIndex(({ status_name }) => status_name === ApplicationStatuses.InternalReview);
    const isLoanPurposeEditable = application.status_flow.findIndex(({ is_current_status }) => is_current_status) <=
        internalReviewStatusIndex;
    const updateProduct = (productId) => {
        Axios.patch(`/admin/applications/${application.applicationId}/product/${productId}`).then(() => {
            void updateApplication();
            updatePendingItems();
            void message.info(t("loans.product-update-success"));
            Axios.get(`/admin/applications/${application.applicationId}/products_available`).then(({ data }) => {
                setAvailableProducts(data);
            }, (err) => {
                void Axios.error(err);
            });
        }, (err) => {
            void Axios.error(err);
        });
    };
    const handleChangePurposeDescription = (purposeDescription) => {
        void updateApplication({ purposeDescription }).then(() => {
            void message.info(t("loans.purpose-description-update-success"));
        });
        updatePendingItems();
    };
    useEffect(() => {
        Axios.get(`/admin/applications/${application.applicationId}/products_available`).then(({ data }) => {
            setAvailableProducts(data);
        }, (err) => {
            void Axios.error(err);
        });
    }, [application.applicationId]);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: t("loans.title") }), _jsx(Wrapper, { children: _jsxs(DataCard, { children: [_jsx(DataCardItem, { label: t("loans.amount"), value: _jsx("strong", { children: currency(application.loanSize, { currency: application.currency }) }) }), _jsx(DataCardItem, { label: t("loans.duration"), value: _jsx("strong", { children: `${application.loanDuration} ${t("loans.months")}` }) }), _jsx(DataCardItem, { label: t("loans.interest-rate"), value: _jsx("strong", { children: useInterestRate(application.pricing) }) }), _jsx(DataCardItem, { label: t("loans.monthly-rate"), value: useMonthlyRate(application.pricing, application.currency) }), _jsx(DataCardDivider, {}), (availableProducts === null || availableProducts === void 0 ? void 0 : availableProducts.length) && !isPaidOut ? (_jsx(DataCardItemEditable, { label: t("loans.product"), displayedValue: application.product.product_name, onChange: updateProduct, customFormItem: (_a) => {
                                var props = __rest(_a, []);
                                return (_jsx(FormItem, Object.assign({}, props, { children: _jsx(Select, { options: availableProducts === null || availableProducts === void 0 ? void 0 : availableProducts.map((availableProduct) => {
                                            var _a;
                                            return {
                                                label: availableProduct.family_name,
                                                options: (_a = availableProduct.products) === null || _a === void 0 ? void 0 : _a.map(({ product_id, product_name, is_eligible }) => ({
                                                    label: (_jsxs(ProductWrapper, { children: [_jsx(Typography.Text, Object.assign({ type: is_eligible ? undefined : "secondary", ellipsis: { tooltip: product_name } }, { children: product_name })), !is_eligible && (_jsx(Tag, Object.assign({ color: "warning", style: { marginLeft: 16 } }, { children: t("product.not_eligible") })))] })),
                                                    value: product_id,
                                                })),
                                            };
                                        }) }) })));
                            }, formItemValue: application.product.product_name })) : (_jsx(DataCardItem, { label: t("loans.product"), value: `${application.product.product_family_name} - ${application.product.product_name}` })), _jsx(DataCardItem, { label: t("loans.loan-type"), value: application.allBanksLoanPurpose }), _jsx(DataCardDivider, {}), isLoanPurposeEditable ? (_jsx(DataCardItemEditable, { label: t("loans.purpose"), displayedValue: application.purposeDescription, formItemValue: application.purposeDescription, onChange: handleChangePurposeDescription, restrictions: { showCount: true, maxLength: 700 }, customFormItem: (props) => (_jsx(Form.Item, Object.assign({}, props, { children: _jsx(TextArea, { maxLength: 700, showCount: true, rows: 6 }) }))) })) : (_jsx(DataCardItem, { label: t("loans.purpose"), value: application.purposeDescription })), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("loans.status"), value: _jsx(ApplicationStatus, { style: { justifySelf: "start" }, closable: false, label: application.status, value: application.status, disabled: false }) }), application.status == ApplicationStatuses.Rejected && (_jsxs(_Fragment, { children: [_jsx(DataCardItem, { label: t("loans.rejection-reason"), value: application.rejectionReason }), _jsx(DataCardItem, { label: t("loans.reject-description"), value: application.reject_description })] })), _jsx(DataCardItem, { label: t("loans.created"), value: dayjs(application.created).format("YYYY.MM.DD HH:mm:ss") })] }) })] }));
};
export default ApplicationLoan;
