import dayjs from "dayjs";

const timePassedUntilNow = (date: string | number | Date) => {
	const yearsPassed = dayjs(new Date()).diff(date, "years");
	const monthsPassed = dayjs(new Date()).diff(date, "months") - yearsPassed * 12;

	return { yearsPassed, monthsPassed };
};

export default timePassedUntilNow;
