import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { List, Typography, message } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import styled from "styled-components";
import { fullDateTimeUTC } from "@teylor-tools/utils/dateFormats";
import { Axios } from "src/utils/Axios";
dayjs.extend(utc);
const { Text } = Typography;
const SmallText = styled.div `
	margin-top: 8px;
	font-size: 12px;
	word-break: break-word;
`;
const StyledListItem = styled(List.Item) `
	padding: 24px;
`;
const StyledUserOutlined = styled(UserOutlined) `
	margin-right: 10px;
`;
const Logs = ({ applicationId }) => {
    const [loading, setLoading] = useState(true);
    const [elements, setElements] = useState();
    useEffect(() => {
        setLoading(true);
        Axios.get(`/api/admin/applications/${applicationId}/logs`)
            .then(({ data }) => {
            setElements(data.logs);
        })
            .catch((error) => {
            console.error(error);
            void message.error("Error getting application logs");
        })
            .finally(() => {
            setLoading(false);
        });
    }, [applicationId]);
    return (_jsx(List, { loading: loading, dataSource: elements, itemLayout: "vertical", renderItem: ({ created, user_id, data }) => (_jsxs(StyledListItem, { children: [_jsx("div", { children: _jsx(Text, Object.assign({ strong: true }, { children: _jsx(_Fragment, { children: created ? dayjs(created).utc().format(fullDateTimeUTC) : "-" }) })) }), _jsx("div", { children: _jsxs(Text, Object.assign({ type: "secondary" }, { children: [_jsx(StyledUserOutlined, { size: 12 }), user_id] })) }), _jsx(SmallText, { children: data })] })) }));
};
export default Logs;
