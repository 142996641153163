var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { message } from "antd";
import { Axios } from "src/utils/Axios";
import { finalStates } from "../Documents/documents.helpers";
export const getOcrFilesById = ({ ocrDocs, path, }) => __awaiter(void 0, void 0, void 0, function* () {
    const ocrStatus = yield Axios.get(path);
    return ocrDocs.map((ocrDoc) => {
        const docInOcr = ocrStatus.data.find((dataItem) => dataItem.document_id === ocrDoc.document_id);
        return Object.assign(Object.assign({}, ocrDoc), { financials_date: docInOcr === null || docInOcr === void 0 ? void 0 : docInOcr.financials_date, fincr_file_id: docInOcr === null || docInOcr === void 0 ? void 0 : docInOcr.fincr_file_id, status: docInOcr === null || docInOcr === void 0 ? void 0 : docInOcr.status, period_type: docInOcr === null || docInOcr === void 0 ? void 0 : docInOcr.period_type });
    });
});
export const getIssues = (docs, key) => __awaiter(void 0, void 0, void 0, function* () {
    let showError = false;
    const docsToCheck = docs
        .map((doc, idx) => ({ originalIndex: idx, id: doc.document_id, status: doc.status }))
        .filter(({ status }) => status && finalStates.includes(status));
    const issuePromises = docsToCheck.map(({ id, originalIndex }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield Axios.get(`admin/ocr/${id}/results`);
            docs[originalIndex].warnings = response.data.document_data.warnings;
        }
        catch (error) {
            showError = true;
        }
    }));
    yield Promise.allSettled(issuePromises);
    if (showError) {
        void message.error(key);
    }
    return docs;
});
