import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import dayjs from "dayjs";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { dayMonthYear, fullDateTime } from "@teylor-tools/utils/dateFormats";
import MainHeader from "@ui/main-header/MainHeader";
import { Axios } from "src/utils/Axios";
const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 30;
const SettingsAccountBalances = () => {
    const { t } = useTranslation();
    const { currency } = useFormatter();
    const [loading, setLoading] = useState(true);
    const [accountBalances, setAccountBalances] = useState([]);
    const [pagination, setPagination] = useState();
    const getAccountBalances = useCallback((page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE) => {
        setLoading(true);
        Axios.get("admin/bank_accounts/balances", { page, page_size: pageSize })
            .then(({ data }) => {
            data.result && setAccountBalances(data.result);
            setPagination(data.pagination);
        }, (err) => Axios.error(err))
            .finally(() => setLoading(false));
    }, []);
    const columns = [
        {
            dataIndex: "bank_account_name",
            title: t("settings.account-balances.account-name"),
        },
        {
            title: t("settings.account-balances.current-balance"),
            render: (value) => value.account_balance_current
                ? currency(value.account_balance_current, { currency: value.currency })
                : "-",
        },
        {
            title: t("settings.account-balances.active-balance"),
            render: (value) => value.account_balance_available
                ? currency(value.account_balance_available, { currency: value.currency })
                : "-",
        },
        {
            title: t("settings.account-balances.locked-balance"),
            render: (value) => value.account_balance_locked
                ? currency(value.account_balance_locked, { currency: value.currency })
                : "-",
        },
        {
            dataIndex: "effective_date",
            title: t("settings.account-balances.effective-date"),
            render: (value) => dayjs(value).format(dayMonthYear),
        },
        {
            dataIndex: "retrieved_at",
            title: t("settings.account-balances.retrieved-at"),
            render: (value) => dayjs(value).format(fullDateTime),
        },
    ];
    useEffect(() => getAccountBalances(DEFAULT_PAGE, DEFAULT_PAGE_SIZE), [getAccountBalances]);
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("settings.account-balances") }), _jsx("div", Object.assign({ style: { padding: "16px 24px" } }, { children: _jsx(Table, { loading: loading, columns: columns, rowKey: "bank_account_balance_id", dataSource: accountBalances, pagination: {
                        showSizeChanger: true,
                        pageSizeOptions: [15, 30, 60],
                        current: pagination === null || pagination === void 0 ? void 0 : pagination.current_page,
                        pageSize: pagination === null || pagination === void 0 ? void 0 : pagination.items_per_page,
                        total: pagination === null || pagination === void 0 ? void 0 : pagination.total_rows,
                    }, onChange: (pagination) => getAccountBalances(pagination.current, pagination.pageSize) }) }))] }));
};
export default SettingsAccountBalances;
