import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Form, Input, Modal, Select } from "antd";
import { PartnerUserRole } from "@teylor-tools/Api";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";
import TelephoneFormItem from "@ui/form/form-items/telephone-form-item/TelephoneFormItem";
const roles = Object.keys(PartnerUserRole);
const PartnerUserModal = ({ user, loading = false, onCancel, onFinish }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const requiredRule = {
        required: true,
        message: t("partner.modal.required-field"),
    };
    return (_jsx(Modal, Object.assign({ title: user ? t("partners.modal.edit_user_title") : t("partners.modal.invite_user_title"), open: true, onCancel: onCancel, onOk: () => void form.validateFields().then(() => form.submit()), okText: user ? t("common.ok") : t("common.add"), cancelText: t("common.cancel"), okButtonProps: {
            htmlType: "submit",
            loading,
        } }, { children: _jsxs(Form, Object.assign({ form: form, layout: "vertical", onFinish: onFinish, initialValues: {
                email: user === null || user === void 0 ? void 0 : user.email,
                name: user === null || user === void 0 ? void 0 : user.name,
                mobile_number: user === null || user === void 0 ? void 0 : user.mobile_number,
                role_id: user === null || user === void 0 ? void 0 : user.role_id,
            }, disabled: loading }, { children: [_jsx(EmailFormItem, { name: "email", label: t("partners.modal.email"), rules: [
                        {
                            required: true,
                            message: t("application_form.field-required"),
                        },
                    ] }), _jsx(Form.Item, Object.assign({ name: "name", rules: [requiredRule], label: t("partners.modal.name") }, { children: _jsx(Input, {}) })), _jsx(TelephoneFormItem, { name: "mobile_number", rules: [requiredRule], label: t("partners.modal.mobile_number") }), _jsx(Form.Item, Object.assign({ name: "role_id", rules: [requiredRule], label: t("partners.modal.role") }, { children: _jsx(Select, { options: roles.map((role) => ({
                            label: t(`partners.modal.role.${PartnerUserRole[role]}`),
                            value: PartnerUserRole[role],
                        })) }) }))] })) })));
};
export default PartnerUserModal;
