import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { PlusOutlined, SelectOutlined } from "@ant-design/icons";
import { Button, Col, Input, Select, Tag, Tooltip } from "antd";
import FormItem from "antd/es/form/FormItem";
import { FeatureName, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import MainHeader from "@ui/main-header/MainHeader";
import { Routes } from "src/Routes";
import TimePassed from "src/components/ui/TimePassed";
import ApplicationStatus from "src/components/ui/statuses/ApplicationStatus";
import { Axios } from "src/utils/Axios";
import SearchList, { DIRECT_OPEN_COLUMN } from "../../components/SearchList";
import ApplicationPreview from "./ApplicationPreview";
var SearchField;
(function (SearchField) {
    SearchField["text"] = "text";
    SearchField["status"] = "status";
    SearchField["partner_id"] = "partner_id";
    SearchField["product_id"] = "product_id";
})(SearchField || (SearchField = {}));
const ApplicationsPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { currency } = useFormatter();
    const [previewApplication, setPreviewApplication] = useState();
    const [statuses, setStatuses] = useState([]);
    const [partners, setPartners] = useState([]);
    const [products, setProducts] = useState([]);
    const { features } = useSelector((state) => state.configState);
    useEffect(() => {
        Axios.get("admin/filter_values")
            .then(({ data }) => {
            setStatuses(data.all_statuses || []);
            setPartners(data.partners || []);
            setProducts(data.products || []);
        })
            .catch((err) => void Axios.error(err));
    }, []);
    const boCreateAppEnabled = useMemo(() => {
        var _a;
        return (_a = features.find((feature) => feature.name === FeatureName.BoUserCreateApplication)) === null || _a === void 0 ? void 0 : _a.value.enabled;
    }, [features]);
    const columns = [
        {
            render: (a) => (_jsx(Button, { type: "link", icon: _jsx(SelectOutlined, {}), onClick: () => navigate(generatePath(Routes.Application, {
                    applicationId: a.application_id,
                })) })),
            className: DIRECT_OPEN_COLUMN,
        },
        {
            dataIndex: "company_name",
            title: t("application.company"),
        },
        {
            dataIndex: "amount",
            title: t("application.amount"),
            sorter: true,
            render(amount, a) {
                return amount ? currency(amount, { currency: a.currency }) : "-";
            },
        },
        {
            dataIndex: "duration",
            title: t("application.duration"),
            render(duration) {
                return `${duration} ${t("common.months")}`;
            },
        },
        {
            dataIndex: "email",
            title: t("application.applicant-email"),
        },
        {
            dataIndex: "status",
            title: t("application.state"),
            width: 70,
            render: (item) => {
                return (_jsx(Tooltip, Object.assign({ title: t(`application.status.${item}`) }, { children: _jsx(ApplicationStatus, { closable: false, label: t(`application.status.${item}`), value: item, disabled: false }) })));
            },
        },
        {
            dataIndex: "status_owner_name",
            title: t("application.assignee"),
        },
        {
            dataIndex: "created_at",
            title: t("application.created"),
            render(created) {
                return _jsx(TimePassed, { date: created });
            },
            sorter: true,
        },
        {
            dataIndex: ["product", "product_name"],
            title: t("application.product-name"),
        },
        {
            dataIndex: "partner_name",
            title: t("application.partner"),
        },
        {
            dataIndex: "account_manager_name",
            title: t("application.account-manager"),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("application.title"), extra: boCreateAppEnabled && (_jsx(Button, Object.assign({ onClick: () => navigate(Routes.CreateApplication), icon: _jsx(PlusOutlined, {}) }, { children: t("application.create-application.new-application") }))) }), _jsxs(SearchList, Object.assign({ endpoint: "/admin/applications", columns: columns, onRow: (record) => {
                    return {
                        onClick() {
                            setPreviewApplication(record);
                        },
                    };
                }, rowKey: ({ application_id }) => application_id }, { children: [_jsx(Col, Object.assign({ span: 6 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.text }, { children: _jsx(Input.Search, { placeholder: t("application.search-placeholder"), allowClear: true }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.status }, { children: _jsx(Select, { loading: !statuses.length, disabled: !statuses.length, placeholder: t("application.state"), mode: "multiple", maxTagCount: "responsive", showArrow: true, allowClear: true, optionFilterProp: "label", tagRender: ({ label, value, disabled, closable, onClose }) => (_jsx(ApplicationStatus, { label: label, value: value, disabled: disabled, closable: closable, onClose: onClose })), options: statuses.map((value) => {
                                    return {
                                        label: t(`application.status.${value}`),
                                        value: value,
                                    };
                                }) }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.partner_id }, { children: _jsx(Select, { loading: !partners.length, disabled: !partners.length, placeholder: t("application.partner"), mode: "multiple", showArrow: true, allowClear: true, maxTagCount: "responsive", optionFilterProp: "label", tagRender: ({ label, closable, onClose }) => (_jsx(Tag, Object.assign({ closable: closable, onClose: onClose }, { children: label }))), options: partners.map(({ partner_id, partner_name }) => {
                                    return {
                                        label: partner_name,
                                        value: partner_id,
                                    };
                                }) }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.product_id }, { children: _jsx(Select, { loading: !products.length, disabled: !products.length, placeholder: t("application.product"), mode: "multiple", showArrow: true, allowClear: true, maxTagCount: "responsive", optionFilterProp: "label", tagRender: ({ label, closable, onClose }) => (_jsx(Tag, Object.assign({ closable: closable, onClose: onClose }, { children: label }))), options: products.map(({ product_id, product_name }) => {
                                    return {
                                        label: product_name,
                                        value: product_id,
                                    };
                                }) }) })) }))] })), _jsx(ApplicationPreview, { application: previewApplication, onClose: () => setPreviewApplication(undefined) })] }));
};
export default ApplicationsPage;
