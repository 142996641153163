import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Table, message } from "antd";
import { Dropdown, Space, Tag, Typography } from "antd";
import styled from "styled-components";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { usePartner } from "src/layouts/PartnerLayout/PartnerTypes";
import { Axios } from "src/utils/Axios";
import PartnerUserModal from "./PartnerUserModal";
import UsersMenu from "./UsersMenu";
import { getUserStatusColor } from "./helpers";
const { Text } = Typography;
const Wrapper = styled.div `
	margin: 88px 24px 24px;
	display: grid;
	gap: 24px;
	grid-template-rows: auto 1fr;
	grid-template-columns: minmax(460px, 1464px);
`;
const InnerWrapper = styled.div `
	display: grid;
	column-gap: 24px;
	grid-template-columns: repeat(auto-fit, minmax(460px, auto));
`;
const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 20;
const PartnerTeam = () => {
    const { t } = useTranslation();
    const { partner } = usePartner();
    const [users, setUsers] = useState();
    const [loading, setLoading] = useState(true);
    const [addNewUser, setAddNewUser] = useState(false);
    const [loadingAdd, setLoadingAdd] = useState(false);
    const [tableParams, setTableParams] = useState({
        page: DEFAULT_PAGE,
        page_size: DEFAULT_PAGE_SIZE,
    });
    const getUsers = useCallback((page = tableParams.page, pageSize = tableParams.page_size) => {
        setLoading(true);
        Axios.get(`/admin/partners/${partner.partner_id}/pusers`, { page, page_size: pageSize })
            .then(({ data }) => {
            var _a;
            setUsers(data.result);
            setTableParams({ page, page_size: pageSize, total: (_a = data.pagination) === null || _a === void 0 ? void 0 : _a.total_rows });
        }, (error) => void Axios.error(error))
            .finally(() => setLoading(false));
    }, [partner.partner_id, tableParams]);
    const handleAddUser = (data) => {
        setLoadingAdd(true);
        Axios.post(`/admin/partners/${partner.partner_id}/pusers`, {
            pusers: [Object.assign({}, data)],
        })
            .then(() => {
            void getUsers();
            void message.success(t("partners.team.new_user_added"));
            setAddNewUser(false);
        }, (error) => void Axios.error(error))
            .finally(() => setLoadingAdd(false));
    };
    const handleTableChange = (pagination) => {
        // reset page to 1 if page size was changed
        const page = pagination.pageSize !== (tableParams === null || tableParams === void 0 ? void 0 : tableParams.page_size) ? DEFAULT_PAGE : pagination.current;
        getUsers(page, pagination.pageSize);
    };
    const columns = [
        {
            dataIndex: "name",
            title: t("partners.team.name"),
        },
        {
            dataIndex: "email",
            title: t("partners.team.contact-details"),
            render: (email, { mobile_number }) => {
                return (_jsxs(Space, Object.assign({ direction: "vertical" }, { children: [_jsx(Text, { children: email }), _jsx(Text, Object.assign({ type: "secondary" }, { children: mobile_number }))] })));
            },
        },
        {
            dataIndex: "role_name",
            title: t("partners.team.role"),
        },
        {
            dataIndex: "status",
            title: t("partners.team.status"),
            render: (status) => {
                return (_jsx(Tag, Object.assign({ color: getUserStatusColor(status) }, { children: t(`partners.team.overview.status.${status}`) })));
            },
        },
        {
            title: "",
            dataIndex: "entity_id",
            key: "operation",
            width: 20,
            render(entity_id, record) {
                return (_jsx(Dropdown, Object.assign({ dropdownRender: () => _jsx(UsersMenu, { user: record, getUsers: getUsers }), placement: "bottomLeft" }, { children: _jsx(MoreOutlined, { onClick: (evt) => {
                            evt.stopPropagation();
                            evt.preventDefault();
                        } }) })));
            },
        },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getUsers, []);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, Object.assign({ title: t("partners.team.count", { count: (users === null || users === void 0 ? void 0 : users.length) || 0 }) }, { children: _jsx(Button, Object.assign({ type: "primary", icon: _jsx(PlusOutlined, {}), onClick: () => setAddNewUser(true) }, { children: t("common.add") })) })), _jsx(Wrapper, { children: _jsx(InnerWrapper, { children: _jsx(Table, { loading: loading, columns: columns, rowKey: "entity_id", dataSource: users, onChange: handleTableChange, scroll: { scrollToFirstRowOnChange: true }, pagination: {
                            current: tableParams.page,
                            pageSize: tableParams.page_size,
                            total: tableParams === null || tableParams === void 0 ? void 0 : tableParams.total,
                            showSizeChanger: true,
                        } }) }) }), addNewUser && (_jsx(PartnerUserModal, { loading: loadingAdd, onFinish: handleAddUser, onCancel: () => setAddNewUser(false) }))] }));
};
export default PartnerTeam;
