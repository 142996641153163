var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DeleteOutlined, DownloadOutlined, EditOutlined, ExclamationCircleOutlined, EyeInvisibleOutlined, EyeOutlined, FileOutlined, } from "@ant-design/icons";
import { Checkbox, Col, Form, Input, Popconfirm, Row, Select, Tooltip, Typography, message, theme, } from "antd";
import styled from "styled-components";
import { DocumentType } from "@teylor-tools/Api";
import TimePassed from "src/components/ui/TimePassed";
import { handleDocumentUpload, isDocument, isDocumentInUpload, } from "src/pages/modules/Documents/documents.helpers";
import { Axios } from "src/utils/Axios";
const { Text } = Typography;
const DocumentRowWrapper = styled(Row) `
	padding: 20px 24px;
	margin: 0 !important;

	&:hover {
		background-color: #fafafa;
	}
`;
const DocumentCheckbox = styled(Checkbox) `
	& .ant-checkbox {
		top: 0;
	}
`;
const Actions = styled.div `
	display: flex;
	justify-content: flex-end;
`;
const NameInput = styled(Input) `
	min-width: 110px;
	max-width: 225px;
	display: inline-block;
	margin-right: -5px;
`;
const FileWithIcon = styled.div `
	display: flex;
	text-align: left !important;
	align-items: center;
	gap: 10px;
`;
const DataText = styled(Text) `
	word-break: break-all !important;
`;
const CategorySelect = styled(Select) `
	min-width: 200px;
	margin-left: 16px;
`;
const getUploadStatus = (status, t) => {
    switch (status) {
        case "success":
            return _jsx(Text, Object.assign({ type: "success" }, { children: t("documents.status.success") }));
        case "error":
            return _jsx(Text, Object.assign({ type: "danger" }, { children: t("documents.status.error") }));
        case "uploading":
            return _jsx(Text, Object.assign({ type: "warning" }, { children: t("documents.status.uploading") }));
        case "waiting":
            return _jsx(Text, Object.assign({ disabled: true }, { children: t("documents.status.waiting") }));
    }
};
const DocumentRow = ({ doc, apiBasePath, updateDocuments, editMode, allowDelete, enableToggleClientVisibility = true, removeDocFromUpload, updateDocumentInUpload, docIdsInSelection, handleSelectChange, }) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [isInEditMode, setIsInEditMode] = useState(!doc.document_type || doc.document_type === DocumentType.None);
    const { documentCategories } = useSelector((state) => state.configState);
    // required for proper type checks
    const isUploadDoc = isDocumentInUpload(doc);
    const isDoc = isDocument(doc);
    const id = isUploadDoc ? doc.updateId : doc.document_id;
    const isSelected = docIdsInSelection.includes(id);
    const [fileName, ext] = useMemo(() => {
        const ext = doc.document_name.substring(doc.document_name.lastIndexOf("."));
        const fileName = doc.document_name.substring(0, doc.document_name.lastIndexOf(ext));
        return [fileName, ext];
    }, [doc.document_name]);
    const categoriesDropdownOptions = useMemo(() => documentCategories.map(({ name, document_types }) => ({
        // @ts-ignore dynamic categories
        label: t(`documents.category.${name}.name`),
        options: document_types === null || document_types === void 0 ? void 0 : document_types.map((type) => ({
            // @ts-ignore dynamic categories types names
            label: t(`documents.type.${type.name}.name`),
            value: type.name,
        })),
    })), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentCategories]);
    const handleRemove = () => {
        Axios.delete(`${apiBasePath}/documents/${doc.document_id}`).then(() => {
            removeDocFromUpload(id);
            updateDocuments();
        }, (error) => Axios.error(error));
    };
    const toggleClientVisibility = (isVisible) => {
        Axios.patch(`${apiBasePath}/documents/${id}/client_visible`, {
            is_client_visible: isVisible,
        }).then(() => updateDocuments(), (err) => void Axios.error(err, t("application.documents.error-toggle-client-visibility")));
    };
    const handleDownload = () => {
        void Axios.get(`${apiBasePath}/documents/${doc.document_id}/download_url`).then(({ data }) => {
            const newTab = window.open(data.download_url, "_blank");
            if (newTab)
                newTab.focus();
        }, (err) => void Axios.error(err));
    };
    const updateDocument = ({ name: newName, type: newType }) => __awaiter(void 0, void 0, void 0, function* () {
        if (!newType && doc.document_type === DocumentType.None) {
            return message.error(t("application.documents.category-error"));
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const nameWithExt = newName ? newName + ext : doc.document_name;
        const nameChanged = doc.document_name !== nameWithExt;
        const typeChanged = newType && doc.document_type !== newType;
        if (!nameChanged && !typeChanged)
            return;
        if (nameChanged) {
            yield Axios.patch(`${apiBasePath}/documents/${doc.document_id}/rename`, { document_name: nameWithExt }).catch((err) => Axios.error(err));
        }
        if (typeChanged) {
            yield Axios.patch(`${apiBasePath}/documents/${doc.document_id}/type`, { document_type: newType }).catch((err) => Axios.error(err));
        }
        isUploadDoc && removeDocFromUpload(id);
        setIsInEditMode(false);
        updateDocuments();
    });
    const handleRetryUpload = (doc) => {
        void handleDocumentUpload(doc, apiBasePath, updateDocumentInUpload);
    };
    const handleCancel = (id, doc) => {
        setIsInEditMode(false);
        form.setFieldsValue({
            [id]: { name: fileName, type: doc.document_type },
        });
    };
    const renderActionBtns = () => {
        const downloadBtn = (_jsx(DownloadOutlined, { style: { fontSize: "16px", color: token.colorLink }, onClick: () => doc.document_id && handleDownload() }));
        const deleteBtn = (_jsx(Popconfirm, Object.assign({ title: t("application.documents.confirm-delete"), onConfirm: handleRemove, okText: t("application.documents.delete"), cancelText: t("application.documents.cancel"), icon: _jsx(ExclamationCircleOutlined, { style: { color: token.colorError } }) }, { children: _jsx(DeleteOutlined, { style: { fontSize: "16px", color: token.colorLink } }) })));
        const saveBtn = (_jsx(Text, Object.assign({ style: { color: token.colorPrimary, cursor: "pointer" }, onClick: form.submit }, { children: t("application.documents.save") })));
        if (doc.document_type === DocumentType.None) {
            if (isUploadDoc) {
                if (doc.status === "waiting" || doc.status === "uploading")
                    return;
                if (doc.status === "error") {
                    return (_jsxs(_Fragment, { children: [allowDelete && _jsx(Col, { children: deleteBtn }), _jsx(Col, { children: _jsx(Text, Object.assign({ style: { color: token.colorPrimary, cursor: "pointer" }, onClick: () => handleRetryUpload(doc) }, { children: t("application.documents.retry") })) })] }));
                }
            }
            return (_jsxs(_Fragment, { children: [allowDelete && _jsx(Col, { children: deleteBtn }), _jsx(Col, { children: downloadBtn }), _jsx(Col, { children: saveBtn })] }));
        }
        if (isInEditMode)
            return (_jsxs(_Fragment, { children: [_jsx(Col, { children: saveBtn }), _jsx(Col, { children: _jsx(Text, Object.assign({ style: { color: token.colorPrimary, cursor: "pointer" }, onClick: () => handleCancel(id, doc) }, { children: t("application.documents.cancel") })) })] }));
        return (_jsxs(_Fragment, { children: [editMode !== "not_allowed" && (_jsx(Col, { children: _jsx(EditOutlined, { style: { fontSize: "16px", color: token.colorLink }, onClick: () => setIsInEditMode(true) }) })), allowDelete && _jsx(Col, { children: deleteBtn }), _jsx(Col, { children: downloadBtn }), isDoc && enableToggleClientVisibility && (_jsx(Col, { children: _jsx(Tooltip, Object.assign({ title: `File is ${!doc.is_client_visible ? t("application.documents.not") : ""} ${t("application.documents.visible-to-user")}` }, { children: doc.is_client_visible ? (_jsx(EyeOutlined, { style: { fontSize: "16px", color: token.colorError }, onClick: () => toggleClientVisibility(false) })) : (_jsx(EyeInvisibleOutlined, { style: { fontSize: "16px", color: token.colorLink }, onClick: () => toggleClientVisibility(true) })) })) }))] }));
    };
    const showCategorySelect = () => {
        if (isUploadDoc)
            return doc.status === "success";
        return (doc.document_type === DocumentType.None ||
            (isInEditMode && (editMode === "full" || editMode === "category_only")));
    };
    return (_jsx(Form, Object.assign({ form: form, style: { margin: 0 }, onFinish: (v) => void updateDocument(v) }, { children: _jsxs(DocumentRowWrapper, Object.assign({ gutter: 8 }, { children: [_jsx(Col, Object.assign({ span: 14 }, { children: _jsxs(Row, Object.assign({ gutter: [4, 4] }, { children: [_jsx(Col, { children: _jsx(DocumentCheckbox, Object.assign({ value: id, checked: isSelected, onChange: () => handleSelectChange(id), disabled: isUploadDoc && doc.status !== "success" }, { children: _jsxs(FileWithIcon, { children: [_jsx(FileOutlined, { style: { color: token.colorPrimary, marginTop: 4 } }), (editMode === "full" || editMode === "name_only") && isInEditMode ? (_jsxs(_Fragment, { children: [_jsx(Form.Item, Object.assign({ name: "name", style: { margin: 0 }, initialValue: fileName }, { children: _jsx(NameInput, { size: "small" }) })), _jsx(Text, { children: ext })] })) : (_jsx(DataText, { children: doc.document_name }))] }) })) }), showCategorySelect() && (_jsx(Col, { children: _jsx(Form.Item, Object.assign({ name: "type", style: { margin: 0 }, initialValue: doc.document_type === DocumentType.None ? undefined : doc.document_type }, { children: _jsx(CategorySelect, { placeholder: t(`application.documents.category-placeholder`), size: "small", listHeight: 350, 
                                        // eslint-disable-next-line
                                        getPopupContainer: (trigger) => trigger.parentElement, optionFilterProp: "label", showSearch: true, options: categoriesDropdownOptions }) })) }))] })) })), _jsx(Col, Object.assign({ span: 4, style: { textAlign: "center" } }, { children: isUploadDoc ? getUploadStatus(doc.status, t) : _jsx(TimePassed, { date: doc.created_at }) })), _jsx(Col, Object.assign({ span: 6 }, { children: _jsx(Actions, { children: _jsx(Row, Object.assign({ gutter: 20, wrap: false, align: "middle" }, { children: renderActionBtns() })) }) }))] })) })));
};
export default DocumentRow;
