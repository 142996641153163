import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Space, Table, Tag, message } from "antd";
import styled from "styled-components";
import { InstallmentActions, InstallmentStatuses, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { useLoan } from "src/layouts/LoanLayout/loan.types";
import PrepaymentModal from "src/pages/loans/subpages/LoanPayments/PrepaymentModal";
import { Axios } from "src/utils/Axios";
import PaymentModal, { ModalMode } from "./PaymentModal";
const ContentWrapper = styled.div `
	margin-top: 64px;
	padding: 24px;
`;
const statusColorMapping = new Map([
    [InstallmentStatuses.Scheduled, "purple"],
    [InstallmentStatuses.PaymentPending, "processing"],
    [InstallmentStatuses.Paid, "success"],
    [InstallmentStatuses.Canceled, "warning"],
    [InstallmentStatuses.Error, "error"],
]);
const LoanPayments = () => {
    const { t } = useTranslation();
    const { currency, date } = useFormatter();
    const [loading, setLoading] = useState(false);
    const { loan: { payment_plan: { installments }, prepayment_allowed, }, updateLoan, } = useLoan();
    const [paymentModalConfig, setPaymentModalConfig] = useState();
    const [showPrepaymentModal, setShowPrepaymentModal] = useState(false);
    const payments = useMemo(() => {
        return installments.map((installment) => ({
            key: installment.installment_id,
            id: installment.installment_id,
            index: installment.installment_index,
            date: installment.due_date,
            amount: installment.amount
                ? currency(installment.amount, { currency: installment.currency })
                : "",
            principal: currency(installment.amount_principal, { currency: installment.currency }),
            interest: installment.amount_interest
                ? currency(installment.amount_interest, { currency: installment.currency })
                : "",
            status: installment.status,
            actions: installment.actions,
        }));
    }, [installments, currency]);
    const columns = useMemo(() => [
        {
            title: t("loans.payments.index"),
            dataIndex: "index",
            render: (v) => v,
        },
        {
            title: t("loans.payments.date"),
            dataIndex: "date",
            render: (v) => date(v),
            sorter: (a, b) => a.date.localeCompare(b.date),
        },
        {
            title: t("loans.payments.amount"),
            dataIndex: "amount",
        },
        {
            title: t("loans.payments.principal"),
            dataIndex: "principal",
        },
        {
            title: t("loans.payments.interest"),
            dataIndex: "interest",
        },
        {
            title: t("loans.payments.status"),
            render: (v) => (_jsx(Tag, Object.assign({ color: statusColorMapping.get(v) }, { children: t(`loans.payment_status.${v}`) }))),
            dataIndex: "status",
        },
        {
            title: "",
            render: (v) => (_jsxs(Space, { children: [v.actions.includes(InstallmentActions.ManualPayment) && (_jsx(Button, Object.assign({ size: "small", onClick: () => openPaymentModal(v.id, ModalMode.MANUAL_PAYMENT) }, { children: t("loans.actions.manual") }))), v.actions.includes(InstallmentActions.RequestPayment) && (_jsx(Button, Object.assign({ size: "small", onClick: () => handleRequestAction(v.id) }, { children: t("loans.actions.request") }))), v.actions.includes(InstallmentActions.ReconcilePayment) && (_jsx(Button, Object.assign({ size: "small", type: "primary", onClick: () => handleReconcileAction(v.id) }, { children: t("loans.actions.reconcile") }))), v.actions.includes(InstallmentActions.ViewPayment) && (_jsx(Button, Object.assign({ size: "small", onClick: () => openPaymentModal(v.id, ModalMode.VIEW) }, { children: t("loans.actions.view") })))] })),
        },
    ], 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    const updateLoanData = () => {
        setLoading(true);
        updateLoan().finally(() => setLoading(false));
    };
    const handleReconcileAction = (installmentId) => {
        Axios.post(`/admin/installments/${installmentId}/reconcile_payments`)
            .then(() => message.success(t("loans.actions.reconcile-success")), (err) => Axios.error(err, t("loans.actions.reconcile-error")))
            .finally(updateLoanData);
    };
    const handleRequestAction = (installmentId) => {
        Axios.post(`/admin/installments/${installmentId}/payments`)
            .then(() => message.success(t("loans.actions.request-success")), (err) => Axios.error(err, t("loans.actions.request-error")))
            .finally(updateLoanData);
    };
    const openPaymentModal = (installmentId, mode) => {
        const installment = installments.find((installment) => installment.installment_id == installmentId);
        if (installment) {
            setPaymentModalConfig({ installment, mode });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, Object.assign({ title: t("loans.payments.title") }, { children: prepayment_allowed && (_jsx(Button, Object.assign({ type: "primary", onClick: () => setShowPrepaymentModal(true) }, { children: t("loans.payments.button.create-prepayment") }))) })), _jsx(ContentWrapper, { children: _jsx(Table, { loading: loading, columns: columns, dataSource: payments, pagination: { pageSize: 20 } }) }), paymentModalConfig && (_jsx(PaymentModal, { mode: paymentModalConfig.mode, installment: paymentModalConfig.installment, closeModal: () => setPaymentModalConfig(undefined) })), _jsx(PrepaymentModal, { open: showPrepaymentModal, close: () => setShowPrepaymentModal(false), updatePayments: updateLoanData })] }));
};
export default LoanPayments;
