import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { EditOutlined, PlusOutlined, SaveOutlined, ScanOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { ApplicationStatuses, FeatureName } from "@teylor-tools/Api";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { Routes } from "src/Routes";
import Loader from "src/components/ui/Loader";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { FinancialsEmpty } from "src/pages/modules/Financials/FinancialsEmpty";
import { FinancialsModal } from "src/pages/modules/Financials/FinancialsModal";
import { Axios } from "src/utils/Axios";
import { FinancialsTable } from "./FinancialsTable";
import { ActionType, initialState, reducer } from "./reducer";
const ButtonsWrapper = styled.div `
	display: flex;
	gap: 16px;
`;
const ContentWrapper = styled.div `
	margin-top: 64px;
`;
const ApplicationFinancials = () => {
    const { application, updatePendingItems } = useApplication();
    const [{ financials }, dispatch] = useReducer(reducer, initialState);
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const { features } = useSelector((state) => state.configState);
    const ocrEnabled = useMemo(() => { var _a; return (_a = features.find((feature) => feature.name === FeatureName.Ocr)) === null || _a === void 0 ? void 0 : _a.value.enabled; }, [features]);
    const navigate = useNavigate();
    const isPaidOut = application.status === ApplicationStatuses.PaidOut;
    const { t } = useTranslation();
    const getFinancials = useCallback(() => {
        setIsLoading(true);
        Axios.get(`/admin/applications/${application.applicationId}/financial_data`)
            .then(({ data }) => {
            dispatch({ type: ActionType.FETCH_STATEMENTS, statements: data.financials });
        }, (err) => {
            void Axios.error(err);
        })
            .finally(() => {
            setIsLoading(false);
        });
    }, [application.applicationId]);
    useEffect(() => {
        getFinancials();
    }, [application.applicationId, getFinancials]);
    const createFinancialStatement = ({ type, date }) => {
        setIsLoading(true);
        const existingStatements = financials.map(({ financials_date, statement_type, input_data }) => ({
            financials_date,
            statement_type,
            input_data,
        }));
        Axios.post(`/admin/applications/${application.applicationId}/financial_data`, {
            financials: [
                ...existingStatements,
                {
                    financials_date: dayjs(date).format("YYYY-MM-DD"),
                    statement_type: type,
                    input_data: {},
                },
            ],
        })
            .then(() => {
            getFinancials();
        }, (err) => {
            void Axios.error(err);
        })
            .finally(() => {
            setIsLoading(false);
            setIsAddModalVisible(false);
            updatePendingItems();
        });
    };
    const updateStatements = () => {
        setIsLoadingSave(true);
        Axios.post(`/admin/applications/${application.applicationId}/financial_data`, {
            financials,
        })
            .then(() => {
            getFinancials();
        }, (err) => {
            void Axios.error(err);
        })
            .finally(() => {
            setIsLoadingSave(false);
            setIsEditing(false);
            updatePendingItems();
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, Object.assign({ title: t("application.financials.financials") }, { children: _jsxs(ButtonsWrapper, { children: [ocrEnabled && (_jsx(Button, Object.assign({ icon: _jsx(ScanOutlined, {}), onClick: () => {
                                navigate(generatePath(Routes.ApplicationFincr, {
                                    applicationId: application.applicationId,
                                }));
                            } }, { children: t("application.financials.fincr") }))), !isEditing && !isPaidOut && (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ icon: _jsx(PlusOutlined, {}), onClick: () => {
                                        setIsAddModalVisible(true);
                                    } }, { children: t("common.add") })), !!financials.length && (_jsx(Button, Object.assign({ type: "primary", icon: _jsx(EditOutlined, {}), onClick: () => setIsEditing(true) }, { children: t("common.edit") })))] })), isEditing && (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ onClick: () => {
                                        setIsEditing(false);
                                        dispatch({ type: ActionType.CANCEL });
                                    } }, { children: t("common.cancel") })), _jsx(Button, Object.assign({ type: "primary", icon: _jsx(SaveOutlined, {}), onClick: updateStatements, loading: isLoadingSave }, { children: t("common.save") }))] }))] }) })), _jsx(ContentWrapper, { children: (isLoading || isLoadingSave) && !financials.length ? (_jsx(Loader, {})) : !(financials === null || financials === void 0 ? void 0 : financials.length) ? (_jsx(FinancialsEmpty, { addFinancials: () => {
                        setIsAddModalVisible(true);
                    } })) : (_jsx(Spin, Object.assign({ spinning: isLoading || isLoadingSave }, { children: _jsx(FinancialsTable, { financials: financials, isEditing: isEditing, dispatch: dispatch }) }))) }), isAddModalVisible && (_jsx(FinancialsModal, { onCancel: () => {
                    setIsAddModalVisible(false);
                    if (!financials.length) {
                        setIsEditing(false);
                    }
                }, onFinish: createFinancialStatement }))] }));
};
export default ApplicationFinancials;
