import { jsx as _jsx } from "react/jsx-runtime";
import { LoanStatus } from "@teylor-tools/Api";
import { useLoan } from "src/layouts/LoanLayout/loan.types";
import DocumentsTab from "src/pages/modules/Documents/DocumentsTab";
import { Axios } from "src/utils/Axios";
const LoanDocuments = () => {
    const { loan: { loan_id, loan_status }, } = useLoan();
    const isPending = loan_status === LoanStatus.Pending;
    return (_jsx(DocumentsTab, { apiBasePath: `admin/loans/${loan_id}`, 
        // @ts-ignore some mismatch between types
        apiGetDocsRequest: () => Axios.get(`admin/loans/${loan_id}/documents_with_application`), apiGetArchivePath: `admin/loans/${loan_id}/files_archive_with_application`, allowUpload: !isPending, allowDelete: false, editMode: "not_allowed", enableToggleClientVisibility: false }));
};
export default LoanDocuments;
