var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DocumentCategory, DocumentType, } from "@teylor-tools/Api";
import { Axios } from "src/utils/Axios";
export const CARD_MIN_WIDTH = 550;
export const CARD_MAX_WIDTH = 1464;
const getUploadInfo = (baseApiPath, fileName, type = DocumentType.None) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield Axios.post(`${baseApiPath}/documents`, {
        file_name: fileName,
        document_type: type,
    });
    return res.data;
});
const uploadFile = (url, file) => __awaiter(void 0, void 0, void 0, function* () {
    yield Axios.put(url, file, {
        headers: {
            "Content-Type": file.type,
            "x-ms-blob-type": "BlockBlob",
        },
    });
});
const updateFileStatus = (baseApiPath, documentId, isSuccessful) => __awaiter(void 0, void 0, void 0, function* () {
    yield Axios.patch(`${baseApiPath}/documents/${documentId}/upload_success`, {
        is_upload_successful: isSuccessful,
    });
});
export const handleDocumentUpload = (doc, baseApiPath, updateDocumentInUpload) => __awaiter(void 0, void 0, void 0, function* () {
    if (!doc.file)
        return;
    updateDocumentInUpload(Object.assign(Object.assign({}, doc), { status: "uploading" }));
    let documentId = doc.document_id || "";
    let uploadUrl = doc.upload_url || "";
    let uploadedToServer = false;
    try {
        // if a doc has document_id and upload_url it means the POST request was successful
        // and a document row has been already created in the DB; no need to call it again then
        if (!documentId || !uploadUrl) {
            const { upload_url, document_id } = yield getUploadInfo(baseApiPath, doc.document_name);
            documentId = document_id;
            uploadUrl = upload_url;
        }
        // upload file to S3 only if it failed previously
        if (!doc.uploadedToServer) {
            yield uploadFile(uploadUrl, doc.file);
            uploadedToServer = true;
        }
        yield updateFileStatus(baseApiPath, documentId, true);
        updateDocumentInUpload(Object.assign(Object.assign({}, doc), { status: "success", file: undefined, document_id: documentId }));
    }
    catch (error) {
        updateDocumentInUpload(Object.assign(Object.assign({}, doc), { status: "error", document_id: documentId, upload_url: uploadUrl, uploadedToServer }));
        yield Axios.error(error);
    }
});
export const categorizeDocuments = (documents) => {
    return documents.reduce((acc, doc) => {
        const category = acc.find((cat) => cat.categoryName === doc.document_category);
        if (category) {
            const type = category.types.find((type) => type.typeName === doc.document_type);
            if (type) {
                type.docs.push(doc);
            }
            else {
                category.types.push({
                    typeName: doc.document_type,
                    docs: [doc],
                });
            }
        }
        else {
            const categoryData = {
                categoryName: doc.document_category,
                types: [
                    {
                        typeName: doc.document_type,
                        docs: [doc],
                    },
                ],
            };
            doc.document_category === DocumentCategory.None
                ? acc.unshift(categoryData)
                : acc.push(categoryData);
        }
        return acc;
    }, []);
};
export const isDocument = (doc) => !!doc.created_at;
export const isDocumentInUpload = (doc) => !!doc.updateId;
export const finalStates = ["error", "request_failed", "completed"];
export const scanningStates = ["scanning", "upload_pending"];
