var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Tag } from "antd";
import styled from "styled-components";
const StyledTag = styled(Tag) `
	border-color: ${(props) => (props.border === "default" ? "none" : props.theme[props.border])};
	background-color: ${(props) => props.background === "default" ? "none" : props.theme[props.background]};
	color: ${(props) => (props.colour === "default" ? "none" : props.theme[props.colour])};
	margin-right: 3px;
`;
const pickStatusShortcut = (status) => {
    return status
        .split(" ")
        .map((chunk) => chunk.charAt(0))
        .join("")
        .toUpperCase();
};
const StatusBase = (_a) => {
    var { short, label, colors, onClose, closable } = _a, props = __rest(_a, ["short", "label", "colors", "onClose", "closable"]);
    return (_jsx(StyledTag, Object.assign({ closable: closable, onClose: onClose }, colors(), props, { children: short ? pickStatusShortcut(label) : label })));
};
export default StatusBase;
