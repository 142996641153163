import { ExclamationCircleFilled } from "@ant-design/icons";
import { Divider, Typography } from "antd";
import styled, { css } from "styled-components";

import { Warning } from "@teylor-tools/Api";

import { DataCard } from "../../data-display/DataCard";
import { useTranslations } from "../../translations/translations";

const { Title } = Typography;

const ExclamationCircleFilledIcon = styled(ExclamationCircleFilled)<{ warning?: boolean }>`
	margin-right: 16px;
	${({ warning, theme }) =>
		warning
			? css`
					color: ${theme.colorWarning};
			  `
			: css`
					color: ${theme.colorError};
			  `}
`;

const IssueTitle = styled(Title)`
	margin-bottom: 24px;
`;

interface Props {
	scoreWarnings?: Warning[] | null;
}

const ScoringOverviewIssues = ({ scoreWarnings = [] }: Props) => {
	const t = useTranslations();

	const redFlags = scoreWarnings?.filter(({ type }) => type === "red_flag");
	const yellowFlags = scoreWarnings?.filter(({ type }) => type === "warning");

	return (
		<DataCard title={`${t.scoring.issues.issues} (${scoreWarnings ? scoreWarnings.length : 0})`}>
			{scoreWarnings && (
				<>
					<IssueTitle level={5}>
						{t.scoring.issues.critical} {`(${redFlags ? redFlags.length : 0})`}
					</IssueTitle>
					{redFlags?.map((flag, idx: number) => {
						return (
							<Typography.Paragraph key={`${flag.type}-${idx}`}>
								<ExclamationCircleFilledIcon />
								{flag.message}
							</Typography.Paragraph>
						);
					})}
					<Divider />
					<IssueTitle level={5}>
						{t.scoring.issues.nonCritical} {`(${yellowFlags ? yellowFlags.length : 0})`}
					</IssueTitle>
					{yellowFlags?.map((flag, idx: number) => {
						return (
							<Typography.Paragraph key={`${flag.type}-${idx}`}>
								<ExclamationCircleFilledIcon warning />
								{flag.message}
							</Typography.Paragraph>
						);
					})}
				</>
			)}
		</DataCard>
	);
};

export default ScoringOverviewIssues;
