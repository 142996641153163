import { AuthActions } from "src/store/actions/authActions";
const defaultState = {
    logged: localStorage.getItem("isLogged") === "true",
    loggedOutByUser: false,
};
const authReducer = function (state = defaultState, action) {
    switch (action.type) {
        case AuthActions.LOGIN:
            localStorage.setItem("isLogged", "true");
            return Object.assign(Object.assign({}, state), { logged: true, loggedOutByUser: false });
        case AuthActions.LOGOUT:
            localStorage.removeItem("isLogged");
            return Object.assign(Object.assign({}, state), { logged: false, loggedOutByUser: action.payload.loggedOutByUser });
    }
    return state;
};
export default authReducer;
