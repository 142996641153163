import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Divider, Row, Tag, Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { ApplicationStatuses } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import PercentInput from "@ui/form/inputs/percent-input/PercentInput";
import { DataCard } from "src/components/DataCard";
import EditableField from "src/components/ui/EditableField";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
const { Text } = Typography;
const Section = styled.div `
	margin: 24px;
`;
const SectionTitle = styled(Text) `
	margin-bottom: 12px;
	font-weight: 600;
	display: inline-block;
`;
const DataRow = styled(Row) `
	margin-bottom: 4px;
`;
const ApplicationPricingTlb = ({ rates, update }) => {
    const { t } = useTranslation();
    const { decimalToPercent, currency: formatCurrency } = useFormatter();
    const { application: { pricing, currency, status }, productConfig, } = useApplication();
    const isEditable = status === ApplicationStatuses.Pricing;
    const euriborRate = useMemo(() => rates.find((rate) => rate.global_rate_name === "euribor" && rate.global_rate_period === "3months"), [rates]);
    const minInterestRate = useMemo(() => Number(decimalToPercent(pricing.minimum_interest_rate)), [pricing.minimum_interest_rate, decimalToPercent]);
    const rateAsOfToday = useMemo(() => {
        const monthlyEuribor = (euriborRate === null || euriborRate === void 0 ? void 0 : euriborRate.global_rate_value)
            ? Number(euriborRate.global_rate_value) / 12
            : 0;
        const interestRateAndEuribor = (pricing.interest_rate || 0) + monthlyEuribor;
        const floor = (productConfig === null || productConfig === void 0 ? void 0 : productConfig.floor_rate) || 0;
        return Math.max(interestRateAndEuribor, floor);
    }, [pricing.interest_rate, euriborRate === null || euriborRate === void 0 ? void 0 : euriborRate.global_rate_value, productConfig === null || productConfig === void 0 ? void 0 : productConfig.floor_rate]);
    const displayValue = (value, type) => {
        if (!isValidNumericValue(value))
            return "-";
        let val;
        if (type === "monthly-to-annual") {
            val = Number(value) * 12;
        }
        else if (type === "annual-to-monthly") {
            val = Number(value) / 12;
        }
        else {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            val = value;
        }
        return decimalToPercent(val, undefined, true);
    };
    const validateMinMax = (min, max) => {
        return (_, value) => {
            if (value > max) {
                return Promise.reject(t("application_form.errors.fieldMustBeMax", {
                    max: `${max}%`,
                }));
            }
            if (value < min) {
                return Promise.reject(t("application_form.errors.fieldMustBeMin", {
                    min: `${min}%`,
                }));
            }
            return Promise.resolve();
        };
    };
    return (_jsxs(DataCard, Object.assign({ bodyStyle: { padding: 0 } }, { children: [_jsx(Section, { children: _jsxs(DataRow, { children: [_jsx(Col, Object.assign({ offset: 8, span: 7 }, { children: _jsx(Text, Object.assign({ strong: true }, { children: t("application.pricing.tlb.montly-rates") })) })), _jsx(Col, Object.assign({ span: 5 }, { children: _jsx(Text, Object.assign({ strong: true }, { children: t("application.pricing.tlb.annual-rates") })) }))] }) }), _jsx(Divider, {}), _jsxs(Section, { children: [_jsx(SectionTitle, { children: t("application.pricing.tlb.floating-component") }), _jsxs(DataRow, { children: [_jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.pricing.tlb.3-month-euribor") })) })), _jsx(Col, Object.assign({ span: 7 }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: displayValue(euriborRate === null || euriborRate === void 0 ? void 0 : euriborRate.global_rate_value, "annual-to-monthly") })) })), _jsx(Col, Object.assign({ span: 5 }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: displayValue(euriborRate === null || euriborRate === void 0 ? void 0 : euriborRate.global_rate_value) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(Tag, { children: t("common.as-of", {
                                        date: dayjs(euriborRate === null || euriborRate === void 0 ? void 0 : euriborRate.global_rate_effective_date).format(dayMonthYear),
                                    }) }) }))] })] }), _jsx(Divider, {}), _jsxs(Section, { children: [_jsx(SectionTitle, { children: t("application.pricing.tlb.fixed-component") }), _jsxs(DataRow, { children: [_jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.pricing.tlb.minimum-margin") })) })), _jsx(Col, Object.assign({ span: 7 }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: displayValue(pricing.minimum_interest_rate) })) })), _jsx(Col, Object.assign({ span: 5 }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: displayValue(pricing.minimum_interest_rate, "monthly-to-annual") })) }))] }), _jsxs(DataRow, { children: [_jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Text, { children: t("application.pricing.tlb.total-margin") }) })), _jsx(Col, Object.assign({ span: 7 }, { children: _jsx(EditableField, Object.assign({ displayedValue: displayValue(pricing.interest_rate), value: decimalToPercent(pricing.interest_rate), isEditable: isEditable, update: (v) => update({ interest_rate: v }), rules: [
                                        {
                                            validator: validateMinMax(minInterestRate, 4),
                                        },
                                    ] }, { children: _jsx(PercentInput, {}) })) })), _jsx(Col, Object.assign({ span: 5 }, { children: _jsx(Text, { children: displayValue(pricing.interest_rate, "monthly-to-annual") }) }))] })] }), _jsx(Divider, {}), _jsxs(Section, { children: [_jsx(SectionTitle, { children: t("application.pricing.tlb.floating-plus-fixed") }), _jsxs(DataRow, { children: [_jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.pricing.tlb.rate-as-of-today") })) })), _jsx(Col, Object.assign({ span: 7 }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: displayValue(rateAsOfToday) })) })), _jsx(Col, Object.assign({ span: 5 }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: displayValue(rateAsOfToday, "monthly-to-annual") })) }))] }), _jsxs(DataRow, { children: [_jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.pricing.tlb.floor") })) })), _jsx(Col, Object.assign({ span: 7 }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: displayValue(productConfig === null || productConfig === void 0 ? void 0 : productConfig.floor_rate) })) })), _jsx(Col, Object.assign({ span: 5 }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: displayValue(productConfig === null || productConfig === void 0 ? void 0 : productConfig.floor_rate, "monthly-to-annual") })) }))] })] }), _jsx(Divider, {}), _jsxs(Section, { children: [_jsx(SectionTitle, { children: t("application.pricing.tlb.fees") }), _jsxs(DataRow, { children: [_jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Text, { children: t("application.pricing.tlb.transaction-fee") }) })), _jsx(Col, Object.assign({ span: 16 }, { children: _jsx(EditableField, Object.assign({ displayedValue: isValidNumericValue(pricing.transaction_fee)
                                        ? /* eslint-disable @typescript-eslint/no-non-null-assertion */
                                            formatCurrency(pricing.transaction_fee, { currency })
                                        : undefined, value: pricing.transaction_fee, isEditable: isEditable, update: (v) => update({ transaction_fee: v }) }, { children: _jsx(CurrencyInput, { style: { width: 150 } }) })) }))] }), _jsxs(DataRow, { children: [_jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Text, { children: t("application.pricing.tlb.prepayment-rate") }) })), _jsx(Col, Object.assign({ span: 16 }, { children: _jsx(EditableField, Object.assign({ displayedValue: isValidNumericValue(pricing.prepayment_rate)
                                        ? decimalToPercent(pricing.prepayment_rate, undefined, true)
                                        : undefined, value: decimalToPercent(pricing.prepayment_rate), isEditable: isEditable, update: (v) => update({ prepayment_rate: v }), rules: [
                                        {
                                            validator: validateMinMax(0, 10),
                                        },
                                    ] }, { children: _jsx(PercentInput, { style: { width: 150 } }) })) }))] })] })] })));
};
export default ApplicationPricingTlb;
