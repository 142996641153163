import { MobileOutlined } from "@ant-design/icons";
import { Form } from "antd";
import styled from "styled-components";
export const MFAViewWrapper = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;
export const MFAViewHeader = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;
export const MFAViewContent = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 56px;
	width: 100%;
`;
export const StyledForm = styled((Form)) `
	width: 100%;

	.ant-form-item {
		width: 100%;

		&:first-child {
			margin-bottom: 0;
		}

		.ant-input {
			width: 100%;
		}
	}

	.ant-form-item-except-input {
		display: flex;
		justify-content: center;
	}
`;
export const MobileIcon = styled(MobileOutlined) `
	color: ${({ theme }) => theme.colorPrimary};
	font-size: 56px;
	margin-bottom: 24px;
`;
