import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Divider as AntdDivider, Typography } from "antd";
import styled from "styled-components";
import EmptyData from "src/components/ui/EmptyData";
import PendingItem from "src/layouts/ApplicationLayout/modules/pending-items/PendingItem";
import { getPendingItemsCount } from "src/layouts/ApplicationLayout/modules/pending-items/pending-items.helpers";
const { Text } = Typography;
const ListItem = styled.li `
	padding: 4px;
`;
const Divider = styled(AntdDivider) `
	margin: 0;
`;
const PendingItemsList = ({ pendingItems, limit = Number.POSITIVE_INFINITY }) => {
    const { t } = useTranslation();
    const pendingItemsToDisplay = useMemo(() => {
        if (!limit) {
            return pendingItems;
        }
        const documentsLimitEnd = limit - pendingItems.general.length;
        const signatoriesLimitEnd = limit - pendingItems.general.length - pendingItems.documents.length;
        return {
            general: pendingItems.general.slice(0, limit),
            documents: pendingItems.documents.slice(0, documentsLimitEnd > -1 ? documentsLimitEnd : 0),
            signatories: pendingItems.signatories.slice(0, signatoriesLimitEnd > -1 ? signatoriesLimitEnd : 0),
        };
    }, [pendingItems, limit]);
    const showEmpty = useMemo(() => !getPendingItemsCount(pendingItemsToDisplay), [pendingItemsToDisplay]);
    if (showEmpty) {
        return _jsx(EmptyData, {});
    }
    return (_jsxs(_Fragment, { children: [pendingItemsToDisplay.general.map((item, index) => (_jsx(PendingItem, { title: t(`common.errors.${item.error}`), onClick: item.action, showDivider: index < pendingItemsToDisplay.general.length - 1 }, item.error))), !!pendingItemsToDisplay.documents.length && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), pendingItemsToDisplay.documents.map((item, index) => (_jsx(PendingItem, { title: t(`common.errors.${item.error}`), onClick: item.action, showDivider: index < pendingItemsToDisplay.documents.length - 1, extra: _jsx("ul", { children: item.documentTypes.map((type) => (_jsx(ListItem, { children: _jsx(Text, { children: t(`documents.type.${type}.name`) }) }, type))) }) }, item.error)))] })), !!pendingItemsToDisplay.signatories.length && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), pendingItemsToDisplay.signatories.map((item, index) => (_jsx(PendingItem, { title: t("application.pending-items.pending-items-for", {
                            name: `${item.first_name} ${item.last_name}`,
                        }), onClick: item.action, showDivider: index < pendingItemsToDisplay.signatories.length - 1, extra: _jsx("ul", { children: item.errors.map((error) => (_jsx(ListItem, { children: _jsx(Text, { children: t(`common.errors.${error}`) }) }, error))) }) }, item.signatory_id)))] }))] }));
};
export default PendingItemsList;
