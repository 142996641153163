import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileSearchOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import styled from "styled-components";
import { ApplicationStatuses } from "@teylor-tools/Api";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { SideActions, useApplication, useLayout, } from "src/layouts/ApplicationLayout/application.types";
import QualitativeGroup from "src/pages/modules/QualitativeGroup";
import { Axios } from "src/utils/Axios";
const Wrapper = styled.div `
	margin-top: 64px;
	padding: 0 24px 24px;
	min-width: 504px;
`;
const StyledSpin = styled(Spin) `
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const ApplicationQualitative = () => {
    var _a;
    const { application, updatePendingItems } = useApplication();
    const { setActiveMenu } = useLayout();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [checklist, setChecklist] = useState();
    const getChecklist = () => {
        Axios.get(`/admin/applications/${application.applicationId}/application_checklist`)
            .then(({ data }) => {
            setChecklist(data);
            if (data.is_done)
                updatePendingItems();
        }, (error) => void Axios.error(error))
            .finally(() => setLoading(false));
    };
    useEffect(getChecklist, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [application.applicationId]);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, Object.assign({ title: t("application.qualitative_analysis.heading") }, { children: _jsx(Button, Object.assign({ type: "primary", icon: _jsx(FileSearchOutlined, {}), onClick: () => setActiveMenu(SideActions.analystReport) }, { children: t("application.qualitative_analysis.button") })) })), _jsx(Wrapper, { children: _jsx(StyledSpin, Object.assign({ size: "large", spinning: loading }, { children: checklist && !!((_a = checklist.checklist_data.groups) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(QualitativeGroup, { groups: checklist.checklist_data.groups, readonly: application.status !== ApplicationStatuses.InternalReview, checklistId: checklist.application_checklist_id, update: getChecklist })) })) })] }));
};
export default ApplicationQualitative;
