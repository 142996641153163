import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, Grid, Steps } from "antd";
const { useBreakpoint } = Grid;
const Timeline = ({ statusFlow }) => {
    const { t } = useTranslation();
    const { lg, xl, xxl } = useBreakpoint();
    const currentStep = statusFlow.findIndex((status) => status.is_current_status);
    const steps = useMemo(() => {
        return statusFlow.map(({ status_name }) => {
            return { title: t(`application.status.${status_name}`) };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusFlow]);
    return (_jsx(Card, Object.assign({ title: t("application.overview.timeline") }, { children: _jsx(Steps, { progressDot: true, current: currentStep, direction: lg || xl || xxl ? "horizontal" : "vertical", items: steps }) })));
};
export default Timeline;
