import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { EditOutlined, PlusOutlined, SaveOutlined, ScanOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { FeatureName } from "@teylor-tools/Api";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { Routes } from "src/Routes";
import Loader from "src/components/ui/Loader";
import { useCompany } from "src/layouts/CompanyLayout/CompanyTypes";
import { FinancialsEmpty } from "src/pages/modules/Financials/FinancialsEmpty";
import { FinancialsModal } from "src/pages/modules/Financials/FinancialsModal";
import { Axios } from "src/utils/Axios";
import { CompanyFinancialsTable } from "./CompanyFinancialsTable";
import { ActionType, initialState, reducer } from "./reducer";
const FinancialActionWrapper = styled.div `
	display: flex;
	gap: 16px;
`;
const ContentWrapper = styled.div `
	margin-top: 64px;
`;
export const CompanyFinancials = () => {
    const { t } = useTranslation();
    const { company } = useCompany();
    const navigate = useNavigate();
    const [{ financials }, dispatch] = useReducer(reducer, initialState);
    const { features } = useSelector((state) => state.configState);
    const [isEditing, setIsEditing] = useState(false);
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const ocrEnabled = useMemo(() => { var _a; return (_a = features.find((feature) => feature.name === FeatureName.Ocr)) === null || _a === void 0 ? void 0 : _a.value.enabled; }, [features]);
    const updateStatements = () => {
        Axios.post(`/admin/companies/${company.companyId}/financial_data`, {
            financials,
        })
            .then(() => {
            fetchFinancialsData();
        }, (err) => {
            void Axios.error(err);
        })
            .finally(() => {
            setIsLoading(false);
            setIsEditing(false);
        });
    };
    const handleFinCr = () => {
        navigate(generatePath(Routes.CompanyFincr, {
            companyId: company.companyId,
        }));
    };
    const fetchFinancialsData = useCallback(() => {
        Axios.get(`/admin/companies/${company.companyId}/financial_data`)
            .then(({ data }) => dispatch({ type: ActionType.FETCH_STATEMENTS, statements: data.financials }))
            .catch((err) => Axios.error(err))
            .finally(() => setIsLoading(false));
    }, [company.companyId]);
    useEffect(() => {
        fetchFinancialsData();
    }, [fetchFinancialsData]);
    const createFinancialStatement = ({ type, date }) => {
        const existingStatements = financials.map(({ financials_date, statement_type, input_data }) => ({
            financials_date,
            statement_type,
            input_data,
        }));
        Axios.post(`/admin/companies/${company.companyId}/financial_data`, {
            financials: [
                ...existingStatements,
                {
                    financials_date: dayjs(date).format("YYYY-MM-DD"),
                    statement_type: type,
                    input_data: {},
                },
            ],
        })
            .then(() => {
            fetchFinancialsData();
        }, (err) => {
            void Axios.error(err);
        })
            .finally(() => {
            setIsLoading(false);
            setIsAddModalVisible(false);
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, Object.assign({ title: t("company.menu.financials") }, { children: _jsxs(FinancialActionWrapper, { children: [ocrEnabled && (_jsx(Button, Object.assign({ icon: _jsx(ScanOutlined, {}), onClick: handleFinCr }, { children: t("company.financials.fincr") }))), !isEditing && (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ icon: _jsx(PlusOutlined, {}), onClick: () => setIsAddModalVisible(true) }, { children: t("common.add") })), !!financials.length && (_jsx(Button, Object.assign({ type: "primary", icon: _jsx(EditOutlined, {}), onClick: () => setIsEditing(true) }, { children: t("common.edit") })))] })), isEditing && (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ onClick: () => {
                                        setIsEditing(false);
                                        dispatch({ type: ActionType.CANCEL });
                                    } }, { children: t("common.cancel") })), _jsx(Button, Object.assign({ type: "primary", icon: _jsx(SaveOutlined, {}), onClick: updateStatements, loading: isLoading }, { children: t("common.save") }))] }))] }) })), _jsx(ContentWrapper, { children: isLoading && !financials.length ? (_jsx(Loader, {})) : !financials.length ? (_jsx(FinancialsEmpty, { addFinancials: () => setIsAddModalVisible(true) })) : (_jsx(Spin, Object.assign({ spinning: isLoading }, { children: _jsx(CompanyFinancialsTable, { financials: financials, isEditing: isEditing, dispatch: dispatch }) }))) }), isAddModalVisible && (_jsx(FinancialsModal, { onCancel: () => {
                    setIsAddModalVisible(false);
                    if (!financials.length) {
                        setIsEditing(false);
                    }
                }, onFinish: createFinancialStatement }))] }));
};
