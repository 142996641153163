import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import styled from "styled-components";
const TypeWrapper = styled.div `
	margin: 24px 0;
`;
const TypeTitle = styled(Typography.Text) `
	padding-left: 24px;
	font-size: 12px;
`;
const DocumentTypeWrapper = ({ name, showName = true, children }) => {
    const { t } = useTranslation();
    return (_jsxs(TypeWrapper, { children: [showName && (
            /* @ts-ignore dynamic types names */
            _jsx(TypeTitle, Object.assign({ strong: true }, { children: t(`documents.type.${name}.name`) }))), children] }));
};
export default DocumentTypeWrapper;
