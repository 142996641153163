var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Select, Space, Spin, Tag, Typography, message } from "antd";
import { BoUserStatus } from "@teylor-tools/Api";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";
import { Axios } from "src/utils/Axios";
import { getUserStatusColor } from "./helpers";
const UserProfile = ({ user, createUser, getUsers, onClose }) => {
    var _a;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isLoadingInvitation, setIsLoadingInvitation] = useState(false);
    const [roleOptions, setRoleOptions] = useState([]);
    useEffect(() => {
        Axios.get(`admin/bo_users/roles`)
            .then(({ data }) => {
            setRoleOptions(data.map((role) => ({ value: role.id || "", label: role.name || "" })));
        }, (error) => {
            void Axios.error(error);
        })
            .finally(() => setIsLoading(false));
    }, []);
    const onSave = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoadingSave(true);
        if (createUser) {
            void Axios.post("/admin/bo_users", values)
                .then(() => {
                void message.success(t("settings.team.profile.user_created_success_message"));
                void getUsers();
                onClose();
            }, (error) => {
                void Axios.error(error);
            })
                .finally(() => setIsLoadingSave(false));
        }
        else {
            if (!(user === null || user === void 0 ? void 0 : user.id))
                return;
            try {
                if (form.isFieldTouched("name") || form.isFieldTouched("email")) {
                    yield Axios.patch(`/admin/bo_users/${user.id}/profile`, { name: values.name, email: values.email });
                }
                yield Axios.patch(`/admin/bo_users/${user.id}/roles`, { roles: values.roles });
                void message.success(t("settings.team.profile.user_updated_success_message"));
                void getUsers();
                onClose();
            }
            catch (err) {
                void Axios.error(err);
            }
            finally {
                setIsLoadingSave(false);
            }
        }
    });
    const handleResendInvite = () => {
        if (!(user === null || user === void 0 ? void 0 : user.id))
            return;
        setIsLoadingInvitation(true);
        void Axios.post(`/admin/bo_users/${user.id}/resend_invite`)
            .then(() => {
            void message.success(t("settings.team.profile.invitation_sent_success_message"));
        }, (error) => {
            void Axios.error(error);
        })
            .finally(() => setIsLoadingInvitation(false));
    };
    return (_jsxs(Spin, Object.assign({ spinning: isLoading }, { children: [user && (_jsxs(Space, Object.assign({ style: { marginBottom: 16 } }, { children: [_jsx(Typography.Text, { children: t("settings.team.profile.status") }), user.status && (_jsx(Tag, Object.assign({ color: getUserStatusColor(user.status), style: { margin: 0 } }, { children: t(`settings.team.overview.status.${user.status}`) }))), user.status === BoUserStatus.Invited && (_jsx(Button, Object.assign({ type: "link", onClick: handleResendInvite, loading: isLoadingInvitation }, { children: t("settings.team.profile.resend_invite") })))] }))), _jsxs(Form, Object.assign({ form: form, layout: "vertical", name: "profile", onFinish: (values) => void onSave(values) }, { children: [_jsx(Form.Item, Object.assign({ name: "name", label: t("settings.team.overview.name"), initialValue: (user === null || user === void 0 ? void 0 : user.name) || "", rules: [{ required: true, message: t("settings.field_required") }] }, { children: _jsx(Input, {}) })), _jsx(EmailFormItem, { initialValue: (user === null || user === void 0 ? void 0 : user.email) || "", rules: [
                            {
                                required: true,
                                message: t("application_form.field-required"),
                            },
                        ] }), _jsx(Form.Item, Object.assign({ name: "roles", label: t("settings.team.overview.roles"), initialValue: (_a = user === null || user === void 0 ? void 0 : user.roles) === null || _a === void 0 ? void 0 : _a.map((role) => role.id), rules: [{ required: true, message: t("settings.field_required") }] }, { children: _jsx(Select, { mode: "multiple", placeholder: t("settings.team.overview.roles"), style: { width: "100%" }, options: roleOptions }) })), _jsx(Form.Item, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit", loading: isLoadingSave }, { children: t("common.save") })) })] }))] })));
};
export default UserProfile;
