import { cellsWithTotalValues, expandable } from "./financialsTableRows";
export const getEditableColumnCel = ({ col, isEditing, dispatch, focusedCell, setFocusedCell, data, }) => {
    if (!col.editable)
        return col;
    return Object.assign(Object.assign({}, col), { onCell: (record) => {
            const isEditable = !expandable.some((section) => section === record.key) &&
                !cellsWithTotalValues.some((total) => total === record.key);
            return {
                record,
                editable: isEditable,
                isEditing,
                dataIndex: col.dataIndex,
                dispatch,
                focusedCell,
                setFocusedCell,
                data,
            };
        } });
};
