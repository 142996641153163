import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Modal, Select } from "antd";
import { Axios } from "src/utils/Axios";
const CompartmentModal = ({ partnerId, loading = false, onCancel, onFinish }) => {
    const { t } = useTranslation();
    const [compartments, setCompartments] = useState([]);
    const [form] = Form.useForm();
    useEffect(() => {
        Axios.get(`admin/partners/${partnerId}/filter_values`)
            .then(({ data }) => setCompartments(data.compartments || []))
            .catch((err) => Axios.error(err));
    }, [partnerId]);
    const handleFinish = (v) => {
        onFinish(v.compartment);
    };
    return (_jsx(Modal, Object.assign({ title: t("application.payment.compartment_modal.title"), open: true, onCancel: onCancel, okText: t("common.finish"), cancelText: t("common.cancel"), onOk: () => void form.validateFields().then(() => form.submit()), okButtonProps: { disabled: loading, htmlType: "submit" } }, { children: _jsx(Form, Object.assign({ form: form, onFinish: handleFinish, initialValues: { compartment: compartments[0] }, disabled: loading }, { children: _jsx(Form.Item, Object.assign({ name: "compartment", label: t("application.payment.compartment_modal.label"), rules: [
                    {
                        required: true,
                        message: t("application_form.field-required"),
                    },
                ] }, { children: _jsx(Select, { style: { width: 100 }, options: compartments.map((compartment) => ({
                        value: compartment,
                        label: t(`application.payment.compartment_modal.${compartment}`),
                    })) }) })) })) })));
};
export default CompartmentModal;
