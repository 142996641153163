import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import i18n from "i18next";
import { getCountryList } from "@teylor-tools/utils/i18n/Countries";
import { TranslationsProvider as TranslationsProviderUI, } from "@ui/translations/translations";
export const TranslationsProvider = ({ children }) => {
    const { t } = useTranslation();
    const translationsUI = useMemo(() => ({
        add: t("common.add"),
        yes: t("common.yes"),
        no: t("common.no"),
        save: t("common.save"),
        select: t("common.select"),
        edit: t("common.edit"),
        delete: t("common.delete"),
        cancel: t("common.cancel"),
        city: t("application_form.city"),
        country: t("application_form.country"),
        countries: getCountryList(i18n.language),
        emailInputErrorMessage: t("application_form.email-invalid"),
        emailInputLabel: t("application_form.email"),
        emailInputPlaceholder: t("application_form.email-example"),
        postcode: t("application_form.post-code"),
        postcodeInvalid: t("application_form.post-code-invalid"),
        postcodePlaceholder: t("application_form.post-code-placeholder"),
        tinInvalid: t("application_form.tax-number-invalid"),
        tinPlaceholder: t("application_form.tax-number-placeholder"),
        ibanInvalid: t("application_form.iban-invalid"),
        formErrors: {
            fieldRequired: t("application_form.field-required"),
            fieldInvalid: t("application_form.errors.fieldInvalid"),
            fieldMustBeBetweenMinMaxNumbers: (min, max) => t("application_form.errors.fieldMustBeBetweenMinMaxNumbers", { min, max }),
            fieldMustBeBetweenMinMaxChars: (min, max) => t("application_form.errors.fieldMustBeBetweenMinMaxChars", { min, max }),
            fieldMustBeBetweenMinMax: (min, max) => t("application_form.errors.fieldMustBeBetweenMinMax", { min, max }),
            fieldMustBeMinChars: (min) => t("application_form.errors.fieldMustBeMinChars", { min }),
            passwordShort: t("application_form.password-too-short"),
            passwordNotComplex: t("application_form.password-not-complex"),
            invalidPhoneNumber: t("application_form.invalid-phone-number"),
            fieldContainsInvalidCharacters: t("application_form.errors.fieldContainsInvalidCharacters"),
            personalTinMustBeDifferentThanCompanyTin: t("application_form.errors.personalTinMustBeDifferentThanCompanyTin"),
        },
        representativeCard: {
            address: t("application.company_representatives.address"),
            addTin: t("application.company_representatives.add-tin"),
            beneficialOwner: t("application.company_representatives.beneficial-owner"),
            canSignAlone: t("application.company_representatives.can-sign-alone"),
            dateOfBirth: t("application.company_representatives.dob"),
            email: t("application.company_representatives.email"),
            legalSignatory: t("application.company_representatives.legal-signatory"),
            nationality: t("application.company_representatives.nationality"),
            ownership: t("application.company_representatives.ownership"),
            phone: t("application.company_representatives.phone"),
            tin: t("application.company_representatives.tin"),
            willingToSign: t("application.company_representatives.willing-to-sign"),
            creditScore: t("application.company_representatives.credit-score"),
            lastUpdated: t("application.company_representatives.last-updated"),
        },
        representativeModal: {
            addRepresentative: t("application.company_representatives.add-representative"),
            allFieldsRequired: t("application.company_representatives.all-fields-required"),
            beneficialOwner: t("application.company_representatives.beneficial-owner"),
            canSignAlone: t("application.company_representatives.can-sign-alone"),
            dateOfBirth: t("application.company_representatives.dob"),
            editRepresentative: t("application.company_representatives.edit-representative"),
            firstName: t("application.company_representatives.first-name"),
            houseNumber: t("application.company_representatives.house-number"),
            lastName: t("application.company_representatives.last-name"),
            legalSignatory: t("application.company_representatives.legal-signatory"),
            nationality: t("application.company_representatives.nationality"),
            optional: t("application.company_representatives.optional"),
            percentageOwnership: t("application.company_representatives.percentage-ownership"),
            phoneNumber: t("application.company_representatives.phone-number"),
            representativeNotContacted: t("application.company_representatives.representative-not-contacted"),
            street: t("application.company_representatives.street"),
            taxNumber: t("application.company_representatives.tax-number"),
            willingToSign: t("application.company_representatives.willing-to-sign"),
        },
        object: {
            object: t("application.objects.object"),
            manufacturer: t("application.objects.object.manufacturer"),
            type: t("application.objects.object.type"),
            yearBuilt: t("application.objects.object.year-built"),
            objectDescription: t("application.objects.object.object-description"),
            netPurchasePrice: t("application.objects.object.net-purchase-price"),
            downPayment: t("application.objects.object.down-payment"),
            remainingValue: t("application.objects.object.remaining-value"),
            numberOfObjects: t("application.objects.object.number-of-objects"),
            financingType: t("application.objects.object.financing-type"),
            rentalState: t("application.objects.object.rental-state"),
            isFixedInterest: t("application.objects.object.is-fixed-interest"),
            companyName: t("application.objects.object.retailer-company-name"),
            street: t("application.objects.object.retailer-street"),
            houseNumber: t("application.objects.object.retailer-house-number"),
            city: t("application.objects.object.retailer-city"),
            postcode: t("application.objects.object.retailer-postcode"),
            country: t("application.objects.object.retailer-country"),
            retailerCompanyId: t("application.objects.object.retailer-company-id"),
            retailerName: t("application.objects.object.retailer-name"),
            retailerAddress: t("application.objects.object.retailer-address"),
            expectedDeliveryDate: t("application.objects.object.expected-delivery-date"),
            createOrderForm: t("application.objects.object.create-order-form"),
            errors: {
                required: t("application.create-application.required"),
                fieldMinLength5: t("application_form.errors.fieldMustBeMin", { min: 5 }),
                postcodeInvalid: t("application_form.post-code-invalid"),
            },
            placeholders: {
                manufacturer: t("application.objects.object.manufacturer.placeholder"),
                type: t("application.objects.object.type.placeholder"),
                yearBuilt: t("application.objects.object.year-built.placeholder"),
                objectDescription: t("application.objects.object.object-description.placeholder"),
                objectCompanyName: t("application.objects.object.retailer-company-name.placeholder"),
                street: t("application.objects.object.retailer-street.placeholder"),
                houseNumber: t("application.objects.object.retailer-house-number.placeholder"),
                city: t("application.objects.object.retailer-city.placeholder"),
                retailerCompanyId: t("application.objects.object.retailer-company-id.placeholder"),
            },
            options: {
                isFixedInterest: {
                    yes: t("common.yes"),
                    no: t("common.no"),
                },
                financingTypes: {
                    leasing: t("application.objects.financing_type.leasing"),
                    hire_purchase: t("application.objects.financing_type.hire_purchase"),
                    financing: t("application.objects.financing_type.financing"),
                    sale_leaseback: t("application.objects.financing_type.sale_leaseback"),
                    credit_line: t("application.objects.financing_type.credit_line"),
                    other: t("application.objects.financing_type.other"),
                },
                rentalStates: {
                    no: t("application.objects.rental_state.no"),
                    internal: t("application.objects.rental_state.internal"),
                    b2c: t("application.objects.rental_state.b2c"),
                },
            },
            sections: {
                retailer: t("application.objects.object.retailer"),
            },
        },
        companySelectFormItem: {
            label: t("application_form.country_select.label"),
            placeholder: t("application_form.country_select.placeholder"),
            errorCompanyNameInvalid: t("application_form.country_select.company_name_invalid"),
            errorCompanyNameNotFound: t("application_form.country_select.company_name_not_found_text"),
            errorCompanyNameNotFoundLinkText: t("application_form.country_select.company_name_not_found_link"),
        },
        industryCodes: {
            industryCode: t("application.company.industry_code"),
            industryCodes: t("application.company.industry_codes"),
            description: t("application.company.description"),
            weight: t("application.company.weight"),
            lastUpdatedOn: (date) => (_jsx(Trans, Object.assign({ i18nKey: "application.company.industry_code.last_updated_on" }, { children: { date } }))),
            source: (source) => (_jsx(Trans, Object.assign({ i18nKey: "application.company.industry_code.source" }, { children: { source } }))),
            codeType: (type) => (_jsx(Trans, Object.assign({ i18nKey: "application.company.industry_code.code_type" }, { children: { type } }))),
        },
        industryCodesEditModal: {
            title: t("industry_codes.title"),
            update: t("industry_codes.update"),
            type: t("industry_codes.type"),
            codeEnum: {
                wz_2008: t("industry_codes.wz_2008"),
                noga_2008: t("industry_codes.noga_2008"),
            },
            industryCodeNumber: (index) => t("industry_codes.industry_code", { index }),
            code: t("industry_codes.code"),
            weight: t("industry_codes.weight"),
            optional: t("industry_codes.optional"),
            description: t("industry_codes.description"),
            addIndustryCode: t("industry_codes.add_code"),
            fieldRequired: t("industry_codes.field-required"),
        },
        scoring: {
            overview: {
                alyze: t("application.quantitative_analysis.overview.alyze"),
                alyzeRating: t("application.quantitative_analysis.overview.alyze-rating"),
                gridRate: t("application.quantitative_analysis.overview.grid-rate"),
            },
            issues: {
                issues: t("application.quantitative_analysis.overview.issues"),
                critical: t("application.quantitative_analysis.overview.critical"),
                nonCritical: t("application.quantitative_analysis.overview.non-critical"),
            },
            scores: {
                scores: t("application.quantitative_analysis.overview.scores"),
                pdScore: t("application.quantitative_analysis.overview.pd-score"),
                totalScore: t("application.quantitative_analysis.overview.total-score"),
                ratingScore: (year) => t("application.quantitative_analysis.overview.rating-score", { year }),
            },
        },
    }), [t]);
    return _jsx(TranslationsProviderUI, Object.assign({ translations: translationsUI }, { children: children }));
};
