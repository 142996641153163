import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import OverviewScores from "@ui/scoring/scores/Scores";
import OverviewAlyze from "@ui/scoring/scoring-overview-alyze/ScoringOverviewAlyze";
import OverviewScoreIssues from "@ui/scoring/scoring-overview-issues/ScoringOverviewIssues";
import OverviewSummaryTables from "src/pages/modules/AnalysisQuantitative/OverviewSummaryTables";
const CONTENT_MAX_WIDTH = 1464;
const Wrapper = styled.div `
	max-width: ${CONTENT_MAX_WIDTH}px;
`;
const TwoColumnsWrapper = styled.div `
	display: grid;
	column-gap: 24px;
	grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
`;
const Overview = ({ scores, pricing, financials, kpis }) => {
    if (!scores || !pricing)
        return null;
    return (_jsxs(Wrapper, { children: [_jsxs(TwoColumnsWrapper, { children: [_jsxs("div", { children: [_jsx(OverviewAlyze, { scoreGrade: scores.final_mark, rate: scores.grid_rate }), _jsx(OverviewScores, { scores: scores })] }), _jsx(OverviewScoreIssues, { scoreWarnings: scores.warnings })] }), _jsx(OverviewSummaryTables, { financials: financials, kpis: kpis })] }));
};
export default Overview;
