import { ReactNode } from "react";

import { Form, FormItemProps } from "antd";
import { RuleObject } from "rc-field-form/lib/interface";

import { useFormatter } from "@teylor-tools/hooks/formatter";

import { useTranslations } from "../../../translations/translations";

interface MinMaxFormItemProps extends FormItemProps {
	minValue?: number;
	maxValue?: number;
	children: ReactNode;
	fieldType: "currency" | "duration";
}

export const MinMaxFormItem = ({
	minValue = 0,
	maxValue = 0,
	children,
	fieldType,
	...props
}: MinMaxFormItemProps) => {
	const t = useTranslations();
	const { localizedNumber, currency } = useFormatter();

	const rejectionMessage = {
		currency: {
			min: currency(minValue),
			max: currency(maxValue),
		},
		duration: {
			min: localizedNumber({ value: minValue, decimalPlaces: 0 }),
			max: localizedNumber({ value: maxValue, decimalPlaces: 0 }),
		},
	};

	const handleValidation = (_: RuleObject, value: number): Promise<void | string> => {
		if (value > maxValue || value < minValue) {
			return Promise.reject(
				t.formErrors.fieldMustBeBetweenMinMax(
					rejectionMessage[fieldType].min,
					rejectionMessage[fieldType].max
				)
			);
		}

		return Promise.resolve();
	};

	return (
		<Form.Item
			{...props}
			rules={[
				{
					validator: handleValidation,
				},
				...(props.rules || []),
			]}
		>
			{children}
		</Form.Item>
	);
};
