import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Divider, Typography } from "antd";
import styled, { css } from "styled-components";
const { Text } = Typography;
const IssueTitle = styled(Text) `
	margin-bottom: 24px;
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
`;
const ExclamationCircleFilledIcon = styled(ExclamationCircleFilled) `
	margin-right: 16px;
	${({ $warning }) => $warning
    ? css `
					color: ${({ theme }) => theme.colorWarning};
			  `
    : css `
					color: ${({ theme }) => theme.colorError};
			  `}
`;
const FinancialsIssues = ({ warnings }) => {
    const { t } = useTranslation();
    const ocrErrors = useMemo(() => warnings.filter(({ level }) => level === "error"), [warnings]);
    const ocrWarnings = useMemo(() => warnings.filter(({ level }) => level === "warning"), [warnings]);
    const renderIssues = (issues, type) => {
        return (_jsxs(_Fragment, { children: [_jsxs(IssueTitle, { children: [t(type === "error" ? "application.fincr.critical" : "application.fincr.non-critical"), " ", `(${issues ? issues.length : 0})`] }), issues.map((issue, idx) => {
                    return (_jsxs(Typography.Paragraph, { children: [_jsx(ExclamationCircleFilledIcon, { "$warning": issue.level === "warning" }), issue.message] }, `${issue.type}-${idx}`));
                })] }));
    };
    return (_jsxs(_Fragment, { children: [renderIssues(ocrErrors, "error"), _jsx(Divider, {}), renderIssues(ocrWarnings, "warning")] }));
};
export default FinancialsIssues;
