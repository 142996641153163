import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ArrowDownOutlined, ArrowUpOutlined, MenuOutlined } from "@ant-design/icons";
import { Typography, theme } from "antd";
const TaskPriorityIndicator = ({ priority, showLabel = false }) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const priorityIcon = useMemo(() => {
        switch (priority) {
            case "low":
                return _jsx(ArrowDownOutlined, { style: { color: token.colorInfo } });
            case "medium":
                return _jsx(MenuOutlined, { style: { color: token.orange } });
            case "high":
                return _jsx(ArrowUpOutlined, { style: { color: token.colorError } });
            default:
                return null;
        }
    }, [priority, token]);
    return (_jsxs(_Fragment, { children: [priorityIcon, showLabel && (_jsx(Typography.Text, Object.assign({ style: { marginLeft: 12 } }, { children: t(`tasks.priority.${priority}`) })))] }));
};
export default TaskPriorityIndicator;
