import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { generate } from "@ant-design/colors";
import { ConfigProvider, Spin } from "antd";
import { theme } from "antd";
import styled, { ThemeProvider as BaseThemeProvider } from "styled-components";
import EmptyData from "src/components/ui/EmptyData";
import { Axios } from "src/utils/Axios";
const Wrapper = styled.div `
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const GreySpin = styled(Spin) `
	.ant-spin-dot-item {
		background-color: grey;
	}
`;
const Spinner = () => {
    return (_jsx(Wrapper, { children: _jsx(GreySpin, { size: "large" }) }));
};
const staticColors = {
    white: "#fff",
    blue1: "#E6F7FF",
    blue2: "#BAE7FF",
    blue3: "#91D5FF",
    blue4: "#69C0FF",
    blue5: "#40A9FF",
    blue6: "#1890FF",
    blue7: "#096DD9",
    blue8: "#0050B3",
    green1: "#F6FFED",
    green3: "#B7EB8F",
    green6: "#52C41A",
    red1: "#FFF1F0",
    red3: "#FFA39E",
    red6: "#F5222D",
    orange1: "#FFF7E6",
    orange3: "#FFD591",
    orange6: "#FA8C16",
};
const defaultConfig = {
    main_menu_bg_color: "#00235C",
    main_menu_color: "#ffffff",
    color_link: "#1890ff",
    color_primary: "#1890ff",
    color_cta_text: "#ffffff",
    logo_web_path: "",
    logo_collapsed_web_path: "",
};
export const ThemeProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [themeConfig, setThemeConfig] = useState();
    const { token } = theme.useToken();
    useEffect(() => {
        Axios.get(`/admin/public_config`)
            .then(({ data }) => {
            setThemeConfig(data.fe_theme_admin);
        }, (error) => {
            void Axios.error(error);
        })
            .finally(() => {
            setLoading(false);
        });
    }, []);
    const { appTheme, antdTheme } = useMemo(() => {
        const ctaFill = (themeConfig === null || themeConfig === void 0 ? void 0 : themeConfig.color_primary) || defaultConfig.color_primary;
        const ctaText = (themeConfig === null || themeConfig === void 0 ? void 0 : themeConfig.color_cta_text) || defaultConfig.color_cta_text;
        const colorLink = (themeConfig === null || themeConfig === void 0 ? void 0 : themeConfig.color_link) || defaultConfig.color_link;
        const appTheme = Object.assign(Object.assign(Object.assign({}, staticColors), defaultConfig), themeConfig);
        const antdTheme = {
            // button type="primary"
            colorPrimary: ctaFill,
            colorTextLightSolid: ctaText,
            // button type="link"
            colorLink,
            colorLinkHover: generate(colorLink)[4],
            colorLinkActive: generate(colorLink)[6],
        };
        return { appTheme, antdTheme };
    }, [themeConfig]);
    if (loading)
        return _jsx(Spinner, {});
    if (!appTheme || !antdTheme)
        return null;
    return (_jsx(ConfigProvider, Object.assign({ theme: { token: antdTheme }, renderEmpty: () => _jsx(EmptyData, {}) }, { children: _jsx(BaseThemeProvider, Object.assign({ theme: Object.assign(Object.assign(Object.assign({}, token), antdTheme), appTheme) }, { children: children })) })));
};
