import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Drawer, Modal, Space, Tabs, Typography, message } from "antd";
import styled from "styled-components";
import { BoUserStatus } from "@teylor-tools/Api";
import { Axios } from "src/utils/Axios";
import UserProfile from "./UserProfile";
const TitleWrapper = styled.div `
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const UserPreview = ({ user, createUser, getUsers, onClose }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const tabs = [
        {
            key: "profile",
            label: t("settings.team.profile"),
            children: (_jsx(UserProfile, { user: user, createUser: createUser, getUsers: getUsers, onClose: onClose })),
        },
        // ...(user
        // 	? [
        // 			{
        // 				key: "permissions",
        // 				label: t("settings.team.profile.permissions"),
        // 				children: `Content of Tab Pane 3`,
        // 			},
        // 	  ]
        // 	: []),
    ];
    const handleDeleteUser = () => {
        // delete user when backend is ready
        setIsModalOpen(false);
    };
    const handleDisableUser = () => {
        if (!(user === null || user === void 0 ? void 0 : user.id))
            return;
        setIsLoading(true);
        void Axios.post(`/admin/bo_users/${user.id}/disable`)
            .then(() => {
            void message.success(t("settings.team.profile.user_was_disabled"));
            void getUsers();
        }, (error) => {
            void Axios.error(error);
        })
            .finally(() => setIsLoading(false));
    };
    const handleEnableUser = () => {
        if (!(user === null || user === void 0 ? void 0 : user.id))
            return;
        setIsLoading(true);
        void Axios.post(`/admin/bo_users/${user.id}/enable`)
            .then(() => {
            void message.success(t("settings.team.profile.user_was_enabled"));
            void getUsers();
        }, (error) => {
            void Axios.error(error);
        })
            .finally(() => setIsLoading(false));
    };
    return (_jsxs(_Fragment, { children: [_jsx(Drawer, Object.assign({ destroyOnClose: true, title: _jsxs(TitleWrapper, { children: [_jsx(Typography.Text, { children: (user === null || user === void 0 ? void 0 : user.name) || t("settings.team.profile.new_user") }), user && (_jsxs(Space, { children: [user.status === BoUserStatus.Active && (_jsx(Button, Object.assign({ onClick: handleDisableUser, loading: isLoading }, { children: t("common.disable") }))), user.status === BoUserStatus.Disabled && (_jsx(Button, Object.assign({ onClick: handleEnableUser, loading: isLoading }, { children: t("common.enable") }))), _jsx(Button, Object.assign({ type: "primary", danger: true, onClick: () => setIsModalOpen(true) }, { children: t("common.delete") }))] }))] }), closeIcon: _jsx(LeftOutlined, {}), placement: "right", onClose: onClose, open: !!user || createUser, size: "large" }, { children: _jsx(Tabs, { defaultActiveKey: "profile", items: tabs }) })), _jsx(Modal, Object.assign({ title: t("settings.team.profile.delete_user_modal_title"), open: isModalOpen, okButtonProps: { danger: true }, okText: t("common.delete"), onOk: handleDeleteUser, onCancel: () => setIsModalOpen(false) }, { children: _jsx("p", { children: t("settings.team.profile.delete_user_modal_message") }) }))] }));
};
export default UserPreview;
