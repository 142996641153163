import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { AppstoreOutlined, ContainerOutlined, EuroCircleOutlined, FileSearchOutlined, PaperClipOutlined, ShopOutlined, } from "@ant-design/icons";
import Menu from "@ui/menu/Menu";
import { CompanyRoutes } from "src/Routes";
const CompanyMenu = ({ companyId }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const menuItems = useMemo(() => {
        return [
            {
                icon: _jsx(AppstoreOutlined, {}),
                label: t("company.menu.overview"),
                key: generatePath(CompanyRoutes.Company, { companyId }),
            },
            {
                icon: _jsx(ShopOutlined, {}),
                label: t("company.menu.company_details"),
                key: generatePath(CompanyRoutes.CompanyDetails, { companyId }),
            },
            {
                icon: _jsx(PaperClipOutlined, {}),
                label: t("company.menu.documents"),
                key: generatePath(CompanyRoutes.CompanyDocuments, { companyId }),
            },
            { type: "divider" },
            {
                icon: _jsx(EuroCircleOutlined, {}),
                label: t("company.menu.financials"),
                key: generatePath(CompanyRoutes.CompanyFinancials, { companyId }),
            },
            {
                icon: _jsx(FileSearchOutlined, {}),
                label: t("company.menu.scoring"),
                key: generatePath(CompanyRoutes.CompanyScoring, { companyId }),
            },
            { type: "divider" },
            {
                icon: _jsx(ContainerOutlined, {}),
                label: t("company.menu.applications"),
                key: generatePath(CompanyRoutes.CompanyApplications, { companyId }),
            },
        ];
    }, [companyId, t]);
    return (_jsx(Menu, { selectedKeys: [location.pathname], multiple: true, mode: "inline", onClick: (menuItem) => navigate(menuItem.key), items: menuItems, isCollapsible: true, collapseText: t("menu.collapse") }));
};
export default CompanyMenu;
