import { combineReducers, createStore } from "redux";
import configReducer from "src/store/reducers/configReducer";
import authReducer from "./reducers/authReducer";
import profileReducer from "./reducers/profileReducer";
const rootReducer = combineReducers({
    authState: authReducer,
    configState: configReducer,
    profileState: profileReducer,
});
let store;
if (process.env.NODE_ENV !== "production") {
    // conditional imports are restricted at typscript configuration
    // eslint-disable-next-line
    const { composeWithDevToolsDevelopmentOnly } = require("@redux-devtools/extension");
    // eslint-disable-next-line
    store = createStore(rootReducer, composeWithDevToolsDevelopmentOnly());
}
else {
    store = createStore(rootReducer);
}
export default store;
