import { Table as AntdTable } from "antd";
import styled from "styled-components";
export const Table = styled(AntdTable) `
	.ant-table {
		background-color: #f5f5f5;
	}
	.ant-table-header {
		width: calc(100% + 48px);
		transform: translateX(-24px) translateY(-24px) !important;
		background-color: #f5f5f5;
		padding: 0 24px;
		border-top: 1px solid rgba(0, 0, 0, 0.06);
		border-bottom: 1px solid rgba(0, 0, 0, 0.06);
		th {
			background-color: #fff;
		}
	}

	.ant-table-body {
		background-color: #fff;
	}

	.ant-table-tbody > tr > td {
		padding: 12px 24px;
	}
	table tr:nth-child(2n) td {
		background-color: #fafafa !important;
	}

	/*  disable all kinds of hover effects*/

	.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
	.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
		background: unset;
	}
	table {
		tr > td:last-child {
			background-color: #f5f5f5 !important;
			border-right: none !important;
			border-bottom: none !important;
		}

		tr > th:last-child {
			border-right: none !important;
			border-bottom: none !important;
		}
	}
`;
