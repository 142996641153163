import { PartnerUserStatus } from "@teylor-tools/Api";
export const getUserStatusColor = (status) => {
    if (status === PartnerUserStatus.Invited)
        return "blue";
    if (status === PartnerUserStatus.Disabled)
        return "red";
    return "default";
};
export const getPartnerAddress = (address) => {
    if (!address)
        return "-";
    const streetLine1 = address.street_line_1 ? `${address.street_line_1}, ` : "";
    const streetLine2 = address.street_line_2 ? `${address.street_line_2}, ` : "";
    const city = address.city ? `${address.city}, ` : "";
    const postalCode = address.postal_code ? `${address.postal_code}, ` : "";
    const country = address.country ? `${address.country} ` : "";
    return `${streetLine1}${streetLine2}${city}${postalCode}${country}`;
};
