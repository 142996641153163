import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Modal, Space } from "antd";
import styled from "styled-components";
import { LoanStatus } from "@teylor-tools/Api";
import LoanStatusTag from "src/components/ui/statuses/LoanStatus";
import { Axios } from "src/utils/Axios";
const { confirm } = Modal;
const POSSIBLE_STATES = Object.values(LoanStatus);
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${({ theme }) => theme.colorError} !important;
`;
const MoveLoan = ({ loanId, loanStatus, update }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const menuItems = POSSIBLE_STATES.map((state) => ({
        key: state,
        label: (_jsx(LoanStatusTag, { label: t(`loans.status.${state}`), value: state, closable: false, disabled: false })),
    }));
    const changeStatus = (key) => {
        setLoading(true);
        Axios.patch(`/admin/loans/${loanId}/status`, {
            loan_status: key,
        })
            .then(update, (error) => {
            void Axios.error(error);
        })
            .finally(() => setLoading(false));
    };
    const showConfirmationModal = (status) => {
        confirm({
            title: t("common.are-you-sure"),
            icon: _jsx(ExclamationCircleOutlinedIcon, {}),
            content: t("loan.move-status"),
            okText: t("common.confirm"),
            onOk: () => void changeStatus(status),
        });
    };
    return (_jsx(Dropdown, Object.assign({ placement: "bottomRight", menu: {
            selectedKeys: [loanStatus],
            items: menuItems,
            onClick: ({ key }) => showConfirmationModal(key),
        } }, { children: _jsx(Button, Object.assign({ loading: loading }, { children: _jsxs(Space, { children: [t("loans.move-loan"), _jsx(DownOutlined, {})] }) })) })));
};
export default MoveLoan;
