import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PlusOutlined, TeamOutlined } from "@ant-design/icons";
import { Alert, Button, Typography } from "antd";
import styled from "styled-components";
const { Title, Text } = Typography;
const Wrapper = styled.div `
	text-align: center;
	padding-top: 68px;
	padding-bottom: 40px;
`;
const TeamIcon = styled(TeamOutlined) `
	font-size: 48px;
	color: #d9d9d9;
	margin-bottom: 24px;
`;
const ButtonWrapper = styled.div `
	margin-top: 32px;
`;
const CompanyRepresentativeEmpty = ({ addRepresentative }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(Alert, { type: "info", message: t("application.company_representatives.complete-section-alert"), showIcon: true }), _jsxs(Wrapper, { children: [_jsx(TeamIcon, {}), _jsx(Title, Object.assign({ level: 5 }, { children: t("application.company_representatives.no-representatives-title") })), _jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.company_representatives.no-representatives-subtitle") })), _jsx(ButtonWrapper, { children: _jsx(Button, Object.assign({ type: "primary", size: "large", onClick: addRepresentative, icon: _jsx(PlusOutlined, {}) }, { children: t("application.company_representatives.new-representative") })) })] })] }));
};
export default CompanyRepresentativeEmpty;
