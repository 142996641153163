export var AuthActions;
(function (AuthActions) {
    AuthActions["LOGIN"] = "LOGIN";
    AuthActions["LOGOUT"] = "LOGOUT";
})(AuthActions || (AuthActions = {}));
export const logout = (loggedOutByUser = false) => {
    return {
        type: AuthActions.LOGOUT,
        payload: { loggedOutByUser },
    };
};
export const login = () => {
    return {
        type: AuthActions.LOGIN,
    };
};
