import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PlusOutlined, WalletOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import styled from "styled-components";
const { Title, Text } = Typography;
const Wrapper = styled.div `
	margin-top: 24px;
	text-align: center;
	padding-top: 68px;
	padding-bottom: 40px;
`;
const WalletIcon = styled(WalletOutlined) `
	font-size: 48px;
	color: #d9d9d9;
	margin-bottom: 24px;
`;
const ButtonWrapper = styled.div `
	margin-top: 32px;
`;
const RepaymentEmpty = ({ addRepayment }) => {
    const { t } = useTranslation();
    return (_jsx(_Fragment, { children: _jsxs(Wrapper, { children: [_jsx(WalletIcon, {}), _jsx(Title, Object.assign({ level: 5 }, { children: t("application.repayment.empty-title") })), _jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.repayment.empty-text") })), _jsx(ButtonWrapper, { children: _jsx(Button, Object.assign({ type: "primary", size: "large", onClick: addRepayment, icon: _jsx(PlusOutlined, {}) }, { children: t("application.repayment.empty-btn") })) })] }) }));
};
export default RepaymentEmpty;
