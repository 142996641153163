import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Modal, Space, message } from "antd";
import { DataCardItem } from "@ui/data-display/DataCard";
import { DataCard } from "src/components/DataCard";
import { usePartner } from "src/layouts/PartnerLayout/PartnerTypes";
import { Axios } from "src/utils/Axios";
import ContactPersonModal from "./ContactPersonModal";
const PartnerContactPersonCard = ({ person }) => {
    const { t } = useTranslation();
    const { partner, updatePartner } = usePartner();
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const handleDeleteUser = () => {
        Axios.delete(`admin/partners/${partner.partner_id}/contact_persons/${person.partner_contact_person_id}`)
            .then(() => {
            void message.success(t("partner.contact_persons.delete_user_success"));
            void updatePartner();
        })
            .catch((err) => Axios.error(err))
            .finally(() => {
            setDeleteModal(false);
        });
    };
    const handleUpdateUser = (data) => {
        setLoadingUpdate(true);
        Axios.patch(`admin/partners/${partner.partner_id}/contact_persons/${person === null || person === void 0 ? void 0 : person.partner_contact_person_id}`, Object.assign({}, data))
            .then(() => {
            void message.success(t("partner.contact_persons.update_user_success"));
            void updatePartner();
            setEditModal(false);
        })
            .catch((err) => Axios.error(err))
            .finally(() => {
            setLoadingUpdate(false);
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs(DataCard, Object.assign({ title: `${person.title} ${person.first_name} ${person.last_name}`, extra: _jsxs(Space, { children: [_jsx(Button, { type: "text", icon: _jsx(EditOutlined, {}), onClick: () => setEditModal(true) }), _jsx(Button, { type: "text", icon: _jsx(DeleteOutlined, {}), onClick: () => setDeleteModal(true) })] }) }, { children: [_jsx(DataCardItem, { label: t("partner.contact_persons.role"), value: person.job_title }), _jsx(DataCardItem, { copyable: true, label: t("partner.contact_persons.mobile_number"), value: person.mobile_number }), _jsx(DataCardItem, { copyable: true, label: t("partner.contact_persons.phone_number"), value: person.phone_number }), _jsx(DataCardItem, { copyable: true, label: t("partner.contact_persons.email"), value: person.email })] }), person.partner_contact_person_id), _jsx(Modal, Object.assign({ title: t("partner.contact_persons.delete_user_modal_title"), open: deleteModal, okButtonProps: { danger: true }, okText: t("common.delete"), onOk: handleDeleteUser, onCancel: () => setDeleteModal(false) }, { children: _jsx("p", { children: t("partner.contact_persons.delete_user_modal_message") }) })), editModal && (_jsx(ContactPersonModal, { user: person, loading: loadingUpdate, onFinish: handleUpdateUser, onCancel: () => setEditModal(false) }))] }));
};
export default PartnerContactPersonCard;
