import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { BorderlessTableOutlined, CalendarOutlined, ClockCircleOutlined, CloseOutlined, GoldOutlined, ReloadOutlined, RightOutlined, ShoppingOutlined, } from "@ant-design/icons";
import { Row, Spin, Typography } from "antd";
import styled from "styled-components";
import { ApplicationStatuses, TaskRelatedEntityType } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import Preview, { Cell } from "@ui/preview/Preview";
import SidebarCreateTask from "src/components/tasks/SidebarCreateTask";
import SidebarTaskDetails from "src/components/tasks/SidebarTaskDetails";
import { Assigned } from "src/components/ui/Assigned";
import TimePassed from "src/components/ui/TimePassed";
import { useMonthlyRate } from "src/hooks/useMonthlyRate";
import { SideActions, useLayout, } from "src/layouts/ApplicationLayout/application.types";
import { FinalReviewChecklistSidebar, PayoutChecklistSidebar, } from "src/layouts/ApplicationLayout/modules/SidebarChecklists";
import SidebarTasks from "src/layouts/ApplicationLayout/modules/SidebarTasks";
import ApplicationAnalystReport from "src/pages/applications/subpages/ApplicationQuantitative/ApplicationAnalystReport";
import ApplicationNotes from "./ApplicationNotes";
import Logs from "./Logs";
const StyledWrapper = styled.div `
	background-color: #f5f5f5;
	position: relative;
	overflow: auto;
	display: flex;
	height: 100%;
`;
const Offset = styled(Row) `
	margin-bottom: 16px;
`;
const Grow = styled.div `
	flex-grow: 1;
	overflow: auto;
`;
const StaticDrawer = styled(Preview) `
	position: relative;

	.ant-drawer-body {
		padding: 0;
		display: flex;
		flex-direction: column;
	}

	.ant-drawer-content-wrapper {
		position: relative;
		height: 100%;
		box-shadow: none;
		border-left: solid 1px #f0f0f0;
	}
`;
const ApplicationOutlet = ({ application, pendingItems, pendingItemsCount, tasks, updateTasks, productConfig, updateApplication, updatePendingItems, }) => {
    const { t } = useTranslation();
    const monthlyRate = useMonthlyRate(application.pricing, application.currency);
    const { currency } = useFormatter();
    const { activeMenu, setActiveMenu } = useLayout();
    const { tasksEnabled } = useSelector((state) => state.configState);
    const renderHeader = (text, goBackTo) => {
        return (_jsxs(Row, Object.assign({ align: "middle", justify: "space-between", style: { width: "100%" } }, { children: [_jsx(Typography.Title, Object.assign({ level: 5, style: { margin: 0 } }, { children: text })), _jsxs("div", { children: [_jsx(CloseOutlined, { onClick: () => setActiveMenu(undefined) }), goBackTo && (_jsx(RightOutlined, { onClick: () => setActiveMenu(goBackTo), style: { marginLeft: 16 } }))] })] })));
    };
    const sideMenuElements = {
        "application-information": {
            header: renderHeader(t("common.toolbar.application-information")),
            lineData: application
                ? [
                    {
                        icon: _jsx(GoldOutlined, {}),
                        label: t("application.amount"),
                        value: `${currency(application.loanSize, { currency: application.currency })}`,
                    },
                    {
                        icon: _jsx(ClockCircleOutlined, {}),
                        label: t("application.duration"),
                        value: `${application.loanDuration} ${t("common.months")}`,
                    },
                    {
                        icon: _jsx(ReloadOutlined, {}),
                        label: t("common.toolbar.monthly-rate"),
                        value: monthlyRate,
                    },
                    {
                        icon: _jsx(ShoppingOutlined, {}),
                        label: t("application.preview.product"),
                        value: `${application.product.product_family_name} / ${application.product.product_name}`,
                    },
                    {
                        icon: _jsx(BorderlessTableOutlined, {}),
                        label: t("common.id"),
                        value: application.shortApplicationId,
                    },
                    {
                        icon: _jsx(CalendarOutlined, {}),
                        label: t("application.created"),
                        value: _jsx(TimePassed, { date: application.created || "" }),
                    },
                ]
                : [],
            children: (_jsx(Cell, { children: _jsxs(Spin, Object.assign({ spinning: !application }, { children: [_jsx(Offset, { children: _jsx(Assigned, { label: "application.assignee", span: 24, name: (application === null || application === void 0 ? void 0 : application.status_owner_name) || "" }) }), _jsx(Offset, { children: _jsx(Assigned, { label: "application.account-manager", span: 24, name: (application === null || application === void 0 ? void 0 : application.status_owner_name) || "" }) })] })) })),
        },
        "tasks-and-pending-items": {
            header: renderHeader(tasksEnabled
                ? `${t("application.tasks.sidebar.title.pending-items-and-tasks")} (${pendingItemsCount + tasks.length})`
                : `${t("application.tasks.sidebar.title.pending-items")} (${pendingItemsCount})`),
            children: (_jsx(SidebarTasks, { pendingItems: pendingItems, pendingItemsCount: pendingItemsCount, tasks: tasks, tasksEnabled: tasksEnabled })),
        },
        "create-task": {
            header: renderHeader(t("tasks.sidebar.title.create-new-task"), SideActions.tasksAndPendingItems),
            children: (_jsx(SidebarCreateTask, { entityType: TaskRelatedEntityType.Application, entityId: application.applicationId, afterSave: () => {
                    void updateTasks();
                    setActiveMenu(SideActions.tasksAndPendingItems);
                } })),
        },
        "task-details": {
            header: renderHeader(t("tasks.sidebar.title.task-details"), SideActions.tasksAndPendingItems),
            children: (_jsx(SidebarTaskDetails, { data: tasks, afterDelete: () => void updateTasks().finally(() => setActiveMenu(SideActions.tasksAndPendingItems)), afterUpdate: () => void updateTasks() })),
        },
        "shared-notes": {
            header: renderHeader(t("common.toolbar.shared-notes")),
            children: _jsx(ApplicationNotes, { applicationId: application.applicationId }),
        },
        "activity-log": {
            header: renderHeader(t("common.toolbar.activity-log")),
            children: _jsx(Logs, { applicationId: application.applicationId }),
        },
        "analyst-report": {
            header: renderHeader(t("application.qualitative_analysis.analyst-report")),
            children: (_jsx(ApplicationAnalystReport, { applicationId: application.applicationId, disabled: application.status !== ApplicationStatuses.InternalReview, updatePendingItems: updatePendingItems })),
        },
        "payout-checklist": {
            header: renderHeader(t("application.payout_checklist.title")),
            children: (_jsx(PayoutChecklistSidebar, { applicationId: application.applicationId, onCloseMenu: () => setActiveMenu(undefined), updatePendingItems: updatePendingItems })),
        },
        "final-review-checklist": {
            header: renderHeader(t("application.final_review.title")),
            children: (_jsx(FinalReviewChecklistSidebar, { applicationId: application.applicationId, onCloseMenu: () => setActiveMenu(undefined), updatePendingItems: updatePendingItems })),
        },
    };
    return (_jsxs(StyledWrapper, { children: [_jsx(Grow, { children: _jsx(Outlet, { context: {
                        application,
                        pendingItems: pendingItems,
                        tasks: tasks,
                        updateApplication,
                        productConfig,
                        updatePendingItems,
                        updateTasks,
                    } }) }), _jsx(StaticDrawer, Object.assign({ entityId: application.applicationId, getContainer: false, visible: !!activeMenu, header: activeMenu ? sideMenuElements[activeMenu].header : undefined, lineData: activeMenu ? sideMenuElements[activeMenu].lineData : undefined }, { children: activeMenu ? sideMenuElements[activeMenu].children : undefined }))] }));
};
export default ApplicationOutlet;
