import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Divider as AntdDivider, Space, Typography } from "antd";
import styled from "styled-components";
import { Routes } from "../Routes";
const { Title, Text } = Typography;
const Content = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;
const QuestionCircleIcon = styled(QuestionCircleOutlined) `
	display: flex;
	justify-content: center;
	color: rgba(0, 0, 0, 0.25);
	font-size: 56px;
	margin-top: 8px;
	margin-bottom: 44px;
`;
const Divider = styled(AntdDivider) `
	margin: 40px 0;
`;
const NotFoundPage = () => {
    const { t } = useTranslation();
    return (_jsxs(Content, { children: [_jsx(QuestionCircleIcon, {}), _jsx(Title, Object.assign({ level: 3, style: { textAlign: "center" } }, { children: t("not_found.title") })), _jsxs(Text, Object.assign({ type: "secondary", style: { textAlign: "center" } }, { children: [t("common.error-code"), ": 404"] })), _jsx(Divider, {}), _jsxs(Space, Object.assign({ direction: "vertical", size: 4 }, { children: [_jsx(Text, Object.assign({ type: "secondary" }, { children: t("not_found_page.notFoundInfo") })), _jsx(Link, Object.assign({ to: Routes.CreateApplication, reloadDocument: true }, { children: t("not_found_page.notFoundCreateLoan") })), _jsx(Link, Object.assign({ to: Routes.Applications, reloadDocument: true }, { children: t("not_found_page.notFoundViewLoans") }))] }))] }));
};
export default NotFoundPage;
