import { generatePath } from "react-router-dom";
import { TaskPriority, TaskStatus } from "@teylor-tools/Api";
import { Routes } from "src/Routes";
export const TaskPriorities = Object.values(TaskPriority);
export const TaskStatuses = Object.values(TaskStatus);
export const navigateToTaskEntity = (navigate, entityType, entityId) => {
    if (!entityType || !entityId)
        return;
    switch (entityType) {
        case "application":
            navigate(generatePath(Routes.Application, {
                applicationId: entityId,
            }));
            break;
        case "loan":
            navigate(generatePath(Routes.Loan, {
                loanId: entityId,
            }));
            break;
        case "company":
            navigate(generatePath(Routes.Company, {
                companyId: entityId,
            }));
            break;
    }
};
