import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Form, Modal } from "antd";
import styled from "styled-components";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import BaseDatePicker from "src/components/ui/DatePicker";
const DatePicker = styled(BaseDatePicker) `
	width: 100%;
`;
const RepaymentModal = ({ payoutDate, loading = false, onCancel, onFinish }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const handleFinish = (v) => {
        onFinish(v.payoutDate);
    };
    return (_jsx(Modal, Object.assign({ title: payoutDate
            ? t("application.repayment.modal-edit-title")
            : t("application.repayment.modal-generate-title"), open: true, onCancel: onCancel, okText: payoutDate
            ? t("application.repayment.modal-edit-btn")
            : t("application.repayment.modal-generate-btn"), cancelText: t("common.cancel"), onOk: () => void form.validateFields().then(() => form.submit()), okButtonProps: { disabled: loading, htmlType: "submit" } }, { children: _jsx(Form, Object.assign({ form: form, layout: "vertical", onFinish: handleFinish, initialValues: { payoutDate }, disabled: loading }, { children: _jsx(Form.Item, Object.assign({ name: "payoutDate", label: t("application.repayment.modal-datepicker-label"), rules: [
                    {
                        required: true,
                        message: t("application_form.field-required"),
                    },
                ] }, { children: _jsx(DatePicker, { format: dayMonthYear }) })) })) })));
};
export default RepaymentModal;
