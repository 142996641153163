import { Tag } from "antd";

export enum ScoringGradeValue {
	"A" = "A",
	"A+" = "A+",
	"A-" = "A-",
	"B" = "B",
	"B+" = "B+",
	"B-" = "B-",
	"C" = "C",
	"C+" = "C+",
	"C-" = "C-",
	"D" = "D",
	"D+" = "D+",
	"D-" = "D-",
}

const getColor = (scoringGrade: ScoringGradeValue) => {
	switch (scoringGrade) {
		case ScoringGradeValue.A:
		case ScoringGradeValue["A+"]:
		case ScoringGradeValue["A-"]:
			return "green";
		case ScoringGradeValue.B:
		case ScoringGradeValue["B+"]:
		case ScoringGradeValue["B-"]:
			return "gold";
		case ScoringGradeValue.C:
		case ScoringGradeValue["C+"]:
		case ScoringGradeValue["C-"]:
			return "volcano";
		case ScoringGradeValue.D:
		case ScoringGradeValue["D+"]:
		case ScoringGradeValue["D-"]:
			return "red";
	}
};

interface Props {
	score: ScoringGradeValue;
}

const ScoringGrade = ({ score }: Props) => {
	return <Tag color={getColor(score)}>{score}</Tag>;
};

export default ScoringGrade;
