import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import Loader from "src/components/ui/Loader";
import { Axios } from "src/utils/Axios";
import FinancialsEmpty from "./FinancialsEmpty";
import { FinancialsTable } from "./FinancialsTable";
const ContentWrapper = styled.div `
	margin-top: 64px;
`;
const LoanFinancials = () => {
    var _a;
    const { loan } = useOutletContext();
    const ref = useRef(null);
    const [financials, setFinancials] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const getFinancials = useCallback(() => {
        setIsLoading(true);
        Axios.get(`/admin/loans/${loan.loan_id}/financial_data`)
            .then(({ data }) => {
            setFinancials(data.financials);
        }, (err) => {
            void Axios.error(err);
        })
            .finally(() => {
            setIsLoading(false);
        });
    }, [loan.loan_id]);
    useEffect(() => {
        getFinancials();
    }, [loan.application_id, getFinancials]);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { ref: ref, title: t("application.financials.financials") }), _jsx(ContentWrapper, { children: isLoading && !(financials === null || financials === void 0 ? void 0 : financials.length) ? (_jsx(Loader, {})) : !(financials === null || financials === void 0 ? void 0 : financials.length) ? (_jsx(FinancialsEmpty, {})) : (_jsx(FinancialsTable, { financials: financials, loading: isLoading, pageHeaderHeight: (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().height })) })] }));
};
export default LoanFinancials;
