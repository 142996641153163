export var Accounts;
(function (Accounts) {
    Accounts["revenue"] = "revenue";
    Accounts["inventory_change"] = "inventory_change";
    Accounts["activated_costs"] = "activated_costs";
    Accounts["other_income"] = "other_income";
    Accounts["extraordinary_income"] = "extraordinary_income";
    Accounts["costs_of_materials"] = "costs_of_materials";
    Accounts["external_services"] = "external_services";
    Accounts["personnel_costs"] = "personnel_costs";
    Accounts["depreciations"] = "depreciations";
    Accounts["other_costs"] = "other_costs";
    Accounts["extraordinary_expense"] = "extraordinary_expense";
    Accounts["interest_income"] = "interest_income";
    Accounts["interest_expense"] = "interest_expense";
    Accounts["other_finance_income"] = "other_finance_income";
    Accounts["other_finance_costs"] = "other_finance_costs";
    Accounts["taxes"] = "taxes";
    Accounts["other_taxes"] = "other_taxes";
    Accounts["net_income"] = "net_income";
})(Accounts || (Accounts = {}));
export var Assets;
(function (Assets) {
    Assets["long_term_intangible_assets"] = "long_term_intangible_assets";
    Assets["self_made_intangible_assets"] = "self_made_intangible_assets";
    Assets["long_term_properties"] = "long_term_properties";
    Assets["long_term_securities"] = "long_term_securities";
    Assets["investments_in_related_companies"] = "investments_in_related_companies";
    Assets["goodwill"] = "goodwill";
    Assets["inventories"] = "inventories";
    Assets["accounts_receivable"] = "accounts_receivable";
    Assets["other_current_assets"] = "other_current_assets";
    Assets["loan_to_related_companies"] = "loan_to_related_companies";
    Assets["loan_to_shareholders"] = "loan_to_shareholders";
    Assets["cash"] = "cash";
    Assets["accruals_aktiva"] = "accruals_aktiva";
    Assets["deferred_tax_assets"] = "deferred_tax_assets";
    Assets["losses_not_covered_by_equity"] = "losses_not_covered_by_equity";
    Assets["total_assets_published"] = "total_assets_published";
})(Assets || (Assets = {}));
export var EquityAndLiabilities;
(function (EquityAndLiabilities) {
    EquityAndLiabilities["subscribed_capital"] = "subscribed_capital";
    EquityAndLiabilities["capital_reserve"] = "capital_reserve";
    EquityAndLiabilities["retained_income"] = "retained_income";
    EquityAndLiabilities["profit_carried_forward"] = "profit_carried_forward";
    EquityAndLiabilities["annual_profit"] = "annual_profit";
    EquityAndLiabilities["other_equity_position"] = "other_equity_position";
    EquityAndLiabilities["equity_published"] = "equity_published";
    EquityAndLiabilities["accrued_liabilities_short_term"] = "accrued_liabilities_short_term";
    EquityAndLiabilities["accrued_liabilities_long_term"] = "accrued_liabilities_long_term";
    EquityAndLiabilities["bonds"] = "bonds";
    EquityAndLiabilities["bank_debts_short_term"] = "bank_debts_short_term";
    EquityAndLiabilities["bank_debts_long_term"] = "bank_debts_long_term";
    EquityAndLiabilities["deposits_received"] = "deposits_received";
    EquityAndLiabilities["accounts_payable"] = "accounts_payable";
    EquityAndLiabilities["other_liabilities_short_term"] = "other_liabilities_short_term";
    EquityAndLiabilities["other_debts_short_term"] = "other_debts_short_term";
    EquityAndLiabilities["other_liabilities_long_term"] = "other_liabilities_long_term";
    EquityAndLiabilities["loan_from_related_companies"] = "loan_from_related_companies";
    EquityAndLiabilities["loan_from_shareholders"] = "loan_from_shareholders";
    EquityAndLiabilities["other_debts_long_term"] = "other_debts_long_term";
    EquityAndLiabilities["accruals_passiva"] = "accruals_passiva";
    EquityAndLiabilities["deferred_tax_liabilities"] = "deferred_tax_liabilities";
    EquityAndLiabilities["total_liabilities_published"] = "total_liabilities_published";
})(EquityAndLiabilities || (EquityAndLiabilities = {}));
export var Adjustments;
(function (Adjustments) {
    Adjustments["total_debt_service"] = "total_debt_service";
})(Adjustments || (Adjustments = {}));
export var Sections;
(function (Sections) {
    Sections["accounts"] = "accounts";
    Sections["assets"] = "assets";
    Sections["equity-and-liabilities"] = "equity-and-liabilities";
    Sections["adjustments"] = "adjustments";
})(Sections || (Sections = {}));
export const tableRows = {
    [Sections.accounts]: Object.values(Accounts),
    [Sections.assets]: Object.values(Assets),
    [Sections["equity-and-liabilities"]]: Object.values(EquityAndLiabilities),
    [Sections.adjustments]: Object.values(Adjustments),
};
export const expandable = Object.keys(tableRows);
export const totalsRows = [
    Accounts.net_income,
    Assets.total_assets_published,
    EquityAndLiabilities.equity_published,
    EquityAndLiabilities.total_liabilities_published,
];
export const cellsWithTotalValues = [
    "accounts-net_income",
    "assets-total_assets_published",
    "equity-and-liabilities-equity_published",
    "equity-and-liabilities-total_liabilities_published",
];
