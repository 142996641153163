import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Col, Row, Space, Typography } from "antd";
import styled from "styled-components";
import SignatureViewSignatoryItem from "./SignatureViewSignatoryItem";
const { Text } = Typography;
const ContentWrapper = styled.div `
	padding: 24px;
	min-width: 504px;
`;
const Heading = styled.div `
	background-color: #ffffff;
	padding: 12px 60px;
	min-width: 504px;
	border-top: 1px solid #f5f5f5;
	border-bottom: 1px solid #f5f5f5;
`;
const SignatureView = ({ signatories, onUpdate, isEditable }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(Heading, { children: _jsxs(Row, Object.assign({ gutter: 16 }, { children: [_jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Text, { children: t("application.contract.signatory") }) })), _jsx(Col, Object.assign({ span: 8, style: { textAlign: "center" } }, { children: _jsx(Text, { children: t("application.contract.status") }) })), isEditable && (_jsx(Col, Object.assign({ span: 8, style: { textAlign: "center" } }, { children: _jsx(Text, { children: t("application.signatures.actions") }) })))] })) }), _jsx(ContentWrapper, { children: _jsx(Space, Object.assign({ direction: "vertical", size: 30, style: { width: "100%" } }, { children: signatories.map((signatory) => {
                        return (_jsx(SignatureViewSignatoryItem, { signatory: signatory, isEditable: isEditable, onUpdate: onUpdate }, signatory.signatory_id));
                    }) })) })] }));
};
export default SignatureView;
