import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { Row, Typography } from "antd";
import styled from "styled-components";
import Preview, { Cell } from "@ui/preview/Preview";
const StyledWrapper = styled.div `
	background-color: #f5f5f5;
	position: relative;
	overflow: auto;
	display: flex;
	height: 100%;
`;
const Grow = styled.div `
	flex-grow: 1;
	overflow: auto;
`;
const StaticDrawer = styled(Preview) `
	position: relative;

	.ant-drawer-body {
		padding: 0;
		display: flex;
		flex-direction: column;
	}

	.ant-drawer-content-wrapper {
		position: relative;
		height: 100%;
		box-shadow: none;
		border-left: solid 1px #f0f0f0;
	}
`;
const Header = ({ text, onClose }) => {
    return (_jsxs(Row, Object.assign({ align: "middle", justify: "space-between", style: { width: "100%" } }, { children: [_jsx(Typography.Title, Object.assign({ level: 5, style: { margin: 0 } }, { children: text })), _jsx(CloseOutlined, { onClick: onClose })] })));
};
const PartnerOutlet = ({ activeMenu, setActiveMenu, partner, updatePartner }) => {
    const { t } = useTranslation();
    const onClose = () => setActiveMenu(undefined);
    const sideMenuElements = {
        "partner-information": {
            header: _jsx(Header, { onClose: onClose, text: t("partners.preview.partner-information") }),
            lineData: [],
            children: (_jsx(_Fragment, { children: _jsx(Cell, {}) })),
        },
        "shared-notes": {
            header: _jsx(Header, { onClose: onClose, text: t("partners.preview.partner-information") }),
            children: _jsx("div", {}),
        },
        "activity-log": {
            header: _jsx(Header, { onClose: onClose, text: t("partners.preview.partner-information") }),
            children: _jsx("div", {}),
        },
        "analyst-report": {
            header: _jsx(Header, { onClose: onClose, text: t("partners.preview.partner-information") }),
            children: _jsx("div", {}),
        },
    };
    return (_jsxs(StyledWrapper, { children: [_jsx(Grow, { children: _jsx(Outlet, { context: { partner, updatePartner, setActiveMenu } }) }), _jsx(StaticDrawer, Object.assign({ entityId: partner.partner_id, getContainer: false, visible: !!activeMenu, header: activeMenu ? sideMenuElements[activeMenu].header : undefined, lineData: activeMenu ? sideMenuElements[activeMenu].lineData : undefined }, { children: activeMenu ? sideMenuElements[activeMenu].children : undefined }))] }));
};
export default PartnerOutlet;
