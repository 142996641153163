import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Typography } from "antd";
import styled from "styled-components";
import { Axios } from "src/utils/Axios";
const Wrapper = styled.div `
	padding: 24px;
`;
const LoanAnalystReport = ({ loanId }) => {
    const [feedback, setFeedback] = useState("");
    useEffect(() => {
        Axios.get(`/admin/loans/${loanId}/scoring_feedback`).then(({ data }) => setFeedback(data.scoring_feedback), (error) => Axios.error(error));
    }, [loanId]);
    return (_jsx(Wrapper, { children: _jsx(Typography.Text, { children: feedback }) }));
};
export default LoanAnalystReport;
