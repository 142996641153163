import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Popover, Typography } from "antd";
import styled from "styled-components";
import BaseRepresentativeOwnershipDetails from "@ui/representative-ownership-details/RepresentativeOwnershipDetails";
const { Text } = Typography;
const RepresentativePopoverTitle = styled(Text) `
	display: block;
	margin: 8px 0px;
`;
const RepresentativeOwnershipDetails = styled(BaseRepresentativeOwnershipDetails) `
	min-width: 452px;
`;
const SignatoryDetailsPopover = ({ signatory }) => {
    return (_jsx(Popover, Object.assign({ title: _jsxs(RepresentativePopoverTitle, Object.assign({ strong: true }, { children: [signatory.first_name, " ", signatory.last_name] })), content: _jsx(RepresentativeOwnershipDetails, { representative: signatory }) }, { children: _jsxs(Text, Object.assign({ strong: true }, { children: [signatory.first_name, " ", signatory.last_name] })) })));
};
export default SignatoryDetailsPopover;
