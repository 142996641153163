import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Layout, Spin } from "antd";
import { Content as AntdContent } from "antd/lib/layout/layout";
import styled from "styled-components";
import { FeatureName } from "@teylor-tools/Api";
import MainHeader from "@ui/main-header/MainHeader";
import { Routes } from "src/Routes";
import { useLastMainRoute } from "src/layouts/MenuLayout/MenuLayout";
import { Axios } from "src/utils/Axios";
import CompanyMenu from "./modules/CompanyMenu";
import CompanyOutlet from "./modules/CompanyOutlet";
const Content = styled(AntdContent) `
	flex: 1 1 auto;
	height: 100%;
	overflow: auto;
`;
const StyledLoader = styled.div `
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
`;
const HeaderWrapper = styled.div `
	display: flex;
	gap: 8px;
	align-items: center;
`;
const CompanyLayout = () => {
    const { companyId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState();
    const { lastMainRoute } = useLastMainRoute();
    const { t } = useTranslation();
    const { features } = useSelector((state) => state.configState);
    const boCreateAppEnabled = useMemo(() => {
        var _a;
        return ((_a = features.find((feature) => feature.name === FeatureName.BoUserCreateApplication)) === null || _a === void 0 ? void 0 : _a.value.enabled) || false;
    }, [features]);
    const updateCompany = (params) => {
        if (params) {
            return Axios.patch(`/admin/companies/${companyId}`, params).then(() => {
                void getCompany();
            }, (err) => {
                void Axios.error(err);
            });
        }
        else {
            return getCompany();
        }
    };
    const getCompany = useCallback(() => {
        return Axios.get(`/admin/companies/${companyId}`).then(({ data }) => {
            setCompany(data);
        }, (err) => {
            void Axios.error(err);
        });
    }, [companyId]);
    useEffect(() => {
        setLoading(true);
        getCompany().finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCompany]);
    return (_jsx(_Fragment, { children: _jsxs(Layout, Object.assign({ style: { minHeight: "100vh" } }, { children: [_jsx(MainHeader, { title: _jsx(Spin, Object.assign({ spinning: loading }, { children: company && (_jsxs(HeaderWrapper, { children: [_jsx(LeftOutlined, { onClick: () => {
                                        navigate(lastMainRoute);
                                    } }), company.companyName] })) })), extra: boCreateAppEnabled && (_jsx(Button, Object.assign({ onClick: () => navigate(generatePath(`${Routes.CreateApplication}?comp_id=${companyId}`)), icon: _jsx(PlusOutlined, {}) }, { children: t("company.preview.new-application") }))) }), _jsxs(Layout, Object.assign({ style: { flexDirection: "row" } }, { children: [company && _jsx(CompanyMenu, { companyId: company.companyId }), _jsx(Content, { children: company ? (_jsx(CompanyOutlet, { company: company, updateCompany: updateCompany })) : (_jsx(StyledLoader, { children: _jsx(Spin, { size: "large" }) })) })] }))] })) }));
};
export default CompanyLayout;
