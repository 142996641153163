export const languages = {
	de: {
		code: "de",
		flag: "🇩🇪",
	},
	en: {
		code: "en",
		flag: "🇬🇧",
	},
	fr: {
		code: "fr",
		flag: "🇫🇷",
	},
} as const;

export type Language = keyof typeof languages;
