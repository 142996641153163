import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { Routes } from "src/Routes";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { DocumentCategoryWrapper } from "src/pages/modules/Documents/DocumentCategoryWrapper";
import { categorizeDocuments } from "src/pages/modules/Documents/documents.helpers";
import { finCrTypes } from "src/pages/modules/Documents/documents.types";
import { FinCr } from "src/pages/modules/FinCr/FinCr";
import { getIssues, getOcrFilesById } from "src/pages/modules/FinCr/fincr.common.request";
import { Axios } from "src/utils/Axios";
const ApplicationFinCR = () => {
    const { t } = useTranslation();
    const { application } = useApplication();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [categoriesWithDocs, setCategoriesWithDocs] = useState([]);
    const handleNavigateTo = () => {
        navigate(generatePath(Routes.ApplicationFinancials, {
            applicationId: application.applicationId,
        }));
    };
    const generateDocuments = useCallback(() => {
        setIsLoading(true);
        Axios.get(`admin/applications/${application.applicationId}/documents`, {
            document_type: finCrTypes.join(","),
        })
            .then(({ data }) => getOcrFilesById({
            ocrDocs: data.result,
            path: `admin/applications/${application.applicationId}/ocr/documents`,
        }))
            .then((docs) => getIssues(docs, t("application.fincr.issues-request-error")))
            .then((docs) => categorizeDocuments(docs))
            .then((docs) => setCategoriesWithDocs(docs))
            .catch((err) => void Axios.error(err))
            .finally(() => setIsLoading(false));
    }, [application.applicationId, t]);
    const goToDocuments = useCallback(() => {
        navigate(generatePath(Routes.ApplicationDocuments, {
            applicationId: application.applicationId,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application.applicationId]);
    useEffect(generateDocuments, [generateDocuments]);
    return (_jsx(_Fragment, { children: _jsx(FinCr, Object.assign({ breadcrumbTitle: t("application.financials.financials"), currentPageTitle: t("application.fincr.scan"), actionTitle: t("application.fincr.add_document"), goToDocuments: goToDocuments, handleNavigateTo: handleNavigateTo, isDocs: !!categoriesWithDocs.length, isLoading: isLoading }, { children: categoriesWithDocs.map((category) => (_jsx(DocumentCategoryWrapper, { 
                // @ts-ignore dynamic types names
                title: t(`documents.category.${category.categoryName}.name`), documents: category.types, generateDocuments: generateDocuments }, category.categoryName))) })) }));
};
export default ApplicationFinCR;
