import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin, Tag } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { ExternalOfferStatusEnum } from "@teylor-tools/Api";
import { fullDateTime } from "@teylor-tools/utils/dateFormats";
import { DataCardItem } from "@ui/data-display/DataCard";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { DataCard } from "src/components/DataCard";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { Axios } from "src/utils/Axios";
const Wrapper = styled.div `
	margin: 92px 24px 24px;
`;
const getColor = (status) => {
    switch (status) {
        case ExternalOfferStatusEnum.OFFER:
        case ExternalOfferStatusEnum.ACTIVATED:
            return "green";
        case ExternalOfferStatusEnum.ABANDONED:
        case ExternalOfferStatusEnum.VALIDATION_ERROR:
            return "gold";
        case ExternalOfferStatusEnum.CREATED:
        case ExternalOfferStatusEnum.RISK_CHECK:
            return "blue";
        case ExternalOfferStatusEnum.REJECTED:
            return "red";
        default:
            return "";
    }
};
const ApplicationExternalOffer = () => {
    const { t } = useTranslation();
    const [externalOffers, setExternalOffers] = useState([]);
    const [externalOffersLoading, setExternalOffersLoading] = useState(true);
    const { application: { applicationId }, } = useApplication();
    const getExternalOffers = useCallback(() => {
        setExternalOffersLoading(true);
        void Axios.get(`/admin/applications/${applicationId}/external_offers`)
            .then(({ data }) => setExternalOffers(data))
            .catch((err) => Axios.error(err))
            .finally(() => setExternalOffersLoading(false));
    }, [applicationId]);
    useEffect(getExternalOffers, [getExternalOffers]);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: t("application.external_offer.pricing") }), _jsx(Spin, Object.assign({ spinning: externalOffersLoading }, { children: externalOffers.length ? (_jsx(Wrapper, { children: externalOffers.map((o, index) => (_jsxs(DataCard, { children: [_jsx(DataCardItem, { label: t("application.external_offer.status"), value: _jsx(Tag, Object.assign({ color: getColor(o.status) }, { children: o.status })) }), _jsx(DataCardItem, { label: t("application.external_offer.submitted_at"), value: dayjs(o.created_at).format(fullDateTime) }), _jsx(DataCardItem, { label: t("application.external_offer.last_updated_at"), value: dayjs(o.updated_at).format(fullDateTime) }), _jsx(DataCardItem, { label: t("application.external_offer.external_id"), value: o.external_id }), _jsx(DataCardItem, { label: t("application.external_offer.rejection_reason"), value: o.rejection_reason })] }, index))) })) : null }))] }));
};
export default ApplicationExternalOffer;
