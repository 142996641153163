import { useOutletContext } from "react-router-dom";
export var SideActions;
(function (SideActions) {
    SideActions["loanInformation"] = "partner-information";
    SideActions["sharedNotes"] = "shared-notes";
    SideActions["activityLog"] = "activity-log";
    SideActions["analystReport"] = "analyst-report";
})(SideActions || (SideActions = {}));
export function usePartner() {
    return useOutletContext();
}
