import { ComponentPropsWithoutRef, useState } from "react";

import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";

import { IndustryCodesCreateRequest, IndustryCodesResponse } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";

import IndustryCodesEditModal from "../industry-codes-edit-modal/CompanyIndustryCodesModal";
import { useTranslations } from "../translations/translations";

export const DataCard = styled(Card)`
	margin-bottom: 24px;
	margin-right: 24px;
	min-width: 420px;
	max-width: 720px;
`;

interface Props extends ComponentPropsWithoutRef<"div"> {
	industryCodes?: IndustryCodesResponse;
	loading?: boolean;
	showEditButton?: boolean;
	onSave?: (values: IndustryCodesCreateRequest) => Promise<void>;
}

const IndustryCodesCard = ({ industryCodes, loading, showEditButton, onSave, ...props }: Props) => {
	const { decimalToPercent } = useFormatter();
	const [editIndustryCodes, setEditIndustryCodes] = useState(false);
	const t = useTranslations();

	const columns = [
		{
			dataIndex: "code",
			title: t.industryCodes.industryCode,
			width: 140,
		},
		{
			dataIndex: "description",
			title: t.industryCodes.description,
		},
		{
			dataIndex: "weight",
			title: t.industryCodes.weight,
			render: (weight?: number) => {
				return weight ? decimalToPercent(weight, 0, true) : "-";
			},
			width: 100,
		},
	];

	return (
		<>
			<DataCard
				title={t.industryCodes.industryCodes}
				loading={loading}
				extra={
					showEditButton && (
						<Button type="link" onClick={() => setEditIndustryCodes(true)}>
							<EditOutlined />
						</Button>
					)
				}
				{...props}
			>
				<Table
					columns={columns}
					rowKey="company_industry_code_id"
					dataSource={industryCodes?.codes}
					pagination={false}
				/>
				<Space size="large" style={{ marginTop: 16 }}>
					<Typography.Text type="secondary">
						{t.industryCodes.lastUpdatedOn(
							industryCodes?.created_at ? dayjs(industryCodes.created_at).format(dayMonthYear) : "-"
						)}
					</Typography.Text>

					<Typography.Text type="secondary">
						{t.industryCodes.source(industryCodes?.source || "-")}
					</Typography.Text>

					<Typography.Text type="secondary">
						{t.industryCodes.codeType(
							industryCodes?.kind ? t.industryCodesEditModal.codeEnum[industryCodes?.kind] : "-"
						)}
					</Typography.Text>
				</Space>
			</DataCard>
			{editIndustryCodes && onSave && (
				<IndustryCodesEditModal
					industryCodes={industryCodes}
					onClose={() => setEditIndustryCodes(false)}
					onSave={onSave}
				/>
			)}
		</>
	);
};

export default IndustryCodesCard;
