import { useTranslation } from "react-i18next";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { useIndicativeRate, } from "@teylor-tools/hooks/useIndicativeRates";
import { checkIsIndicative } from "src/utils/isIndicative";
export const useMonthlyRate = (pricing, currency) => {
    const { t } = useTranslation();
    const { currency: formatCurrency } = useFormatter();
    const { indicativeMonthlyRate } = useIndicativeRate();
    const isIndicative = checkIsIndicative(pricing);
    if (isIndicative) {
        const indicativeMR = indicativeMonthlyRate(pricing, currency);
        return indicativeMR ? `${indicativeMR} (${t("loans.indicative")})` : "-";
    }
    return pricing.monthly_payment ? formatCurrency(pricing.monthly_payment, { currency }) : "-";
};
