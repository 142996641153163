import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tabs as AntdTabs } from "antd";
import styled from "styled-components";
import { ApplicationStatuses } from "@teylor-tools/Api";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import ObjectsDetailsTab from "src/pages/applications/subpages/ApplicationObjects/ObjectsDetailsTab";
import ObjectsOrderFormsTab from "src/pages/applications/subpages/ApplicationObjects/ObjectsOrderFormsTab";
const Tabs = styled(AntdTabs) `
	.ant-tabs-nav {
		background-color: #fff;
		padding-left: 24px;
		padding-right: 24px;
		margin: 0;
	}
`;
var Tab;
(function (Tab) {
    Tab["OBJECTS_DETAILS"] = "objects_details";
    Tab["ORDER_FORMS"] = "order_forms";
})(Tab || (Tab = {}));
const ApplicationObjects = () => {
    const { t } = useTranslation();
    const { application: { status }, } = useApplication();
    const isEditable = useMemo(() => ![ApplicationStatuses.PaidOut, ApplicationStatuses.Rejected].includes(status), [status]);
    const tabItems = [
        {
            key: Tab.OBJECTS_DETAILS,
            label: t("application.objects.tab-title-objects"),
            children: _jsx(ObjectsDetailsTab, { isEditable: isEditable }),
        },
        {
            key: Tab.ORDER_FORMS,
            label: t("application.objects.tab-title-order-forms"),
            children: _jsx(ObjectsOrderFormsTab, { isEditable: isEditable }),
        },
    ];
    return _jsx(Tabs, { items: tabItems });
};
export default ApplicationObjects;
