export var ProfileActions;
(function (ProfileActions) {
    ProfileActions["SET_PROFILE"] = "SET_PROFILE";
    ProfileActions["RESET_PROFILE"] = "RESET_PROFILE";
})(ProfileActions || (ProfileActions = {}));
export const setProfile = (profile) => {
    return {
        type: ProfileActions.SET_PROFILE,
        payload: profile,
    };
};
export const resetProfile = () => {
    return {
        type: ProfileActions.RESET_PROFILE,
    };
};
