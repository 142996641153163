import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DownOutlined, DownloadOutlined, FileSearchOutlined, MoreOutlined, RightOutlined, } from "@ant-design/icons";
import { Button, Divider, Dropdown, Space, Spin, Typography, message } from "antd";
import styled from "styled-components";
import { downloadFileFromUrl } from "@teylor-tools/utils/downloadFileFromUrl";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { Axios } from "src/utils/Axios";
import BenchmarkResults from "./BenchmarkResults";
import FinancialSummary from "./FinancialSummary";
import KpiData from "./KpiData";
import Overview from "./Overview";
const Wrapper = styled.div `
	margin-top: 64px;
	padding: 24px;
`;
const DropdownArea = styled.div `
	display: flex;
	align-items: center;
	margin-right: auto;
	cursor: pointer;
`;
const DropdownTitle = styled.span `
	font-weight: 600;
	line-height: 24px;
	text-align: left;
`;
var MenuItem;
(function (MenuItem) {
    MenuItem["OVERVIEW"] = "overview";
    MenuItem["FINANCIAL_SUMMARY"] = "financial-summary";
    MenuItem["BENCHMARK_RESULTS"] = "benchmark-results";
    MenuItem["RISK_STANDARD"] = "risk-standard";
    MenuItem["RISK_STANDARD_MULTI"] = "risk-standard-multi";
    MenuItem["FUTURE_PERFORMANCE"] = "future-performance";
    MenuItem["CASH_FLOW"] = "cash-flow";
    MenuItem["ADDITIONAL_LOAN"] = "additional-loan";
})(MenuItem || (MenuItem = {}));
const AnalysisQuantitative = ({ applicationId, openAnalystReport, apiBasePath }) => {
    const { t } = useTranslation();
    const [currentMenuItem, setCurrentMenuItem] = useState(MenuItem.OVERVIEW);
    const [loading, setLoading] = useState(true);
    const [scores, setScores] = useState();
    const [financials, setFinancials] = useState([]);
    const [kpis, setKpis] = useState([]);
    const [pricing, setPricing] = useState();
    const menuItems = Object.values(MenuItem);
    const getScoringData = useCallback(() => {
        setLoading(true);
        Promise.all([
            Axios.get(`${apiBasePath}/score`),
            Axios.get(`${apiBasePath}/financial_data`),
            Axios.get(`${apiBasePath}/kpis`),
            Axios.get(`${apiBasePath}/pricing`),
        ])
            .then(([scoring, financial, kpi, pricing]) => {
            setScores(scoring.data);
            setFinancials(financial.data.financials);
            setKpis(kpi.data.kpis);
            setPricing(pricing.data);
        })
            .catch((error) => void Axios.error(error))
            .finally(() => setLoading(false));
    }, [apiBasePath]);
    useEffect(() => {
        getScoringData();
    }, [getScoringData]);
    const downloadScoringData = () => {
        try {
            // TODO: change to apiBasePath when BE for loans is ready
            downloadFileFromUrl(`${Axios.apiPath}/admin/applications/${applicationId}/raw_scoring_data?format=attachment`, `scoring-${applicationId}.json`);
        }
        catch (err) {
            void message.error(t("application.quantitative_analysis.download-error"));
        }
    };
    const renderMenuItems = (items) => {
        return items.map((item, idx) => {
            return {
                label: (_jsxs(_Fragment, { children: [_jsx(Typography.Text, Object.assign({ onClick: () => setCurrentMenuItem(item) }, { children: t(`application.quantitative_analysis.${item}`) })), !idx && _jsx(Divider, { style: { margin: "0" } })] })),
                key: item,
            };
        });
    };
    const renderSelectedDropdown = (current) => {
        switch (current) {
            case MenuItem.OVERVIEW:
                return _jsx(Overview, { scores: scores, pricing: pricing, financials: financials, kpis: kpis });
            case MenuItem.FINANCIAL_SUMMARY:
                return (_jsx(FinancialSummary, { title: t("application.quantitative_analysis.financial-summary"), summary: financials }));
            case MenuItem.BENCHMARK_RESULTS:
                return (_jsx(BenchmarkResults, { title: t("application.quantitative_analysis.benchmark-results"), benchmarkData: scores === null || scores === void 0 ? void 0 : scores.benchmark_result }));
            case MenuItem.RISK_STANDARD:
                return (_jsx(KpiData, { title: t("application.quantitative_analysis.risk-standard"), data: kpis.filter((kpi) => kpi.category === "risk_standard") }));
            case MenuItem.RISK_STANDARD_MULTI:
                return (_jsx(KpiData, { title: t("application.quantitative_analysis.risk-standard-multi"), data: kpis.filter((kpi) => kpi.category === "risk_standard_multi") }));
            case MenuItem.FUTURE_PERFORMANCE:
                return (_jsx(KpiData, { title: t("application.quantitative_analysis.future-performance"), data: kpis.filter((kpi) => kpi.category === "future_performance") }));
            case MenuItem.CASH_FLOW:
                return (_jsx(KpiData, { title: t("application.quantitative_analysis.cash-flow"), data: kpis === null || kpis === void 0 ? void 0 : kpis.filter((kpi) => kpi.category === "cash_flow") }));
            case MenuItem.ADDITIONAL_LOAN:
                return (_jsx(KpiData, { title: t("application.quantitative_analysis.additional-loan"), data: kpis.filter((kpi) => kpi.category === "additional_loan") }));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs(SecondaryHeader, Object.assign({ title: t("application.quantitative_analysis.heading") }, { children: [_jsxs(DropdownArea, { children: [_jsx(RightOutlined, { style: { marginInline: "12px", color: "#D9D9D9" } }), _jsx(Dropdown, Object.assign({ menu: {
                                    items: renderMenuItems(menuItems),
                                } }, { children: _jsxs(Space, { children: [_jsx(DropdownTitle, { children: t(`application.quantitative_analysis.${currentMenuItem}`) }), _jsx(DownOutlined, {})] }) }))] }), _jsxs(Space, { children: [_jsx(Button, Object.assign({ type: "primary", icon: _jsx(FileSearchOutlined, {}), onClick: openAnalystReport }, { children: t("application.quantitative_analysis.button") })), _jsx(Dropdown, Object.assign({ menu: {
                                    items: [
                                        {
                                            key: "downloadBtn",
                                            label: t("application.quantitative_analysis.downloadRawData"),
                                            icon: _jsx(DownloadOutlined, {}),
                                            onClick: downloadScoringData,
                                        },
                                    ],
                                } }, { children: _jsx(Button, { children: _jsx(MoreOutlined, {}) }) }))] })] })), _jsx(Wrapper, { children: _jsx(Spin, Object.assign({ spinning: loading }, { children: renderSelectedDropdown(currentMenuItem) })) })] }));
};
export default AnalysisQuantitative;
