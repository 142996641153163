import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Space, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
const FORM_ITEM = "form-item";
const EditableField = ({ displayedValue, value, isEditable, update, children, rules = [], }) => {
    const { t } = useTranslation();
    const [editMode, setEditMode] = useState(false);
    const [form] = useForm();
    const handleSubmit = (v) => {
        update(v[FORM_ITEM]);
        setEditMode(false);
    };
    const handleClose = () => {
        setEditMode(false);
        form.resetFields();
    };
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            form.submit();
        }
        else if (e.key === "Escape") {
            setEditMode(false);
        }
    };
    if (editMode) {
        return (_jsx(Form, Object.assign({ form: form, initialValues: { [FORM_ITEM]: value }, onFinish: handleSubmit, onKeyDown: handleKeyDown }, { children: _jsxs(Space, Object.assign({ align: "start" }, { children: [_jsx(Form.Item, Object.assign({ name: FORM_ITEM, style: { margin: 0 }, rules: rules }, { children: children })), _jsx(Button, { type: "primary", ghost: true, onClick: () => form.submit(), icon: _jsx(CheckOutlined, {}) }), _jsx(Button, { onClick: handleClose, icon: _jsx(CloseOutlined, {}) })] })) })));
    }
    if (displayedValue !== undefined) {
        return (_jsxs(_Fragment, { children: [_jsx(Typography.Text, { children: displayedValue }), isEditable && (_jsx(Button, Object.assign({ type: "link", onClick: () => setEditMode(true) }, { children: _jsx(EditOutlined, {}) })))] }));
    }
    if (!isEditable)
        return _jsx(_Fragment, { children: "-" });
    return (_jsxs(Button, Object.assign({ type: "link", onClick: () => setEditMode(true), style: { padding: 0 } }, { children: [t("common.set"), " ", _jsx(EditOutlined, {})] })));
};
export default EditableField;
