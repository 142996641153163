import { Form, FormItemProps, Input, InputProps } from "antd";
import { isValid } from "iban";
import { RuleObject } from "rc-field-form/lib/interface";

import { useTranslations } from "../../../translations/translations";

interface Props extends FormItemProps {
	inputProps?: InputProps;
}

const IbanFormItem = ({ inputProps, ...props }: Props) => {
	const t = useTranslations();

	const validateIban = (_: RuleObject, iban: string): Promise<void | string> => {
		if (!iban) {
			return Promise.resolve();
		}
		if (!iban.includes("_") && isValid(iban)) {
			return Promise.resolve();
		}
		return Promise.reject(t.ibanInvalid);
	};

	return (
		<Form.Item
			validateFirst
			{...props}
			rules={[
				{
					validator: validateIban,
				},
				...(props.rules || []),
			]}
		>
			<Input {...inputProps} />
		</Form.Item>
	);
};

export default IbanFormItem;
