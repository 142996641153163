import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Popover, Space, Table, Tag } from "antd";
import MainHeader from "@ui/main-header/MainHeader";
import { Routes } from "src/Routes";
import { Axios } from "src/utils/Axios";
import UserPreview from "./UserPreview";
import { getUserStatusColor } from "./helpers";
const SettingsTeamPage = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState();
    const { userId } = useParams();
    const navigate = useNavigate();
    const [selectedUser, setSelectedUser] = useState();
    const [createUser, setCreateUser] = useState(false);
    const getUsers = useCallback(() => {
        return Axios.get(`admin/bo_users`).then(({ data }) => {
            setUsers(data);
            if (userId) {
                const user = data.find((u) => u.id === userId);
                setSelectedUser(user);
            }
        }, (error) => {
            void Axios.error(error);
        });
    }, [userId]);
    useEffect(() => {
        void getUsers().finally(() => setLoading(false));
    }, [getUsers]);
    const columns = [
        {
            dataIndex: "name",
            title: t("settings.team.overview.name"),
        },
        {
            dataIndex: "email",
            title: t("settings.team.overview.email"),
        },
        {
            dataIndex: "roles",
            title: t("settings.team.overview.roles"),
            render: (roles) => {
                if (!roles.length)
                    return "-";
                const rolesToDisplay = roles.slice(0, 2);
                const moreRoles = roles.slice(2);
                return (_jsxs(Space, Object.assign({ size: [0, 8], wrap: true }, { children: [rolesToDisplay.map((role) => (_jsx(Tag, { children: role.name }, role.id))), moreRoles.length > 0 && (_jsx(Popover, Object.assign({ content: _jsx(Space, Object.assign({ direction: "vertical", size: [0, 8], wrap: true }, { children: moreRoles.map((role) => (_jsx(Tag, { children: role.name }, role.id))) })) }, { children: _jsx(Tag, { children: `+ ${moreRoles.length}` }) })))] })));
            },
        },
        {
            dataIndex: "status",
            title: t("settings.team.overview.status"),
            render: (status) => {
                return (_jsx(Tag, Object.assign({ color: getUserStatusColor(status) }, { children: t(`settings.team.overview.status.${status}`) })));
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("settings.team"), extra: _jsx(Button, Object.assign({ icon: _jsx(PlusOutlined, {}), type: "primary", onClick: () => setCreateUser(true) }, { children: t("settings.team.invite") })) }), _jsx("div", Object.assign({ style: { padding: "16px 24px" } }, { children: _jsx(Table, { loading: loading, columns: columns, rowKey: "id", dataSource: users, pagination: false, onRow: (record) => {
                        return {
                            onClick: () => {
                                if (!record.id)
                                    return;
                                navigate(generatePath(Routes.SettingsTeamUser, { userId: record.id }));
                                setSelectedUser(record);
                            },
                        };
                    } }) })), _jsx(UserPreview, { user: selectedUser, createUser: createUser, getUsers: getUsers, onClose: () => {
                    navigate(generatePath(Routes.SettingsTeam));
                    setSelectedUser(undefined);
                    setCreateUser(false);
                } })] }));
};
export default SettingsTeamPage;
