import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Select } from "antd";
import styled from "styled-components";
import { RejectionReason } from "@teylor-tools/Api";
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${({ theme }) => theme.colorWarning} !important;
`;
const { TextArea } = Input;
const RejectionModal = ({ onFinish, onCancel }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const rejectionReasons = [
        RejectionReason.UserRejectedOffer,
        RejectionReason.Unknown,
        RejectionReason.Other,
    ];
    const initialSelection = rejectionReasons[0];
    const [selectedReason, setSelectedReason] = useState(RejectionReason.UserRejectedOffer);
    const handleFinish = (values) => {
        const { rejectionReason, rejectionDescription } = values;
        onFinish(rejectionReason, rejectionDescription);
    };
    return (_jsx(Modal, Object.assign({ title: _jsxs("div", { children: [_jsx(ExclamationCircleOutlinedIcon, { className: "anticon", style: { marginRight: "8px" } }), t("application.are-you-sure")] }), open: true, onCancel: onCancel, okButtonProps: { htmlType: "submit" }, onOk: () => void form.validateFields().then(() => form.submit()), okText: t("application.reject") }, { children: _jsxs(Form, Object.assign({ form: form, onFinish: handleFinish, initialValues: { rejectionReason: initialSelection }, layout: "vertical" }, { children: [_jsx(Form.Item, Object.assign({ name: "rejectionReason", label: t("application.reject_modal.reason.label"), rules: [
                        {
                            required: true,
                            message: t("application_form.field-required"),
                        },
                    ] }, { children: _jsx(Select, { style: { width: 300 }, options: rejectionReasons.map((rejectionReason) => ({
                            value: rejectionReason,
                            label: t(`application.rejection_reasons.${rejectionReason}`),
                        })), onChange: (value) => setSelectedReason(value) }) })), selectedReason === RejectionReason.Other && (_jsx(Form.Item, Object.assign({ name: "rejectionDescription", label: t("application.reject_modal.reason_description.label"), rules: [
                        {
                            required: true,
                            message: t("application_form.field-required"),
                        },
                    ] }, { children: _jsx(TextArea, { maxLength: 700, showCount: true, rows: 6, placeholder: t("application.reject_modal.reason_description.placeholder") }) })))] })) })));
};
export default RejectionModal;
