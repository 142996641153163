import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { Space as AntdSpace, Button, Typography } from "antd";
import styled from "styled-components";
import TasksList from "src/components/tasks/TasksList";
import { SideActions, useLayout } from "src/layouts/LoanLayout/loan.types";
const { Title } = Typography;
const Wrapper = styled.div `
	padding: 24px 0;
`;
const Space = styled(AntdSpace) `
	width: 100%;
	padding-left: 24px;
	padding-right: 24px;
`;
const LoanTasks = ({ tasks }) => {
    const { t } = useTranslation();
    const { setActiveMenu } = useLayout();
    return (_jsx(Wrapper, { children: _jsxs(Space, Object.assign({ direction: "vertical", size: 24 }, { children: [_jsx(Title, Object.assign({ level: 5 }, { children: `${t("tasks.tasks")} (${tasks.length})` })), _jsx(Button, Object.assign({ type: "primary", ghost: true, icon: _jsx(PlusOutlined, {}), onClick: () => setActiveMenu(SideActions.createTask) }, { children: t("tasks.sidebar.create-new-task-btn") })), _jsx(TasksList, { tasks: tasks, showTaskDetailsSidebar: () => setActiveMenu(SideActions.taskDetails) })] })) }));
};
export default LoanTasks;
