import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { TeamOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import styled from "styled-components";
const { Title } = Typography;
const Wrapper = styled.div `
	text-align: center;
	padding-top: 68px;
	padding-bottom: 40px;
`;
const TeamIcon = styled(TeamOutlined) `
	font-size: 48px;
	color: #d9d9d9;
	margin-bottom: 24px;
`;
const CompanyRepresentativeEmpty = () => {
    const { t } = useTranslation();
    return (_jsxs(Wrapper, { children: [_jsx(TeamIcon, {}), _jsx(Title, Object.assign({ level: 5 }, { children: t("loans.company_representatives.no-representatives-title") }))] }));
};
export default CompanyRepresentativeEmpty;
