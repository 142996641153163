import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Checkbox, Form, Input, Tag, Typography } from "antd";
import { MFADeviceType } from "@teylor-tools/Api";
import { login } from "src/store/actions/authActions";
import { Axios } from "src/utils/Axios";
import { MFAViewContent, MFAViewHeader, MFAViewWrapper, MobileIcon, StyledForm, } from "../MFAStyledView";
export const MFALogin = ({ userData, option }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const onFinish = ({ code, remember }) => {
        var _a, _b;
        void Axios.post(`/admin/account/mfa_devices/${(_a = option === null || option === void 0 ? void 0 : option.mfa_device) === null || _a === void 0 ? void 0 : _a.mfa_device_id}/authenticate`, {
            device_type: ((_b = option === null || option === void 0 ? void 0 : option.mfa_device) === null || _b === void 0 ? void 0 : _b.mfa_device_type)
                ? option === null || option === void 0 ? void 0 : option.mfa_device.mfa_device_type
                : MFADeviceType.Totp,
            code,
            remember,
        })
            .then(() => dispatch(login()))
            .catch((error) => void Axios.error(error));
    };
    return (_jsxs(MFAViewWrapper, { children: [_jsxs(MFAViewHeader, { children: [_jsx(Typography.Title, Object.assign({ level: 3 }, { children: t("mfa.login.title") })), _jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: t("mfa.login.title-secondary") })), (userData === null || userData === void 0 ? void 0 : userData.email) && (_jsx(Tag, Object.assign({ color: "blue", style: { marginTop: "20px" } }, { children: userData === null || userData === void 0 ? void 0 : userData.email })))] }), _jsxs(MFAViewContent, { children: [_jsx(MobileIcon, {}), _jsxs(StyledForm, Object.assign({ onFinish: onFinish, layout: "vertical", initialValues: { remember: true } }, { children: [_jsxs("div", Object.assign({ style: { marginBottom: "24px" } }, { children: [_jsx(Form.Item, Object.assign({ label: t("mfa.common.input.label"), name: "code", rules: [
                                            {
                                                required: true,
                                                message: t("mfa.common.input.error"),
                                            },
                                        ] }, { children: _jsx(Input, {}) })), _jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: t("mfa.common.input.secondary.label") }))] })), _jsx(Form.Item, Object.assign({ name: "remember", valuePropName: "checked", className: "ant-form-item-except-input" }, { children: _jsx(Checkbox, { children: t("mfa.common.checkbox.label") }) })), _jsx(Form.Item, Object.assign({ className: "ant-form-item-except-input" }, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit" }, { children: t("mfa.common.btn.label") })) }))] }))] })] }));
};
