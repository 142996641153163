import styled, { css } from "styled-components";
export const Cell = styled.td `
	background: #ffffff;
	${({ editable, editing, theme }) => editable &&
    css `
			cursor: pointer;
			border: solid 1px ${editing ? "transparent" : "rgba(0, 0, 0, 0.06)"} !important;
			padding: ${editing ? "0px" : "8px 16px"} !important;
			${editing && "box-shadow: 0px 0px 4px rgba(24, 144, 255, 0.5)"};

			&:hover {
				border: solid 1px ${editing ? "transparent" : theme.colorPrimary} !important;
			}
		`};
`;
export const EditableCellValueWrapper = styled.div `
	height: 22px;
	cursor: pointer;
`;
