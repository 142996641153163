import { Avatar, Space } from "antd";
import styled from "styled-components";
export const RejectedButtons = styled(Space) `
	width: 100%;
	margin-top: 10px;
`;
export const RejectedWrapper = styled(Space) `
	width: 100%;
	max-width: 600px;
	padding: 40px;
	margin-top: 60px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	background: #fff;
`;
export const WrappedButtons = styled.div `
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 48px;
`;
export const FormContent = styled.div `
	margin: 16px 0;
	width: 100%;
	max-width: 600px;
	background: #fff;
	.ant-form-item {
		margin-bottom: 12px;
	}
`;
export const StyledAvatar = styled(Avatar) `
	${({ theme, $active, $done }) => `
        color: ${$active ? "#FFF" : $done ? theme.colorPrimary : "rgba(0, 0, 0, 0.25)"};
        background: ${$active ? theme.colorPrimary : "#FFF"};
        border: ${$active ? "none" : `1px solid ${$done ? theme.colorPrimary : "rgba(0, 0, 0, 0.15)"}`};
    `}
`;
