import { useEffect, useState } from "react";
import { Axios } from "src/utils/Axios";
export const useTaskUsers = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        void Axios.get("admin/bo_user_groups")
            .then(({ data }) => {
            // BE returns always only one group
            data[0].bo_users && setUsers(data[0].bo_users);
        }, (err) => void Axios.error(err))
            .finally(() => setLoading(false));
    }, []);
    return [users, loading];
};
