import { jsx as _jsx } from "react/jsx-runtime";
import { ApplicationStatuses } from "@teylor-tools/Api";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import DocumentsTab from "src/pages/modules/Documents/DocumentsTab";
import { Axios } from "src/utils/Axios";
const ApplicationDocuments = () => {
    const { application: { applicationId, status }, updatePendingItems, } = useApplication();
    const isEditable = ![ApplicationStatuses.PaidOut, ApplicationStatuses.Rejected].includes(status);
    return (_jsx(DocumentsTab, { apiBasePath: `admin/applications/${applicationId}`, 
        // @ts-ignore some mismatch between types
        apiGetDocsRequest: () => Axios.get(`admin/applications/${applicationId}/documents`), apiGetArchivePath: `admin/applications/${applicationId}/files_archive`, onUpdate: updatePendingItems, allowUpload: isEditable, allowDelete: isEditable, editMode: isEditable ? "full" : "not_allowed" }));
};
export default ApplicationDocuments;
