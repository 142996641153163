import { jsx as _jsx } from "react/jsx-runtime";
import { InputNumber as AntdInputNumber } from "antd";
import styled from "styled-components";
import { useLocale } from "@teylor-tools/utils/LocaleProvider";
const InputNumber = styled(AntdInputNumber) `
	width: 100%;
	height: 38px;
	border-radius: 0;

	.ant-input-number-input-wrap {
		padding-right: 6px;

		> input.ant-input-number-input {
			text-align: right;
		}
	}
`;
export function parseNumber(value, locale) {
    const group = new Intl.NumberFormat(locale).format(4444).replace(/\d/g, "");
    const decimal = new Intl.NumberFormat(locale).format(4.44).replace(/\d/g, "");
    let reversedValue = value;
    if (group) {
        reversedValue = value.replace(new RegExp("\\" + group, "g"), "");
    }
    reversedValue = reversedValue.replace(new RegExp("\\" + decimal, "g"), ".");
    const parsedValue = parseFloat(reversedValue);
    return Number.isNaN(parsedValue) ? 0 : parsedValue === 0 ? value : parsedValue;
}
export const FinancialsInput = (props) => {
    const locale = useLocale();
    const numberFormatter = new Intl.NumberFormat(locale, {
        maximumFractionDigits: 2,
    });
    const onKeyPress = (evt) => {
        // don't allow to press key other than the following kit
        const allowedKeys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ".", ",", "-"];
        if (!allowedKeys.includes(evt.nativeEvent.key)) {
            evt.preventDefault();
            evt.stopPropagation();
        }
    };
    return (_jsx(InputNumber, Object.assign({ formatter: (value) => {
            if (!value)
                return "";
            return numberFormatter.format(Number(value)).toString();
        }, parser: (value) => {
            if (!value)
                return "";
            return parseNumber(value, locale).toString();
        }, controls: false, onKeyPress: onKeyPress }, props)));
};
FinancialsInput.displayName = "FinancialsInput";
