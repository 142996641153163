import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker, Form, Modal, Select, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { rawDateFormat } from "@teylor-tools/utils/dateFormats";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import { useLoan } from "src/layouts/LoanLayout/loan.types";
import { Axios } from "src/utils/Axios";
const { Text } = Typography;
const PrepaymentModal = ({ open, close, updatePayments }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const { loan } = useLoan();
    const { currency } = useFormatter();
    const requiredRule = {
        required: true,
        message: t("application_form.field-required"),
    };
    const createPrepayment = (values) => {
        setIsLoading(true);
        Axios.post(`/admin/loans/${loan.loan_id}/prepayments`, Object.assign(Object.assign({}, values), { date: dayjs(values.date).format(rawDateFormat) }))
            .then(() => {
            close();
            form.resetFields();
            updatePayments();
        }, (err) => Axios.error(err, t("common.request-failed")))
            .finally(() => setIsLoading(false));
    };
    const validateAmount = (_, value) => {
        if (value > 0) {
            return Promise.resolve();
        }
        return Promise.reject(t("application_form.errors.fieldMustBeMin", {
            min: currency(0.01, { currency: loan.currency }),
        }));
    };
    return (_jsx(Modal, Object.assign({ open: open, onCancel: () => {
            close();
            form.resetFields();
        }, title: t("loans.payments.create-prepayment.title"), okText: t("loans.payments.create-prepayment.create-btn"), okButtonProps: { disabled: isLoading, htmlType: "submit" }, onOk: () => void form.validateFields().then(() => form.submit()), maskClosable: false }, { children: _jsxs(Form, Object.assign({ form: form, onFinish: createPrepayment, layout: "vertical", disabled: isLoading, initialValues: {
                amount: "",
                amortization_type: "principal_only",
            } }, { children: [_jsx(Form.Item, Object.assign({ label: t("loans.payments.create-prepayment.amount"), name: "amount", rules: [requiredRule, { validator: validateAmount }], validateFirst: true }, { children: _jsx(CurrencyInput, { style: { width: "100%" }, currency: loan.currency }) })), _jsx(Form.Item, Object.assign({ label: t("loans.payments.create-prepayment.date"), name: "date", rules: [requiredRule] }, { children: _jsx(DatePicker, { style: { width: "100%" } }) })), _jsx(Form.Item, Object.assign({ label: t("loans.payments.create-prepayment.amortization-type"), name: "amortization_type", rules: [requiredRule] }, { children: _jsx(Select, { options: [
                            {
                                value: "principal_only",
                                label: t("loans.payments.create-prepayment.amortization-types.principal_only"),
                            },
                        ] }) })), _jsx(Form.Item, Object.assign({ label: _jsxs(_Fragment, { children: [_jsx(Text, { children: t("loans.payments.create-prepayment.notes") }), _jsxs(Text, Object.assign({ type: "secondary", style: { fontSize: 12, marginLeft: 4 } }, { children: ["(", t("common.optional"), ")"] }))] }), name: "notes" }, { children: _jsx(TextArea, { rows: 6 }) }))] })) })));
};
export default PrepaymentModal;
