var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Modal, Space, Spin, Tooltip, Typography, message } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import styled from "styled-components";
import PercentInput from "@ui/form/inputs/percent-input/PercentInput";
import NoObjectsCard from "@ui/object-financing/NoObjectsCard";
import ObjectCard from "@ui/object-financing/ObjectCard";
import ObjectForm, { MAX_NUMBER_OF_OBJECTS, } from "@ui/object-financing/ObjectForm";
import { prepareObjectFormFromResponse, prepareRequestObjectFromForm, } from "@ui/object-financing/objects.utils";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { Axios } from "src/utils/Axios";
const { Text, Title } = Typography;
const Wrapper = styled.div `
	margin: 0 24px 124px 24px;
	translate: 0 94px;
`;
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${(props) => props.theme.colorError};
	margin-top: 2px;
	font-size: 22px;
`;
const ObjectsWrapper = styled.div `
	display: grid;
	gap: 16px;
	grid-template-columns: repeat(auto-fill, minmax(460px, auto));
`;
const ObjectsDetailsTab = ({ isEditable }) => {
    const { t } = useTranslation();
    const [objects, setObjects] = useState([]);
    const [objectsLoading, setObjectsLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [objectToDelete, setObjectToDelete] = useState();
    const [objectToEdit, setObjectToEdit] = useState();
    const [createOrderFormObject, setCreateOrderFormObject] = useState();
    const [isModalSendingData, setIsModalSendingData] = useState(false);
    const { countries } = useSelector((state) => state.configState);
    const [addForm] = useForm();
    const [editForm] = useForm();
    const [createOrderForm] = useForm();
    const { application: { applicationId, currency }, updatePendingItems, } = useApplication();
    const defaultCountry = useMemo(() => { var _a; return (_a = countries[0]) === null || _a === void 0 ? void 0 : _a.code; }, [countries]);
    const isAddObjectBtnDisabled = useMemo(() => objects.length >= MAX_NUMBER_OF_OBJECTS, [objects]);
    const getObjects = useCallback(() => {
        setObjectsLoading(true);
        void Axios.get(`/admin/applications/${applicationId}/finance_objects`)
            .then(({ data }) => setObjects(data))
            .catch((err) => Axios.error(err))
            .finally(() => setObjectsLoading(false));
    }, [applicationId]);
    const onEdit = (obj) => {
        setObjectToEdit(obj);
        editForm.setFieldsValue(prepareObjectFormFromResponse(obj));
    };
    const addObject = (form) => {
        setIsModalSendingData(true);
        void Axios.post(`/admin/applications/${applicationId}/finance_objects`, prepareRequestObjectFromForm(form))
            .then(() => {
            void message.success(t("application.objects.object-add-success"));
            setShowAddModal(false);
            getObjects();
        })
            .catch((err) => void Axios.error(err, t("application.objects.object-add-error")))
            .finally(() => {
            updatePendingItems();
            setIsModalSendingData(false);
        });
    };
    const deleteObject = () => {
        if (!objectToDelete)
            return;
        setIsModalSendingData(true);
        void Axios.delete(`/admin/applications/${applicationId}/finance_objects/${objectToDelete.id}`)
            .then(() => {
            void message.success(t("application.objects.object-delete-success"));
            getObjects();
        }, (err) => Axios.error(err, t("application.objects.object-delete-error")))
            .finally(() => {
            setObjectToDelete(undefined);
            setIsModalSendingData(false);
        });
    };
    const editObject = (form) => {
        setIsModalSendingData(true);
        void Axios.put(`/admin/applications/${applicationId}/finance_objects/${objectToEdit === null || objectToEdit === void 0 ? void 0 : objectToEdit.finance_object_id}`, prepareRequestObjectFromForm(form))
            .then(() => {
            void message.success(t(`application.objects.object-edit-success`));
            getObjects();
            setObjectToEdit(undefined);
        })
            .catch((err) => void Axios.error(err, t("application.objects.object-edit-error")))
            .finally(() => {
            updatePendingItems();
            setIsModalSendingData(false);
        });
    };
    const handleCreateOrderForm = (form) => __awaiter(void 0, void 0, void 0, function* () {
        if (!createOrderFormObject)
            return;
        setIsModalSendingData(true);
        try {
            if (form.commission !== undefined) {
                yield Axios.put(`/admin/applications/${applicationId}/finance_objects/${createOrderFormObject.finance_object_id}`, Object.assign(Object.assign({}, prepareRequestObjectFromForm(createOrderFormObject)), { commission: form.commission / 100 }));
            }
            yield Axios.post(`/admin/applications/${applicationId}/finance_objects/${createOrderFormObject.finance_object_id}/generate_order_form`, { requested_offer_details: form.details || "" });
            void message.success(t("application.objects.create-order-form-success"));
            setCreateOrderFormObject(undefined);
        }
        catch (err) {
            void Axios.error(err, t("application.objects.create-order-form-error"));
        }
        finally {
            updatePendingItems();
            setIsModalSendingData(false);
        }
    });
    useEffect(getObjects, [getObjects]);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, Object.assign({ title: t("application.objects.tab-title-objects") }, { children: isEditable && (_jsx(Tooltip, Object.assign({ title: isAddObjectBtnDisabled &&
                        t("application.objects.add-object-btn-max-objects-tooltip", {
                            max: MAX_NUMBER_OF_OBJECTS,
                        }) }, { children: _jsx(Button, Object.assign({ type: "primary", icon: _jsx(PlusOutlined, {}), disabled: isAddObjectBtnDisabled, onClick: () => setShowAddModal(true) }, { children: t("application.objects.add-object-btn") })) }))) })), _jsx(Spin, Object.assign({ spinning: objectsLoading }, { children: objects.length ? (_jsx(Wrapper, { children: _jsx(ObjectsWrapper, { children: objects.map((obj, index) => (_jsx(ObjectCard, { object: obj, index: index, isEditable: isEditable, onEdit: onEdit, onDelete: () => setObjectToDelete({ id: obj.finance_object_id, displayedIndex: index + 1 }), currency: currency, handleCreateOrderForm: setCreateOrderFormObject }, obj.finance_object_id))) }) })) : (_jsxs(Wrapper, { children: [_jsx(Alert, { message: t("application.objects.complete-section-alert"), type: "info", showIcon: true }), _jsx(NoObjectsCard, { onClick: () => setShowAddModal(true), translations: {
                                title: t("application.objects.no-objects-title"),
                                subtitle: t("application.objects.no-objects-text"),
                                addObject: t("application.objects.no-objects-btn"),
                            } })] })) })), _jsx(Modal, Object.assign({ title: t("application.objects.add-modal-title"), open: showAddModal, onCancel: () => setShowAddModal(false), cancelText: t("common.cancel"), okText: t("common.save"), okButtonProps: {
                    htmlType: "submit",
                    disabled: isModalSendingData,
                    loading: isModalSendingData,
                }, cancelButtonProps: {
                    disabled: isModalSendingData,
                }, onOk: () => void addForm.validateFields().then(() => addForm.submit()), width: 650, maskClosable: false, destroyOnClose: true }, { children: _jsx(Form, Object.assign({ form: addForm, layout: "vertical", onFinish: addObject, preserve: false, disabled: isModalSendingData, initialValues: { merchant_country: defaultCountry } }, { children: _jsx(ObjectForm, { countries: countries }) })) })), _jsx(Modal, Object.assign({ open: !!objectToDelete, okText: t("common.delete"), okButtonProps: {
                    htmlType: "submit",
                    disabled: isModalSendingData,
                    loading: isModalSendingData,
                    danger: true,
                }, cancelButtonProps: {
                    disabled: isModalSendingData,
                }, onOk: deleteObject, onCancel: () => setObjectToDelete(undefined), destroyOnClose: true, closable: false, width: 400 }, { children: _jsxs(Space, Object.assign({ size: 16, align: "start" }, { children: [_jsx(ExclamationCircleOutlinedIcon, {}), _jsxs("div", { children: [_jsx(Title, Object.assign({ level: 5 }, { children: t("application.objects.delete-modal-title") })), _jsx(Text, { children: _jsx(Trans, Object.assign({ i18nKey: "application.objects.delete-modal-content" }, { children: { idx: objectToDelete === null || objectToDelete === void 0 ? void 0 : objectToDelete.displayedIndex } })) })] })] })) })), _jsx(Modal, Object.assign({ title: t("application.objects.edit-modal-title"), open: !!objectToEdit, onCancel: () => setObjectToEdit(undefined), cancelText: t("common.cancel"), okText: t("common.save"), okButtonProps: {
                    htmlType: "submit",
                    disabled: isModalSendingData,
                    loading: isModalSendingData,
                }, cancelButtonProps: {
                    disabled: isModalSendingData,
                }, onOk: () => void editForm.validateFields().then(() => editForm.submit()), width: 650, maskClosable: false, destroyOnClose: true }, { children: _jsx(Form, Object.assign({ form: editForm, layout: "vertical", onFinish: editObject, preserve: false, disabled: isModalSendingData, initialValues: { merchant_country: defaultCountry } }, { children: _jsx(ObjectForm, { countries: countries }) })) })), _jsx(Modal, Object.assign({ title: t("application.objects.create-order-form.modal-title"), open: !!createOrderFormObject, okText: t("application.objects.create-order-form.modal-ok-btn"), cancelText: t("common.cancel"), onCancel: () => setCreateOrderFormObject(undefined), destroyOnClose: true, width: 650, maskClosable: false, okButtonProps: {
                    htmlType: "submit",
                    disabled: isModalSendingData,
                    loading: isModalSendingData,
                }, cancelButtonProps: {
                    disabled: isModalSendingData,
                }, onOk: () => void createOrderForm.validateFields().then(() => createOrderForm.submit()) }, { children: _jsxs(Form, Object.assign({ form: createOrderForm, onFinish: (v) => void handleCreateOrderForm(v), layout: "vertical", preserve: false, disabled: isModalSendingData, initialValues: { commission: 0 } }, { children: [_jsx(Form.Item, Object.assign({ name: "commission", label: t("application.objects.create-order-form.commission") }, { children: _jsx(PercentInput, { style: { width: "30%" }, max: 100, min: 0 }) })), _jsx(Form.Item, Object.assign({ name: "details", label: _jsxs(_Fragment, { children: [_jsx(Text, Object.assign({ style: { marginRight: 4 } }, { children: t("application.objects.create-order-form.offer-details") })), _jsxs(Text, Object.assign({ type: "secondary" }, { children: ["(", t("common.optional"), ")"] }))] }) }, { children: _jsx(TextArea, { rows: 5 }) }))] })) }))] }));
};
export default ObjectsDetailsTab;
