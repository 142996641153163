import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import dayjs from "dayjs";
import { FinancialStatementType } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { useLoan } from "src/layouts/LoanLayout/loan.types";
import { DownIcon, HeaderCell, Table, UpIcon } from "src/pages/modules/Financials/FinancialStyles";
import { Sections, tableRows, totalsRows } from "src/pages/modules/Financials/financialsTableRows";
const expandable = Object.values(Sections);
export const FinancialsTable = ({ financials, loading, pageHeaderHeight, }) => {
    const { t } = useTranslation();
    const { loanPageHeaderHeight } = useLoan();
    const ref = useRef(null);
    const [tableHeaderHeight, setTableHeaderHeight] = useState();
    const { localizedNumber } = useFormatter();
    const headingsHeight = useMemo(() => (tableHeaderHeight || 0) + (pageHeaderHeight || 0) + (loanPageHeaderHeight || 0), [tableHeaderHeight, pageHeaderHeight, loanPageHeaderHeight]);
    const [scrollY, setScrollY] = useState(window.innerHeight - headingsHeight);
    useEffect(() => {
        var _a, _b;
        if (!(ref === null || ref === void 0 ? void 0 : ref.current))
            return;
        setTableHeaderHeight((_b = (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.querySelector(".ant-table-header")) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect().height);
    }, [ref]);
    // This properly sets the scroll behavior of the table
    useEffect(() => {
        function handleResize() {
            setScrollY(window.innerHeight - headingsHeight);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [headingsHeight]);
    const data = expandable.map((section) => {
        return {
            key: section,
            section: t(`application.financials.${section}`),
            children: tableRows[section].map((row) => {
                const isTotalRow = totalsRows.some((totalRow) => totalRow === row);
                const data = financials.reduce((acc, fianacialStatement) => {
                    acc[fianacialStatement.financial_data_id] = isTotalRow
                        ? // @ts-ignore take a look at it if you work on this file
                            fianacialStatement.summary_data && fianacialStatement.summary_data[row]
                        : // @ts-ignore take a look at it if you work on this file
                            fianacialStatement.input_data[row];
                    return acc;
                }, {});
                return Object.assign({ key: `${section}-${row}`, inputDataKey: row, isBold: isTotalRow, section: t(`application.financials.${row}`) }, data);
            }),
        };
    });
    const columns = [
        {
            dataIndex: "section",
            width: 330,
            align: "left",
            title: "",
        },
        ...financials.map((financialStatement) => {
            return {
                dataIndex: financialStatement.financial_data_id,
                width: 210,
                align: "right",
                render: (value) => {
                    if (!value)
                        return "";
                    return _jsx("span", { children: localizedNumber({ value }) });
                },
                title: () => {
                    return (_jsx(HeaderCell, { children: _jsx(Typography.Text, { children: financialStatement.statement_type === FinancialStatementType.InterimFinancials
                                ? `${t("application.financials.interim")} ${dayjs(financialStatement.financials_date).format("YYYY")}.${dayjs(financialStatement.financials_date).format("MM")}`
                                : `${t("application.financials.annual")} ${dayjs(financialStatement.financials_date).format("YYYY")}` }) }));
                },
            };
        }),
        { dataIndex: "empty" }, // one extra empty column to properly handle the layout
    ];
    return (_jsx(Table, { ref: ref, loading: loading, columns: columns, dataSource: data, bordered: true, scroll: { y: scrollY }, pagination: { hideOnSinglePage: true }, expandable: {
            indentSize: 0,
            defaultExpandAllRows: true,
            expandIcon: ({ expanded, onExpand, record }) => {
                // @ts-ignore take a look at it if you work on this file
                if (!expandable.some((section) => section === record["key"]))
                    return null;
                return expanded ? (_jsx(UpIcon, { onClick: (e) => onExpand(record, e) })) : (_jsx(DownIcon, { onClick: (e) => onExpand(record, e) }));
            },
        } }));
};
