import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, Outlet } from "react-router-dom";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import styled, { useTheme } from "styled-components";
import { Routes } from "src/Routes";
const Container = styled.div `
	width: 567px;
	padding: 40px 64px;
	background: #fff;
	border-radius: 8px;
	overflow-y: auto;
	max-height: 100%;
`;
const StyledContent = styled(Content) `
	display: flex;
	align-items: center;
	justify-content: center;
`;
const Wrapper = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: #fbfbfb;
`;
const LogoWrapper = styled.div `
	position: absolute;
	top: 55px;
	left: 55px;
`;
const AuthLayout = () => {
    const theme = useTheme();
    return (_jsx(Layout, { children: _jsx(StyledContent, { children: _jsxs(Wrapper, { children: [theme.logo_web_path && (_jsx(LogoWrapper, { children: _jsx(Link, Object.assign({ to: Routes.Index }, { children: _jsx("img", { src: theme.logo_web_path, alt: "Logo", width: 150 }) })) })), _jsx(Container, { children: _jsx(Outlet, {}) })] }) }) }));
};
export default AuthLayout;
