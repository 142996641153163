var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { generatePath } from "react-router-dom";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Spin, Tag, Typography } from "antd";
import styled from "styled-components";
import { FeatureName } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import Preview, { Cell, Heading, StyledListRow } from "@ui/preview/Preview";
import { Routes } from "src/Routes";
import TimePassed from "src/components/ui/TimePassed";
import ApplicationStatus from "src/components/ui/statuses/ApplicationStatus";
import { Axios } from "src/utils/Axios";
const StyledRow = styled(Row) `
	align-items: center;
	justify-content: space-between;

	&:hover {
		cursor: pointer;
		background-color: #fafafa;
	}
`;
const PreviewHeader = styled.div `
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;
const CompanyPreview = ({ company, onClose }) => {
    const [usersLoading, setUsersLoading] = useState(true);
    const [appsLoading, setAppsLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const { t } = useTranslation();
    const [applications, setApplications] = useState([]);
    const { currency } = useFormatter();
    const navigate = useNavigate();
    const { features } = useSelector((state) => state.configState);
    const boCreateAppEnabled = useMemo(() => {
        var _a;
        return ((_a = features.find((feature) => feature.name === FeatureName.BoUserCreateApplication)) === null || _a === void 0 ? void 0 : _a.value.enabled) || false;
    }, [features]);
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!(company === null || company === void 0 ? void 0 : company.company_name) || !(company === null || company === void 0 ? void 0 : company.company_postcode) || !(company === null || company === void 0 ? void 0 : company.company_id)) {
                return;
            }
            try {
                setUsersLoading(true);
                setAppsLoading(true);
                const [usersResponse, applicationsResponse] = yield Promise.allSettled([
                    Axios.get(`/admin/companies/${company.company_name}/${company.company_postcode}/users`),
                    Axios.get(`/admin/companies/${company.company_id}/applications`, {
                        page: 1,
                        page_size: 9999,
                    }),
                ]);
                // Check if both promises are fulfilled (resolved) and set the data for users and applications
                if (usersResponse.status === "fulfilled") {
                    setUsers(usersResponse.value.data.companyUsers);
                }
                else {
                    void Axios.error(usersResponse.reason, t("companies.errors.fetch-users"));
                }
                if (applicationsResponse.status === "fulfilled") {
                    setApplications(applicationsResponse.value.data.result);
                }
                else {
                    void Axios.error(applicationsResponse.reason, t("companies.errors.fetch-apps"));
                }
            }
            catch (error) {
                void Axios.error(error);
            }
            finally {
                setUsersLoading(false);
                setAppsLoading(false);
            }
        });
        void fetchData();
    }, [company === null || company === void 0 ? void 0 : company.company_id, company === null || company === void 0 ? void 0 : company.company_name, company === null || company === void 0 ? void 0 : company.company_postcode, t]);
    const handleNavigateToApplication = (id) => () => {
        navigate(generatePath(Routes.Application, {
            applicationId: id,
        }));
    };
    const handleCreateNewApplication = () => {
        const pathToAppPageWithQuery = generatePath(`${Routes.CreateApplication}?comp_id=${company === null || company === void 0 ? void 0 : company.company_id}`);
        navigate(pathToAppPageWithQuery);
    };
    const lineData = useMemo(() => {
        if (!company)
            return [];
        return [
            {
                label: t("companies.preview.hr-number"),
                value: company.hr_number ? company.hr_number : "-",
            },
            {
                label: t("companies.preview.founded"),
                value: company.foundation_date ? _jsx(TimePassed, { date: company.foundation_date }) : "-",
            },
            {
                label: t("companies.preview.address"),
                value: `${company.company_street} ${company.company_house_number}, ${company.company_postcode}`,
            },
            {
                label: t("companies.preview.created"),
                value: company.created_at ? _jsx(TimePassed, { date: company.created_at }) : "-",
            },
        ];
    }, [company, t]);
    return (_jsxs(Preview, Object.assign({ entityId: (company === null || company === void 0 ? void 0 : company.company_id) || "", visible: !!company, header: company && (_jsx(PreviewHeader, { children: _jsxs(Row, Object.assign({ style: { width: "100%" }, align: "middle", justify: "space-between" }, { children: [_jsx(Col, { children: _jsx(Typography.Title, Object.assign({ level: 5, style: { margin: 0 } }, { children: company.company_name })) }), _jsx(Col, { children: _jsxs(Space, { children: [_jsx(Button, Object.assign({ type: "primary", onClick: () => navigate(generatePath(Routes.Company, {
                                        companyId: company.company_id,
                                    })) }, { children: t("common.view") })), _jsx(Button, { onClick: onClose, icon: _jsx(CloseOutlined, {}) })] }) })] })) })), lineData: lineData }, { children: [_jsxs(Cell, { children: [_jsxs(Heading, Object.assign({ style: { display: "flex", justifyContent: "space-between" } }, { children: [_jsx(Typography.Text, { children: t("company.applications.count", { count: applications.length }) }), boCreateAppEnabled && (_jsx(Button, Object.assign({ type: "link", size: "small", icon: _jsx(PlusOutlined, {}), onClick: handleCreateNewApplication }, { children: t("company.preview.new-application") })))] })), _jsx(Spin, Object.assign({ spinning: appsLoading }, { children: applications.map((application) => {
                            return (_jsx(StyledListRow, { children: _jsxs(StyledRow, Object.assign({ onClick: handleNavigateToApplication(application.application_id) }, { children: [_jsx(Col, { children: application.company_name }), _jsxs(Col, { children: [_jsx(Tag, { children: currency(application.amount, { currency: application.currency }) }), _jsx(ApplicationStatus, { value: application.status, label: t(`application.status.${application.status}`), closable: false, disabled: false, short: true })] })] })) }, application.application_id));
                        }) }))] }), _jsxs(Cell, { children: [_jsx(Heading, { children: t("companies.preview.people", { amount: users.length }) }), _jsx(Spin, Object.assign({ spinning: usersLoading }, { children: users.map((user, index) => {
                            return (_jsx(StyledListRow, { children: _jsx(Col, { children: user.email }) }, `${user.email}_${index}`));
                        }) }))] })] })));
};
export default CompanyPreview;
