import { FC, useMemo } from "react";

import { Avatar, AvatarProps } from "antd";
import styled from "styled-components";

export interface Props extends AvatarProps {
	name: string;
}

const StyledAvatar = styled(Avatar)`
	&.ant-avatar-sm {
		font-size: 10px;
	}
`;

interface ColorSet {
	r: number;
	g: number;
	b: number;
}

const AvatarBadge: FC<Props> = ({ name, ...props }: Props) => {
	const initials = useMemo(() => {
		return (name || "")
			.split(" ")
			.map((word) => {
				return word.charAt(0);
			})
			.join("")
			.toUpperCase();
	}, [name]);

	const avatarColor: ColorSet = useMemo(() => {
		const getAlphabetPosition = (letter: string): number => letter.charCodeAt(0) - 64;
		const getColorIndex = (letter: string): number =>
			Math.round(getAlphabetPosition(letter.toUpperCase()) * 11);
		const blueIndex =
			((getAlphabetPosition(initials.charAt(0)) + getAlphabetPosition(initials.charAt(1))) / 2) *
			11;

		return {
			r: getColorIndex(initials.charAt(0)),
			g: getColorIndex(initials.charAt(1)),
			b: blueIndex > 255 ? 255 : blueIndex,
		};
	}, [initials]);

	const avatarColorIndex = useMemo(() => {
		const { r, g, b } = avatarColor;
		return `rgb(${r}, ${g}, ${b})`;
	}, [avatarColor]);

	const textColor = useMemo(() => {
		const { r, g, b } = avatarColor;
		return r + g + b < 660 ? "#ffffff" : "#434343";
	}, [avatarColor]);

	return (
		<StyledAvatar
			size="large"
			{...props}
			style={{
				backgroundColor: avatarColorIndex,
				color: textColor,
			}}
		>
			{initials}
		</StyledAvatar>
	);
};

export default AvatarBadge;
