import { ComponentPropsWithoutRef } from "react";

import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Col, Row, Space, Typography } from "antd";

import AvatarBadge from "../data-display/Avatar";

interface Representative {
	name: string;
	phone: string;
	email?: string;
}

interface Props extends ComponentPropsWithoutRef<"div"> {
	representative: Representative;
}

const RepresentativeListItem = ({ representative, ...props }: Props) => {
	const { name, phone, email } = representative;

	return (
		<Row {...props}>
			<Col>
				<AvatarBadge name={name} size="small" />
			</Col>
			<Col offset={1}>
				<Typography.Text strong={true}>{name}</Typography.Text>
				{phone && (
					<div>
						<Typography.Text type="secondary">
							<Space>
								<PhoneOutlined />
								{phone}
							</Space>
						</Typography.Text>
					</div>
				)}
				{email && (
					<div>
						<Typography.Text type="secondary">
							<Space>
								<MailOutlined />
								{email}
							</Space>
						</Typography.Text>
					</div>
				)}
			</Col>
		</Row>
	);
};

export default RepresentativeListItem;
