import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Collapse as AntdCollapse, Button, Form, Table } from "antd";
import styled from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import NumberInputLocalized from "@ui/form/inputs/number-input-localized/NumberInputLocalized";
import PercentInput from "@ui/form/inputs/percent-input/PercentInput";
const Collapse = styled(AntdCollapse) `
	.ant-collapse-header {
		padding: 0 !important;
		color: ${(props) => props.theme.color_link} !important;
	}

	.ant-collapse-content-box {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
`;
var Column;
(function (Column) {
    Column["companyName"] = "companyName";
    Column["amount"] = "amount";
    Column["duration"] = "duration";
    Column["totalInterest"] = "totalInterest";
    Column["hedgingRate"] = "hedgingRate";
})(Column || (Column = {}));
const EditableCell = ({ editing, dataIndex, inputType, children, currency }) => {
    const getInputNode = () => {
        switch (inputType) {
            case "number":
                return _jsx(NumberInputLocalized, { style: { width: "100%" } });
            case "currency":
                return _jsx(CurrencyInput, { currency: currency, style: { width: "100%" } });
            case "percent":
                return _jsx(PercentInput, { style: { width: "100%" } });
        }
    };
    return (_jsx("td", { children: editing ? (_jsx(Form.Item, Object.assign({ name: dataIndex, style: { margin: 0 }, rules: [
                {
                    required: true,
                    message: ``,
                },
            ] }, { children: getInputNode() }))) : (children) }));
};
const ConcentrationLimitsSessionApplications = ({ applications = [], startSession }) => {
    const { t } = useTranslation();
    const { defaultCurrency } = useSelector((state) => state.configState);
    const { currency, decimalToPercent, percentToDecimal } = useFormatter();
    const [rowInEdit, setRowInEdit] = useState("");
    const [form] = Form.useForm();
    const data = useMemo(() => {
        return applications.map((application) => ({
            key: application.application_id,
            [Column.companyName]: application.company_name,
            [Column.amount]: Number(application.amount) || 0,
            [Column.duration]: application.duration || 0,
            [Column.totalInterest]: decimalToPercent(application.total_interest_rate || 0),
            [Column.hedgingRate]: decimalToPercent(application.hedging_rate || 0),
        }));
    }, [applications, decimalToPercent]);
    const columns = useMemo(() => {
        return [
            {
                dataIndex: Column.companyName,
                title: t("portfolio.concentration-limits.session-collapse.company-name"),
            },
            {
                dataIndex: Column.amount,
                title: t("portfolio.concentration-limits.session-collapse.amount"),
                render: (v) => currency(v, { currency: defaultCurrency }),
                editable: true,
                inputType: "currency",
                currency: defaultCurrency,
                width: 150,
            },
            {
                dataIndex: Column.duration,
                title: t("portfolio.concentration-limits.session-collapse.duration"),
                editable: true,
                inputType: "number",
                width: 150,
            },
            {
                dataIndex: Column.totalInterest,
                title: t("portfolio.concentration-limits.session-collapse.total-interest"),
                render: (v) => v + "%",
                editable: true,
                inputType: "percent",
                width: 150,
            },
            {
                dataIndex: Column.hedgingRate,
                title: t("portfolio.concentration-limits.session-collapse.hedging-rate"),
                render: (v) => v + "%",
                editable: true,
                inputType: "percent",
                width: 150,
            },
            {
                render: (row) => {
                    const editable = row.key === rowInEdit;
                    return editable ? (_jsxs("div", Object.assign({ style: { textAlign: "right" } }, { children: [_jsx(Button, Object.assign({ type: "link", onClick: handleRowSave }, { children: t("common.save") })), _jsx(Button, Object.assign({ type: "link", onClick: handleCancelEdit }, { children: t("common.cancel") }))] }))) : (_jsxs("div", Object.assign({ style: { textAlign: "right" } }, { children: [_jsx(Button, Object.assign({ type: "link", onClick: () => handleRowEdit(row), disabled: rowInEdit !== "" }, { children: t("common.edit") })), _jsx(Button, Object.assign({ type: "link", onClick: () => removeRowFromSession(row.key), disabled: rowInEdit !== "" }, { children: t("common.remove") }))] })));
                },
                width: 200,
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowInEdit, data]);
    const mergedColumns = useMemo(() => columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return Object.assign(Object.assign({}, col), { onCell: (row) => ({
                record: row,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                editing: row.key === rowInEdit,
            }) });
    }), [columns, rowInEdit]);
    const handleRowEdit = (row) => {
        form.setFieldsValue(Object.assign({}, row));
        setRowInEdit(row.key);
    };
    const handleCancelEdit = () => {
        setRowInEdit("");
    };
    const removeRowFromSession = (key) => {
        const payload = applications === null || applications === void 0 ? void 0 : applications.filter((a) => a.application_id !== key);
        setRowInEdit("");
        startSession(payload);
    };
    const handleRowSave = () => {
        const fields = form.getFieldsValue();
        const editedApplicationIndex = applications === null || applications === void 0 ? void 0 : applications.findIndex((a) => a.application_id === rowInEdit);
        const payload = [...applications];
        payload[editedApplicationIndex] = {
            application_id: rowInEdit,
            amount: fields[Column.amount].toString(),
            duration: fields[Column.duration],
            total_interest_rate: percentToDecimal(fields[Column.totalInterest]),
            hedging_rate: percentToDecimal(fields[Column.hedgingRate]),
        };
        startSession(payload);
        setRowInEdit("");
    };
    return (_jsx(Collapse, Object.assign({ ghost: true }, { children: _jsx(Collapse.Panel, Object.assign({ header: t("portfolio.concentration-limits.session-collapse.title") }, { children: _jsx(Form, Object.assign({ form: form, component: false }, { children: _jsx(Table
                // @ts-ignore fuck those types!
                , { 
                    // @ts-ignore fuck those types!
                    columns: mergedColumns, dataSource: data, rowKey: "key", pagination: false, components: {
                        body: {
                            cell: EditableCell,
                        },
                    } }) })) }), "session") })));
};
export default ConcentrationLimitsSessionApplications;
