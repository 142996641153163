import { message } from "antd";
import { AxiosSingleton } from "@teylor-tools/utils/constructors/AxiosConstructor";
import store from "src/store/Store";
import { clearFeatures } from "src/store/actions/configActions";
import { logout } from "../store/actions/authActions";
export let Axios;
export const initAxios = (t) => {
    const beErrorString = (code) => t(`common.errors.${code}`);
    let sessionExpiredShown = false;
    Axios = AxiosSingleton.Instance({
        apiPath: env.API_URL,
        t,
        message: (text, duration) => {
            void message.error(text, duration);
        },
        // had to pass it here to keep typescript types inside the project scope
        beErrorString,
    });
    Axios.axios.interceptors.response.use((response) => response, (error) => {
        var _a;
        if (!error) {
            return Promise.reject();
        }
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
            return handle401Error(error);
        }
        return Promise.reject(error);
    });
    const handle401Error = (error) => {
        const responseURL = error["request"].responseURL;
        const isUnauthedEndpoint = ["/login", "/admin/account/register", "/admin/account/recover"].some((path) => responseURL.includes(path));
        if (!isUnauthedEndpoint) {
            store.dispatch(logout());
            store.dispatch(clearFeatures());
            if (!sessionExpiredShown) {
                sessionExpiredShown = true;
                void message.error(t("login.session-expired")).then(() => (sessionExpiredShown = false));
            }
        }
        return Promise.reject(error);
    };
};
