import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Col, Form, Input, Modal, Row, Select } from "antd";
import { PersonTitle, } from "@teylor-tools/Api";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";
import TelephoneFormItem from "@ui/form/form-items/telephone-form-item/TelephoneFormItem";
const titles = Object.keys(PersonTitle);
const ContactPersonModal = ({ user, loading = false, onCancel, onFinish, }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const requiredRule = {
        required: true,
        message: t("partner.modal.required-field"),
    };
    return (_jsx(Modal, Object.assign({ title: user
            ? t("partner.contact_persons.modal.edit_user_title")
            : t("partner.contact_persons.modal.add_user_title"), open: true, onCancel: onCancel, onOk: () => void form.validateFields().then(() => form.submit()), okText: user ? t("common.ok") : t("common.add"), cancelText: t("common.cancel"), okButtonProps: {
            htmlType: "submit",
            loading,
        } }, { children: _jsxs(Form, Object.assign({ form: form, layout: "vertical", onFinish: onFinish, initialValues: {
                title: user === null || user === void 0 ? void 0 : user.title,
                first_name: user === null || user === void 0 ? void 0 : user.first_name,
                last_name: user === null || user === void 0 ? void 0 : user.last_name,
                job_title: user === null || user === void 0 ? void 0 : user.job_title,
                mobile_number: user === null || user === void 0 ? void 0 : user.mobile_number,
                phone_number: user === null || user === void 0 ? void 0 : user.phone_number,
                email: user === null || user === void 0 ? void 0 : user.email,
            }, disabled: loading }, { children: [_jsxs(Row, Object.assign({ gutter: [16, 16] }, { children: [_jsx(Col, Object.assign({ span: 6 }, { children: _jsx(Form.Item, Object.assign({ name: "title", rules: [requiredRule], label: t("partner.contact_persons.modal.title") }, { children: _jsx(Select, { options: titles.map((title) => ({
                                        label: t(`partner.contact_persons.modal.title.${PersonTitle[title]}`),
                                        value: PersonTitle[title],
                                    })) }) })) })), _jsx(Col, Object.assign({ span: 9 }, { children: _jsx(Form.Item, Object.assign({ name: "first_name", rules: [requiredRule], label: t("partner.contact_persons.modal.first_name") }, { children: _jsx(Input, {}) })) })), _jsx(Col, Object.assign({ span: 9 }, { children: _jsx(Form.Item, Object.assign({ name: "last_name", rules: [requiredRule], label: t("partner.contact_persons.modal.last_name") }, { children: _jsx(Input, {}) })) }))] })), _jsx(Form.Item, Object.assign({ name: "job_title", rules: [requiredRule], label: t("partner.contact_persons.modal.job_title") }, { children: _jsx(Input, {}) })), _jsxs(Row, Object.assign({ gutter: [16, 16] }, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(TelephoneFormItem, { name: "mobile_number", rules: [requiredRule], label: t("partner.contact_persons.modal.mobile_number") }) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(TelephoneFormItem, { name: "phone_number", rules: [requiredRule], label: t("partner.contact_persons.modal.phone_number") }) }))] })), _jsx(EmailFormItem, { name: "email", label: t("partner.contact_persons.modal.email"), rules: [
                        {
                            required: true,
                            message: t("application_form.field-required"),
                        },
                    ] })] })) })));
};
export default ContactPersonModal;
