import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { DataCardItem } from "@ui/data-display/DataCard";
const CreditScore = ({ riskClassType, riskClassValue, creditScore, creditScoreInfoText, creditScoreProvider, creditScoreUpdatedAt, }) => {
    const { t } = useTranslation();
    const { date } = useFormatter();
    return (_jsxs(_Fragment, { children: [riskClassType && (_jsx(DataCardItem, { label: t("application.company_representatives.risk-class-type"), value: riskClassType })), riskClassValue && (_jsx(DataCardItem, { label: t("application.company_representatives.risk-class-value"), value: riskClassValue })), creditScore && (_jsx(DataCardItem, { label: t("application.company_representatives.credit-score"), value: creditScore })), creditScoreInfoText && (_jsx(DataCardItem, { label: t("application.company_representatives.credit-score-details"), value: creditScoreInfoText })), creditScoreProvider && (_jsx(DataCardItem, { label: t("application.company_representatives.credit-score-provider"), value: creditScoreProvider })), creditScore && creditScoreUpdatedAt && (_jsx(DataCardItem, { label: t("application.company_representatives.credit-score-last-fetched"), value: date(creditScoreUpdatedAt) }))] }));
};
export default CreditScore;
