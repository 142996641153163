import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Divider as AntdDivider, Button, Col, Row, Typography } from "antd";
import styled from "styled-components";
const { Text } = Typography;
const PendingItemWrapper = styled.div `
	padding: 8px 0;
`;
const PendingItemIcon = styled(CheckCircleOutlined) `
	color: rgba(0, 0, 0, 0.25);
	margin-top: 4px;
`;
const ExtraWrapper = styled.div `
	padding-left: 22px;
	margin-top: 8px;
`;
const Divider = styled(AntdDivider) `
	margin: 0;
`;
const PendingItem = ({ title, onClick, showDivider, extra }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsxs(PendingItemWrapper, { children: [_jsxs(Row, Object.assign({ wrap: false }, { children: [_jsx(Col, Object.assign({ flex: "24px" }, { children: _jsx(PendingItemIcon, {}) })), _jsx(Col, Object.assign({ flex: "auto" }, { children: _jsx(Text, { children: title }) })), _jsx(Col, Object.assign({ flex: "60px", style: { textAlign: "right" } }, { children: _jsx(Button, Object.assign({ size: "small", onClick: onClick }, { children: t("application.overview.tasks.open-task") })) }))] })), extra && _jsx(ExtraWrapper, { children: extra })] }), showDivider && _jsx(Divider, {})] }));
};
export default PendingItem;
