import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ArrowLeftOutlined, ArrowRightOutlined, CloseCircleOutlined, DownOutlined, ExclamationCircleOutlined, QuestionCircleOutlined, } from "@ant-design/icons";
import { Button, Dropdown, Modal, Space, Typography, message } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { ApplicationStatuses, } from "@teylor-tools/Api";
import { rawDateFormat } from "@teylor-tools/utils/dateFormats";
import RepaymentModal from "src/components/RepaymentModal";
import ApplicationStatus from "src/components/ui/statuses/ApplicationStatus";
import { Axios } from "src/utils/Axios";
import CompartmentModal from "./CompartmentModal";
import RejectionModal from "./RejectionModal";
const { confirm } = Modal;
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${({ theme }) => theme.colorError} !important;
`;
const QuestionCircleOutlinedIcon = styled(QuestionCircleOutlined) `
	color: ${({ theme }) => theme.colorPrimary} !important;
`;
const MoveApplication = ({ applicationId, possibleStates, statusFlow, partnerId, update, }) => {
    var _a, _b;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [isModalSaveLoading, setIsModalSaveLoading] = useState(false);
    const [isRepaymentModalVisible, setIsRepaymentModalVisible] = useState(false);
    const [isCompartmentModalVisible, setIsCompartmentModalVisible] = useState(false);
    const [isRejectionModalVisible, setIsRejectionModalVisible] = useState(false);
    const [loan, setLoan] = useState();
    const currentStatusIndex = statusFlow.findIndex(({ is_current_status }) => is_current_status);
    const previousStatus = (_a = statusFlow[currentStatusIndex - 1]) === null || _a === void 0 ? void 0 : _a.status_name;
    const nextStatus = (_b = statusFlow[currentStatusIndex + 1]) === null || _b === void 0 ? void 0 : _b.status_name;
    const changeStatus = (status, rejection_reason, reject_description) => {
        setLoading(true);
        Axios.patch(`/admin/applications/${applicationId}/status`, {
            status,
            rejection_reason,
            reject_description,
        })
            .then(update, (error) => {
            void Axios.error(error);
        })
            .finally(() => {
            setLoading(false);
            setIsRejectionModalVisible(false);
        });
    };
    const showMoveToPreviousConfirmationModal = (status) => {
        confirm({
            title: t("application.are-you-sure"),
            icon: _jsx(ExclamationCircleOutlinedIcon, {}),
            content: t("application.move_status_back"),
            okText: t("common.confirm"),
            onOk: () => {
                void changeStatus(status);
            },
        });
    };
    const showPayOutDateConfirmationModal = (loan) => {
        confirm({
            title: t("application.is-payout-date-correct"),
            icon: _jsx(QuestionCircleOutlinedIcon, {}),
            content: _jsx(Trans, Object.assign({ i18nKey: "application.payout-date-info" }, { children: { date: loan.payout_date } })),
            cancelText: t("application.change-date"),
            okText: t("application.date-is-correct"),
            onCancel: () => {
                setIsRepaymentModalVisible(true);
            },
            onOk: () => {
                if (!partnerId) {
                    void message.error(t("application.missing-partner-id"));
                }
                else {
                    setIsCompartmentModalVisible(true);
                }
            },
        });
    };
    const getLoan = () => {
        setLoading(true);
        Axios.get(`/admin/applications/${applicationId}/loan`)
            .then(({ data }) => {
            setLoan(data);
            showPayOutDateConfirmationModal(data);
        }, (err) => {
            var _a;
            // 404 status means there's no loan associated with the application
            // it's OK for brokered applications
            ((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === 404
                ? changeStatus(ApplicationStatuses.PaidOut)
                : void Axios.error(err);
        })
            .finally(() => setLoading(false));
    };
    const createRepayment = (date) => {
        setIsModalSaveLoading(true);
        Axios.patch(`/admin/loans/${loan === null || loan === void 0 ? void 0 : loan.loan_id}/payout_date`, {
            payout_date: date.format(rawDateFormat),
        })
            .then(() => {
            getLoan();
            setIsRepaymentModalVisible(false);
        }, (err) => Axios.error(err, t("application.repayment.error.error-saving-date")))
            .finally(() => setIsModalSaveLoading(false));
    };
    const confirmCompartment = (compartment) => {
        if (!loan)
            return;
        setLoading(true);
        Axios.patch(`/admin/loans/${loan.loan_id}`, { compartment_id: compartment })
            .then(() => {
            changeStatus(ApplicationStatuses.PaidOut);
        }, (err) => Axios.error(err))
            .finally(() => {
            setLoading(false);
            setIsCompartmentModalVisible(false);
        });
    };
    const menuItems = useMemo(() => {
        if (!(possibleStates === null || possibleStates === void 0 ? void 0 : possibleStates.length))
            return [];
        return [
            ...(nextStatus
                ? [
                    {
                        label: (_jsxs(Space, { children: [_jsx(Typography.Text, { children: t("application.forward-to") }), _jsx(ApplicationStatus, { label: t(`application.status.${nextStatus}`), value: nextStatus, closable: false, disabled: false })] })),
                        key: nextStatus,
                        icon: _jsx(ArrowRightOutlined, {}),
                    },
                ]
                : []),
            ...(previousStatus
                ? [
                    {
                        label: (_jsxs(Space, { children: [_jsx(Typography.Text, { children: t("application.back-to") }), _jsx(ApplicationStatus, { label: t(`application.status.${previousStatus}`), value: previousStatus, closable: false, disabled: false })] })),
                        key: previousStatus,
                        icon: _jsx(ArrowLeftOutlined, {}),
                    },
                ]
                : []),
            ...(possibleStates.includes(ApplicationStatuses.Rejected)
                ? [
                    { key: "divider", type: "divider" },
                    {
                        label: t("application.rejected"),
                        key: ApplicationStatuses.Rejected,
                        icon: _jsx(CloseCircleOutlined, {}),
                        danger: true,
                    },
                ]
                : []),
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [possibleStates, previousStatus, nextStatus]);
    return (_jsxs(_Fragment, { children: [_jsx(Dropdown, Object.assign({ placement: "bottomRight", disabled: !possibleStates, trigger: ["click"], menu: {
                    items: menuItems,
                    onClick: ({ key }) => {
                        if (key === ApplicationStatuses.Rejected) {
                            setIsRejectionModalVisible(true);
                        }
                        else if (key === ApplicationStatuses.PaidOut) {
                            getLoan();
                        }
                        else if (key === previousStatus) {
                            showMoveToPreviousConfirmationModal(key);
                        }
                        else {
                            changeStatus(key);
                        }
                    },
                } }, { children: _jsx(Button, Object.assign({ loading: loading }, { children: _jsxs(Space, { children: [t("application.name"), _jsx(DownOutlined, {})] }) })) })), isRepaymentModalVisible && loan && (_jsx(RepaymentModal, { payoutDate: dayjs(loan.payout_date), onCancel: () => {
                    setIsRepaymentModalVisible(false);
                }, onFinish: createRepayment, loading: isModalSaveLoading })), isCompartmentModalVisible && partnerId && (_jsx(CompartmentModal, { partnerId: partnerId, onCancel: () => {
                    setIsCompartmentModalVisible(false);
                }, onFinish: confirmCompartment, loading: isModalSaveLoading })), isRejectionModalVisible && (_jsx(RejectionModal, { onCancel: () => {
                    setIsRejectionModalVisible(false);
                }, onFinish: (rejectionReason, rejectionDescription) => {
                    void changeStatus(ApplicationStatuses.Rejected, rejectionReason, rejectionDescription);
                } }))] }));
};
export default MoveApplication;
