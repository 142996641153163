import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { FeatureName } from "@teylor-tools/Api";
import RepresentativeCard from "@ui/representative-card/RepresentativeCard";
import { Routes } from "src/Routes";
import CreditScore from "src/components/CreditScore";
import CreditScoreReport from "src/components/CreditScoreReport";
import PepCheck from "src/components/PepCheck";
import { Axios } from "src/utils/Axios";
const CompanyRepresentative = ({ representative, loanId }) => {
    const { t } = useTranslation();
    const { features } = useSelector((rootState) => rootState.configState);
    const [creditScores, setCreditScores] = useState([]);
    const showPepCheck = useMemo(() => { var _a; return (_a = features.find((f) => f.name === FeatureName.BoPepVisibility)) === null || _a === void 0 ? void 0 : _a.value.enabled; }, [features]);
    const getCreditScores = useCallback(() => {
        Axios.get(`admin/signatories/${representative.signatory_id}/credit_score`).then(({ data }) => setCreditScores(data), (err) => {
            void Axios.error(err, t("application.company.error.error-fetching-report"));
            setCreditScores([]);
        });
    }, [representative.signatory_id, t]);
    useEffect(() => {
        void getCreditScores();
    }, [getCreditScores]);
    return (_jsx(_Fragment, { children: _jsx(RepresentativeCard, { representative: representative, creditCheck: _jsxs(_Fragment, { children: [_jsx(CreditScore, { riskClassType: representative.risk_class_type, riskClassValue: representative.risk_class_value, creditScore: representative.credit_score, creditScoreInfoText: representative.credit_score_info_text, creditScoreProvider: representative.credit_score_provider, creditScoreUpdatedAt: representative.credit_score_updated_at }), _jsx(CreditScoreReport, { getCreditScore: getCreditScores, creditScores: creditScores, documentsPath: generatePath(Routes.LoanDocuments, {
                            loanId: loanId,
                        }) })] }), pepCheck: showPepCheck ? (_jsx(PepCheck, { pepCheckResult: representative.pep_check_result, signatoryId: representative.signatory_id, documentsPath: generatePath(Routes.LoanDocuments, {
                    loanId: loanId,
                }), readonly: true })) : undefined }) }));
};
export default CompanyRepresentative;
