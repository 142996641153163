import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Typography } from "antd";
import styled from "styled-components";
import { Routes } from "src/Routes";
const Header = styled.div `
	padding: 24px;
	width: calc(100% - 80px);
	background: white;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	position: absolute;
	left: 80px;
	z-index: 9;
	height: max-content;
	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
	.ant-typography {
		word-break: keep-all;
	}
`;
const TopSection = styled(Breadcrumb) `
	ol {
		display: flex;
		align-items: center;

		> * h5 {
			margin-bottom: 0;
		}
	}
`;
const StyledText = styled(Typography.Text) `
	font-size: 16px;
	cursor: pointer;
`;
export const CreateApplicationHeader = ({ companyId, companyName }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (_jsx(_Fragment, { children: companyId && companyName ? (_jsxs(Header, { children: [_jsxs(TopSection, Object.assign({ separator: _jsx(RightOutlined, {}) }, { children: [_jsx(Breadcrumb.Item, { children: _jsx(StyledText, Object.assign({ onClick: () => navigate(Routes.Companies) }, { children: t("companies.title") })) }), _jsx(Breadcrumb.Item, { children: _jsx(StyledText, Object.assign({ onClick: () => navigate(generatePath(Routes.Company, {
                                    companyId: companyId,
                                })) }, { children: companyName })) }), _jsx(Breadcrumb.Item, { children: _jsx(Typography.Title, Object.assign({ level: 5 }, { children: t("application.create-application.new-application") })) })] })), _jsx(Button, Object.assign({ onClick: () => navigate(generatePath(Routes.CompanyApplications, { companyId })) }, { children: t("common.cancel") }))] })) : (_jsxs(Header, { children: [_jsxs(TopSection, Object.assign({ separator: _jsx(RightOutlined, {}) }, { children: [_jsx(Breadcrumb.Item, { children: _jsx(StyledText, Object.assign({ onClick: () => navigate(Routes.Applications) }, { children: t("application.create-application.applications") })) }), _jsx(Breadcrumb.Item, { children: _jsx(Typography.Title, Object.assign({ level: 5 }, { children: t("application.create-application.new-application") })) })] })), _jsx(Button, Object.assign({ onClick: () => navigate(Routes.Applications) }, { children: t("common.cancel") }))] })) }));
};
