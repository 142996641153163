import { ReactNode, forwardRef } from "react";

import { Divider as AntDivider } from "antd";
import styled from "styled-components";

export const MAIN_HEADER_HEIGHT = 80;

interface Props {
	title: string | ReactNode;
	extra?: string | ReactNode;
}

const StyledHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 24px 0;
	font-weight: 400;
	font-size: 20px;
	line-height: 36px;
`;

const Divider = styled(AntDivider)`
	margin-bottom: 0;
`;

const Wrapper = styled.div`
	background-color: white;
	height: ${MAIN_HEADER_HEIGHT}px;
`;

const MainHeader = forwardRef<HTMLDivElement, Props>(({ title, extra, ...props }, ref) => {
	return (
		<Wrapper ref={ref} {...props}>
			<StyledHeader>
				<div>{title}</div>
				{extra && <div>{extra}</div>}
			</StyledHeader>
			<Divider />
		</Wrapper>
	);
});

MainHeader.displayName = "MainHeader";
export default MainHeader;
