import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Form, Spin, message } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { ApplicationStatuses } from "@teylor-tools/Api";
import { rawDateFormat } from "@teylor-tools/utils/dateFormats";
import { downloadFileFromUrl } from "@teylor-tools/utils/downloadFileFromUrl";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import RepaymentModal from "src/components/RepaymentModal";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import RepaymentEmpty from "src/pages/applications/subpages/ApplicationRepayment/RepaymentEmpty";
import { RepaymentTable } from "src/pages/applications/subpages/ApplicationRepayment/RepaymentTable";
import { Axios } from "src/utils/Axios";
const ButtonsWrapper = styled.div `
	display: flex;
	gap: 16px;
`;
const ContentWrapper = styled.div `
	margin-top: 64px;
	overflow: hidden !important;
`;
var ExtraBtnKey;
(function (ExtraBtnKey) {
    ExtraBtnKey["CHANGE_PAYOUT_DATE"] = "CHANGE_PAYOUT_DATE";
    ExtraBtnKey["EXPORT_REPAYMENT_PLAN"] = "EXPORT_REPAYMENT_PLAN";
    ExtraBtnKey["EXPORT_REPAYMENT_PLAN_CSV"] = "EXPORT_REPAYMENT_PLAN_CSV";
})(ExtraBtnKey || (ExtraBtnKey = {}));
const ApplicationRepayment = () => {
    const { t } = useTranslation();
    const { application, updatePendingItems } = useApplication();
    const [loan, setLoan] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalSaveLoading, setIsModalSaveLoading] = useState(false);
    const [isDownloadConfirmationLoading, setIsDownloadConfirmationLoading] = useState(false);
    const [form] = Form.useForm();
    const noRepaymentScheduleData = useMemo(() => !(loan === null || loan === void 0 ? void 0 : loan.payment_plan.installments.length), [loan === null || loan === void 0 ? void 0 : loan.payment_plan.installments.length]);
    const extraMenuItems = useMemo(() => [
        ...(application.status === ApplicationStatuses.AwaitingPayout
            ? [
                {
                    label: t("application.repayment.change-payout-date"),
                    key: ExtraBtnKey.CHANGE_PAYOUT_DATE,
                },
            ]
            : []),
        {
            label: t("application.repayment.export-repayment-plan"),
            key: ExtraBtnKey.EXPORT_REPAYMENT_PLAN,
        },
        {
            label: t("application.repayment.export-repayment-plan-csv"),
            key: ExtraBtnKey.EXPORT_REPAYMENT_PLAN_CSV,
        },
    ], [t, application.status]);
    const getLoan = useCallback(() => {
        setIsLoading(true);
        Axios.get(`/admin/applications/${application.applicationId}/loan`)
            .then(({ data }) => setLoan(data), (err) => void Axios.error(err))
            .finally(() => setIsLoading(false));
    }, [application.applicationId]);
    useEffect(getLoan, [getLoan]);
    const createRepayment = (date) => {
        setIsModalSaveLoading(true);
        Axios.patch(`/admin/loans/${loan === null || loan === void 0 ? void 0 : loan.loan_id}/payout_date`, {
            payout_date: date.format(rawDateFormat),
        })
            .then(() => {
            getLoan();
            form.resetFields();
            setIsModalVisible(false);
        }, (err) => Axios.error(err, t("application.repayment.error.error-saving-date")))
            .finally(() => {
            updatePendingItems();
            setIsModalSaveLoading(false);
        });
    };
    const downloadPaymentConfirmation = () => {
        setIsDownloadConfirmationLoading(true);
        Axios.post(`/admin/loans/${loan === null || loan === void 0 ? void 0 : loan.loan_id}/generate_payment_confirmation`)
            .then(({ data }) => {
            if (!data.url)
                throw new Error();
            downloadFileFromUrl(data.url, `${application.companyName} - Payment confirmation`);
        })
            .catch((err) => Axios.error(err, t("application.repayment.error.error-download-payment-confirmation")))
            .finally(() => setIsDownloadConfirmationLoading(false));
    };
    const handleExtraMenuClick = (v) => {
        switch (v.key) {
            case ExtraBtnKey.CHANGE_PAYOUT_DATE:
                setIsModalVisible(true);
                break;
            case ExtraBtnKey.EXPORT_REPAYMENT_PLAN:
                Axios.post(`admin/loans/${loan === null || loan === void 0 ? void 0 : loan.loan_id}/generate_payment_plan`).then(() => message.success(t("application.repayment.export-repayment-plan-successful")), () => Axios.error(t("application.repayment.error.error-export-repayment-plan")));
                break;
            case ExtraBtnKey.EXPORT_REPAYMENT_PLAN_CSV:
                Axios.downloadGeneratedFile(`admin/loans/${loan === null || loan === void 0 ? void 0 : loan.loan_id}/repayment_plan?export_type=csv`, `${loan === null || loan === void 0 ? void 0 : loan.loan_id}_repayment_plan.csv`).then(() => message.success(t("application.repayment.export-repayment-plan-successful")), () => Axios.error(t("application.repayment.error.error-export-repayment-plan")));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, Object.assign({ title: t("application.repayment.title") }, { children: !noRepaymentScheduleData && (_jsxs(ButtonsWrapper, { children: [_jsx(Button, Object.assign({ type: "primary", onClick: downloadPaymentConfirmation, loading: isDownloadConfirmationLoading }, { children: t("application.repayment.download-payment-confirmation") })), _jsx(Dropdown, Object.assign({ menu: { items: extraMenuItems, onClick: handleExtraMenuClick } }, { children: _jsx(Button, { children: _jsx(MoreOutlined, {}) }) }))] })) })), _jsx(Spin, Object.assign({ spinning: isLoading || isModalSaveLoading }, { children: _jsx(ContentWrapper, { children: noRepaymentScheduleData ? (_jsx(RepaymentEmpty, { addRepayment: () => {
                            setIsModalVisible(true);
                        } })) : (_jsx(RepaymentTable, { repayments: (loan === null || loan === void 0 ? void 0 : loan.payment_plan.installments) || [] })) }) })), isModalVisible && (_jsx(RepaymentModal, { payoutDate: (loan === null || loan === void 0 ? void 0 : loan.payout_date) ? dayjs(loan.payout_date) : undefined, onCancel: () => {
                    setIsModalVisible(false);
                }, onFinish: createRepayment, loading: isModalSaveLoading }))] }));
};
export default ApplicationRepayment;
