import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Table, Tag, Typography } from "antd";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
const ConcentrationLimitsTable = ({ data, type }) => {
    const { t } = useTranslation();
    const [tableData, setTableData] = useState();
    const { decimalToPercent, currency } = useFormatter();
    const { defaultCurrency } = useSelector((state) => state.configState);
    const createCommonColumns = () => {
        return [
            {
                title: t("portfolio.concentration-limits.limit"),
                render: ({ limit }) => 
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                isValidNumericValue(limit) ? decimalToPercent(limit, 2, true) : "-",
                width: 120,
            },
            {
                title: t("portfolio.concentration-limits.actual"),
                render: ({ actual, actualDifference, actualDifferenceImproved }) => {
                    if (!isValidNumericValue(actual))
                        return "-";
                    return (_jsxs(_Fragment, { children: [_jsx(Typography.Text, Object.assign({ style: { marginRight: 4 } }, { children: decimalToPercent(actual, 2, true) })), actualDifference ? (_jsxs(Typography.Text, Object.assign({ type: actualDifferenceImproved ? "success" : "danger" }, { children: ["(", Number(actualDifference) > 0 ? "+" : "", decimalToPercent(actualDifference, 2, true), ")"] }))) : ("")] }));
                },
                width: 150,
            },
            {
                title: t("portfolio.concentration-limits.excess-amount"),
                render: ({ excessAmount }) => isValidNumericValue(excessAmount)
                    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        currency(excessAmount, { currency: defaultCurrency })
                    : "-",
                width: 120,
            },
            {
                title: t("portfolio.concentration-limits.test"),
                render: ({ conditionMet }) => conditionMet ? (_jsx(Tag, Object.assign({ color: "success" }, { children: t("portfolio.concentration-limits.general.test.pass") }))) : (_jsx(Tag, Object.assign({ color: "error" }, { children: t("portfolio.concentration-limits.general.test.fail") }))),
                width: 120,
            },
        ];
    };
    const columns = [
        ...(type === "general"
            ? [
                {
                    title: t("portfolio.concentration-limits.parameter"),
                    render: ({ desc }) => {
                        // @ts-ignore dynamic values from BE
                        return t(`portfolio.concentration-limits.descriptions.${desc}`);
                    },
                    ellipsis: true,
                },
            ]
            : [
                {
                    title: t("portfolio.concentration-limits.nace_code"),
                    render: ({ nace_code }) => nace_code,
                    width: 100,
                },
                {
                    title: t("portfolio.concentration-limits.industry"),
                    render: ({ desc }) => 
                    // @ts-ignore dynamic values from BE
                    t(`portfolio.concentration-limits.descriptions.${desc}`),
                    ellipsis: true,
                },
            ]),
        ...createCommonColumns(),
    ];
    useEffect(() => {
        const mappedData = (data || []).map((check) => ({
            key: check.concentration_limit_id,
            nace_code: check.parameter_name,
            desc: check.concentration_limit_description,
            limit: check.parameter_limit,
            actual: check.parameter_value,
            actualDifference: check.parameter_difference_based_on_what_if_analysis,
            actualDifferenceImproved: check.parameter_improved_based_on_what_if_analysis,
            excessAmount: check.parameter_excess_amount_value,
            conditionMet: check.is_condition_met,
        }));
        setTableData(mappedData);
    }, [data]);
    return _jsx(Table, { columns: columns, dataSource: tableData, rowKey: "key", pagination: false });
};
export default ConcentrationLimitsTable;
