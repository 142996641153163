import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider, useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/reset.css";
import "core-js";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import i18n from "i18next";
import styled from "styled-components";
import i18nInit from "src/i18n/i18nInit";
import { ThemeProvider } from "src/providers/theme-provider/theme";
import { TranslationsProvider } from "src/providers/translations-provider/translations";
import store from "src/store/Store";
import Routes from "./Routes";
import "./global.css";
import { initAxios } from "./utils/Axios";
i18nInit();
const AppContainer = styled.div `
	display: flex;
	overflow: hidden;
	height: 100vh;

	.ant-layout-content {
		background: #fff;
	}
`;
const Setup = () => {
    const { t } = useTranslation();
    const [ready, setReady] = useState(false);
    useEffect(() => {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        dayjs.extend(customParseFormat);
        initAxios(t);
        setReady(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(Provider, Object.assign({ store: store }, { children: _jsx(I18nextProvider, Object.assign({ i18n: i18n }, { children: ready && (_jsx(ThemeProvider, { children: _jsx(BrowserRouter, { children: _jsx(AppContainer, { children: _jsx(TranslationsProvider, { children: _jsx(Routes, {}) }) }) }) })) })) })));
};
const rootElement = document.getElementById("root");
if (rootElement) {
    const root = createRoot(rootElement);
    root.render(_jsx(Setup, {}));
}
