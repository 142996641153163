import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import { Col, Form, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import styled from "styled-components";
import timePassedUntilNow from "@teylor-tools/utils/timePassedUntilNow";
import { DataCardItem } from "@ui/data-display/DataCard";
import TinFormItem from "@ui/form/form-items/tin-form-item/TinFormItem";
import IndustryCodesCard from "@ui/industry-codes-card/IndustryCodesCard";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { Routes } from "src/Routes";
import CreditScoreReport from "src/components/CreditScoreReport";
import { DataCard, DataCardDivider, DataCardItemEditable } from "src/components/DataCard";
import { useCompany } from "src/layouts/CompanyLayout/CompanyTypes";
import { Axios } from "src/utils/Axios";
const Wrapper = styled.div `
	margin: 92px 24px 24px;
`;
const pickTimePassed = (foundationDate, t) => {
    const { monthsPassed, yearsPassed } = timePassedUntilNow(foundationDate);
    return `${t("application.company.year_other", { count: yearsPassed })} ${t("application.company.month_other", { count: monthsPassed })}`;
};
const CompanyDetails = () => {
    var _a;
    const { t } = useTranslation();
    const { company, updateCompany } = useCompany();
    const [creditScores, setCreditScores] = useState([]);
    const getCreditScores = useCallback(() => {
        Axios.get(`admin/companies/${company.companyId}/credit_score`).then(({ data }) => setCreditScores(data), (err) => {
            void Axios.error(err, t("application.company.error.error-fetching-report"));
            setCreditScores([]);
        });
    }, [company.companyId, t]);
    const updateIndustryCodes = (values) => {
        return Axios.patch(`/admin/companies/${company.companyId}/industry_codes`, values).then(() => {
            void updateCompany();
        }, (err) => void Axios.error(err));
    };
    useEffect(() => {
        getCreditScores();
    }, [getCreditScores]);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: t("company.menu.company") }), _jsx(Wrapper, { children: _jsx(Row, { children: _jsxs(Col, Object.assign({ sm: 24 }, { children: [_jsxs(DataCard, Object.assign({ loading: !company }, { children: [_jsx(DataCardItem, { label: t("company.name"), value: _jsx("strong", { children: company.companyName }) }), _jsx(DataCardItemEditable, { label: t("company.purpose"), displayedValue: company.businessPurpose, formItemValue: company.businessPurpose, onChange: (value) => {
                                            void updateCompany({ businessPurpose: value });
                                        }, customFormItem: (props) => (_jsx(Form.Item, Object.assign({}, props, { children: _jsx(TextArea, { rows: 6 }) }))) }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("company.address"), value: [
                                            `${company.address_street} ${company.address_house_number}`,
                                            `${company.address_post_code} ${company.address_location}`,
                                            t(`common.countries.${company.address_country}`),
                                        ].join(", ") }), _jsx(DataCardItem, { label: t("company.country"), value: company.address_country }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("company.hr-number"), value: company.hrNumber }), _jsx(DataCardItemEditable, { label: t("company.tin"), displayedValue: company.taxIdNumber, onChange: (value) => {
                                            void updateCompany({ taxIdNumber: value });
                                        }, customFormItem: (props) => _jsx(TinFormItem, Object.assign({}, props)), formItemValue: company.taxIdNumber }), _jsx(DataCardItem, { label: t("company.client-id"), value: company.company_id_short }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("company.legal-form"), value: company.companyType }), _jsx(DataCardItem, { label: t("company.founded"), value: company.companyFoundationDate &&
                                            new Date(company.companyFoundationDate).getFullYear() }), _jsx(DataCardItem, { label: t("company.age"), value: company.companyFoundationDate && pickTimePassed(company.companyFoundationDate, t) }), _jsx(DataCardItem, { label: t("company.employees"), value: company.numberOfEmployees })] })), _jsx(IndustryCodesCard, { industryCodes: company === null || company === void 0 ? void 0 : company.industry_codes, showEditButton: true, onSave: updateIndustryCodes }), _jsxs(DataCard, { children: [_jsx(CreditScoreReport, { getCreditScore: getCreditScores, creditScores: creditScores, requestCreditCheck: () => Axios.patch(`admin/companies/${company.companyId}/credit_check`).then(({ data }) => Promise.resolve(data), (err) => Promise.reject(err)), documentsPath: generatePath(Routes.CompanyDocuments, {
                                            companyId: company.companyId,
                                        }) }), _jsx(DataCardItem, { label: t("company.pd-rate"), value: ((_a = creditScores[0]) === null || _a === void 0 ? void 0 : _a.pd) && `${creditScores[0].pd}%` })] })] })) }) })] }));
};
export default CompanyDetails;
