import { jsx as _jsx } from "react/jsx-runtime";
import { LoanStatus as LoanStatusType } from "@teylor-tools/Api";
import StatusBase from "./StatusBase";
const LoanStatus = (props) => {
    const pickColors = () => {
        switch (props.value) {
            case LoanStatusType.Open:
                return {
                    border: "blue7",
                    background: "blue7",
                    colour: "white",
                };
            case LoanStatusType.Delay:
                return {
                    border: "orange1",
                    background: "orange1",
                    colour: "orange6",
                };
            case LoanStatusType.Repaid:
                return {
                    border: "green1",
                    background: "green1",
                    colour: "green6",
                };
            default:
                return {
                    border: "red1",
                    background: "red1",
                    colour: "red6",
                };
        }
    };
    return _jsx(StatusBase, Object.assign({}, props, { colors: pickColors }));
};
export default LoanStatus;
