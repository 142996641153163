import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { CalendarOutlined, ClockCircleOutlined, CloseOutlined, GoldOutlined, PercentageOutlined, RightOutlined, } from "@ant-design/icons";
import { Divider as AntdDivider, Row, Spin, Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { Currency, TaskRelatedEntityType } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import Preview, { Cell, PreviewDetailsLine } from "@ui/preview/Preview";
import SidebarCreateTask from "src/components/tasks/SidebarCreateTask";
import SidebarTaskDetails from "src/components/tasks/SidebarTaskDetails";
import { SideActions } from "src/layouts/LoanLayout/loan.types";
import LoanAnalystReport from "src/pages/loans/subpages/LoanQuantitative/LoanAnalystReport";
import LoanLogs from "./LoanLogs";
import LoanNotes from "./LoanNotes";
import LoanTasks from "./LoanTasks";
const StyledWrapper = styled.div `
	background-color: #f5f5f5;
	position: relative;
	overflow: auto;
	display: flex;
	height: 100%;
`;
const Divider = styled(AntdDivider) `
	margin: 0;
`;
const Grow = styled.div `
	flex-grow: 1;
	overflow: auto;
`;
const StaticDrawer = styled(Preview) `
	position: relative;

	.ant-drawer-body {
		padding: 0;
		display: flex;
		flex-direction: column;
	}

	.ant-drawer-content-wrapper {
		position: relative;
		height: 100%;
		box-shadow: none;
		border-left: solid 1px #f0f0f0;
	}
`;
const Header = ({ text, onClose, onBack, }) => {
    return (_jsxs(Row, Object.assign({ align: "middle", justify: "space-between", style: { width: "100%" } }, { children: [_jsx(Typography.Title, Object.assign({ level: 5, style: { margin: 0 } }, { children: text })), _jsxs("div", { children: [_jsx(CloseOutlined, { onClick: onClose }), onBack && _jsx(RightOutlined, { onClick: onBack, style: { marginLeft: 16 } })] })] })));
};
const LoanOutlet = ({ activeMenu, setActiveMenu, loan, updateLoan, loanPageHeaderHeight, tasks, updateTasks, }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { currency, decimalToPercent } = useFormatter();
    const onClose = () => setActiveMenu(undefined);
    const sideMenuElements = {
        "loan-information": {
            header: _jsx(Header, { onClose: onClose, text: t("loans.preview.loan-information") }),
            lineData: loan
                ? [
                    {
                        icon: _jsx(GoldOutlined, {}),
                        label: t("loans.preview.amount"),
                        value: currency(loan.amount, {
                            currency: loan.currency || Currency.EUR,
                        }),
                    },
                    {
                        icon: _jsx(ClockCircleOutlined, {}),
                        label: t("loans.preview.duration"),
                        value: `${loan.duration_months} ${t("common.months")}`,
                    },
                    {
                        icon: _jsx(PercentageOutlined, {}),
                        label: t("loans.preview.interest-rate"),
                        value: loan.interest_rate ? decimalToPercent(loan.interest_rate, 2, true) : "-",
                    },
                    {
                        icon: _jsx(CalendarOutlined, {}),
                        label: t("loans.preview.payout-date"),
                        value: (_jsx(Typography.Text, { children: loan.payout_date ? dayjs(loan.payout_date).format(dayMonthYear) : "-" })),
                    },
                ]
                : [],
            children: (_jsxs(_Fragment, { children: [_jsx(Cell, { children: _jsxs(Spin, Object.assign({ spinning: !loan }, { children: [_jsx(PreviewDetailsLine, Object.assign({ label: t("loans.preview.principal-balance") }, { children: _jsx("span", { children: currency(loan.outstanding_balance_principal, {
                                            currency: loan.currency || Currency.EUR,
                                        }) }) })), _jsx(PreviewDetailsLine, Object.assign({ label: t("loans.preview.interest-balance") }, { children: _jsx("span", { children: currency(loan.outstanding_balance_interest, {
                                            currency: loan.currency || Currency.EUR,
                                        }) }) })), _jsx(PreviewDetailsLine, Object.assign({ label: t("loans.preview.fee-balance") }, { children: _jsx("span", { children: currency(loan.outstanding_balance_fees, {
                                            currency: loan.currency || Currency.EUR,
                                        }) }) }))] })) }), _jsx(Cell, { children: _jsx(Spin, Object.assign({ spinning: !loan }, { children: _jsx(PreviewDetailsLine, Object.assign({ label: t("loans.preview.product") }, { children: _jsx("span", { children: `${(_a = loan.product) === null || _a === void 0 ? void 0 : _a.product_family_name} / ${(_b = loan.product) === null || _b === void 0 ? void 0 : _b.product_name}` }) })) })) }), _jsx(Cell, { children: _jsxs(Spin, Object.assign({ spinning: !loan }, { children: [_jsx(PreviewDetailsLine, Object.assign({ label: t("loans.preview.loan-id") }, { children: _jsx("span", { children: loan.loan_id_short }) })), _jsx(PreviewDetailsLine, Object.assign({ label: t("loans.preview.application-id") }, { children: _jsx("span", { children: loan.application_id }) }))] })) }), _jsx(Cell, { children: _jsxs(Spin, Object.assign({ spinning: !loan }, { children: [_jsx(PreviewDetailsLine, Object.assign({ label: t("loans.preview.bank-reference") }, { children: _jsx("span", { children: loan.fronting_bank_iban }) })), _jsx(PreviewDetailsLine, Object.assign({ label: t("loans.preview.external-reference") }, { children: _jsx("span", { children: loan.external_reference }) }))] })) }), _jsx(Divider, {})] })),
        },
        "shared-notes": {
            header: _jsx(Header, { onClose: onClose, text: t("loans.preview.shared-notes") }),
            children: _jsx(LoanNotes, { loanId: loan.loan_id }),
        },
        "activity-log": {
            header: _jsx(Header, { onClose: onClose, text: t("loans.preview.activity-log") }),
            children: _jsx(LoanLogs, { loanId: loan.loan_id }),
        },
        "analyst-report": {
            header: _jsx(Header, { onClose: onClose, text: t("loans.preview.analyst-report") }),
            children: _jsx(LoanAnalystReport, { loanId: loan.loan_id }),
        },
        tasks: {
            header: _jsx(Header, { onClose: onClose, text: t("loans.tasks.sidebar.title") }),
            children: _jsx(LoanTasks, { tasks: tasks }),
        },
        "create-task": {
            header: (_jsx(Header, { onClose: onClose, onBack: () => setActiveMenu(SideActions.tasks), text: t("tasks.sidebar.title.create-new-task") })),
            children: (_jsx(SidebarCreateTask, { entityId: loan.loan_id, entityType: TaskRelatedEntityType.Loan, afterSave: () => {
                    void updateTasks();
                    setActiveMenu(SideActions.tasks);
                } })),
        },
        "task-details": {
            header: (_jsx(Header, { onClose: onClose, onBack: () => setActiveMenu(SideActions.tasks), text: t("tasks.sidebar.title.task-details") })),
            children: (_jsx(SidebarTaskDetails, { data: tasks, afterDelete: () => void updateTasks().finally(() => setActiveMenu(SideActions.tasks)), afterUpdate: () => void updateTasks() })),
        },
    };
    return (_jsxs(StyledWrapper, { children: [_jsx(Grow, { children: _jsx(Outlet, { context: { loan, updateLoan, setActiveMenu, loanPageHeaderHeight, tasks } }) }), _jsx(StaticDrawer, Object.assign({ entityId: loan.loan_id, getContainer: false, visible: !!activeMenu, header: activeMenu ? sideMenuElements[activeMenu].header : undefined, lineData: activeMenu ? sideMenuElements[activeMenu].lineData : undefined }, { children: activeMenu ? sideMenuElements[activeMenu].children : undefined }))] }));
};
export default LoanOutlet;
