import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Empty, Space, Table, Tag, Typography } from "antd";
import styled from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { DataCardItem } from "@ui/data-display/DataCard";
import { Routes } from "src/Routes";
import ContactPerson from "src/components/ContactPerson";
import { DataCard, DataCardDivider } from "src/components/DataCard";
import TimePassed from "src/components/ui/TimePassed";
import ApplicationStatus from "src/components/ui/statuses/ApplicationStatus";
import { usePartner } from "src/layouts/PartnerLayout/PartnerTypes";
import { Axios } from "src/utils/Axios";
import UsersMenu from "./UsersMenu";
import { getPartnerAddress } from "./helpers";
const Wrapper = styled.div `
	margin: 24px;
	display: grid;
	gap: 24px;
	grid-template-rows: auto 1fr;
	grid-template-columns: minmax(460px, 1464px);
`;
const InnerWrapper = styled.div `
	display: grid;
	column-gap: 24px;
	grid-template-columns: repeat(auto-fit, minmax(460px, auto));
`;
const FullWidthCard = styled(DataCard) `
	grid-column-start: 1;
	grid-column-end: 3;
	max-width: none;
`;
const PartnerOverview = () => {
    var _a, _b, _c, _d;
    const { t } = useTranslation();
    const { partner } = usePartner();
    const { currency } = useFormatter();
    const [partnerApplications, setPartnerApplications] = useState();
    const [loadingApplications, setLoadingApplications] = useState(false);
    const [partnerUsers, setPartnerUsers] = useState();
    const [loadingUsers, setLoadingUsers] = useState(true);
    const getUsers = useCallback(() => {
        setLoadingUsers(true);
        Axios.get(`/admin/partners/${partner.partner_id}/pusers`, { page_size: 3, page: 1 })
            .then(({ data }) => {
            setPartnerUsers(data);
        })
            .catch((err) => void Axios.error(err))
            .finally(() => setLoadingUsers(false));
    }, [partner.partner_id]);
    useEffect(getUsers, [getUsers]);
    useEffect(() => {
        setLoadingApplications(true);
        Axios.get(`/admin/partners/${partner.partner_id}/applications`, { page_size: 3, page: 1 })
            .then(({ data }) => {
            setPartnerApplications(data);
        })
            .catch((err) => void Axios.error(err))
            .finally(() => setLoadingApplications(false));
    }, [partner]);
    const applicationsTotalCount = ((_a = partnerApplications === null || partnerApplications === void 0 ? void 0 : partnerApplications.pagination) === null || _a === void 0 ? void 0 : _a.total_rows)
        ? Number(partnerApplications.pagination.total_rows)
        : 0;
    const usersTotalCount = ((_b = partnerUsers === null || partnerUsers === void 0 ? void 0 : partnerUsers.pagination) === null || _b === void 0 ? void 0 : _b.total_rows)
        ? Number(partnerUsers.pagination.total_rows)
        : 0;
    const columns = [
        {
            dataIndex: "company_name",
            title: t("application.company"),
        },
        {
            title: t("application.amount"),
            render(a) {
                return a.amount ? currency(a.amount, { currency: a.currency }) : "-";
            },
        },
        {
            dataIndex: "status",
            title: t("application.state"),
            width: 70,
            render: (item) => {
                return (_jsx(ApplicationStatus, { closable: false, label: t(`application.status.${item}`), value: item, disabled: false }));
            },
        },
        {
            dataIndex: "duration",
            title: t("application.duration"),
            render(duration) {
                return `${duration} ${t("common.months")}`;
            },
        },
        {
            dataIndex: ["status_owner_name"],
            title: t("application.assignee"),
            render(status_owner_name) {
                return status_owner_name || "-";
            },
        },
        {
            dataIndex: "created_at",
            title: t("application.created"),
            render(created) {
                return _jsx(TimePassed, { date: created });
            },
        },
    ];
    const columnsUsers = [
        {
            dataIndex: "email",
            title: t("partner.overview.users.email"),
        },
        {
            dataIndex: "is_email_confirmed",
            title: t("partner.overview.users.email_verified"),
            render: (is_email_confirmed) => {
                return (_jsx(Tag, Object.assign({ color: is_email_confirmed ? "default" : "red" }, { children: is_email_confirmed
                        ? t("partner.overview.users.email.verified")
                        : t("partner.overview.users.email.not_verified") })));
            },
        },
        {
            dataIndex: "created_at",
            title: t("partner.overview.users.email"),
            render(created) {
                return _jsx(TimePassed, { date: created });
            },
        },
        {
            title: "",
            dataIndex: "entity_id",
            key: "operation",
            width: 20,
            render(entity_id, record) {
                return (_jsx(Dropdown, Object.assign({ dropdownRender: () => _jsx(UsersMenu, { user: record, getUsers: getUsers }), placement: "bottomLeft" }, { children: _jsx(MoreOutlined, { onClick: (evt) => {
                            evt.stopPropagation();
                            evt.preventDefault();
                        } }) })));
            },
        },
    ];
    return (_jsx(Wrapper, { children: _jsxs(InnerWrapper, { children: [_jsxs(DataCard, Object.assign({ title: t("partner.overview.details"), extra: _jsx(Link, Object.assign({ to: generatePath(Routes.PartnerDetails, {
                            partnerId: partner.partner_id,
                        }) }, { children: t("partner.overview.view_full_details") })) }, { children: [_jsx(DataCardItem, { label: t("partner.overview.name"), value: _jsx("strong", { children: partner.name }) }), _jsx(DataCardItem, { label: t("partner.overview.type"), value: partner.type }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { copyable: true, label: t("partner.overview.website"), value: partner.website }), _jsx(DataCardItem, { copyable: true, label: t("partner.overview.email"), value: partner.email }), _jsx(DataCardItem, { copyable: true, label: t("partner.overview.telephone"), value: partner.phone }), _jsx(DataCardItem, { label: t("partner.overview.address"), value: getPartnerAddress(partner.address) }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { copyable: true, label: t("partner.overview.partner_id"), value: partner.short_id }), partner.crm_link && (_jsx(DataCardItem, { style: { alignItems: "center" }, label: t("partner.details.partner_external_id"), value: _jsx(Button, Object.assign({ type: "link", onClick: () => window.open(partner.crm_link, "_blank"), style: { padding: 0 } }, { children: t("partner.open_in_zoho") })) }))] })), _jsx(DataCard, Object.assign({ title: t("partner.overview.contact_persons"), extra: _jsx(Link, Object.assign({ to: generatePath(Routes.PartnerContactPersons, {
                            partnerId: partner.partner_id,
                        }) }, { children: t("common.edit") })) }, { children: ((_c = partner.contact_persons) === null || _c === void 0 ? void 0 : _c.length) ? (_jsx(Space, Object.assign({ direction: "vertical", size: "large" }, { children: partner.contact_persons.map((person) => (_jsx(ContactPerson, { person: person }, person.partner_contact_person_id))) }))) : (_jsx(Empty, {})) })), _jsx(FullWidthCard, Object.assign({ title: t("partner.overview.applications", {
                        count: applicationsTotalCount,
                    }), extra: _jsx(Link, Object.assign({ to: `${generatePath(Routes.Applications)}?partner_id=${partner.partner_id}` }, { children: t("partner.overview.view_all") })) }, { children: _jsxs(Space, Object.assign({ direction: "vertical", style: { width: "100%" } }, { children: [_jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: _jsx(Trans, Object.assign({ i18nKey: "partner.overview.applications_count", count: (partnerApplications === null || partnerApplications === void 0 ? void 0 : partnerApplications.result.length) || 0 }, { children: {
                                        total: applicationsTotalCount,
                                    } })) })), _jsx(Table, { loading: loadingApplications, columns: columns, rowKey: "application_id", pagination: false, dataSource: (partnerApplications === null || partnerApplications === void 0 ? void 0 : partnerApplications.result) || [] })] })) })), _jsx(FullWidthCard, Object.assign({ title: t("partner.overview.users", {
                        count: usersTotalCount,
                    }), extra: _jsx(Link, Object.assign({ to: generatePath(Routes.PartnerTeam, { partnerId: partner.partner_id }) }, { children: t("partner.overview.view_all") })) }, { children: _jsxs(Space, Object.assign({ direction: "vertical", style: { width: "100%" } }, { children: [_jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: _jsx(Trans, Object.assign({ i18nKey: "partner.overview.users_count", count: ((_d = partnerUsers === null || partnerUsers === void 0 ? void 0 : partnerUsers.result) === null || _d === void 0 ? void 0 : _d.length) || 0 }, { children: {
                                        total: usersTotalCount,
                                    } })) })), _jsx(Table, { loading: loadingUsers, columns: columnsUsers, rowKey: "entity_id", pagination: false, dataSource: (partnerUsers === null || partnerUsers === void 0 ? void 0 : partnerUsers.result) || [] })] })) }))] }) }));
};
export default PartnerOverview;
