import { CountryCode, FeatureName, } from "@teylor-tools/Api";
import { getCountries } from "@teylor-tools/utils/getCountries";
import { ConfigActions } from "src/store/actions/configActions";
const defaultState = {
    features: [],
    countries: [],
    documentCategories: [],
    tasksEnabled: false,
    defaultCurrency: undefined,
};
const getPreferredCountries = (features) => {
    const feature = features.find((f) => f.name === FeatureName.ApplicationCountries);
    return (feature === null || feature === void 0 ? void 0 : feature.value.preferred)
        ? feature.value.preferred.map((country) => CountryCode[country])
        : [];
};
const areTasksEnabled = (features) => { var _a; return !!((_a = features.find((f) => f.name === FeatureName.Tasks)) === null || _a === void 0 ? void 0 : _a.value.enabled); };
const configReducer = function (state = defaultState, action) {
    var _a;
    switch (action.type) {
        case ConfigActions.SET_FEATURES:
            return Object.assign(Object.assign({}, state), { features: action.payload.features || [], tasksEnabled: areTasksEnabled(action.payload.features), defaultCurrency: (_a = action.payload.features.find((f) => f.name === FeatureName.Currencies)) === null || _a === void 0 ? void 0 : _a.value.default });
        case ConfigActions.CLEAR_FEATURES:
            return Object.assign(Object.assign({}, state), { features: [], tasksEnabled: false });
        case ConfigActions.SET_COUNTRY_LIST:
            return Object.assign(Object.assign({}, state), { countries: getCountries({
                    preferredCountries: getPreferredCountries(action.payload.features),
                    countries: action.payload.countries,
                }) });
        case ConfigActions.SET_DOCUMENT_CATEGORIES:
            return Object.assign(Object.assign({}, state), { documentCategories: action.payload.documentCategories || [] });
    }
    return state;
};
export default configReducer;
