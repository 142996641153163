import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoCircleOutlined as AntdInfoCircleOutlined, CheckCircleOutlined, } from "@ant-design/icons";
import { Card as AntdCard, Button, Divider, Space, Spin, Typography } from "antd";
import styled from "styled-components";
import { ApplicationStatuses } from "@teylor-tools/Api";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { SideActions, useApplication, useLayout, } from "src/layouts/ApplicationLayout/application.types";
import { Axios } from "src/utils/Axios";
import isMinimumStatus from "src/utils/isMinimumStatus";
const { Text } = Typography;
const InfoCircleOutlined = styled(AntdInfoCircleOutlined) `
	font-size: 56px;
	color: #d9d9d9;
	margin-bottom: 34px;
`;
const Wrapper = styled.div `
	margin: 92px 24px 24px;
`;
const Card = styled(AntdCard) `
	max-width: 720px;
	min-width: 420px;
`;
const GroupWrapper = styled.div `
	display: flex;
`;
const GroupHeader = styled.div `
	flex: 0 0 140px;
`;
const CheckItem = styled.div `
	display: flex;
	gap: 8px;
	margin-bottom: 8px;
	align-items: flex-start;
`;
const CheckIcon = styled(CheckCircleOutlined) `
	color: ${({ theme }) => theme.colorSuccess};
	margin-top: 3px;
`;
const getChecklist = (url) => {
    return Axios.get(url).then(({ data }) => Promise.resolve(data), (err) => Promise.reject(err));
};
const FinalReviewChecklist = () => {
    const { t } = useTranslation();
    const { application: { status_flow, applicationId }, } = useApplication();
    const showCompletedChecklist = useMemo(() => isMinimumStatus(ApplicationStatuses.AwaitingPayout, status_flow), [status_flow]);
    if (showCompletedChecklist) {
        return (_jsx(CompletedChecklist, { title: t("application.final_review.title"), getChecklist: () => getChecklist(`/admin/applications/${applicationId}/four_eye_checklist`) }));
    }
    return (_jsx(EmptyChecklist, { title: t("application.final_review.title"), text: t("application.final_review.complete-the-final-review"), subtext: t("application.final_review.click-the-button-below"), btnText: t("application.final_review.open-final-review-panel"), sideAction: SideActions.finalReviewChecklist }));
};
const PayoutChecklist = () => {
    const { t } = useTranslation();
    const { application: { status_flow, applicationId }, } = useApplication();
    const showCompletedChecklist = isMinimumStatus(ApplicationStatuses.PaidOut, status_flow);
    if (showCompletedChecklist) {
        return (_jsx(CompletedChecklist, { title: t("application.payout_checklist.title"), getChecklist: () => getChecklist(`/admin/applications/${applicationId}/payout_checklist`) }));
    }
    return (_jsx(EmptyChecklist, { title: t("application.payout_checklist.title"), text: t("application.payout_checklist.complete-the-final-review"), subtext: t("application.payout_checklist.click-the-button-below"), btnText: t("application.payout_checklist.open-final-review-panel"), sideAction: SideActions.payoutChecklist }));
};
const CompletedChecklist = ({ title, getChecklist }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [checklistGroups, setChecklistGroups] = useState([]);
    useEffect(() => {
        setLoading(true);
        getChecklist()
            .then((checklist) => { var _a; return setChecklistGroups(((_a = checklist.checklist_data) === null || _a === void 0 ? void 0 : _a.groups) || []); }, (err) => Axios.error(err, t("common.request-failed")))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getChecklist]);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: title }), _jsx(Spin, Object.assign({ spinning: loading }, { children: checklistGroups.length && (_jsx(Wrapper, { children: _jsx(Card, { children: checklistGroups.map((group, i) => (_jsxs(_Fragment, { children: [_jsxs(GroupWrapper, { children: [_jsx(GroupHeader, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: group.description })) }), _jsx("div", { children: group.items.map((item) => {
                                                switch (item.item_type) {
                                                    case "checkbox":
                                                        return (_jsxs(CheckItem, { children: [_jsx(CheckIcon, {}), _jsx(Text, { children: item.options[0].description })] }));
                                                    case "text":
                                                        return _jsx(Text, { children: item.text });
                                                }
                                            }) })] }), i < checklistGroups.length - 1 && _jsx(Divider, {})] }))) }) })) }))] }));
};
const EmptyChecklist = ({ title, text, subtext, btnText, sideAction }) => {
    const { setActiveMenu } = useLayout();
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: title }), _jsx(Wrapper, { children: _jsxs(Space, Object.assign({ direction: "vertical", style: { textAlign: "center", width: "100%" } }, { children: [_jsx(InfoCircleOutlined, {}), _jsx(Typography.Title, Object.assign({ level: 5 }, { children: text })), _jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: subtext })), _jsx(Button, Object.assign({ type: "primary", style: { marginTop: 24 }, onClick: () => setActiveMenu(sideAction), size: "large" }, { children: btnText }))] })) })] }));
};
export { FinalReviewChecklist, PayoutChecklist };
