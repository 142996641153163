import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { BorderlessTableOutlined, CalendarOutlined, ClockCircleOutlined, CloseOutlined, PercentageOutlined, ShoppingOutlined, } from "@ant-design/icons";
import { Button, Col, Row, Space, Spin, Typography } from "antd";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import Preview, { Cell } from "@ui/preview/Preview";
import { Routes } from "src/Routes";
import RepresentativeList from "src/components/RepresentativeList";
import TimePassed from "src/components/ui/TimePassed";
import LoanStatus from "src/components/ui/statuses/LoanStatus";
import { Axios } from "src/utils/Axios";
const LoanPreview = ({ loan, onClose }) => {
    const [signatoriesLoading, setSignatoriesLoading] = useState(true);
    const [signatories, setSignatories] = useState([]);
    const { currency, decimalToPercent } = useFormatter();
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        if (loan === null || loan === void 0 ? void 0 : loan.loan_id) {
            setSignatoriesLoading(true);
            Axios.get(`/admin/loans/${loan.loan_id}/signatories`)
                .then(({ data }) => {
                setSignatories(data.signatories);
            }, (err) => Axios.error(err))
                .finally(() => {
                setSignatoriesLoading(false);
            });
        }
    }, [loan === null || loan === void 0 ? void 0 : loan.loan_id]);
    const lineData = useMemo(() => {
        var _a;
        if (!loan)
            return [];
        return [
            {
                icon: _jsx(ClockCircleOutlined, { size: 12 }),
                label: t("loans.duration"),
                value: `${loan.duration_months}M`,
            },
            {
                icon: _jsx(PercentageOutlined, {}),
                label: t("loans.interest-rate"),
                value: loan.interest_rate ? decimalToPercent(loan.interest_rate, 2, true) : "-",
            },
            {
                icon: _jsx(ShoppingOutlined, {}),
                label: t("application.product-name"),
                value: ((_a = loan.product) === null || _a === void 0 ? void 0 : _a.product_name) || "",
            },
            {
                icon: _jsx(CalendarOutlined, {}),
                label: t("application.created"),
                value: _jsx(TimePassed, { date: loan.created_at }),
            },
            {
                icon: _jsx(BorderlessTableOutlined, {}),
                label: t("common.id"),
                value: loan.loan_id,
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loan, t]);
    return (_jsx(Preview, Object.assign({ entityId: (loan === null || loan === void 0 ? void 0 : loan.loan_id) || "", visible: !!loan, header: loan && (_jsxs(Row, Object.assign({ style: { width: "100%" }, align: "middle", justify: "space-between" }, { children: [_jsxs(Col, { children: [_jsx(LoanStatus, { label: t(`loans.status.${loan.loan_status}`), value: loan.loan_status, closable: false, disabled: false }), _jsx(Typography.Title, Object.assign({ level: 5, style: { marginTop: 8 } }, { children: loan.company.companyName })), _jsx(Typography.Text, Object.assign({ strong: true, style: { fontSize: 16 } }, { children: currency(loan.amount, { currency: loan.currency }) }))] }), _jsx(Col, { children: _jsxs(Space, { children: [_jsx(Button, Object.assign({ type: "primary", onClick: () => navigate(generatePath(Routes.Loan, {
                                    loanId: loan.loan_id,
                                })) }, { children: t("common.view") })), _jsx(Button, { onClick: onClose, icon: _jsx(CloseOutlined, {}) })] }) })] }))), lineData: lineData }, { children: _jsx(_Fragment, { children: _jsx(Cell, { children: _jsx(Spin, Object.assign({ spinning: signatoriesLoading }, { children: _jsx(RepresentativeList, { representatives: signatories }) })) }) }) })));
};
export default LoanPreview;
