var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState, } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Button, Col, Form, Row, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import Table from "antd/lib/table";
import styled from "styled-components";
import useDebounce from "@teylor-tools/hooks/debounce";
import EmptyData from "src/components/ui/EmptyData";
import { Axios } from "src/utils/Axios";
const CountText = styled(Typography.Title) `
	margin: 5px 0;
	font-weight: 400;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.45);
`;
export const DIRECT_OPEN_COLUMN = "direct-open-column";
const StyledTable = styled(Table) `
	.ant-table-title {
		padding-left: 0;
		padding-right: 0;
	}

	.ant-col {
		margin: 0 4px;

		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}

	.ant-pagination-item-active {
		border-color: ${({ theme }) => theme.colorPrimary};
		a {
			color: ${({ theme }) => theme.colorPrimary};
		}
	}

	.${DIRECT_OPEN_COLUMN} {
		padding: 0 !important;
		width: 38px;
	}
`;
const DEFAULT_PAGE = "1";
const DEFAULT_PAGE_SIZE = "20";
// eslint-disable-next-line
const filterObjectValues = (obj) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => !!v && (Array.isArray(v) ? v.length : true)));
const SearchList = forwardRef((_a, ref) => {
    var { children, columns, endpoint, showingOfString } = _a, props = __rest(_a, ["children", "columns", "endpoint", "showingOfString"]);
    const { t } = useTranslation();
    const [form] = useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [totalRows, setTotalRows] = useState();
    const firstRender = useRef(true);
    const [formValues, setFormValues] = useState();
    const debouncedFormValues = useDebounce(formValues, 500);
    const searchParamsValues = useMemo(() => {
        const values = Object.fromEntries(searchParams);
        return Object.assign(Object.assign({}, values), { page: values.page || DEFAULT_PAGE, page_size: values.page_size || DEFAULT_PAGE_SIZE });
    }, [searchParams]);
    const handleFilterChange = () => {
        setFormValues(Object.assign({}, form.getFieldsValue()));
    };
    const handleTableChange = (pagination, filters, sort) => {
        var _a, _b, _c, _d, _e, _f;
        // reset page to 1 if page size was changed
        const page = ((_a = pagination.pageSize) === null || _a === void 0 ? void 0 : _a.toString()) !== searchParamsValues.page_size
            ? DEFAULT_PAGE
            : (_b = pagination.current) === null || _b === void 0 ? void 0 : _b.toString();
        updateSearchParams(Object.assign(Object.assign({}, searchParamsValues), { page, page_size: (_c = pagination.pageSize) === null || _c === void 0 ? void 0 : _c.toString(), sort_by: (_e = (_d = sort.column) === null || _d === void 0 ? void 0 : _d.dataIndex) === null || _e === void 0 ? void 0 : _e.toString(), sort_order: (_f = sort.order) === null || _f === void 0 ? void 0 : _f.toString() }));
    };
    const getTableData = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const decodedFilter = new URLSearchParams(filterObjectValues(searchParamsValues));
        return Axios.get(`${endpoint}?${decodedFilter}`)
            .then(({ data }) => {
            setTableData(data.result || []);
            setTotalRows(data.pagination.total_rows);
            return data.result;
        })
            .finally(() => setLoading(false));
    });
    useImperativeHandle(ref, () => ({
        getTableData,
    }));
    const updateSearchParams = (v) => {
        const filteredConfig = filterObjectValues(v);
        filteredConfig.page === DEFAULT_PAGE && delete filteredConfig.page;
        filteredConfig.page_size === DEFAULT_PAGE_SIZE && delete filteredConfig.page_size;
        setSearchParams(new URLSearchParams(filteredConfig).toString());
    };
    const updateFormValues = () => {
        const paramsArray = Object.entries(searchParamsValues);
        if (!paramsArray.length) {
            return form.resetFields();
        }
        const values = paramsArray.reduce((accumulate, [key, value]) => {
            const val = value;
            if (val === null || val === void 0 ? void 0 : val.includes(",")) {
                accumulate[key] = [...val.split(",")];
            }
            else {
                // <Select mode="multiple" /> requires an array
                if (key === "status") {
                    accumulate[key] = [val];
                }
                else {
                    accumulate[key] = val;
                }
            }
            return accumulate;
        }, {});
        form.setFieldsValue(values);
    };
    const showClearBtn = useMemo(() => {
        return [
            searchParamsValues.text,
            searchParamsValues.status,
            searchParamsValues.partner_id,
            searchParamsValues.product_id,
            searchParamsValues.assignee_id,
            searchParamsValues.reporter_id,
            searchParamsValues.priority,
            searchParamsValues.is_eligible,
        ].some((filter) => filter && filter !== "");
    }, [searchParamsValues]);
    useEffect(() => {
        if (firstRender.current)
            return;
        updateSearchParams(Object.assign(Object.assign(Object.assign({}, searchParamsValues), formValues), { page: DEFAULT_PAGE }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedFormValues]);
    useEffect(() => {
        updateFormValues();
        void getTableData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);
    useEffect(() => {
        firstRender.current = false;
    }, []);
    return (_jsxs("div", Object.assign({ style: { padding: "16px 24px" } }, { children: [children && (_jsx(Form, Object.assign({ form: form, onValuesChange: handleFilterChange }, { children: _jsxs(Row, Object.assign({ gutter: 8 }, { children: [children, _jsx(Col, Object.assign({ span: 2 }, { children: showClearBtn && (_jsx(Button, Object.assign({ type: "text", style: { color: "rgba(0,0,0,.45)" }, onClick: () => {
                                    form.resetFields();
                                    handleFilterChange();
                                } }, { children: t("common.clear") }))) }))] })) }))), _jsx(CountText, Object.assign({ level: 5, style: { height: 24 } }, { children: !loading && (_jsx(_Fragment, { children: _jsxs(Trans, Object.assign({ i18nKey: showingOfString || "common.search.search-showing" }, { children: [{ items: tableData.length }, " ", { of: totalRows }] })) })) })), _jsx(StyledTable, Object.assign({ loading: loading, columns: columns, onChange: (pagination, filter, sorter) => handleTableChange(pagination, filter, sorter), dataSource: tableData, pagination: {
                    pageSize: Number(searchParamsValues.page_size || DEFAULT_PAGE_SIZE),
                    total: totalRows,
                    current: Number(searchParamsValues.page || DEFAULT_PAGE),
                    position: ["bottomRight"],
                    showSizeChanger: true,
                }, scroll: { x: "100%" }, locale: { emptyText: _jsx(EmptyData, {}) } }, props))] })));
});
SearchList.displayName = "SearchList";
export default SearchList;
